import React, { Component } from "react";
import Icon from "../../global/Icon/Icon";
import fetchAPI from "../../../utils/fetchAPI";
import { getVarConfig } from "../../../utils/environment";
import { getUser } from "../../../utils/sessionInfo";
import { trigger } from "../../../utils/events";

import "./MazTuInfoBeesHeader.scss";

export default class MazTuInfoBeesHeader extends Component {

  handleBackNavigation = () => {
    if (
      this.props.path === "/desafios" ||
      this.props.path === "/historial" ||
      this.props.path === "/resultados" ||
      this.props.path === "/registro" ||
      this.props.path === "/facturacion" ||
      this.props.path === "/cobertura" ||
      this.props.path === "/registro/exito" ||
      this.props.path === "/contrato" ||
      this.props.path === "/programa-mercado"
    ) {
      if(this.props.path === "/registro" || this.props.path === "/registro/exito") {
        this.props.setPath("/");
      } else {
        if(this.props.path === "/resultados") {
          this.setResultadosLastSeen();
        } else {
          if(this.props.path === "/programa-mercado") {
            trigger("click-back-navigation-programa-mercado");
          } else {
            this.props.setPath("/dashboard");
          }
        }
      }
    } else if (this.props.needBackButton){
      console.log("Path previo: " + this.props.previousPath);
    }
  }

  setResultadosLastSeen = () => {
    this.props.setLoaderOpen(true);
    let date = new Date();
    const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
    const dateSeen = (month + 1) + "/" + day + "/" + year;
    const user = getUser();
    let urlService = getVarConfig({ key: "REACT_APP_SERVICES_POST_RESULT_SEEN" });
    urlService = urlService.replace("{cliente}", user.ID);
    fetchAPI({
      service: urlService,
      protocol: "POST",
      params: {
        resultsSeen: dateSeen,
      },
      callbackThen: (response) => {
        this.props.setLoaderOpen(false);
        this.props.setPath("/dashboard");
      },
      callbackError: (status) => {
        this.props.setLoaderOpen(false);
        this.props.setPath("/dashboard");
      },
    });
  }

  render() {

    const userInfo = getUser();
    let name, ID;
    if(userInfo !== null) {
      name = typeof userInfo.name !== "undefined" ? userInfo.name : "";
      ID = typeof userInfo.ID !== "undefined" ? (this.props.user.isFinanceUser ? (userInfo.ID.charAt(0) == 1 ? "Matriz - " : `Sucursal ${userInfo.sucursal} - `):"") + userInfo.ID : "";
    } else {
      name = "";
      ID = "";
    }


    return (
      <div className="maz-tu-info-bees-header">
        <div className="container-fluid px-0 flex-wrap">
          <div className="container d-flex justify-content-between align-items-center flex-wrap px-0">
        {this.props.path === "/desafios" ||
        this.props.path === "/registro" ||
        this.props.path === "/facturacion" ||
        this.props.path === "/cobertura" ||
        this.props.path === "/historial" ||
        this.props.path === "/resultados" ||
        this.props.path === "/registro/exito" ||
        this.props.path === "/contrato" ||
        this.props.path === "/programa-mercado" ||
        this.props.needBackButton ? (
          <div
            className="maz-tu-info-bees-header__back-navigation"
            onClick={this.props.needBackButton? this.props.backButtonAction : this.handleBackNavigation}
          >
            <Icon icon={"back-navigation"} size="30" />
            <span>Atrás</span>
          </div>
          ) : ("")}

        <div className="maz-tu-info-bees-header__logo ms-3"></div>

          <div className={`maz-tu-info-bees-header__user-info ${this.props.user.isFinanceUser ? "col-12 col-lg-auto": ""}`}>
          <div className="maz-tu-info-bees-header__user-name">
            <span><b>{name}</b></span>
          </div>
          <div className="maz-tu-info-bees-header__user-id">
            {ID}
          </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
