import React, { Component } from "react";

import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import fetchAPI from "./../../utils/fetchAPI";
import { getVarConfig } from "./../../utils/environment";
import { getUser } from "../../utils/sessionInfo";

import "./Resultados.scss";

export default class Resultados extends Component {

  state = {
    meta1: null,
    meta2: null,
    meta3: null,
    meta1points: 0,
    meta2points: 0,
    meta3points: 0,
  }

  componentDidMount() {
    this.getResults();
  }

  getResults = () => {

    const user = getUser();
    let urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_HISTORY" });
    urlService = urlService + user.ID;

    fetchAPI({
      service: urlService,
      protocol: "GET",
      callbackThen: (response) => {

        let meta1 = false, meta2 = false, meta3 = false, meta1points = 0, meta2points = 0, meta3points = 0;

        const history = response.data;

        if(history.length > 0) {
          for(var i in history) {
            
            //Ger current month
            const date = new Date();
            const currentMonth = date.getMonth() + 1;

            const pastMonth = currentMonth > 1 ? currentMonth - 1 : 12;

            // If register is from last month only
            const dateRegister = history[i].welcomePointsDate;
            let dateString = dateRegister.split("T");
            dateString = dateString[0].split("-");
            const dataDateMonth = parseInt(dateString[1]);
            
            if(pastMonth === dataDateMonth) {

              switch(history[i].challengeType) {
                case "total":
                  meta1points = meta1points + history[i].pointsToPay;
                  meta1 = true;
                  break;
                case "premium":
                  meta2points = meta2points + history[i].pointsToPay;
                  meta2 = true;
                  break;
                case "coverage":
                  meta3points = meta3points + history[i].pointsToPay;
                  meta3 = true;
                  break;  
                default:
                  console.log('default');
              }

            }
            
          }
        }

        this.setState({
          meta1,
          meta2,
          meta3,
          meta1points,
          meta2points,
          meta3points,
        });

      },
      callbackError: (status) => {
        console.log(status);
      },
    });

  }

  render() {

    const user = getUser();

    let classClub;

    switch(user.clubLevel) {
      case 'CLUB B BLACK':
        classClub = ' black';
        break;
      case 'CLUB B BLACK ZONAS':
        classClub = ' black-zonas';
        break;
      case 'CLUB B BLACK PREMIUM':
        classClub = ' black-premium';
        break;
      case 'CLUB B BLACK MIXTO':
        classClub = ' mixto';
        break;
      case 'CLUB B BLACK PREMIUM MIXTO':
        classClub = ' mixto-premium';
        break;
      case 'B_MAX':
        classClub = ' b-max';
        break;
      case 'B_BLACK':
        classClub = ' b-black';
        break;
      default:
        console.log('default');
    }

    const meta1 = this.state.meta1;
    const meta2 = this.state.meta2;
    const meta3 = this.state.meta3;

    const title = (meta1 && meta2 && meta3) 
      ? "¡Felicitaciones!"
      : (!meta1 && !meta2 && !meta3)
        ? "Tus metas no se completaron" 
        : "¡Estuviste cerca";
    const subtitle = (meta1 && meta2 && meta3) 
      ? "Lograste llegar a todas tu metas este mes"
      : (!meta1 && !meta2 && !meta3)
        ? "Recuerda seguir las recomendaciones" 
        : "Entre más metas logres más puntos ganas";

    const textMeta1 = meta1 !== null ? meta1 ? "¡Ganaste!" : "¡Casi lo logras!" : "Cargando...";
    const textMeta2 = meta2 !== null ? meta2 ? "¡Ganaste!" : "¡Casi lo logras!" : "Cargando...";
    const textMeta3 = meta3 !== null ? meta3 ? "¡Ganaste!" : "¡Casi lo logras!" : "Cargando...";

    const classMeta1 = meta1 !== null ? meta1 ? "success" : "error" : "standby";
    const classMeta2 = meta2 !== null ? meta2 ? "success" : "error" : "standby";
    const classMeta3 = meta3 !== null ? meta3 ? "success" : "error" : "standby";

    let date = new Date();
    const [weekDay] = [date.getDay()];
    let backDays = weekDay === 1 ? 3 : weekDay === 0 ? 2 : 1;
    date.setDate(date.getDate() - backDays);
    const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

    let dateUpdate = day + "/" + (month + 1) + "/" + year;

    return (
      <div className="resultados">

        <MazTuInfoBeesTitle 
          title="Resultados" 
          subtitle="Detalle resultados por franja" 
        />

        <div className="resultados__card">

          <span className={`resultados__card__logo` + classClub}></span>

          <div className="resultados__card__title">
            {title}
          </div>
          
          <div className="resultados__card__subtitle">
            {subtitle}
          </div>

          <div className="resultados__card__performance">

            <div className="resultados__card__performance__hex">

              <div className="resultados__card__performance__hex__line">
                <div className="resultados__card__performance__hex__line__subtitle">
                  Premio
                </div>
                <div className="resultados__card__performance__hex__line__points">
                  {this.state.meta1points.toLocaleString()}
                </div>
                <div className="resultados__card__performance__hex__line__label">
                  Puntos
                </div>
              </div>

              <div className="resultados__card__performance__hex__line">
                <div className="resultados__card__performance__hex__line__subtitle">
                  Premio
                </div>
                <div className="resultados__card__performance__hex__line__points">
                  {this.state.meta2points.toLocaleString()}  
                </div>
                <div className="resultados__card__performance__hex__line__label">
                  Puntos
                </div>
              </div>

              <div className="resultados__card__performance__hex__line">
                <div className="resultados__card__performance__hex__line__subtitle">
                  Premio
                </div>
                <div className="resultados__card__performance__hex__line__points">
                  {this.state.meta3points.toLocaleString()}
                </div>
                <div className="resultados__card__performance__hex__line__label">
                  Puntos
                </div>
              </div>

            </div>

            <div className="resultados__card__performance__bars">
              <div className={`resultados__card__performance__bars__bar ` + classMeta1}>
                {textMeta1}
              </div>
              <div className={`resultados__card__performance__bars__bar ` + classMeta2}>
                {textMeta2}
              </div>
              <div className={`resultados__card__performance__bars__bar ` + classMeta3}>
                {textMeta3}
              </div>
            </div>

          </div>

          <div className="resultados__card__info">
            *Información actualizada el {dateUpdate}
          </div>

        </div>
        
      </div>
    );
  }
}
