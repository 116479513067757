import React, { useState } from 'react';
import AccountRow from './AccountRow';
import ButtonsNav from './ButtonsNav';
import FilterButtons from './FilterButtons';
import styles from './styles.module.scss';
import {
  cashpaymenttypes, payment_doc_types,
  bills_doc_types, financeOpenItemsNavOptions,
  financeOrderByOptions, financeOrderWay,
} from '../../../utils/utils'

let showedList;
let fiterByParamsList;

const AccountDetails = props => {
  const {
    data,
    onClickRow,
    sendGA,
    setBackButton,
    setPath,
    needBackButton
  } = props;


  const [listFiltered, setListFiltered] = useState(data);
  const [sortField, setSortField] = useState(financeOrderByOptions[0]);
  const [activeFilter, setActiveFilter] = useState(financeOpenItemsNavOptions[0]);
  const [actualOrderWay, setOrderWay] = useState(financeOrderWay[0]);
  /*   const [showedList, setShowedList] = useState(data); */

  //if (!data || !data?.length) return null;

  const setShowedList = (list) => {
    showedList = list;
  }

  const filterByType = (filter_name) => {
    props.sendGA('GAEvent', 'Content', 'Content', 'Filter', true, 'filter_by_button', 'Filtrar');
    if (!showedList) {
      setShowedList(data);
    }
    switch (filter_name.activeNav) {
      case financeOpenItemsNavOptions[0]:
        setShowedList(data);
        break;
      case financeOpenItemsNavOptions[1]:
        setShowedList(data.filter(obj => {
          return (bills_doc_types.includes(obj.doc_type) && cashpaymenttypes.includes(obj.zterm))
        }));
        break;
      case financeOpenItemsNavOptions[2]:
        setShowedList(data.filter(obj => {
          return (bills_doc_types.includes(obj.doc_type) && !cashpaymenttypes.includes(obj.zterm))
        }));
        break;
      case financeOpenItemsNavOptions[3]:
        setShowedList(data.filter(obj => {
          return payment_doc_types.includes(obj.doc_type);
        }));
        break;
      default:
        setShowedList(data);
    }
    setListFiltered(showedList);
    orderByField({ option: sortField });
  }

  const orderByField = field => {

    var title = 'Order by ';
    switch (field.option.toString().toLowerCase()) {
      case "monto":
        title += "amount";
        break;
      case "fecha de creación":
        title += "creation date";
        break;
      case "Fecha de vencimiento":
        title += 'expiration date';
        break;
      default:
        title += field.option.toString().toLowerCase();
        break
    }

    props.sendGA('GAEvent', 'Content', 'Content', title, true, 'order_by_button', 'Ordenar por');
    if (!listFiltered) {
      setListFiltered(showedList ? showedList : data);
    }
    setSortField(field.option);
    switch (actualOrderWay) {
      case financeOrderWay[0]:
        switch (field.option) {
          case financeOrderByOptions[0]:
            listFiltered.sort((a, b) => (parseFloat(a.amt_doccur) > parseFloat(b.amt_doccur)) ? 1 : -1);
            break;
          case financeOrderByOptions[1]:
            listFiltered.sort((a, b) => (new Date(a.creation_date) > new Date(b.creation_date)) ? 1 : -1);
            break;
          case financeOrderByOptions[2]:
            listFiltered.sort((a, b) => (new Date(a.net_due_date) > new Date(b.net_due_date)) ? 1 : -1);
            break;
          default:
            break;
        }
        break;
      case financeOrderWay[1]:
        switch (field.option) {
          case financeOrderByOptions[0]:
            listFiltered.sort((a, b) => (parseFloat(a.amt_doccur) < parseFloat(b.amt_doccur)) ? 1 : -1);
            break;
          case financeOrderByOptions[1]:
            listFiltered.sort((a, b) => (new Date(a.creation_date) < new Date(b.creation_date)) ? 1 : -1);
            break;
          case financeOrderByOptions[2]:
            listFiltered.sort((a, b) => (new Date(a.net_due_date) < new Date(b.net_due_date)) ? 1 : -1);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    //setListFiltered(listFiltered);
  }

  const changeWay = way => {
    setOrderWay(way);
    orderByField({ option: sortField });
  }

  const filterByParams = params => {
    if (!showedList) {
      setShowedList(data);
    }
    const minammount = params.filters.sliderData.currentMin;
    const maxammount = params.filters.sliderData.currentMax;
    const mindate = params.filters.dates.start;
    const maxdate = params.filters.dates.end;

    const sucursales = params.filters.sucursalesSelected;

    var list = showedList.filter(obj => {
      if (sucursales.length >= 1) {        
        if (obj?.order_data?.CustomerInfo.customer_no) {
          return (
            minammount <= parseFloat(obj.amt_doccur) && parseFloat(obj.amt_doccur) <= maxammount &&
            mindate.setHours(0, 0, 0, 0) <= new Date(obj.creation_date) && new Date(obj.creation_date) <= maxdate.setHours(23, 59, 59, 999) &&
            sucursales.includes(obj.order_data.CustomerInfo.customer_no.trim())
          )
        }
      }
      else
        return (
          minammount <= parseFloat(obj.amt_doccur) && parseFloat(obj.amt_doccur) <= maxammount &&
          mindate.setHours(0, 0, 0, 0) <= new Date(obj.creation_date) && new Date(obj.creation_date) <= maxdate.setHours(23, 59, 59, 999)
        )
    });
    setListFiltered(list);
  }

  const dataFilterParams = {
    min_ammount: Math.min(...data.map(o => o.amt_doccur)),
    max_ammount: Math.max(...data.map(o => o.amt_doccur)),
    min_date: Math.min(...data.map(o => new Date(o.creation_date))),
    max_date: Math.max(...data.map(o => new Date(o.creation_date))),
  }

  const sucursales = () => {
    const sucursal = [];
    const temp = [];
    data.map(o => {
      if (o.order_data?.CustomerInfo) {
        if (temp.includes(o.order_data.CustomerInfo.customer_no.trim()) != true)
          sucursal.push({
            name: `${o.order_data.CustomerInfo.city.trim().toLowerCase()}`,
            id: `${o.order_data.CustomerInfo.customer_no.trim()}`
          });
        temp.push(o.order_data.CustomerInfo.customer_no.trim());
      }
    });
    return sucursal;
  }

  return (

    <div className="mt-2 mb-3 col-12 col-lg-8">
      <div className="card">
        <ButtonsNav
          onClick={filterByType}
          activeOption={activeFilter}
          setActiveOption={setActiveFilter}
          options={financeOpenItemsNavOptions}
        />
       
        <FilterButtons sendGA={props.sendGA} orderByField={orderByField} changeWay={changeWay} filterByParams={filterByParams} dataFilterParams={dataFilterParams} sucursales={sucursales} />

        {(listFiltered || data)?.map(bill => (
          <AccountRow
            {...bill}
            setBackButton={setBackButton}
            onClick={onClickRow}
            setPath={setPath}
            needBackButton={needBackButton}
          />
        ))}
      </div>
    </div>
  );
};

export default AccountDetails;
