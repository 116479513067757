import React, { useEffect } from 'react';

import HeaderTable from '../../ProgramsTable/HeaderTable';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';

const DetailsTable = props => {
  const {
    setLoading,
    loading,
    hide,
    list,
    isLoading,
    sortByKey,
    sortDescStates,
  } = props;

  useEffect(() => {
    if (!hide && isLoading && !loading) setLoading(true);
  }, [hide, isLoading, loading, setLoading]);

  useEffect(() => {
    if (!hide && !isLoading && loading) setLoading(false);
  }, [hide, isLoading, loading, setLoading]);

  if (hide) return null;
  if (isLoading) return <>Loading ...</>;
  if (!list?.length && !isLoading) return <>No se encontraro información</>;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeaderTable
          onClick={() => sortByKey('name')}
          title="Nombre del candidato"
          iconDesc={sortDescStates?.nameSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('email')}
          title="Correo"
          iconDesc={sortDescStates?.emailSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('phone')}
          title="Teléfono"
          iconDesc={sortDescStates?.phoneSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('error')}
          title="Error"
          iconDesc={sortDescStates?.errorSortDesc}
        />
      </div>

      <div className={styles.body}>
        {list
            .map((historyDetail, index) => {
              const {
                name,
                email,
                phone,
                error,
              } = historyDetail;
              return (
                <div
                  className={styles.row}
                  key={`${name}-${index}`}
                >
                  <div className={styles.ml10}>{name}</div>
                  <div>{email}</div>
                  <div>{phone}</div>
                  <div className={styles.fail}>{`${error}`}</div>
                </div>
              )}
        )}
      </div>
    </div>
  );
};

DetailsTable.prototype = propTypes;
DetailsTable.defaultProps = defaultProps;

export default DetailsTable;
