import React, { useState } from "react"
import Header from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header";
import ConfirmDialog from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/ProgramForm/ConfirmDialog";
import fetchAPI from "./../../../utils/fetchAPI";
import { getVarConfig } from "./../../../utils/environment";
import './EndContracts.scss'

const EndContracts = props => {
  const { loading, setLoading } = props;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [message, setMessage] = useState('');
  const [draftContracts, setDraftContracts] = useState(false);

  const updateContracts = async () => {
    setMessage("Actuallizando contratos, espere un momento...");
    const service = `${getVarConfig({ key: "END_ALL_CONTRACTS"})}${draftContracts ? '?state=draft' : ''}`;

    await fetchAPI({
      service,
      protocol: "POST",
      params: {
      },
      callbackThen: callBackInApp,
      callbackError: callBackInAppErr,
    });
  }

  const callBackInApp = (res) => {
    const qtyContractsDone = res?.data;
    const letterQtyContracts = Array.isArray(qtyContractsDone) ? `: ${qtyContractsDone.length}` : '';
    const newMessage = `Contratos Finalizados${letterQtyContracts}`;
    setMessage(newMessage);
    setLoading(false);
    console.log('done')
  }

  const callBackInAppErr = () => {
    setMessage("Se produjo un error al finalizar los contratos");
    setLoading(false);
    console.log('not done')
  }

  const handleOnConfirm = () => {
    setOpenConfirm(false);
    setLoading(true);
    updateContracts();
  }

  const handleOpenConfirm = (event, isDraft) => {
    setMessage('');
    setDraftContracts(!!isDraft);
    setOpenConfirm(true);
  }

  const onCancelDialog = () => setOpenConfirm(false);

  return(
    <div
      className="containerEndContracts"
    >
      <ConfirmDialog
        open={openConfirm}
        onCancel={onCancelDialog}
        onConfirm={handleOnConfirm}
        text={draftContracts ? 'Se cancelarán todos los contratos en borrador. ¿Deseas continuar?' : '¿Deseas finalizar todos los contratos firmados disponibles?'}
        confirmTxt="Aceptar"
      />

      <Header
        title="Actualizar contratos"
        userName="User Name"
      />
      <div
        className="containerInAppTable__body"
      >
        <div
          className="containerInAppTable__body__padding"
        >
          <div
            style={{padding: 5}}
          />

          <div className="btn__container">
            <section>
              <button
                disabled={loading}
                className="btn btn--black"
                onClick={handleOpenConfirm}
              >
                Finalizar contratos firmados
              </button>
            </section>

            <section>
              <button
                disabled={loading}
                className="btn btn--black"
                onClick={() => handleOpenConfirm(null, true)}
              >
                Cancelar contratos en borrador
              </button>
            </section>
          </div>

          <p>{message}</p>
        </div>
      </div>
    </div>
    )
}

export default EndContracts;
