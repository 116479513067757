import React, { Component } from "react";
import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesResumeTotals from "../../components/maz-tu-info-bees/maz-tu-info-bees-resume-totals/MazTuInfoBeesResumeTotals";
import { getVarConfig } from "../../utils/environment";

import "./Historial.scss";
import MazTuInfoBeesHistoryTable from "../../components/maz-tu-info-bees/maz-tu-info-bees-history-table/MazTuInfoBeesHistoryTable";

export default class Historial extends Component {

  state = {
    challenges: null
  }

  filterChallengues = (e) => {
    const valueOption = e.target.value;
    if(valueOption !== "all") {
      this.setState({
        challenges: this.props.history.filter(word => word.challengeType === valueOption)
      });
    } else {
      this.setState({
        challenges: this.props.history
      });
    }
  }

  render() {

    const nameVolumenTotal = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_VOLUMEN_TOTAL" });
    const nameMarcasPremium = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_MARCAS_PREMIUM" });
    const nameCobertura = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA" });

    const dataHistoryCount = typeof this.props.history !== "undefined" 
      ? this.props.history : this.state.challenges;

    let countVolumen = 0, countPremium = 0, countCoverage = 0, countTotal = 0;

    const challenges = this.state.challenges !== null ? this.state.challenges : this.props.history;

    for(let history in dataHistoryCount) {
      countTotal = countTotal + dataHistoryCount[history].pointsToPay;
      switch(dataHistoryCount[history].challengeType) {
        case 'total':
          countVolumen = countVolumen + dataHistoryCount[history].pointsToPay;
          break;
        case 'premium':
          countPremium = countPremium + dataHistoryCount[history].pointsToPay;
          break;
        case 'coverage':
          countCoverage = countCoverage + dataHistoryCount[history].pointsToPay;
          break;
        default:
          console.log('default');
      }
    }

    const totals = [
      {
        label: "Puntos " + nameVolumenTotal,
        value: countVolumen,
        isTotal: false,
      },
      {
        label: "Puntos " + nameMarcasPremium,
        value: countPremium,
        isTotal: false,
      },
      {
        label: "Puntos " + nameCobertura,
        value: countCoverage,
        isTotal: false,
      },
      {
        label: "Puntos totales acumulados",
        value: countTotal,
        isTotal: true,
      },
    ];

    let date = new Date();
    date.setDate(1);
    date.setHours(-1);

    let dateLastMonth = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

    return (
      <div className="historial">
        <MazTuInfoBeesTitle title="Historial" subtitle="Puntos" />

        <select className="historial__select" onChange={this.filterChallengues}>
          <option value={`all`}>Todos</option>
          <option value={`total`}>{nameVolumenTotal}</option>
          <option value={`premium`}>{nameMarcasPremium}</option>
          <option value={`coverage`}>{nameCobertura}</option>
        </select>

        <MazTuInfoBeesHistoryTable data={challenges} />

        <MazTuInfoBeesResumeTotals
          title="Puntos acumulados"
          subtitle={`Saldo ganado hasta ` + dateLastMonth}
          totals={totals}
        />
      </div>
    );
  }
}
