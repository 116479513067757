import React, { Component } from "react";

import "./MazTuInfoBeesGallery.scss";
import MazTuInfoBeesGalleryCard from "./MazTuInfoBeesGalleryCard";

export default class MazTuInfoBeesGallery extends Component {

  render() {

    const type = typeof this.props.type !== "undefined" ? this.props.type : "";
    const data = typeof this.props.data !== "undefined" ? this.props.data : [];
    const coverage = typeof this.props.coverage !== "undefined" ? this.props.coverage : [];

    const dataGallery = data.concat(coverage);

    const widthList = dataGallery.length * 303;

    let title, counter;

    switch(type) {
      case 'not-accepted':
        title = "Desafíos no aceptados";
        break;
      case 'progress':
        title = "Desafíos en progreso";
        break;
      default: 
        title = 'Desafíos completados';
    }

    counter = dataGallery.length;

    return(
      
      <div className="maz-tu-info-bees-gallery">

        <div className="maz-tu-info-bees-gallery__title">
          <span>{ title }</span>
          <span className={"maz-tu-info-bees-gallery__title__counter maz-tu-info-bees-gallery__title__counter--" + type}>{ counter }</span>
        </div>

        <div className="maz-tu-info-bees-gallery__carousel">

          <div className="maz-tu-info-bees-gallery__carousel__list" style={{ width: widthList }}>

            {
              dataGallery.map((card, index) => (

                <MazTuInfoBeesGalleryCard 
                  type={type}
                  data={card} />

              ))
            }

          </div>

        </div>

      </div>

    )
  }
}