import React, { Component } from "react";
import moment from "moment";
import Login from "./../Login/Login";
import TagManager from 'react-gtm-module';
import Dashboard from "./../Dashboard/Dashboard";
import Desafios from "./../Desafios/Desafios";
import Historial from "./../Historial/Historial";
import Contrato from "./../Contrato/Contrato";
import Registro from "./../Registro/Registro";
import RegistroExito from "./../Registro/RegistroExito";
import ProgramaMercado from "../ProgramaMercado/ProgramaMercado";

//Finance Modules
import VirtualAccount from '../VirtualAccountFinance';
import AccountStateFinance from '../AccountStateFinance';
import Help from '../Help';

import MazTuInfoBeesHeader from "./../../components/maz-tu-info-bees/maz-tu-info-bees-header/MazTuInfoBeesHeader";
import MazTuInfoBeesNavBar from "./../../components/maz-tu-info-bees/maz-tu-info-bees-nav-bar/mazTuInfoBeesNavBar";
import MazTuInfoBeesLoader from "./../../components/maz-tu-info-bees/maz-tu-info-bees-loader/MazTuInfoBeesLoader";
import Resultados from "./../Resultados/Resultados";
import MazTuInfoBeesToast from "./../../components/maz-tu-info-bees/maz-tu-info-bees-toast/MazTuInfoBeesToast";

import { MainContext } from './../../MainContext';

import { getUser, setUser } from "../../utils/sessionInfo";
import Facturacion from "../Facturacion/Facturacion";
import Cobertura from "../Cobertura/Cobertura";
import Fomentos from "../Fomentos/Fomentos";
// Club B Max
import TerminosBmax from '../../components/maz-tu-info-bees/maz-info-bees-makemodal';

// In App
import InApp from "../../components/maz-tu-info-bees/maz-tu-info-bees-inapp";


// utils
import fetchAPI from "./../../utils/fetchAPI";
import { getVarConfig } from "./../../utils/environment";

let i = 1;

export default class Main extends Component {

  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.setUser = this.setUser.bind(this);
    this.setContract = this.setContract.bind(this);
    this.setPerformance = this.setPerformance.bind(this);
    this.setPath = this.setPath.bind(this);
    this.setTypeDesafio = this.setTypeDesafio.bind(this);
    this.setLoaderOpen = this.setLoaderOpen.bind(this);
    this.setBackButton = this.setBackButton.bind(this);
    this.state = {
      user: {},
      typeDesafio: "",
      path: "/",
      loaderOpen: false,
      history: [],
      challenges: [],
      contract: {},
      performance: {},
      toastType: "info",
      toastMessage: "",
      toastOpen: false,
      needBackButton: false,
      backButtonAction: null,
      modalBmax: false,
      bmaxEndModal: false,
      modalInApp: null
    };
  }

  componentDidMount() {
    let path = this.state.path;
    let userSession = getUser();
    if(path === "/" && typeof userSession.name !== "undefined" ) {
      path = (userSession.isCandidateUser)
          ? "/programa-mercado"
          : (userSession.isTradeUser)
            ? "/dashboard"
            : path;
      this.setPath(path);
    }
    this.getNotif();
    // this.getPerformance();
  }

  getNotif = async() => {
    const excludeRoutes = [
      '/programa-mercado',
    ];
    setInterval(async () => {
      const localUser = getUser();
      if (!localUser.ID) return;
      if (localUser.isBmax !== null) {
        if (localUser.isBmax !== "COMPLETE") return;
      };
      if (!localUser.isTradeUser) return;
      if (excludeRoutes.includes(this.state.path)) return;

      let urlService = getVarConfig({ key: "REACT_APP_GET_INAPP"});
      await fetchAPI({
        service: urlService,
        protocol: "POST",
        params: {
          viewer: localUser.ID,
        },
        callbackThen: this.callBackInApp,
        callbackError: this.callBackInAppErr,
      });
    }, 60000)
  }

  getPerformance = () => {

    const user = getUser();
    if (!!user?.ID){
      let urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_PERFORMANCE" });
      urlService = urlService.replace("{client}", user.ID);
      this.setState({
        loaderPerformanceOpen: true
      });
      fetchAPI({
        service: urlService,
        protocol: "GET",
        callbackThen: this.callbackThenGetPerformance,
        callbackError: this.callbackErrorGetPerformance,
      });
    }
  }

  callbackThenGetPerformance = (response) => {
    try {
      this.setState({
        loaderPerformanceOpen: false
      });
      this.setPerformance(response.data);
    } catch(e) {
      console.log(e);
      this.setState({
        loaderPerformanceOpen: false
      });
    }
  }

  callbackErrorGetPerformance = (status) => {
    let messageError, type;
    switch(status) {
      case 404:
        messageError = "No se encuentra información sobre el Desempeño";
        type = "error";
        break;
      case 505:
        messageError = "Hubo un error al obtener la información de Desempeño";
        type = "error";
        break;
      default:
        messageError = "Hubo un error al obtener la información de Desempeño";
        type = "error";
    }
    this.props.handleToast({
      open: true,
      type: type,
      message: messageError
    });
    this.setState({
      loaderPerformanceOpen: false
    });
  }

  callBackInApp = (response) => {
    if(response.data.length == 0) return;
    const newNotif = response.data[0];
    const notiDate = moment(`${newNotif.date} ${newNotif.time}`, 'YYYY-MM-DD HH:mm');
    const now = moment();
    if(notiDate.diff(now, 'minutes') <= 0) {
      this.setState({modalInApp: newNotif});
    };
  };

  callBackInAppErr = () => {
    console.log('err')
  }

  setUser = (user) => {
    this.setState({ user });
    setUser(user);
  }

  setPerformance(data) {
    this.setState({
      performance: data,
    });
  }

  setHistory = (data) => {
    this.setState({
      history: data
    });
  }

  setChallenges = (data) => {
    this.setState({
      challenges: data
    });
  }

  setContract(data) {
    this.setState({
      contract: data
    });
  }

  setLoaderOpen(open) {
    this.setState({
      loaderOpen: open,
    });
  }

  setTypeDesafio(type) {
    this.setState({
      typeDesafio: type,
    });
  }

  setPath(path) {
    this.setState({
      path: path,
    });
    const user = getUser();
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent',
        userid: user.ID
      },
      dataLayerName: 'PageDataLayer'
    });
  }

  setBackButton(set, backPath) {
    this.setState({
      needBackButton: set,
      backButtonAction: backPath
    });
  }

  getPath() {
    return this.state.path;
  }

  sendGA = (GAevent, category, action, label, interaction, component_name, element_text) => {

    const dataLayers = {
      event: GAevent,
      event_category: category,
      event_action: action,
      event_label: label,
      userid: getUser().ID,
      POC_name: getUser().name
    }

    if (interaction !== null)
      dataLayers["interaction"] = interaction

    if (component_name !== null)
      dataLayers["component_name"] = component_name

    if (element_text !== null)
      dataLayers["element_text"] = element_text

    const tagManagerArgs = {
      dataLayer: dataLayers,
      dataLayerName: "PageDataLayer"
    }

    TagManager.dataLayer(tagManagerArgs);
  }

  handleToast = ({ open = false, message = "", type = "info", timeoutClose = 5000 }) => {
    const toastClose = this.toastClose;
    if (open) {
      this.setState({
        toastOpen: true,
        toastMessage: message,
        toastType: type
      });
      setTimeout(function () {
        toastClose();
      }, timeoutClose);
    } else {
      toastClose();
    }
  }

  toastClose = () => {
    this.setState({
      toastOpen: false,
    });
  }

  render() {

    let path = this.state.path;

    return (
      <div className="main">
        <div className="maz-tu-info-bees">
          <MazTuInfoBeesHeader
            sendGA={this.sendGA}
            user={this.state.user}
            path={this.state.path}
            setPath={this.setPath}
            setLoaderOpen={this.setLoaderOpen}
            setBackButton={this.setBackButton}
            needBackButton={this.state.needBackButton}
            backButtonAction={this.state.backButtonAction}
          />
          {path === "/" || path === "/cerrar" ? (
            <Login
              setUser={this.setUser}
              setPath={this.setPath}
              setLoaderOpen={this.setLoaderOpen}
              handleToast={this.handleToast}
              showModalBMax={() => this.setState({ modalBmax: true })}
            />
          ) : path === "/dashboard" ? (
            <Dashboard
              setPath={this.setPath}
              user={this.state.user}
              setTypeDesafio={this.setTypeDesafio}
              setLoaderOpen={this.setLoaderOpen}
              setHistory={this.setHistory}
              setChallenges={this.setChallenges}
              setContract={this.setContract}
              handleToast={this.handleToast}
              setPerformance={this.setPerformance}
              setUser={(v) => {
                this.setState({ user: v })
              }}
              showModalBMax={() => this.setState({ modalBmax: true })}
              bmaxEndModal={this.state.bmaxEndModal}
              openBmaxEndModal={() => {
                this.setState({ bmaxEndModal: true })
              }}
              closeBmaxEndModal={() => {
                this.setState({ bmaxEndModal: false })
              }}
            />
          ) : path === "/desafios" ? (
            <Desafios
              typeDesafio={this.state.typeDesafio}
              setPath={this.setPath}
              challenges={this.state.challenges}
              performance={this.state.performance}
              user={this.state.user}
            />
          ) : path === "/historial" ? (
            <Historial setPath={this.setPath} history={this.state.history} />
          ) : path === "/contrato" ? (
            <Contrato
              setPath={this.setPath}
              contract={this.state.contract}
              performance={this.state.performance} />
          ) : path === "/registro" ? (
            <Registro setPath={this.setPath} setLoaderOpen={this.setLoaderOpen} />
          ) : path === "/registro/exito" ? (
            <RegistroExito setPath={this.setPath} />
          ) : path === "/resultados" ? (
            <Resultados setPath={this.setPath} setLoaderOpen={this.setLoaderOpen} />
          ) : path === "/facturacion" ? (
            <Facturacion setPath={this.setPath} setLoaderOpen={this.setLoaderOpen} />
          ) : path === "/cobertura" ? (
            <Cobertura setPath={this.setPath} setLoaderOpen={this.setLoaderOpen} />
          ) : path === "/programa-mercado" ? (
            <ProgramaMercado
              setPath={this.setPath}
              setLoaderOpen={this.setLoaderOpen}
              handleToast={this.handleToast}
            />
          ) : path === '/cuenta-virtual' ? (
            <VirtualAccount user={this.state.user}
              sendGA={this.sendGA}
              path={this.state.path}
              setPath={this.setPath}
              setLoaderOpen={this.setLoaderOpen}
              setBackButton={this.setBackButton}
              needBackButton={this.state.needBackButton}
              backButtonAction={this.state.backButtonAction} />
          ) : path === '/estado-cuenta' ? (
            <AccountStateFinance
              sendGA={this.sendGA}
              user={this.state.user}
              setPath={this.setPath}
              needBackButton={this.state.needBackButton}
              setBackButton={this.setBackButton}
              backButtonAction={this.state.backButtonAction}
              setLoaderOpen={this.setLoaderOpen} />
          ) : path === '/ayuda' ? (
            <Help
              sendGA={this.sendGA}
              user={this.state.user} setPath={this.setPath} setBackButton={this.setBackButton} />
          ) : path === '/fomentos' ? (
            <Fomentos sendGA={this.sendGA}
              user={this.state.user} setPath={this.setPath} setBackButton={this.setBackButton} />
          ) : null}
          {(
            //path === "/home"
            path === "/dashboard"
            || path === "/historial"
            || path === "/desafios"
            || path === "/contrato"
            || path === "/programa-mercado"
            || path === "/facturacion"
            || path === "/cobertura"
            || path === '/cuenta-virtual'
            || path === '/estado-cuenta'
            || path === '/ayuda'
            || path === '/fomentos'
          ) ?
            <MazTuInfoBeesNavBar
              setLoaderOpen={this.setLoaderOpen}
              setUser={this.setUser}
              setPath={this.setPath}
              path={this.state.path}
              user={this.state.user} /> : null
          }
          <MazTuInfoBeesLoader open={this.state.loaderOpen} />
          <MazTuInfoBeesToast
            type={this.state.toastType}
            message={this.state.toastMessage}
            open={this.state.toastOpen}
            handleToast={this.handleToast}
          />
          <TerminosBmax
            setUser={(v) => {
              this.setState({ user: v })
            }}
            openBmaxEndModal={() => {
              this.setState({ bmaxEndModal: true })
            }}
            closeBmaxEndModal={() => {
              this.setState({ bmaxEndModal: false })
            }}
            isVissible={this.state.modalBmax}
            setPath={this.setPath}
            user={this.state.user}
            onClose={() => this.setState({ modalBmax: false })}
          />
          <InApp
            vissible={this.state.modalInApp}
            onClose={() => this.setState({modalInApp: null})}
            inApp={this.state.modalInApp}
          />
        </div>
      </div>
    );
  }
}
