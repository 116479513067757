export function getUser() {
  let name = "userInfoBees=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const strUser = c.substring(name.length, c.length);
      return JSON.parse(strUser);
    }
  }
  return {};
}

export function setUser(cookieValue) {
  const d = new Date();
  d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();

  if (typeof cookieValue === 'object' && cookieValue !== null)
    cookieValue = JSON.stringify(cookieValue)

  document.cookie = "userInfoBees=" + cookieValue + ";" + expires + ";path=/;secure";
}

export function cleanUser() {
  document.cookie = 'userInfoBees=;path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}