import React from "react";

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";
import useUenPoints from "./useUenPoints";

const MazTuInfoBeesCardInfoV2 = () => {
  const { points } = useUenPoints();
  const localPoints = points ? `${points}` : '';

  return (
    <div className={styles.container}>
      <span
        className={`${styles[`points__digits--${localPoints.length}`]} ${styles.points}`}
      >{localPoints}</span>
    </div>
  );
};

MazTuInfoBeesCardInfoV2.propTypes = propTypes;
MazTuInfoBeesCardInfoV2.defaultProps = defaultProps;

export default MazTuInfoBeesCardInfoV2;
