import PropTypes from 'prop-types';

export const propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  iconDesc: PropTypes.bool,
};

export const defaultProps = {
  onClick: () => {},
  title: '',
  iconDesc: false,
};
