import React from 'react';
import { Dialog } from '@mui/material';

import styles from './styles.module.scss';
import Icon from '../../../global/Icon/Icon';
import fetchAPI from '../../../../utils/fetchAPI';
// import { encodeBase64 } from 'bcryptjs';

const DownloadDialog = (props) => {
  const { user, open, onClose } = props;

  const getInvoice = () => {
    console.log("hola mundito!")
    //const user = getUser();
    let urlService = 'https://gwcloud.pegasotecnologia.mx/CfdiQAPEGASO_EmisionBaseExternalService'; //getVarConfig({ key: "REACT_APP_SERVICES_URL_DOWNLOAD" });
    let username = 'xxx';//getVarConfig({ key: "REACT_APP_SERVICES_USER_INVOICES_DOWNLOAD" });
    let password = 'xxx';//getVarConfig({ key: "REACT_APP_SERVICES_USERPS_INVOICES_DOWNLOAD" });
    const headers = new Headers();

    headers.append('Content-Type', 'text/xml');
    headers.append('SOAPAction', 'basicInvoke');
    headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Authorization', 'Basic ' + encodeBase64(username + ":" + password));

    let dataPrefix = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:scfd="${urlService}" >
                       <soapenv:Header/>
                       <soapenv:Body>
                       <scfd:consultarCFDPago>
                       <scfd:PoRequestAdmon>
                       <RequestAdmon version="4.0">
                       <Emisor rfc="MQC970605IF2"/>
                       <Transaccion id="04082022211757.5342549"
                      tipo="CONSULTA_PAGO"/>
                       </RequestAdmon>
                       </scfd:PoRequestAdmon>
                       </scfd:consultarCFDPago>
                       </soapenv:Body>
                      </soapenv: Envelope >`;

    const data = dataPrefix;

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', urlService, true);
    var sr =
      '<?xml version="1.0" encoding="utf-8"?>' +
      '<SOAP-ENV:Envelope ' +
      'xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" ' +
      'xmlns:main="' + urlService + '" ' +
      'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
      'xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
      '<SOAP-ENV:Body>' +
      '<main:DoGetCountriesRequest>' +
      '<main:countryCode>1</main:countryCode>' +
      '<main:webapiKey>xxxxxxxx</main:webapiKey>' +
      '</main:DoGetCountriesRequest>' +
      '</SOAP-ENV:Body>' +
      '</SOAP-ENV:Envelope>';

    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        console.log(xmlhttp.response + ":(");
      }
    };
    xmlhttp.setRequestHeader('Content-Type', 'text/xml');
    xmlhttp.send(sr);

    console.log('about to send ' + data);

    fetch(urlService, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: headers
    })
    .then(response => {
      // console.log(response + 'hola :(')
      return response;
    })
    .catch(function (error) {
      console.log(error);
      // console.log("hola mundito2")
    });




    /*
    this.setState({
      loaderVirtualAccountOpen: true
    });*/


  }

  const callbackThenGetInvoice = (response) => {
    console.log(response)
    try {
      /*this.props.user.virtualAccount = virtualAccount;
      this.setState({
        loaderVirtualAccountOpen: false
      });*/
    } catch (e) {
      console.log(e);
      /*this.setState({
        loaderVirtualAccountOpen: false
      });*/
    }
    askForTurnOffLoader();
  }

  const callbackErrorGetInvoice = (status) => {
    let messageError, type;
    switch (status) {
      case 404:
        messageError = "No se encuentra información sobre cuenta virtual";
        type = "error";
        break;
      case 505:
        messageError = "Hubo un error al obtener la información sobre cuenta virtual";
        type = "error";
        break;
      default:
        messageError = "Hubo un error al obtener la información sobre cuenta virtual";
        type = "error";
    }
    this.props.handleToast({
      open: true,
      type: type,
      message: messageError
    });
    /*this.setState({
      loaderVirtualAccountOpen: false
    });*/
    askForTurnOffLoader();
  }

  const askForTurnOffLoader = () => {
    /*if (!this.loaderVirtualAccountOpen && !this.loaderAccountStateOpen && !this.loaderOpenItemsStateOpen) {
      this.props.setLoaderOpen(false);
    }*/
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.dialog}
      fullScreen
    >
      <div className={styles.card}>
          <div className={styles.card__header}>
          <h1>Selecciona el formato que deseas descargar</h1>
            <div
              className={styles.card__close}
              onClick={onClose}
            >
              <Icon
                icon={"close-black"}
                width="14"
                height="14"
              />
            </div>
          </div>
          <br />
          <div className={styles.card__body}>
          <div className={styles.card__info_row}>
            <h4 onClick={getInvoice}>PDF</h4>
            </div>
            <div className={styles.card__info_row}>
              <h4>XML</h4>
            </div>
          </div>
      </div>
    </Dialog>
  );
};

export default DownloadDialog;
