import React, { Component } from "react";
import MazTuInfoBeesButton from "../maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesTitle from "../maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesModalAction from "../maz-tu-info-bees-modal-action/MazTuInfoBeesModalAction";
import "./Brochuere.scss";
import fetchAPI from '../../../utils/fetchAPI';
import { getVarConfig } from "./../../../utils/environment";
import { getUser } from "../../../utils/sessionInfo";
import { setUser } from "../../../utils/sessionInfo";


const imageBrouchere = require('../../../assets/images/image_club_bmax.jpg');


export default class Brochuere extends Component {

  state = {
    showTerms: false,
    termsHasAccepted: false,
  }

  constructor(props) {
    super(props);
  }

  handleOpenTerms = () => {
    this.setState({
      showTerms: true,
    });
  }

  handleCloseTerms = () => {
    this.setState({
      showTerms: false
    });
  }

  handleClosePrivacy = () => {
    this.setState({
      showPrivacy: false
    });
  }

  handleContinue = async () => {
    if (!this.state.termsHasAccepted) {
      return;
    }
    const localUser = getUser();
    let urlService = getVarConfig({ key: "REACT_APP_CHANGE_BMAX_STATUS" });
    await fetchAPI({
      service: urlService,
      protocol: "POST",
      params: {
        prevStatus: localUser.isBmax,
        customerId: localUser.ID,
      },
      callbackThen: this.handleContinueSuccess,
      callbackError: this.handleContinueError,
    });

    const userBees = {
      name: localUser.name,
      ID:  localUser.ID,
      drv: localUser.drv,
      rfc: localUser.rfc,
      phone: localUser.phone,
      permissions: localUser.permissions,
      level: localUser.level,
      clubLevel: localUser.clubLevel,
      resultsSeen: localUser.resultsSeen,
      isTradeUser : localUser.isTradeUser,
      isBmax: 'ACCEPTED',
    }
    this.props.openBmaxEndModal()
    setUser(
      JSON.stringify(userBees)
    );
    this.props.onClose();
  }

  handleContinueSuccess = () => {
    console.log('success')
  }

  handleContinueError = () => {
    console.log('error')
  }
  render() {

    if (!this.props.isVissible) {
      return null
    };

    return(
      <div className="containerBMax">
        <div
          className="blockBMax"
        />
        <div className="brochuereBmax">
          <div
            className="headerBmax"
          >
            <div className="headerBmaxLeft">
              <MazTuInfoBeesTitle title="¡Has sido seleccionado!" />
            </div>
            <div className="headerBmaxRigth">
              <div
                onClick={this.props.onClose}
                className="closeButtonContainer"
              >
                <img
                  src={require('../../../assets/icons/icon-close-black.png')}
                  width="14"
                  height="14"
                />
              </div>
            </div>
          </div>
          <div className="brochuereBmax__image">
            <img src={imageBrouchere} alt="Brouchere detail" />
          </div>
          <div className="termnsDivider"/>
          <div
          >
            <span>
              <input
                type="checkbox"
                className="termnsCheck"
                onChange={() => {
                  this.setState({termsHasAccepted: !this.state.termsHasAccepted})
                }}
              />
                Aceptar los
                <a
                  className={"termnsBtn"}
                  href={'#'}
                  onClick={() => {
                    this.setState({showTerms: true})
                  }}
                >
                  {' términos y condiciones'}
                </a>
            </span>
          </div>
          <div
            className="termnsDivider"
          />
          <div
            className="termnsDivider"
          />
          <MazTuInfoBeesButton
            textButton="FORMAR PARTE DE CLUB BMAX"
            handleClick={this.handleContinue}
            disabled={!this.state.termsHasAccepted}
          />
          <MazTuInfoBeesModalAction
            show={this.state.showTerms}
            title="TÉRMINOS Y CONDICIONES"
            body={termns}
            buttonAcceptText="ENTENDIDO"
            handleClickAccept={this.handleCloseTerms}
          />
        </div>
      </div>
    )
  }

}

const termns = `
CONVENIO MODIFICATORIO DEL CONTRATO DE COLABORACIÓN CLUB B BLACK

Hacemos referencia al contrato de colaboración celebrado entre El Comerciante y Modelo (las “Partes”), que tiene como objeto, entre otros, la entrega de Incentivos al Comerciante por parte de Modelo, sujeto al cumplimiento de diferentes actividades por parte del primero (el “Contrato”).

En este sentido, con la finalidad de que Modelo pueda aportar mayores Incentivos y Beneficios al Comerciante, por virtud del presente convenio, las Partes deciden sujetarse a las siguientes Cláusulas:

PRIMERA .- OBJETO. Las Partes acuerdan realizar las siguientes modificaciones y/o adiciones al Contrato:

“PRIMERA. - Modelo aportará al Comerciante los apoyos
que se señalan y describen en cantidad, valor y características
generales en el presente contrato ("Incentivos"), y una serie de beneficios por el cumplimiento de los compromisos descritos en los términos y condiciones del Programa Club B Max (los “Beneficios”),.
mismos que se consideran necesarios para equipar
adecuadamente el Establecimiento, con la finalidad de
motivar la venta y promoción de productos derivados de la
malta con o sin alcohol producidos o comercializados por
Cervecería Modelo de México, S de R. L. de C. V. ("Modelo")
o cualquiera de sus subsidiarias, así como cualesquiera
otra marca o producto que en el futuro llegue a comercializar,
ya sea nacional o importado ("Productos Cerveceros"), al igual que de los alimentos, bebidas con y sin alcohol, productos de higiene y limpieza y demás productos que Modelo o cualquiera de sus subsidiarias comercialice actualmente o llegue a comercializar en un futuro (los “Productos de Miscelánea” y en conjunto con los Productos Cerveceros, los “Productos”).

[…]

SEGUNDA. - Para que los premios sean proporcionados
por Modelo al Comerciante se obliga a realizar, durante
toda la vigencia del presente, las siguientes actividades:
(I) La imagen de la fachada del Establecimiento deberá
mostrar de manera preferente la imagen del Programa Club B Max y/o de la(s) marca(s) de los Productos de Modelo y de los Productos de Miscelánea,
(II) Exhibir en primera posición los Productos de Modelo y los Productos de Miscelánea.

[…]

 (VIII) El Comerciante no podrá anunciar y se obliga a que
no se comercialicen, vendan, almacenen, ofrezcan, o
distribuyan en el Establecimiento productos que compiten
con los Productos Cerveceros y/o de sus sociedades
relacionadas, controladoras, filiales ("Productos que
Compiten"). Para efectos del presente contrato, se
entenderá por "Productos que Compiten", cualesquiera
marcas y/o productos de personas físicas o morales
dedicadas a la producción, distribución, comercialización,
venta, importación y exportación de productos
cerveceros, con o sin alcohol distintas de Modelo.

[…]

QUINTA. - El Comerciante deberá sujetarse y cumplir con
lo establecido en todas las leyes y reglamentos aplicables,
así como lo dispuesto por las autoridades competentes,
incluyendo: (I) la obtención y mantenimiento de las
autorizaciones, licencias y/o permisos para anunciar y comercializar los Productos en el Establecimiento.”

SEGUNDA. - ÚNICAS MODIFICACIONES Y/O ADICIONES. Las Partes acuerdan que, salvo por las modificaciones y/o adiciones que se han hecho
al Contrato en virtud del presente Convenio, el Contrato se mantiene vigente y en pleno vigor en todos y cada uno de sus términos, condiciones, derechos y obligaciones de cada una de las Partes, por lo que no se modificarán en forma distinta a la aquí señalada, y subsistirán las disposiciones en todos sus términos, conservando su fuerza legal y vigencia. Asimismo, las Partes convienen que en todo lo no previsto por este Convenio, se estará a lo dispuesto por el Contrato.
TERCERA. - NO NOVACIÓN. Las Partes reconocen y convienen de manera expresa que la celebración del presente Convenio no implica compensación, confusión, remisión, novación y/o subrogación a cualquiera de sus derechos y obligaciones correspondientemente de conformidad con el Contrato, y en ninguna circunstancia se deberá considerar que, como consecuencia de la celebración del presente Convenio, el Contrato perderá su validez y exigibilidad, ya sea de forma parcial o total.
CUARTA. - INVALIDEZ PARCIAL. Las Partes acuerdan que en el supuesto de que cualquier Cláusula del presente Convenio sea declarada nula o inválida, el resto de las disposiciones del Convenio conservarán completamente su validez y efectos legales, y dicha Cláusula se tendrá por no puesta, salvo que la nulidad recaiga en los acuerdos contenidos en la Cláusula Primera (Objeto) del presente Convenio, en cuyo caso, las Partes acuerdan negociar de buena fe para que continúe en vigor el acuerdo de negocios alcanzado a la fecha del presente. Es decir, el presente Convenio no deberá ser interpretado restrictivamente, ya que sus Cláusulas son independientes.
QUINTA. - OBLIGATORIEDAD. Este Convenio, y los respectivos derechos y obligaciones asumidos por cada una de las Partes serán obligatorios y tendrán efecto para el beneficio de las Partes y de sus respectos sucesores, representantes legales y cesionarios permitidos, y estarán sujetos a los términos y condiciones del Contrato en todo lo que no se oponga a lo pactado en la Cláusula Primera (Objeto) del presente Convenio.
SEXTA. - ENCABEZADOS. Todos los encabezados de las Cláusulas del presente Convenio son para fines de conveniencia y no modifican, definen o limitan, de modo alguno, los términos o disposiciones que en ellas se contienen y o del presente Convenio.
SÉPTIMA. - EJEMPLARES. -Este documento podrá firmarse en uno o más ejemplares, cada una de los cuales se deberá considerar como un original, y el conjunto de todos ellos constituirá un instrumento jurídico único e idéntico.
`
