import React, { Component } from "react";
import MazTuInfoBeesColumnsInfo from "../../components/maz-tu-info-bees/maz-tu-info-bees-columns-info/MazTuInfoBeesColumnsInfo";
import MazTuInfoBeesResume from "../../components/maz-tu-info-bees/maz-tu-info-bees-resume/MazTuInfoBeesResume";
import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";

import { getUser } from "../../utils/sessionInfo";

import "./Contrato.scss";

export default class Contrato extends Component {
  render() {

    const contract = typeof this.props.contract !== "undefined" 
      ? typeof this.props.contract.contract !== "undefined" 
      ? this.props.contract.contract : {} : {};

    const user = getUser();
    const nivelMeta = typeof user.level !== "undefined" ? user.level : 0;

    const number = typeof contract.number !== "undefined" ? contract.number : "";
    const startDate = typeof contract.startDate !== "undefined" 
      ? contract.startDate : "00/00/0000";
    const endDate = typeof contract.endDate !== "undefined" 
      ? contract.endDate : "00/00/0000";

    let datesRange;
    if(startDate === null || endDate === null) {
      datesRange = "";
    } else {
      datesRange = "Fecha inicio: " + startDate + " \n Fecha fin: " + endDate
    }

    const dataColumnsInfo = [
      {
        title: "Número de contrato",
        content: number,
        isBold: false
      },
      {
        title: "Vigencia contrato",
        content: datesRange,
        isBold: false
      },
      {
        title: "Nivel meta",
        content: nivelMeta,
        isBold: true
      }
    ];

    return (
      <div className="contrato">
        <MazTuInfoBeesTitle
          title="Contrato"
          subtitle="Detalle programa de mercado"
        />
        <MazTuInfoBeesColumnsInfo data={dataColumnsInfo} />
        <MazTuInfoBeesResume nivel={nivelMeta} performance={this.props.performance} />
      </div>
    );
  }
}
