import React, { Component } from "react";

import "./MazTuInfoBeesColumnsInfo.scss";

export default class MazTuInfoBeesColumnsInfo extends Component {
  render() {
    const data =
      typeof this.props.data !== "undefined" ? this.props.data : null;

    return (
      <div className="maz-tu-info-bees-columns-info">
        {data !== null
          ? data.map((column, index) => (
              <div className="maz-tu-info-bees-columns-info__column">
                <div className="maz-tu-info-bees-columns-info__column__title">
                  {column.title}
                </div>
                <div className={`maz-tu-info-bees-columns-info__column__content`}>
                  {column.content}
                </div>
              </div>
            ))
          : null}
      </div>
    );
  }
}
