import PropTypes from 'prop-types';

export const propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  hide: PropTypes.bool,
};

export const defaultProps = {
  loading: false,
  setLoading: () => {},
  hide: false,
};
