import PropTypes from 'prop-types';
import { propTypes as ItemType } from '../MenuItem/types';

export const propTypes = {
  item: ItemType,
  onClick: PropTypes.func,
};

export const defaultProps = {
  item: null,
  onClick: null,
};
