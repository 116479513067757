import React, { useEffect, useState } from "react";

import ConfirmDialog from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/ProgramForm/ConfirmDialog";
import MazTuInfoBeesGoalOptions from "../../../components/maz-tu-info-bees/maz-tu-info-bees-goal-options";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";

import RowResume from "./RowResume";
import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";
import useInitialResume from "./useInitialResume";

const InitialResume = props => {
  const { handleProcess } = props;

  const [goalSelected, setGoalSelected] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [maxMinValue, setMaxMinValue] = useState({ max: 0, min: 0 });
  const [hideInput, setHideInput] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);

  const initialData = useInitialResume();

  useEffect(() => {
    if (null !== initialData.currentSales
        && 0 === initialData.currentSales
        && !openConfirm
        && !initialData.isLoading
        && !acknowledge) {
      setOpenConfirm(true);
    }
  }, [acknowledge, initialData.currentSales, initialData.isLoading, openConfirm]);

  const handleGoalOptions = (args) => {
    const { value, text } = args;
    setInputValue('');
    if (value === 'custom') {
      setHideInput(false);
      setGoalSelected(null);
    } else {
      setHideInput(true);
      setGoalSelected(text)
    }
    if (value === '0') setGoalSelected(null);
  };

  const handleConfirmAlert = () => {
    setOpenConfirm(false);
    setAcknowledge(true);
  };

  const handleSetInputValue = (value, maxMin) => {
    setMaxMinValue(maxMin);
    setInputValue(value);
    setGoalSelected(value)
  };

  const isValidGoalSelected = () => {
    const { max, min } = maxMinValue || {};
    if (hideInput && goalSelected) return true;
    if (min <= goalSelected && goalSelected <= max) {
      return true;
    }
    return false;
  };

  const handleContinue = () => {
    if (isValidGoalSelected()) {
      handleProcess({
        protocol: "PATCH",
        params: {
          transition: "initial",
          stepData: {
            goalSelected
          }
        }
      });
    } else {
      alert(`Elige una meta entre ${maxMinValue.min} y ${maxMinValue.max}`);
      handleSetInputValue('0', maxMinValue);
      setGoalSelected(null);
    }
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={`${styles.title} ${styles.mb20}`}>Venta de cartones</div>
        <div className={`${styles.box} ${styles.mb20}`}>
          <li>
            {
              initialData?.volumeHistory?.lastMonths?.map(({ month, points, highlighted }) => (
                <RowResume
                  key={`${month}-${points}-${highlighted}`}
                  label={month}
                  points={points}
                  highlighted={!!highlighted}
                />
              ))
            }
          </li>
        </div>

        <MazTuInfoBeesGoalOptions
          onChange={handleGoalOptions}
          input={{
            value: inputValue,
            setValue: handleSetInputValue,
            hide: hideInput,
          }}
        />
      </div>

      <MazTuInfoBeesButton
        textButton="CONTINUAR"
        handleClick={handleContinue}
        disabled={null === goalSelected}
      />

      <ConfirmDialog
        open={openConfirm}
        noCancelBtn
        onConfirm={handleConfirmAlert}
        text={"El contrato se activará en cuanto tengas un pedido entregado durante este mes."}
        confirmTxt={"Aceptar"}
        className={styles.dialog__alert__container}
      />

      <div style={{ paddingBlock: '200px' }}></div>
    </div>
  );
}

InitialResume.propTypes = propTypes;
InitialResume.defaultProps = defaultProps;

export default InitialResume;
