import React, { useEffect } from 'react';

import ImageUploader from '../ImageUploader';
import TextEditor from '../TextEditor';
import ConfirmDialog from './ConfirmDialog';
import styles from './styles.module.scss';

import useProgramForm from './useProgramForm';
import { defaultProps, propTypes } from './types';


const ProgramForm = props => {
  const {
    program,
    onCancel,
    loading,
    setLoading,
  } = props;

  const {
    editedProgram,
    setNewBrochure,
    setTermSheet,
    setPrivacyDocument,
    showErrors,
    validForm,
    handleSubmit,
    openConfirm,
    setOpenConfirm,
    isLoading,
    file,
    setFile,
  } = useProgramForm(props);

  const termSheet = program?.termSheet ? decodeURIComponent(program.termSheet) : '';
  const privacyDocument = program?.privacyDocument ? decodeURIComponent(program.privacyDocument) : '';

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  return (
    <div className={styles.container}>
      <ConfirmDialog
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={handleSubmit}
      />

      <div className={styles.form}>
        <h1>Editar programa de mercado</h1>

        <div className={styles.form__control}>
          <h2>Nombre</h2>
          <input type="text" disabled value={program.name} />
        </div>

        <div>
          <h2>Imagen brochure</h2>
          <ImageUploader
            thumbnail={editedProgram?.brochure}
            file={file}
            setFile={setFile}
            onDelete={() => setNewBrochure('')}
          />
          {(showErrors && !editedProgram?.brochure) && (
            <h3>* Se requiere una imagen para continuar</h3>
          )}
        </div>

        <div className={styles.form__control__text_editor}>
          <h2>Términos y condiciones</h2>
          <TextEditor
            initialValue={termSheet}
            onChange={setTermSheet}
          />
          {(showErrors && !editedProgram?.termSheet) && (
            <h3>* Se requiere términos y condiciones continuar</h3>
          )}
        </div>

        <div className={styles.form__control__text_editor}>
          <h2>Políticas de privacidad</h2>
          <TextEditor
            initialValue={privacyDocument}
            onChange={setPrivacyDocument}
          />
          {(showErrors && !editedProgram?.privacyDocument) && (
            <h3>* Se requiere términos y condiciones continuar</h3>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <button
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancelar
        </button>

        <button
          className={styles.save}
          disabled={isLoading || !validForm}
          onClick={() => setOpenConfirm(true)}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

ProgramForm.prototype = propTypes;
ProgramForm.defaultProps = defaultProps;

export default ProgramForm;
