import React, { Component } from "react";
import Icon from "../../global/Icon/Icon";

import "./MazTuInfoBeesToast.scss";

export default class MazTuInfoBeesToast extends Component {

  handleClose = () => {
    this.props.handleToast({open: false});
  }

  render() {

    const type = typeof this.props.type !== "undefined" ? this.props.type : "info";
    const message = typeof this.props.message !== "undefined" ? this.props.message : "Informational Message";
    const open = typeof this.props.open !== "undefined" ? this.props.open : false;
    const classOpen = open ? 'open' : '';

    return (
      <div
        style={this.props.style}
        className={`maz-tu-info-bees-toast maz-tu-info-bees-toast--` + type + ` ` + classOpen}
      >

        {
          type === "info"
          ? <Icon icon={"info-white"} size="20" />
          : type === "success"
          ? <Icon icon={"success-white"} size="20" />
          : type === "warning"
          ? <Icon icon={"warning-white"} size="20" />
          : type === "error"
          ? <Icon icon={"error-white"} size="22" />
          : null
        }

        <span className="maz-tu-info-bees-toast__message">
          {message}
        </span>
        <Icon icon={"close-white"} size="16" handleClick={this.handleClose} />
      </div>
    );
  }
}
