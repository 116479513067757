import React, { Component } from "react";
// import ReactHtmlParser from 'react-html-parser';

import MazTuInfoBeesLoader from "../maz-tu-info-bees-loader/MazTuInfoBeesLoader";

import "./MazTuInfoBeesCardProgress.scss";

export default class MazTuInfoBeesCardProgress extends Component {

  state = {
    loader: false
  }

  formatHTML = (str) => {
    try {
      let description = str;
      description = description.replace("*", "<b>");
      description = description.replace("**", "</b>");
      return description;
    } catch(e) {
      //console.log(e);
    }
  }

  render() {

    const title = typeof this.props.title !== "undefined" ? this.props.title : null;
    let description = typeof this.props.description !== "undefined" ? this.props.description : null;
    const totalProgress = typeof this.props.totalProgress !== "undefined" ? this.props.totalProgress : null;
    const realProgress = typeof this.props.realProgress !== "undefined" ? this.props.realProgress : null;
    const billingReal = typeof this.props.billingReal !== "undefined" ? this.props.billingReal : null;
    const billingGoal = typeof this.props.billingGoal !== "undefined" ? this.props.billingGoal : null;
    const billingPoints = typeof this.props.billingPoints !== "undefined" ? this.props.billingPoints : null;
    const progressTitle = typeof this.props.progressTitle !== "undefined" ? this.props.progressTitle : null;
    let bottomLabel = typeof this.props.bottomLabel !== "undefined" ? this.props.bottomLabel : null;
    const type = typeof this.props.type !== "undefined" ? this.props.type : null;
    const loader = typeof this.props.loader !== "undefined" ? this.props.loader : null;

    const handleClick = typeof this.props.handleClick !== "undefined" ? this.props.handleClick : null;

    const percentageProgress = realProgress === 0 ? realProgress : (100 * realProgress) / totalProgress;

    const styleProgressContent = {
      width: type === "coverage" ? percentageProgress + "%" : "100%"
    };

    const billingLabel = billingPoints !== null ? billingPoints : null;

    //Formating description text:
    description = this.formatHTML(description);
    bottomLabel = this.formatHTML(bottomLabel);

    const dataDivisions = [
      {
        amount: 4000,
        points: 500,
        real: 4000
      },
      {
        amount: 6000,
        points: 1800,
        real: 6000
      },
      {
        amount: 9000,
        points: 3500,
        real: 1000
      },
    ];

    let widthBilling = 0;

    let goalProgress = 0;
    goalProgress = parseInt(billingPoints) === 0 ? parseInt(billingGoal) + 6000 : parseInt(billingPoints) === 500 ? parseInt(billingGoal) + 3000 : parseInt(billingGoal);

    if(parseInt(billingReal) > parseInt(billingGoal)) {
      widthBilling = 100;
    } else {
      widthBilling = (100 * parseInt(billingReal)) / goalProgress;
    }

    return (
      <div className="maz-tu-info-bees-card-progress" onClick={handleClick}>

        <MazTuInfoBeesLoader open={loader} />

        <div className="maz-tu-info-bees-card-progress__chevron"></div>

        {
          title !== null 
            ? <div className="maz-tu-info-bees-card-progress__title">{title}</div> : null
        }

        {
          description !== null
            ? <div className="maz-tu-info-bees-card-progress__description">
                {/*ReactHtmlParser(description)*/}
                {`${description}`}
              </div> : null
        }
        
        { this.props.hideProgressBar ? null :  (
          <div className="maz-tu-info-bees-card-progress__progress">

          <div className="maz-tu-info-bees-card-progress__progress__title">
            <span>{progressTitle}</span>
            {realProgress !== null && totalProgress !== null 
              ? <span>{realProgress} de {totalProgress}</span> : null
            }
          </div>

          <div className={"maz-tu-info-bees-card-progress__progress__bar maz-tu-info-bees-card-progress__progress__bar--" + type}>
            <div 
              className={"maz-tu-info-bees-card-progress__progress__bar__content maz-tu-info-bees-card-progress__progress__bar__content--" + type}
              style={styleProgressContent}
            >

              {
                type === "billing" && type !== null ?

                <> 
                  <div 
                    className="maz-tu-info-bees-card-progress__progress__bar__content__progress-billing"
                    style={{
                      width: widthBilling + "%"
                    }}
                    ></div>
                  {
                    dataDivisions !== null ?
                      dataDivisions.map((bill, index) => (
                        <div className="maz-tu-info-bees-card-progress__progress__bar__content__division">
                          <div className="maz-tu-info-bees-card-progress__progress__bar__content__division__amount">{"$" + bill.amount.toLocaleString()}</div>
                          <div className="maz-tu-info-bees-card-progress__progress__bar__content__division__points">{bill.points.toLocaleString() + " puntos"}</div>
                        </div>
                      )) : null
                  }
                </> : null
                
              }

            </div>
          </div>

          </div>
        )}

        <div className="maz-tu-info-bees-card-progress__bottom-label maz-tu-info-bees-card-progress__bottom-label--right">
          {/*
            billingPoints === null ?
              ReactHtmlParser(bottomLabel) : <>Premio alcanzado: <b>{parseInt(billingPoints).toLocaleString()} puntos</b></>
          */}
          {
            billingPoints === null ?
              `${bottomLabel}` : <>Premio alcanzado: <b>{parseInt(billingPoints).toLocaleString()} puntos</b></>
          }
        </div>

      </div>
    );
  }
}
