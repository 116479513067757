import React, { Component } from "react";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesCardInfo from "../../../components/maz-tu-info-bees/maz-tu-info-bees-card-info/MazTuInfoBeesCardInfo";
import MazTuInfoBeesTitle from "../../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";

import { once } from "../../../utils/events";

import { getUser } from "../../../utils/sessionInfo";

import "./Resumen.scss";
import ResumenRow from "./ResumeRow";
import MazTuInfoBeesCardInfoV2 from "../../../components/maz-tu-info-bees/maz-tu-info-bees-card-info-v2";

export default class Resumen extends Component {

  state = {
    data: [],
    dataCURP: "",
    dataEmail: "",
    dataName: "",
    dataPhone: "",
    dataSatType: "",
    validateData: true
  }

  componentDidMount() {
    try{
      const handleProcess = this.props.handleProcess;
      once("click-back-navigation-programa-mercado", function(event) {
        handleProcess({
          protocol: "PATCH",
          params: {
            transition: "returnToLoadDocs"
          }
        });
      });
    } catch(e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps) {
    const { dataProcess: prevDataProcess } = prevProps;
    const { dataProcess } = this.props;
    if (dataProcess?.responseData?.currentStep?.type && dataProcess?.responseData?.currentStep?.type !== prevDataProcess?.responseData?.currentStep?.type) {
      this.setDataResumen(this.props.dataProcess);
    }
    // if(this.state.validateData) {
    //   this.setDataResumen(this.props.dataProcess);
    // }
  }

  setDataResumen = (data) => {

    console.log(this.props.dataProcess);

    let typeDocumentID, typeDocument;

    try {
      typeDocumentID = this.props.dataProcess.responseData.process.contractData.personalInfo.id;
    } catch(e) {
      typeDocumentID = "";
    }

    switch(typeDocumentID) {
      case 'ine':
        typeDocument = "INE/IFE";
        break;
      case 'passport':
        typeDocument = "Pasaporte";
        break;
      case 'driving_license':
        typeDocument = "Licencia para conducir";
        break;
      default:
        typeDocument = "";
    }

    let dataPersona = [
      {
        subtitle: "Tipo de persona",
        text: data.satType,
        status: "success",
        edit: false
      },
      {
        subtitle: "Correo electrónico",
        text: data.email,
        status: "success",
        edit: false
      },
      {
        subtitle: "Número de celular",
        text: data.phone,
        status: "success",
        edit: false
      },
      {
        subtitle: "Tipo de identificación",
        text: typeDocument,
        status: "success",
        edit: false
      },
      {
        subtitle: "Nombre cliente",
        text: data.fullName,
        status: data.fullName !== "" ? "success" : "error",
        edit: true
      },
      {
        subtitle: "Numero de CURP/RFC",
        text: data.curp,
        status: data.curp !== "" ? "success" : "error",
        edit: true
      }
    ];

    if(data.satType === "Moral") {
      dataPersona.push({
        subtitle: "Acta constitutiva",
        text: "photo12345678",
        status: "success",
        edit: true
      });
      dataPersona.push({
        subtitle: "RFC Empresa",
        text: "1234567890123456789",
        status: "success",
        edit: true
      });
    }

    this.setState({
      dataName: data.fullName,
      dataCURP: data.curp,
      dataPhone: data.phone,
      dataEmail: data.email,
      dataSatType: data.satType,
      validateData: false,
      data: dataPersona
    });
  }

  setData = ({ key, value}) => {
    if(value !== "") {
      this.setState({
        [key]: value
      });
    }
  }

  handleContinue = () => {
    try {

      const stepData = {
        curp: this.state.dataCURP,
        email: this.state.dataEmail,
        fullName: this.state.dataName,
        phone: this.state.dataPhone,
      };

      this.props.handleAlert({
        toggle: true,
        title: "Contrato",
        message: "Al crear el contrato para su firma, no podrá editar la información antes capturada.",
        alertButtonCancel: "Cerrar",
        alertButton: "Continuar",
        callback: () => {
          this.props.setCallbackAlertClose(() => {
            this.props.handleProcess({
              protocol: "PATCH",
              params: {
                transition: "startSigning",
                stepData
              }
            });
          });
        }
      });
    } catch(e) {
      console.log(e);
    }
  }

  setEditing = editing => this.setState({ editing: { ...this.state.editing, ...editing } });

  render() {

    let enableButton = false;

    if(
      typeof this.state.dataName !== "undefined" &&
      typeof this.state.dataCURP !== "undefined" &&
      typeof this.state.dataEmail !== "undefined" &&
      typeof this.state.dataPhone !== "undefined" &&
      this.state.dataName !== null &&
      this.state.dataCURP !== null &&
      this.state.dataEmail !== null &&
      this.state.dataPhone !== null &&
      this.state.dataName !== "" &&
      this.state.dataCURP !== "" &&
      this.state.dataEmail !== "" &&
      this.state.dataPhone !== ""
    ) {
      enableButton = true;
    } else {
      enableButton = false;
    }

    const user = getUser();

    return(
      <div className="resumen">

        <MazTuInfoBeesTitle
          title="Resumen"
          />

        <MazTuInfoBeesCardInfoV2 />

        { user.clubLevel === "B_MAX" ? <MazTuInfoBeesCardInfo info="bmax" /> : null }

        <div className="resumen__datos">

          <div className="resumen__datos__title">
            Datos adicionales
          </div>

          {
            this.state.data.map((row, key) => (

              <ResumenRow
                data={row}
                setDataRow={this.setData}
                setEditing={this.setEditing}
              />

            ))
          }

        </div>

        <div className="resumen__buttons">
          <MazTuInfoBeesButton
            disabled={!enableButton || this.state.editing?.['Numero de CURP/RFC'] || this.state.editing?.['Nombre cliente'] }
            textButton="CONTINUAR CON LA FIRMA"
            handleClick={this.handleContinue} />
        </div>

      </div>
    )
  }

}