import React, { Component } from "react";

import "./MazTuInfoBeesContract.scss";

import MazTuInfoBeesLoader from "../maz-tu-info-bees-loader/MazTuInfoBeesLoader";

export default class MazTuInfoBeesContract extends Component {

  render() {

    const loaderContractOpen = typeof this.props.loaderContractOpen !== "undefined"
      ? this.props.loaderContractOpen : false;

    const contract = typeof this.props.contract !== "undefined" ? this.props.contract : {};
    const contractNumber = typeof contract.contract !== "undefined" ? contract.contract.number : "";
    const contractStartDate = typeof contract.contract !== "undefined" ? contract.contract.startDate : "";
    const contractEndDate = typeof contract.contract !== "undefined" ? contract.contract.endDate : "";

    return (
      <div className="maz-tu-info-bees-contract" onClick={this.props.handleClick}>
        <MazTuInfoBeesLoader open={loaderContractOpen} />
        <div className="maz-tu-info-bees-contract__title">Contrato</div>

        <div className="maz-tu-info-bees-contract__contract-number">
          <div className="maz-tu-info-bees-contract__contract-number__label">
            Número contrato
          </div>
          <div className="maz-tu-info-bees-contract__contract-number__number">
            {contractNumber}
          </div>
        </div>

        <div className="maz-tu-info-bees-contract__dates">
          <div className="maz-tu-info-bees-contract__dates__info">
            <div className="maz-tu-info-bees-contract__dates__info__label">Fecha inicio:</div>
            <div className="maz-tu-info-bees-contract__dates__info__date">{contractStartDate}</div>
          </div>
          <div className="maz-tu-info-bees-contract__dates__separator"></div>
          <div className="maz-tu-info-bees-contract__dates__info">
            <div className="maz-tu-info-bees-contract__dates__info__label">Fecha fin:</div>
            <div className="maz-tu-info-bees-contract__dates__info__date">{contractEndDate}</div>
          </div>
        </div>
      </div>
    );
  }
}
