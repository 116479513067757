import React, { useState } from 'react';
import Icon from '../../../components/global/Icon/Icon';
import ButtonsNav from '../../../components/maz-tu-info-bees/maz-tu-info-bees-account-details/ButtonsNav';
import MazTuInfoBeesBill from '../../../components/maz-tu-info-bees/maz-tu-info-bees-bill';
import MazTuInfoBeesOrder from '../../../components/maz-tu-info-bees/maz-tu-info-bees-order';
import MazTuInfoBeesAccountCredit from '../../../components/maz-tu-info-bees/maz-tu-info-bees-resume-credit';
import { financeOrderAndBill } from '../../../utils/utils';
import styles from './styles.module.scss';

const OrderAndBill = props => {
  const { data, user, setBackButton, sendGA, setPath, backButtonAction, needBackButton } = props;
  const [orderNav, billNav] = financeOrderAndBill;
  const [activeButton, setActiveButton] = useState(orderNav);

  const sendGAB = () => {
    if (orderNav === activeButton)
      props.sendGA('GAEvent', 'Content', 'Content', 'Billing', true, 'billing_button', 'Facturacion');
    if (billNav === activeButton)
      props.sendGA('GAEvent', 'Content', 'Content', 'Order details', true, 'order_details_button', 'Detalles del pedido');
  }

  return (
    <div className="mt-2 mb-3 col-12 col-lg-8">
      <div className={`${styles.container} card`}>
      <div className={styles.body}>
        {needBackButton ? (
          <div
            className="d-none d-lg-flex align-items-center p-2"
            onClick={backButtonAction}
          >
            <Icon icon={"back-navigation"} size="30" />
            <span>Atrás</span>
          </div>
        ) : ("")}
        <ButtonsNav
          options={financeOrderAndBill}
          activeOption={activeButton}
          setActiveOption={setActiveButton}
          onClick={sendGAB}
        />

        {orderNav === activeButton ? (
          <MazTuInfoBeesOrder
            data={data}
            user={user}
            back = {setBackButton}
          />
        ) : null}

        {billNav === activeButton ? (
          <MazTuInfoBeesBill
            data={data}
            user={user}
          />
        ) : null}
        </div>
        </div>
      </div>

  );
};

export default OrderAndBill;
