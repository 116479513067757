import { useCallback, useEffect, useState } from "react";
import useUenGoalsTable from "../UenGoalsTable/useUenGoalsTable";

const useUenGoalsFile = (setLoading, handleToast) => {
  const { postUenGoals, isLoading } = useUenGoalsTable(handleToast);
  const [file, setFile] = useState(null);
  const [parsedFile, setParsedFile] = useState(null);

  const triggerError = useCallback(() => {
    typeof handleToast === 'function' && handleToast({
      open: true,
      message: 'No fue posible extraer la información del archivo. Verifica que cumpla con el formato preestablecido.',
      type: 'error',
    });
  }, [handleToast]);

  const handleOnChange = ({ target }) => {
    const { files } = target || {};
    const [file] = files || [];
    if (file) setFile(file);
  };

  const csvFileToArray = useCallback((string = '') => {
    const array = string.toString().split(/\r?\n/).slice(0,101);
    if (2 !== array[0]?.toString()?.split(',').length) {
      triggerError();
      return;
    }
    
    return array.slice(1, 101).map((row) => {
      const [uen, filePoints] = row?.toString().split(",") || [];
      const intPoints = Number.parseInt(filePoints);
      const points = Number.isNaN(intPoints) ? null : intPoints;
      return (uen && points) ? { uen, points } : null;
    }).filter(validPair => !!validPair);
  }, [triggerError]);

  const fileCsvToJson = useCallback(() => {
    const fileReader = new FileReader();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        const jsonFile = csvFileToArray(text);
        if (jsonFile.length) { setParsedFile(jsonFile); }
        else { triggerError(); }
      };

      fileReader.readAsText(file, "UTF-8");
    }
  }, [csvFileToArray, file, triggerError]);

  useEffect(() => {
    if (!!file) {
      fileCsvToJson();
      setFile(null);
    }
  }, [file, fileCsvToJson]);

  useEffect(() => {
    if (!!parsedFile) {
      postUenGoals(parsedFile);
      setParsedFile(null);
    }
  }, [parsedFile, postUenGoals]);

  useEffect(() => (setLoading && setLoading(isLoading)), [isLoading, setLoading]);

  return {
    isLoading,
    handleOnChange,
    file,
  }
}

export default useUenGoalsFile;
