import React, { Component } from "react";

import fetchAPI from "../../utils/fetchAPI";
import { getVarConfig } from "../../utils/environment";
import { getUser } from "../../utils/sessionInfo";

import "./ProgramaMercado.scss";
import PageComponentSelector, { PAGES } from "./PageComponentSelector";

export default class ProgramaMercado extends Component {

  state = {
    page: "",
    data: null,
    alertTitle: "",
    alertMessage: "",
    alertButton: "",
    alertButtonCancel: "",
    alertOpen: false,
    alertType: "",
    callbackAlertClose: null,
    callbackAlertCancel: null
  }

  componentDidMount() {
    this.handleProcess({});
  }

  componentDidUpdate(prevProps, prevState) {
    const { page: prevPage } = prevState;
    const { page } = this.state;
    if ((prevPage === 'contrato-firma' && page === 'resumen') ||
        (prevPage === PAGES.resumen && page === PAGES.documentUpload)
    ) {
      this.handleProcess({});
    }
  }

  setCallbackAlertClose = (callbackAlertClose) => {
    this.setState({
      callbackAlertClose
    });
  }

  setCallbackAlertCancel = (callbackAlertCancel) => {
    this.setState({
      callbackAlertCancel
    });
  }

  setResumeStep = () => {
    this.handleProcess({
      isDocumentUpload: false,
      params: { transition: 'revertSigning' },
      protocol: "PATCH",
    });

    this.setPage('ReviewingInfo');

    this.props.handleToast({
      open: true,
      type: 'error',
      message: 'No fue posible obtener el contrato'
    });
  }

  setLoadingDocumentsStep = () => {
    this.handleProcess({
      params: { transition: 'revertSigningAndLoadDocs' },
      protocol: "PATCH",
    });

    this.setPage('LoadingDocuments');

    this.props.handleToast({
      open: true,
      type: 'error',
      message: 'Hubo un problema al obtener los documentos cargados'
    });
  }

  backToLoadDocumentsOnError = () => {
    this.props.handleToast({
      open: true,
      type: 'error',
      message: 'No se encontraron documentos cargados'
    });

    this.handleProcess({
      protocol: "PATCH",
      params: {
        transition: "returnToLoadDocs"
      }
    });
  };

  handleProcess = ({
    protocol = "GET",
    transition = "",
    loader = true,
    params = {},
    isDocumentUpload = false,
    isCreateContract = false,
    isStatusSignature = false,
    isSignature = false,
    callbackSuccess = null
  }) => {

    this.props.setLoaderOpen(loader);

    const user = getUser();
    let service = getVarConfig({ key: "REACT_APP_SERVICES_GET_ENROLLMENT" });
    service = service.replace("{cliente}", user.ID);

    if(isDocumentUpload) {
      service = service + "/step";
    }

    if(isCreateContract) {
      service = service + "/contract"
    }

    if(isSignature) {
      service = service + "/contract/sign"
    }

    if(isStatusSignature) {
      service = service + "/" + this.state.data.processId + "/contract"
    }

    fetchAPI({
      service,
      protocol,
      params,
      callbackThen: (response) => {

        try {
          this.setPage(response.data.process.status);
        } catch(e) {
          console.log(e);
        }

        try {
          if((isDocumentUpload || isCreateContract || isSignature || isStatusSignature) && callbackSuccess !== null) {
            callbackSuccess(response);
          }
        } catch(e) {
          console.log(e);
        }

        try {
          let data = response.data.currentStep !== null ? response.data.currentStep.stepData : response.data.process !== null ? response.data.process : {};
          if(response.data.currentStep !== null) {
            data.satType = response.data.process.satType;
            data.processId = response.data.currentStep.processId;
            data.responseData = response.data;
          }
          this.setState({
            data
          });
        } catch(e) {
          console.warn("No data for transition");
        }

        this.props.setLoaderOpen(false);

      },
      callbackError: (status) => {
        switch(status) {
          case 400:
          case 404:
            fetchAPI({
              service: service,
              protocol: "POST",
              params,
              callbackThen: (response) => {
                if(response.data.process !== null) {
                  this.setPage(response.data.process.status);

                  if ('ReviewingInfo' === response.data.process.status && 404 === status) {
                    this.backToLoadDocumentsOnError();
                  }

                } else {
                  this.setPage("Initial");
                }
                this.props.setLoaderOpen(false);
              },
              callbackError: (statusResponse, response) => {
                response.json().then(({ message }) => {
                  if (this.state.page === 'contrato-firma') {
                    if (message === 'Files not found') {
                      this.setLoadingDocumentsStep();
                    } else {
                      this.setResumeStep();
                    }
                  }
                });
              }
            });
            break;
          default:
            console.log(status);
        }
        this.props.setLoaderOpen(false);
      },

    });
  }

  setPage = (status) => {
    let page;
    switch(status) {
      case "Initial":
        page = PAGES.initialResume;
        break;
      case "Created":
        page = PAGES.brochuere;
        break;
      case "UpdatingPersonalInfo":
        page = PAGES.capturaInfo;
        break;
      case "LoadingDocuments":
        page = PAGES.documentUpload;
        break;
      case "ReviewingInfo":
        page = PAGES.resumen;
        break;
      case "SigningContract":
        page = PAGES.contratoFirma;
        break;
      case "returnToPersonalInfo":
        page = PAGES.capturaInfo;
        break;
      case "ProcessCompleted":
        page = PAGES.thankYou;
        break;
      default:
        page= PAGES.initialResume;
    }
    this.setState({
      page
    });
  }

  handleAlert = ({
    title = "",
    message = "",
    toggle = false,
    alertButtonCancel = "",
    alertButton = "",
    callback = null,
    type = "normal"
  }) => {

    if(callback !== null) {
      callback();
    }

    this.setState({
      alertOpen: toggle,
      alertTitle: title,
      alertMessage: message,
      alertButtonCancel,
      alertButton,
      alertType: type
    });

  }

  render() {

    const alertOpen = this.state.alertOpen ? "open" : "close";

    return(
      <div className="programa-mercado">

        {<PageComponentSelector
          page={this.state.page}
          handleProcess={this.handleProcess}
          data={this.state.data}
          setLoaderOpen={this.props.setLoaderOpen}
          handleAlert={this.handleAlert}
          setCallbackAlertClose={this.setCallbackAlertClose}
          setCallbackAlertCancel={this.setCallbackAlertCancel}
        />}

        <div className={"programa-mercado__alert programa-mercado__alert--" + alertOpen + " programa-mercado__alert programa-mercado__alert--" + this.state.alertType}>

          <div className="programa-mercado__alert__message">

            <div className="programa-mercado__alert__message__title">{ this.state.alertTitle }</div>

            <div className="programa-mercado__alert__message__text">{ this.state.alertMessage }</div>

            <div className="programa-mercado__alert__message__buttons">

              {
                this.state.alertButton !== "" ?
                  <button
                    className="programa-mercado__alert__message__buttons__close"
                    onClick={() => {
                      this.handleAlert({
                        toggle: false,
                        callback: this.state.callbackAlertClose
                      })
                    }}>
                      { this.state.alertButton }
                  </button> : null
              }

              {
                this.state.alertButtonCancel !== "" ?
                  <button
                    className="programa-mercado__alert__message__buttons__close--secondary"
                    onClick={() => {
                      this.handleAlert({
                        toggle: false,
                        callback: this.state.callbackAlertCancel
                      })
                    }}>
                      { this.state.alertButtonCancel }
                  </button> : null
              }

            </div>

          </div>

        </div>

      </div>
    )
  }

}