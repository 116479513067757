import React, { Component } from "react";

import "./MazTuInfoBeesTitle.scss";

export default class MazTuInfoBeesTitle extends Component {

  render() {

    const title = typeof this.props.title !== "undefined" ? this.props.title : "";
    const subtitle = typeof this.props.subtitle !== "undefined" ? this.props.subtitle : "";

    return (
      <div className="maz-tu-info-bees-title">
        {title !== "" ? 
          <div className="maz-tu-info-bees-title__title">
            {title}
          </div> : null
        }
        {subtitle !== "" ? 
          <div className="maz-tu-info-bees-title__subtitle">
            {subtitle}
          </div> : null
        }
      </div>
    );
  }
}
