import React, { Component } from "react";

import "./MazTuInfoBeesModalAction.scss";

export default class MazTuInfoBeesModalAction extends Component {

  state = {
    disabledAccept: true
  }

  handleCheck = (e) => {
    this.setState({
      disabledAccept: !e.target.checked
    });
  }

  render() {

    const title = typeof this.props.title !== "undefined" ? this.props.title : "Título";
    const terms = typeof this.props.terms !== "undefined" ? this.props.terms : "";
    const buttonCancelText = typeof this.props.buttonCancelText !== "undefined" ? this.props.buttonCancelText : "CANCELAR";
    const buttonAcceptText = typeof this.props.buttonAcceptText !== "undefined" ? this.props.buttonAcceptText : "ACEPTAR";
    const checkboxText = typeof this.props.checkboxText !== "undefined" ? this.props.checkboxText : "Checkbox";
    const showAcceptTerms = typeof this.props.showAcceptTerms !== "undefined" ? this.props.showAcceptTerms : false;
    const handleClickAccept = typeof this.props.handleClickAccept !== "undefined" ? this.props.handleClickAccept : null;
    const handleClickCancel = typeof this.props.handleClickCancel !== "undefined" ? this.props.handleClickCancel : null;
    const showCancelButton = typeof this.props.showCancelButton !== "undefined" ? this.props.showCancelButton : false;

    const show = typeof this.props.show !== "undefined" ? this.props.show : false;

    const disabledAccept = showAcceptTerms ? this.state.disabledAccept : false;

    const styleShow = {
      display: show ? "flex" : "none"
    }

    return(
      <div className="maz-tu-info-bees-modal-action" style={styleShow}>

        <div className="maz-tu-info-bees-modal-action__card">

          <div className="maz-tu-info-bees-modal-action__card__title">
            {title}
          </div>

          <div 
            className="maz-tu-info-bees-modal-action__card__content"
            dangerouslySetInnerHTML={{ __html: terms }}>     
          </div>

          <div className="maz-tu-info-bees-modal-action__card__actions">

            {
              showAcceptTerms ? 
                <div className="maz-tu-info-bees-modal-action__card__actions__accept-terms">
                  <label className="form-control">
                    <input type="checkbox" name="checkbox" onClick={this.handleCheck} />
                    {checkboxText}
                  </label>
                </div> : null
            }

            <div className="maz-tu-info-bees-modal-action__card__actions__buttons">

              {
                showCancelButton ?
                  <button className="secondary" onClick={handleClickCancel}>
                    {buttonCancelText}
                  </button> : null
              }

              <button 
                className="primary" 
                onClick={handleClickAccept}
                disabled={disabledAccept}>
                {buttonAcceptText}
              </button>

            </div>

          </div>

        </div>

      </div>
    )
  }

}
