import React, { Component } from "react";

import MazTuInfoBeesCardInfo from "../../../components/maz-tu-info-bees/maz-tu-info-bees-card-info/MazTuInfoBeesCardInfo";
import MazTuInfoBeesInput from "../../../components/maz-tu-info-bees/maz-tu-info-bees-input/MazTuInfoBeesInput";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesCardInfoV2 from "../../../components/maz-tu-info-bees/maz-tu-info-bees-card-info-v2";

import { once } from "../../../utils/events";
import { getUser } from "../../../utils/sessionInfo";

import "./CapturaInfo.scss";
import MazTuInfoBeesGoalOptions from "../../../components/maz-tu-info-bees/maz-tu-info-bees-goal-options";

export default class CapturaInfo extends Component {

  state = {
    optionsSelectTipoPersona: [
      {
        key: "Fisica",
        value: "Persona Física"
      },
      {
        key: "Moral",
        value: "Persona Moral"
      }
    ],
    optionsSelectTipoID: [],
    valueSelTipoPersona: "",
    valueSelTipoID: "",
    valueEmail: "",
    valuePhoneNumber: "",
    validEmail: false,
    validPhone: false,
    personaType: "",
  }

  componentDidMount() {
    this.setOptionsSelectTipoID([
      {
        key: "ine",
        value: "INE"
      },
      {
        key: "passport",
        value: "Pasaporte"
      }
    ]);
    try{
      const handleProcess = this.props.handleProcess;
      once("click-back-navigation-programa-mercado", function(event) {
        handleProcess({
          protocol: "PATCH",
          params: {
            transition: "returnToTerms"
          }
        });
      });
    } catch(e) {
      console.log(e);
    }
  }

  setOptionsSelectTipoID = (options) => {
    this.setState({
      optionsSelectTipoID: options
    });
  }

  setValueSelTipoPersona = (value) => {
    this.setState({
      valueSelTipoPersona: value.value
    });
  }

  setValueSelTipoID = (value) => {
    this.setState({
      valueSelTipoID: value.value
    });
  }

  setValueEmail = (value) => {
    this.setState({
      valueEmail: value
    });
    this.validateEmail(value);
  }

  setValuePhoneNumber = (value) => {
    this.setState({
      valuePhoneNumber: value
    });
    this.validatePhone(value);
  }

  validateEmail = (value) => {
    const regex = new RegExp(/^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    this.setState({
      validEmail: regex.test(value)
    });
  }

  validatePhone = (value) => {
    const regex = new RegExp(/^[0-9]{10}$/);
    this.setState({
      validPhone: regex.test(value)
    });
  }

  handleContinue = () => {
    try {
      this.props.handleProcess({
        protocol: "PATCH",
        params: {
          transition: "updatePersonalInfo",
          stepData: {
            satType: this.state.valueSelTipoPersona,
            email: this.state.valueEmail,
            phone: this.state.valuePhoneNumber,
            id: this.state.valueSelTipoID,
          }
        }
      });
    } catch(e) {
      console.log(e);
    }
  }

  render() {

    const errorEmail = this.state.valueEmail === ""
      ? false
      : !this.state.validEmail;

    const errorPhone = this.state.valuePhoneNumber === ""
      ? false
      : !this.state.validPhone;

    const disableButton =
      (this.state.validEmail
      && this.state.validPhone
      && this.state.valueSelTipoPersona !== ""
      && this.state.valueSelTipoPersona !== "0"
      && this.state.valueSelTipoID !== ""
      && this.state.valueSelTipoID !== "0") ? false : true;

    const user = getUser();

    return(
      <div className="captura-info">

        <MazTuInfoBeesCardInfoV2 />

        { user.clubLevel === "B_MAX" ? <MazTuInfoBeesCardInfo info="bmax" /> : null }

        <div className="captura-info__form">

          <div className="captura-info__title">
            Datos adicionales
          </div>

          <MazTuInfoBeesInput
            textLabel="Tipo de persona"
            id="sel-tipo-persona"
            type="select"
            options={this.state.optionsSelectTipoPersona}
            setValue={this.setValueSelTipoPersona}
          />

          <MazTuInfoBeesInput
            textLabel="Correo electrónico"
            textPlaceHolder="digite acá"
            id="txt-correo-electronico"
            type="email"
            setValue={this.setValueEmail}
            error={errorEmail}
          />

          <MazTuInfoBeesInput
            textLabel="Número de celular"
            textPlaceHolder="+52 "
            id="txt-numero-celular"
            type="number"
            setValue={this.setValuePhoneNumber}
            error={errorPhone}
          />

          <MazTuInfoBeesInput
            textLabel="Tipo de identificación"
            id="sel-tipo-identificacion"
            type="select"
            options={this.state.optionsSelectTipoID}
            setValue={this.setValueSelTipoID}
          />

          <div className="captura-info__notice">
            Recuerda que el documento cargado debe coincidir con el del titular registrado en Grupo Modelo
          </div>

          <MazTuInfoBeesButton
            textButton="CARGAR DOCUMENTOS"
            handleClick={this.handleContinue}
            disabled={disableButton} />

        </div>

      </div>
    )
  }

}