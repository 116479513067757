import React from 'react';
import Icon from '../../../global/Icon/Icon';
import styles from './styles.module.scss';

const SearchInput = (props) => {
  const { hide, searchText, setSearchText } = props;
  if (hide) return null;
  return (
    <div className={`${styles.searcher}`}>
      <input
        type="text"
        placeholder="Buscar"
        value={searchText}
        onChange={({target}) => setSearchText(target.value)}
      />
      <button>
        <Icon
          icon="search"
          width="14"
          height="14"
        />
      </button>
    </div>
  );
};

export default SearchInput;
