import { getVarConfig } from "./environment";

const ddDate = date => {
  let dd = `${date.getDate()}`;
  if (dd.length < 2) dd = `0${dd}`;
  return dd;
}

const mmDate = date => {
  let mm = `${date.getMonth() + 1}`;
  if (mm.length < 2) mm = `0${mm}`;
  return mm;
}

/**
 * It takes a date, converts it to a new Date object,
 * then returns a string of the day, month, and
 * year, separated by a forward slash.
 * @returns A function that takes a date as an argument and
 * returns a string in the format dd/mm/yyyy.
 */
const formatDateDDMMYYYY = date => {
  if (!date) return '';

  const d = new Date(date);
  const mm = mmDate(d);
  const dd = ddDate(d);
  const year = `${d.getFullYear()}`;

  return [dd, mm, year].join('/');
};

const formatDateDDMMYYYYFinance = date => {
  if (!date) return '';

  date = date + 'T00:00:00.000-05:00'

  const d = new Date(date);
  const mm = mmDate(d);
  const dd = ddDate(d);
  const year = `${d.getFullYear()}`;

  return [dd, mm, year].join('/');
};


/**
 * It sorts an array of objects by a key in descending order.
 * @param arr - The array to sort
 * @param key - The key to sort by.
 */
const sortArrayObjectByKeyDesc = (arr, key) => arr
.sort((a, b) => {
  const firstValue = String(a[key]);
  const secondValue = String(b[key]);

  if (firstValue > secondValue) return 1;
  if (firstValue < secondValue) return -1;
  return 0;
});

/**
 * It sorts an array of objects by a key in ascending order.
 * @param arr - The array you want to sort
 * @param key - The key to sort by.
 */

const sortArrayObjectByKeyAsc = (arr, key) => arr
.sort((a, b) => {
const firstValue = String(a[key]);
const secondValue = String(b[key]);

if (firstValue > secondValue) return -1;
if (firstValue < secondValue) return 1;
return 0;
});


const financeCurrencyFormat = plainNum => {
  let num = plainNum?plainNum:0;
  let number = typeof num === 'string'? parseFloat(num):num;
  const formatedNumber = '$ ' + number.toLocaleString('en-US', { maximumFractionDigits: 2 , minimumFractionDigits: 2 });
  return formatedNumber;
}

const cashpaymenttypes = ['D000','D03P'];
const payment_doc_types = ['EF','TE','TJ','CH','3P','EW'];
const bills_doc_types = ['FA','RG','RD','RC'];
const payment_types = {'EF':'Efectivo', 'TE':'Transferencia electr�nica','TJ':'Tarjeta','CH':'Cheque','3P':'GLP', 'EW':'E-Wallet'}
const financeOpenItemsNavOptions= ['Todos', 'Contado', 'Cr�dito', 'Pagos'];
const financeOrderAndBill = ['Detalle del pedido', 'Facturaci�n'];
const financeOrderByOptions= [
  'Monto',
  'Fecha de creaci�n',
  'Fecha de vencimiento',
  'Sucursal'
];

const financeOrderWay = ["ASC","DESC"]

const getStripeByType = (type) => {
  type = type || '';
  type = type.trim().toLowerCase();
  switch(type) {
    case 'coverage':
      return 'Cobertura marca premium';
    case 'premium':
    case 'marcas p':
      return 'Volumen marca premium';
    case 'total':
      return 'Cerveza volumen total';
    case 'marketpl':
        return 'Marketplace';
    case 'lo + nue':
      return 'Lo + Nuevo';
    case 'retornab':
      return 'Retornable';
    case 'foto gan':
      return 'Foto ganadora';
    case 'cerveza':
      return 'Cerveza volumen total';
      default: return '';
  }
};

const escapeAccents = (str) => {
  try{
    const parsed = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return parsed;
  } catch {
    return ""
  }

}

const getNicknameByFullName = (fullName) => {
  if (fullName) {
    const [name, surname] = fullName.split(' ');
    return `${name ? name[0] : ''}${surname ? surname[0] : (name ? name[1] : '')}`.toUpperCase();
  }

  return '';
}

const removeNumbersFronString = (str) => {
  if (str?.trim()) {
    return str.replace(/[0-9]/g, '');
  }
  return '';
};

const removeLettersFronString = (str) => {
  if (str?.trim()) {
    return str.replace(/[^0-9]/g, '');
  }
  return '';
};

//Funci�n para validar un RFC
// @see https://es.stackoverflow.com/a/31714
function rfcValido(rfc, aceptarGenerico = true) {
  const re       = /^([A-Z�&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var   validado = rfc.match(re);

  if (!validado)  //Coincide con el formato general del regex?
      return false;

  //Separar el d�gito verificador del resto del RFC
  const digitoVerificador = validado.pop(),
        rfcSinDigito      = validado.slice(1).join(''),
        len               = rfcSinDigito.length,

  //Obtener el digito esperado
        diccionario       = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ �",
        indice            = len + 1;
  var   suma,
        digitoEsperado;

  if (len == 12) suma = 0
  else suma = 481; //Ajuste para persona moral

  for(var i=0; i<len; i++)
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  digitoEsperado = 11 - suma % 11;
  if (digitoEsperado == 11) digitoEsperado = 0;
  else if (digitoEsperado == 10) digitoEsperado = "A";

  //El d�gito verificador coincide con el esperado?
  // o es un RFC Gen�rico (ventas a p�blico general)?
  if ((digitoVerificador != digitoEsperado)
   && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
      return false;
  else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
      return false;
  return !!(rfcSinDigito + digitoVerificador);
}


const validEmail = email => {
  if (!email) return false;
  const regex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
  const validado = email.match(regex);
  return !!validado;
}

const monthLabel = (date = new Date()) => {
  const localDate = new Date(date);
  if (date && !Number.isNaN(localDate)) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return months[localDate.getMonth()];
  }
  return '';
};

const challengeTypeLabel = (challengeType) => {
  switch(challengeType) {
    case "cerveza":
    case "total":
      return getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_VOLUMEN_TOTAL" });
    case "marcas p":
    case "premium":
      return getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_MARCAS_PREMIUM" });
    case "lo + nue":
    case "coverage":
      return getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA" });
    case "retornab":
      return 'Retornable';
    case 'marketpl':
      return getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA_CALENDARIO" });
    case 'billing':
      return getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_FACTURACION" });
    default:
      return challengeType;
}}

export {
  sortArrayObjectByKeyAsc,
  sortArrayObjectByKeyDesc,
  formatDateDDMMYYYY,
  formatDateDDMMYYYYFinance,
  financeCurrencyFormat,
  cashpaymenttypes,
  payment_doc_types,
  bills_doc_types,
  payment_types,
  financeOpenItemsNavOptions,
  financeOrderByOptions,
  financeOrderAndBill,
  financeOrderWay,
  getStripeByType,
  escapeAccents,
  rfcValido,
  validEmail,
  removeNumbersFronString,
  removeLettersFronString,
  getNicknameByFullName,
  monthLabel,
  challengeTypeLabel,
}
