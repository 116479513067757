import Brochuere from "../Brochuere/Brochuere";
import CapturaInfo from "../CapturaInfo/CapturaInfo";
import ContratoFirma from "../ContratoFirma/ContratoFirma";
import DocumentUpload from "../DocumentUpload/DocumentUpload";
import InitialResume from "../InitialResume";
import Resumen from "../Resume/Resumen";
import ThankYou from "../ThankYou/ThankYou";
import ValidatingRequest from "../ValidatingRequest/ValidatingRequest";

import { defaultProps, propTypes } from "./types";

export const PAGES = {
  brochuere: 'brochuere',
  capturaInfo: 'captura-info',
  documentUpload: 'document-upload',
  resumen: 'resumen',
  contratoFirma: 'contrato-firma',
  thankYou: 'thank-you',
  validatingRequest: 'validating-request',
  initialResume: 'initial-resume',
};

const PageComponentSelector = props => {
  const {
    handleProcess,
    page,
    data,
    setLoaderOpen,
    handleAlert,
    setCallbackAlertClose,
    setCallbackAlertCancel,
  } = props;

  const componentSelector = () => {
    switch (page) {
      case PAGES.brochuere:
        return <Brochuere handleProcess={handleProcess} />;
      case PAGES.capturaInfo:
        return <CapturaInfo handleProcess={handleProcess} dataProcess={data} />
      case PAGES.documentUpload:
        return <DocumentUpload
                handleProcess={handleProcess}
                setLoaderOpen={setLoaderOpen}
                dataProcess={data}
                handleAlert={handleAlert}
                setCallbackAlertClose={setCallbackAlertClose}
              />;
      case PAGES.resumen:
        return <Resumen
                handleProcess={handleProcess}
                dataProcess={data}
                handleAlert={handleAlert}
                setCallbackAlertClose={setCallbackAlertClose}
                callbackAlertCancel={setCallbackAlertCancel}
              />;
      case PAGES.contratoFirma:
        return <ContratoFirma
                handleProcess={handleProcess}
                setLoaderOpen={setLoaderOpen}
                dataProcess={data}
              />;
      case PAGES.thankYou:
        return <ThankYou handleProcess={handleProcess} />;
      case PAGES.validatingRequest:
        return <ValidatingRequest handleProcess={handleProcess} />;
      case PAGES.initialResume:
        return <InitialResume handleProcess={handleProcess} />;
      default:
        return null;
    }
  }

  return (componentSelector());
}

PageComponentSelector.propTypes = propTypes;
PageComponentSelector.defaultProps = defaultProps;

export default PageComponentSelector;
