import React from 'react';
import { Dialog as MUIDialog } from '@mui/material';
import './styles.scss';

const Dialog = (props) => {
  const { open, onClose, children, customDialog } = props;
  const handleClose = () => {
    if (typeof onClose === 'function') onClose();
    return;
  }
  return (
    <MUIDialog
      onClose={handleClose} open={open}
      {...customDialog}
    >
      {children}
    </MUIDialog>
  );
};

export default Dialog;
