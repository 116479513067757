import React, { Component } from "react";
import moment from 'moment';
import fetchAPI from "../../utils/fetchAPI";
import { getVarConfig } from "../../utils/environment";
import { MainContext } from '../../MainContext';
import { getUser } from "../../utils/sessionInfo";
import { setUser } from "../../utils/sessionInfo";

import "./Dashboard.scss";

import MazTuInfoBeesContract from "../../components/maz-tu-info-bees/maz-tu-info-bees-contract/MazTuInfoBeesContract";
import MazTuInfoBeesCardProgress from "../../components/maz-tu-info-bees/maz-tu-info-bees-card-progress/MazTuInfoBeesCardProgress";
import MazTuInfoBeesFooter from "../../components/maz-tu-info-bees/maz-tu-info-bees-footer/MazTuInfoBeesFooter";

// B_MAX
import ClubBMaxModal from "../../components/maz-tu-info-bees/maz-tu-info-bees-modal-bmax";

import TagManager from 'react-gtm-module';
import MazTuInfoBeesGallery from "../../components/maz-tu-info-bees/maz-tu-info-bees-gallery/MazTuInfoBeesGallery";
import MazTuInfoBeesCardPointsV2 from "../../components/maz-tu-info-bees/maz-tu-info-bees-card-points/MazTuInfoBeesCardPointsV2";

const imgClubBBlack = require('../../assets/images/B_BLACK.png');
const imgClubBMax = require('../../assets/images/B_MAX.png');
const ESCAPED_SPACE = '%20';
const ESCAPED_PLUS = '%2B';

export default class Dashboard extends Component {

  static contextType = MainContext;

  state = {
    challenges: [],
    history: [],
    performance: {},
    contract: {},
    loaderPerformanceOpen: false,
    loaderContractOpen: false,
    coverageTotalProducts: 0,
    coveragePurchasedProducts: 0,
    coverageLoader: false,
    billingLoader: false,
    billingReal: 0,
    billingGoal: 0,
    billingPoints: 0,
    challengesNotAccepted: [],
    challengesProgress: [],
    challengesCompleted: [],
    challengesCoverageNotAccepted: [],
    challengesCoverageProgress: [],
    challengesCoverageCompleted: [],
    challengesCoverage: []
  }

  componentDidMount() {
    this.getChallengeInfo();
    this.getContractInfo();
    this.getCoverage();
    this.getBilling();
    this.getBmaxStatus();
  }

  getChallengeInfo = () => {

    const user = getUser();
    const setLoaderOpen = this.props.setLoaderOpen;

    setLoaderOpen(true);

    let urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_CHALLENGES" });
    urlService = urlService.replace("{client}", user.ID);
    const service = `${urlService}total,premium,coverage,retornab,marcas${ESCAPED_SPACE}p,lo${ESCAPED_SPACE}${ESCAPED_PLUS}${ESCAPED_SPACE}nue,cerveza`;

    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: (response) => {
        setLoaderOpen(false);

        let arrChallengeNotAccepted = [], arrChallengeProgress = [], arrChallengeCompleted = [];

        try {
          response.data.map((challenge, index) => {
            if(challenge.challengeAccepted === null || challenge.challengeAccepted === 0) {
              arrChallengeNotAccepted.push({
                urlImage: challenge.challenge.image,
                title: challenge.challenge.name,
                challengeType: challenge.challenge.type,
                points: challenge.goalPoints,
                expirationDate: challenge.challenge.updatedAt
              });
            } else {
              if(challenge.challengeAccepted === 1) {
                if(challenge.real >= challenge.goal) {
                  arrChallengeCompleted.push({
                    urlImage: challenge.challenge.image,
                    title: challenge.challenge.name,
                    challengeType: challenge.challenge.type,
                    points: challenge.goalPoints,
                    expirationDate: challenge.challenge.updatedAt
                  });
                } else {
                  arrChallengeProgress.push({
                    urlImage: challenge.challenge.image,
                    title: challenge.challenge.name,
                    challengeType: challenge.challenge.type,
                    points: challenge.goalPoints,
                    expirationDate: challenge.challenge.updatedAt
                  });
                }
              }
            }
          });
        } catch(e) {
          console.log(e);
        }

        this.setState({
          responseChallenges: response?.data,
          challengesNotAccepted: arrChallengeNotAccepted,
          challengesProgress: arrChallengeProgress,
          challengesCompleted: arrChallengeCompleted
        });

      },
      callbackError: (status) => {
        if(status === 404) {

        }
        setLoaderOpen(false);
      },
    });
  }

  getCoverage = () => {
    const user = getUser();
    this.setState({
      loader: true
    });
    fetchAPI({
      service: "v1/mdcolombia/challenges/customers/" + user.ID + "?type=marketpl",
      protocol: "GET",
      callbackThen: (response) => {

        let arrChallengeNotAccepted = [], arrChallengeProgress = [], arrChallengeCompleted = [];

        try {
          response?.data?.map((challenge, index) => {
            if(challenge.challengeAccepted === null || challenge.challengeAccepted === 0) {
              arrChallengeNotAccepted.push({
                urlImage: challenge.challenge.image,
                title: challenge.challenge.name,
                challengeType: challenge.challenge.type,
                points: challenge.goalPoints,
                expirationDate: challenge.challenge.updatedAt
              });
            } else {
              if(challenge.challengeAccepted === 1) {
                if(challenge.real >= challenge.goal) {
                  arrChallengeCompleted.push({
                    urlImage: challenge.challenge.image,
                    title: challenge.challenge.name,
                    challengeType: challenge.challenge.type,
                    points: challenge.goalPoints,
                    expirationDate: challenge.challenge.updatedAt
                  });
                } else {
                  arrChallengeProgress.push({
                    urlImage: challenge.challenge.image,
                    title: challenge.challenge.name,
                    challengeType: challenge.challenge.type,
                    points: challenge.goalPoints,
                    expirationDate: challenge.challenge.updatedAt
                  });
                }
              }
            }
          });
        } catch(e) {
          console.log(e);
        }

        this.setState({
          responseChallengesMarketpl: response.data,
          challengesCoverageNotAccepted: arrChallengeNotAccepted,
          challengesCoverageProgress: arrChallengeProgress,
          challengesCoverageCompleted: arrChallengeCompleted,
          challengesCoverage: response.data
        });

      },
      callbackError: (status) => {
        console.log(status);
      },
    });
  }

  getBmaxStatus = () => {
    let localUser = getUser();

    if (localUser.isBmax === 'CANDIDATE') {
      if (localStorage.getItem('bmaxLogin')) {
        localStorage.removeItem('bmaxLogin')
        this.props.showModalBMax()
        return;
      };
      let startTime = moment();
      let endTime = moment('15:00:00', 'HH:mm:ss a');
      let duration = moment.duration(endTime.diff(startTime));

      let minutes = parseInt(duration.asMinutes());
      if (
        minutes <= 120 &&
        minutes >= 0
      ) {
        this.props.showModalBMax()
      }
    }
    if (localUser.isBmax === 'ACCEPTED') {
      this.props.openBmaxEndModal()
    }
  }

  setChallengeLabel = () => {

    const user = getUser();

    let urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_CHALLENGE_PROGRESS" });
    urlService = urlService.replace("{client}", user.ID);

    fetchAPI({
      service: urlService,
      protocol: "GET",
      callbackThen: this.callbackThenSetChallengeLabel,
      callbackError: this.callbackErrorSetChallengeLabel,
    });
  }

  handleNavigationHistorial = () => {
    this.props.setLoaderOpen(true);
    this.getHistoryInfo();
  };

  getHistoryInfo = () => {
    const user = getUser();
    const userID = user.ID;
    fetchAPI({
      service: getVarConfig({ key: "REACT_APP_SERVICES_GET_HISTORY" }) + userID,
      protocol: "GET",
      callbackThen: this.handleGetHistory,
      callbackError: this.handleErrorGetHistory,
    });
  }

  handleGetHistory = (response) => {
    this.props.setHistory(response.data);
    this.props.setPath("/historial");
    this.props.setLoaderOpen(false);
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent',
        event_category: 'Ver historial',
        event_action: 'Success',
        event_label: null,
        userid: this.props.user.ID,
        POC_name: this.props.user.name
      },
      dataLayerName: 'PageDataLayer'
    });
  }

  handleErrorGetHistory = (status) => {
    this.props.setLoaderOpen(false);
  }

  getContractInfo = () => {

    const user = getUser();

    let urlService = "v1/mdcolombia/customers/{client}/contract";
    urlService = urlService.replace("{client}", user.ID);

    this.setState({
      loaderContractOpen: true
    });

    fetchAPI({
      service: urlService,
      protocol: "GET",
      callbackThen: this.callbackThenGetContract,
      callbackError: this.callbackErrorGetContract,
    });
  }

  callbackThenGetContract = (response) => {
    try {
      this.setState({
        contract: response.data
      });
      this.setState({
        loaderContractOpen: false
      });
      this.props.setContract(response.data);
    } catch(e) {
      console.log(e);
      this.setState({
        loaderContractOpen: false
      });
    }
  }

  callbackErrorGetContract = (status) => {
    let messageError, type;
    switch(status) {
      case 404:
        messageError = "No se encuentra información del Contrato";
        type = "error";
        break;
      case 505:
        messageError = "Hubo un error al obtener la información del Contrato";
        type = "error";
        break;
      default:
        messageError = "Hubo un error al obtener la información del Contrato";
        type = "error";
    }
    this.props.handleToast({
      open: true,
      type: type,
      message: messageError
    });
    this.setState({
      loaderContractOpen: false
    });
  }

  handleContract = () => {
    const contractData = this.state.contract;
    const setPath = this.props.setPath;

    if(typeof contractData.contract !== "undefined") {
      setPath("/contrato");
    } else {
      this.props.handleToast({
        open: true,
        type: "error",
        message: "No se encuentra la información del contrato"
      });
    }

  }

  handleNavigationBilling = () => {
    const setPath = this.props.setPath;
    setPath("/facturacion");
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent',
        event_category: 'Billing',
        event_action: 'Interaction',
        event_label: 'Click',
        userid: this.props.user.ID,
        POC_name: this.props.user.name
      },
      dataLayerName: 'PageDataLayer'
    });
  }

  handleNavigationCoverage = () => {
    const setPath = this.props.setPath;
    setPath("/cobertura");
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent',
        event_category: 'Calendar coverage',
        event_action: 'Interaction',
        event_label: 'Click',
        userid: this.props.user.ID,
        POC_name: this.props.user.name
      },
      dataLayerName: 'PageDataLayer'
    });
  }

  getBilling = () => {
    const user = getUser();
    let urlService = "v1/MX/challenges/billing/customers/{client}";
    urlService = urlService.replace("{client}", user.ID);
    this.setState({
      billingLoader: true
    });
    fetchAPI({
      service: urlService,
      protocol: "GET",
      callbackThen: (response) => {
        if(response.data.length > 0) {
          this.setState({
            billingGoal: response.data[0].goal,
            billingReal: response.data[0].real,
            billingPoints: response.data[0].points,
            billingLoader: false
          });
        }
      },
      callbackError: (status) => {
        console.log(status);
        this.setState({
          billingLoader: false
        });
      },
    });
  }

  endBMax = async() => {
    const localUser = getUser();
    let urlService = getVarConfig({ key: "REACT_APP_CHANGE_BMAX_STATUS" });
    await fetchAPI({
      service: urlService,
      protocol: "POST",
      params: {
        prevStatus: localUser.isBmax,
        customerId: localUser.ID,
      },
      callbackThen: this.handleContinueBmax,
      callbackError: this.handleContinueErrorBmax,
    });
    const userBees = {
      name: localUser.name,
      ID:  localUser.ID,
      drv: localUser.drv,
      rfc: localUser.rfc,
      phone: localUser.phone,
      permissions: localUser.permissions,
      level: localUser.level,
      clubLevel: localUser.clubLevel,
      resultsSeen: localUser.resultsSeen,
      isTradeUser : localUser.isTradeUser,
      isBmax: 'COMPLETE',
    }
    this.props.closeBmaxEndModal()
    setUser(
      JSON.stringify(userBees)
    )
  }

  render() {

    const user = getUser();
    const nameFacturacion = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_FACTURACION" });
    let valueGoalBilling = getVarConfig({ key: "REACT_APP_VALUE_GOAL_CHALLENGE_BILLING" });
    valueGoalBilling = valueGoalBilling !== "" ? valueGoalBilling : "3,500";

    return (
      <div className="dashboard">

        <div className="dashboard__title">
          <div className="dashboard__title__club">
            <img src={ user.clubLevel === "B_BLACK" ? imgClubBBlack : imgClubBMax } alt="club-type" />
            Desempeño
          </div>
          <div className="dashboard__title__level">
            { "Nivel: " + user.level }
          </div>
        </div>

        <MazTuInfoBeesCardPointsV2
          challenges={this.state.responseChallenges}
          challengesMarketpl={this.state.responseChallengesMarketpl}
          billing={{
                    real: this.state.billingReal,
                    goal: this.state.billingGoal,
                    points: this.state.billingPoints,
                  }}
        />

        {
          user.clubLevel === 'B_MAX' ?
            <>
              <MazTuInfoBeesCardProgress
                type="billing"
                title={nameFacturacion}
                description={`Recibe hasta *${valueGoalBilling} puntos** al completar este desafío.`}
                progressTitle="Cantidad facturada"
                loader={this.state.billingLoader}
                billingReal={this.state.billingReal}
                billingGoal={this.state.billingGoal}
                billingPoints={this.state.billingPoints}
                handleClick={this.handleNavigationBilling} />
            </> : null
        }

        <div className="dashboard__gallery">

          { this.state.challengesNotAccepted.length > 0 ?
            <MazTuInfoBeesGallery
              data={this.state.challengesNotAccepted}
              coverage={this.state.challengesCoverageNotAccepted}
              type="not-accepted" /> : null }

          { this.state.challengesProgress.length > 0 ?
            <MazTuInfoBeesGallery
              data={this.state.challengesProgress}
              coverage={this.state.challengesCoverageProgress}
              type="progress" /> : null }

          { this.state.challengesCompleted.length > 0 ?
            <MazTuInfoBeesGallery
              data={this.state.challengesCompleted}
              coverage={this.state.challengesCoverageCompleted}
              type="completed" /> : null }

        </div>

        <MazTuInfoBeesContract
          setPath={this.props.setPath}
          setLoaderOpen={this.props.setLoaderOpen}
          contract={this.state.contract}
          loaderContractOpen={this.state.loaderContractOpen}
          handleClick={this.handleContract}
        />

	      <ClubBMaxModal
          logoClass={"logo_clubBMax"}
          isVissible={
            this.props.bmaxEndModal
          }
          title="¡Bienvenido a Club B MAX!"
          titleStyle={{
            fontWeigth: 'bold',
            fontSize: 22,
            marginTop: 45,
          }}
          Logo={() => {
            return (
              <img
                src={require('../../assets/icons/Logo_ClubBMaxt_P0 1.png')}
                height="116.52"
                width="99.17"
                alt="logo"
              />
            )
          }}
          Body={() => {
            return (
              <>
                <p
                  style={{fontSize: 16, textAlign: 'center'}}
                >
                  Aprovecha todos los desafíos: <br/>
                  <strong>Cerveza 🍺</strong>{' y '}<strong>Miscelánea 🧺 </strong>
                </p>
                <p
                  style={{
                    marginTop: 8,
                    fontSize: 16,
                    textAlign: 'center'
                  }}
                >
                  GANA el máximo de Puntos BEES
                </p>
              </>
            );
          }}
          textButton={"COMENZAR"}
          onClick={this.endBMax}
          buttonType="secondary"
        />

        <MazTuInfoBeesFooter />

      </div>
    );
  }
}
