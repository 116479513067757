import React, { useState } from 'react';
import BandButton from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/BandButton';
import Header from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header';
import LegalRepresentativeForm from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/LegalRepresentativeForm';
import LegalRepresentativeTable from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/LegalRepresentativeTable';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const LegalRepresentative = props => {
  const {
    setLoading,
    loading,
    handleToast,
  } = props;

  const [showForm, setShowForm] = useState(false);
  const [currentLegalRepresentative, setCurrentLegalRepresentative] = useState(undefined);

  const cancelForm = () => {
    setCurrentLegalRepresentative(undefined);
    setShowForm(false);
  };

  const handleEdit = (legalRepresentative) => {
    setCurrentLegalRepresentative(legalRepresentative);
    setShowForm(true);
  }

  const handleSubmit = (isEdit) => {
    const message = isEdit ? 'Representante legal editado correctamente' : 'Representante legal agregado correctamente';
    cancelForm();
    handleToast({
      open: true,
      message,
      type: 'success',
    });
  }

  return (
    <div className={styles.container}>
      <Header
        title="Representantes legales"
        userName="User Name"
        onClickBack={() => setShowForm(false)}
        back={showForm}
      />

      <div className={styles.body}>
        {!showForm ? (
          <div className={styles.body__padding}>
            <section>
              <button
                className={`${styles.btn} ${styles['btn--black']}`}
                onClick={() => setShowForm(true)}
              >
                AGREGAR REPR. LEGAL
              </button>
            </section>

            <BandButton
              title="Representantes legales por UEN"
              color="yellow"
              colorIcon="black"
            />

            <LegalRepresentativeTable
              onClickEdit={handleEdit}
              setLoading={setLoading}
              loading={loading}
            />
          </div>
        ) : null}

        {showForm ? (
          <LegalRepresentativeForm
            legalRepresentative={currentLegalRepresentative}
            loading={loading}
            setLoading={setLoading}
            onCancel={cancelForm}
            onSubmit={() => handleSubmit(!!currentLegalRepresentative)}
          />
        ) : null}
      </div>
    </div>
  );
};

LegalRepresentative.propTypes = propTypes;
LegalRepresentative.defaultProps = defaultProps;

export default LegalRepresentative;
