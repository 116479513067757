import { getCookie, setCookie } from "./cookiesUtils";
import { getVarConfig } from "./environment";

const buildExpiresDate = (expiresInSecondsParam = 0) => {
  const expiresInSeconds = Number.parseInt(expiresInSecondsParam);
  const ONE_DAY_MS = 86400000;
  const nowDate = new Date();
  return new Date().setTime(nowDate.getTime() + ((expiresInSeconds || 0) * 1000) + ONE_DAY_MS);
}

export default async function fetchAuth() {
  const accessToken = getCookie('bearer');
  if (!!accessToken) return { access_token: accessToken };

  const urlService = getVarConfig({ key: "REACT_APP_SERVICES_URL" }) + "oauth/token";

  var details = {
    'grant_type': 'client_credentials',
    'client_id': getVarConfig({ key: "REACT_APP_SERVICES_CLIENT_ID" }),
    'client_secret': getVarConfig({ key: "REACT_APP_SERVICES_CLIENT_SECRET" })
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(urlService, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: formBody,
  })
  .then((response) => {
    if(response.ok)
    {
      return response.text();
    }
    throw new Error('Something went wrong.');
  })
  .then((data) => {
    try {
      const jsonValue = JSON.parse(data) || {};
      const { access_token: bearer, expires_in: expiresInSeconds  } = jsonValue;
      const expires = buildExpiresDate(expiresInSeconds);
      setCookie('bearer', bearer, { expires });
      return jsonValue;
    }catch(e) {
      return {};
    }
  })
  .catch((e) => {
    return e;
  });
}
