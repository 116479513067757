import PropTypes from 'prop-types';

export const propTypes = {
  initialValue: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export const defaultProps = {
  onChange: null,
};
