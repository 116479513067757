import React, { Component } from "react";

import "./MazTuInfoBeesResumeTotals.scss";

export default class MazTuInfoBeesResumeTotals extends Component {

  render() {

    const title = typeof this.props.title !== "undefined" ? this.props.title : "";
    const subtitle = typeof this.props.subtitle !== "undefined" ? this.props.subtitle : "";
    const totals = typeof this.props.totals !== "undefined" ? this.props.totals : null;
    
    return (
      <div className="maz-tu-info-bees-resume-totals">
        {title !== "" ? 
          <div className="maz-tu-info-bees-resume-totals__title">
            {title}
          </div> : null
        }
        {subtitle !== "" ? 
          <div className="maz-tu-info-bees-resume-totals__subtitle">
            {subtitle}
          </div> : null
        }
        {totals !== null ? 
          totals.map((total, index) => (
            <div className="maz-tu-info-bees-resume-totals__row">
              <span className={`label` + (total.isTotal ? ` label--bold` : '')}>
                {total.label}
              </span>
              <span className="value">{total.value.toLocaleString()}</span>
            </div>
          )) : null
        }
      </div>
    );
  }
}
