import React from 'react';
import styles from '../styles.module.scss';

const InfoRow = ({ title, description }) => (
  <div className={styles.card__row}>
    <h5>{title}</h5>
    <div>{description}</div>
  </div>
);

export default InfoRow;
