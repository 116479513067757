import React, { Component } from "react";
import { getVarConfig } from "../../../utils/environment";

import "./MazTuInfoBeesHistoryTable.scss";

export default class MazTuInfoBeesHistoryTable extends Component {

  formatDate = (unformatedDate) => {
    let dateHistory = unformatedDate.split("T");
    dateHistory = dateHistory[0].split("-");
    const year = dateHistory[0];
    const day = dateHistory[2];
    let month;
    switch(dateHistory[1]) {
      case "01": month = "Enero"; break;
      case "02": month = "Febrero"; break;
      case "03": month = "Marzo"; break;
      case "04": month = "Abril"; break;
      case "05": month = "Mayo"; break;
      case "06": month = "Junio"; break;
      case "07": month = "Julio"; break;
      case "08": month = "Agosto"; break;
      case "09": month = "Septiembre"; break;
      case "10": month = "Octubre"; break;
      case "11": month = "Noviembre"; break;
      case "12": month = "Diciembre"; break;
      default: month = "";
    }
    return day + " de " + month + ", " + year;
  }

  render() {

    const nameVolumenTotal = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_VOLUMEN_TOTAL" });
    const nameMarcasPremium = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_MARCAS_PREMIUM" });
    const nameCobertura = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA" });
    const nameFacturacion = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_FACTURACION" });
    const nameCoberturaCalendario = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA_CALENDARIO" });
    
    const data = typeof this.props.data !== "undefined" ? this.props.data : null;
    const textNoData = typeof this.props.textNoData !== "undefined" ? this.props.textNoData : "No existen datos";

    let mainText = "Puntos por ";

    return (
      <div className="maz-tu-info-bees-history-table">

        {data !== null ?
          data.map((row, index) => (
            <div className="maz-tu-info-bees-history-table__row">
              <div className="maz-tu-info-bees-history-table__row__info">
                <div className="maz-tu-info-bees-history-table__row__info__date">
                  {row.welcomePointsDate !== null ?
                    this.formatDate(row.welcomePointsDate) : ""}
                </div>
                <div className="maz-tu-info-bees-history-table__row__info__text">
                  {
                    row.challengeType === 'total'
                      ? mainText + nameVolumenTotal
                      : row.challengeType === 'premium'
                        ? mainText + nameMarcasPremium
                        : row.challengeType === 'Facturación'
                          ? mainText + nameFacturacion
                          : row.challengeType === "Cobertura Calendario Promocional"
                            ? mainText + nameCoberturaCalendario
                            : mainText + nameCobertura
                  }
                </div>
              </div>
              <div className="maz-tu-info-bees-history-table__row__value">
                {row.pointsToPay !== null ? `+` + parseInt(row.pointsToPay).toLocaleString() : "0"}
              </div>
            </div>
          )) :
          <div className="maz-tu-info-bees-history-table__no-data">
            {textNoData}
          </div>
        }

      </div>
    );
  }
}
