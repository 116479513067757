import React, { useEffect, useState } from 'react';

import ChallegesByStatus from '../ChallegesByStatus';

import { monthLabel } from "../../../../utils/utils";
import { getUser } from '../../../../utils/sessionInfo';

import '../MazTuInfoBeesCardPoints.scss';

const MazTuInfoBeesCardPointsV2 = (props) => {
  const { challenges = [], challengesMarketpl = [], billing = {} } = props;
  const [challengesJoined, setChallengesJoined] = useState([...challenges, ...challengesMarketpl]);

  useEffect(() => {
    const joinedArray = [...challenges, ...challengesMarketpl];
    if (joinedArray.length !== challengesJoined.length){
      setChallengesJoined(joinedArray);
    }
  }, [challenges, challengesJoined.length, challengesMarketpl]);

  const notAccepted = challengesJoined.filter(({ challengeAccepted }) => (!challengeAccepted));
  const progress = challengesJoined.filter(({ challengeAccepted, real, goal }) => {
    if (challengeAccepted && real < goal) {
      return true;
    }
    return false;
  });
  const completed = challengesJoined.filter(({ challengeAccepted, real, goal }) => {
    if (challengeAccepted && real >= goal) {
      return true;
    }
    return false;
  });
  const totalPoints = completed.reduce((a, b) => (a + b.goalPoints), 0);
  const isUserBMax = getUser().clubLevel === "B_MAX";

  return (
    <div className="maz-tu-info-bees-card-points">
      <div className="maz-tu-info-bees-card-points__total">
          <div className="maz-tu-info-bees-card-points__total__points">
            <span>{ totalPoints?.toLocaleString() }</span> puntos
          </div>
          <div className="maz-tu-info-bees-card-points__total__label">
            Ganados durante {monthLabel()}
          </div>
      </div>

      <ChallegesByStatus
        status="notAccepted"
        challenges={notAccepted}
        isUserBMax={isUserBMax}
        billing={billing}
      />

      <ChallegesByStatus
        status="progress"
        challenges={progress}
        isUserBMax={isUserBMax}
        billing={billing}
      />

      <ChallegesByStatus
        status="completed"
        challenges={completed}
        isUserBMax={isUserBMax}
        billing={billing}
      />

    </div>
  );
};

export default MazTuInfoBeesCardPointsV2;
