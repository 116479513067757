import React from 'react';
import Icon from '../../global/Icon/Icon';
import styles from './styles.module.scss';

const MazTuInfoBeesAccountCard = props => {
  const {
    title,
    description,
    onClick,
    bannerIcon,
    bannerColor,
    classC
  } = props;
  return (
    <div className={`col-6 col-lg-4 py-2`}>
      <div className={styles.body__card} onClick={onClick}>
        <div className={`${styles["headerCard"]} ${bannerColor} ${bannerIcon} ${styles["headerCard__" + bannerColor]} ${styles['body__card__banner__' + bannerIcon]}`} />
        <div className={styles.body__card__content}>
          <div className="container py-3">
          <h1>{title}</h1>
          <div>{description}</div>
          <button
            onClick={onClick}
          >
            <Icon
              icon={"arrow-right"}
              width="14"
              height="14"
            />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MazTuInfoBeesAccountCard;
