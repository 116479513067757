import fetchAuth from "./fetchAuth";
import { getVarConfig } from './environment';
import { cleanCookiesAndReload } from "./cookiesUtils";

export default async function fetchAPI({
  params = {},
  service,
  protocol = "POST",
  callbackThen,
  callbackError,
  customBody,
  customHeaders,
  noContentType,
  customParams,
}) {
  const urlService = getVarConfig({ key: "REACT_APP_SERVICES_URL" }) + service;

  fetchAuth().then(async (tokenResponse) => {

    let bearerNumber;

    try {
      bearerNumber = tokenResponse.access_token;
    } catch (error) {
      if (typeof callbackThen !== "undefined") {
        callbackThen(error);
      }
    }

    const options = {
      method: protocol,
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + bearerNumber,
        "CSRF-Token": window.CSRF_TOKEN
      }
    };

    switch(protocol) {
      case "POST":
        options.body = JSON.stringify(params);
        break;
      case "PATCH":
        options.body = JSON.stringify(params);
        break;
      case "PUT":
        options.body = JSON.stringify(params);
        break;
      case "DELETE":
        options.body = JSON.stringify(params);
        break;
      default:
        if(protocol !== "GET") console.log("Not recognized protocol: " + protocol);
    }

    if (noContentType) {
      delete options.headers['Content-Type'];
    }

    options.body = customParams || customBody || options.body;
    options.headers = {
      ...options.headers,
      ...customHeaders,
    }

    return fetch(urlService, options)
      .then((response) => {
        if (response.ok || response.status === 401) {
          return response.text();
        } else {
          if(typeof callbackError !== "undefined") {
            callbackError(response.status, response);
          } else {
            return 500;
          }
        }
      })
      .then((data) => {
        const jData = data ? JSON.parse(data) : {};
        if (jData.error === 'invalid_token') {
          cleanCookiesAndReload();
        }

        if (typeof callbackThen !== "undefined") {
          callbackThen(jData)
        }
        return jData;
      })
      .catch((error) => {
        if (typeof callbackThen !== "undefined") {
          callbackThen(error);
        }
      });

  });

}
