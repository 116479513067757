import React from 'react';
import { Dialog } from '@mui/material';

import styles from './styles.module.scss';
import Icon from '../../../global/Icon/Icon';

const TransferDialog = (props) => {
  const { user, open, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.dialog}
      fullScreen
    >
      <div className={styles.card}>
          <div className={styles.card__header}>
            <h1>Transferencia</h1>
            <div
              className={styles.card__close}
              onClick={onClose}
            >
              <Icon
                icon={"close-black"}
                width="14"
                height="14"
              />
            </div>
          </div>
          <br />
          <div className={styles.card__body}>
            <div className={styles.card__info_row}>
              <span>Utiliza los siguientes datos para pagar por transferencia</span><br/>
              <span className={styles.card__footer}>Si tu banca móvil no te permite ingresar tu referencia completa captura tu número de referencia en el campo de concepto</span>
            </div>
            <div className={styles.card__info_row}>
              <h4>Nombre del beneficiario</h4>
              <span>Grupo Modelo</span>
            </div>
            <div className={styles.card__info_row}>
              <h4>Banco</h4>
              <span>Banamex</span>
            </div>
            <div className={styles.card__info_row}>
              <h4>Sucursal</h4>
              <span>6505</span>
            </div>
            <div className={styles.card__info_row}>
              <h4>Número de cuenta</h4>
              <span>{user.virtualAccount?user.virtualAccount.virtual_account:null}</span>
            </div>
            <div className={styles.card__info_row}>
              <h4>Referencia* </h4>
              <span>{user.virtualAccount?user.virtualAccount.payment_reference:null}</span><br/>
              <span className={styles.card__footer}>*Debe colocarse en campo de referencia o concepto</span>
            </div> 
          {user.virtualAccount ? user.virtualAccount.foment_payment_reference ? (
            <div className={styles.card__info_row}>
              <h4>Referencia Fomento*</h4>
              <span>{user.virtualAccount ? user.virtualAccount.foment_payment_reference : null}</span>
            </div>
          ): null : null}
            

          <div className={`${styles.card__footer_icon}`}>
                Recuerda siempre utilizar tu referencia para poder identificar tus pagos
            </div>
          </div>
      </div>
    </Dialog>
  );
};

export default TransferDialog;
