import React, { Component } from "react";
import TagManager from 'react-gtm-module';
import Main from "./pages/Main/Main";
import { MainContext } from "./MainContext";

import { setUser } from "./utils/sessionInfo";

import "./maz-tu-info-bees.scss";
import "./styles/styles.scss";
import "./styles/variables.scss";
import "./styles/bootstrap-grid.scss";

const tagManagerArgs = {
  gtmId: 'GTM-K3JTM54',
  dataLayerName: 'PageDataLayer'
};

TagManager.initialize(tagManagerArgs);


export default class App extends Component {

  constructor(props) {
    super(props);
    this.setUser = this.setUser.bind(this);
    this.state = {
      user: {},
      setUser: this.setUser
    }
  }

  setUser = (user) => {
    this.setState({ user });
    setUser(JSON.stringify(user));
  }

  render() {

    return (

      <MainContext.Provider value={this.state}>
        <Main />
      </MainContext.Provider>

    );
  }
}
