import {
  account_1,
  account_2,
  account_3,
  account_4,
  account_5,
  bill_1,
  bill_2,
  bill_3,
  bill_4,
  bill_5,
  new_1,
  new_2,
  new_3,
  new_4,
  new_5,
  order_1,
  order_2,
  order_3,
  order_4,
  order_5,
} from "../../../assets/images/tutorials";

import {
  tipofomento,
  montodepago,
  cartonaje,
  montomensualidad,
  condicionesdetufomento,
  totalacumulado,
  progresodetusmetas,
  saldopendienteapagar,
  cuentavirtualfomentos
} from "../../../assets/images/fomento";

export const faqs = [
  {
    title: "¿Qué es Tu Info BEES?",
    description: "Es la plataforma donde podrás consultar tu avance mensual de acuerdo a las metas de tu iniciativa Club B Black y Club B Max, siendo más fácil para ti saber cuánto te falta para llegar a tu meta, cumplir tus desafíos y ganarte todos los Puntos BEES. Además, podrás tener visibilidad en todo momento de tu información financiera (estado de cuenta, facturación, límite de crédito, entre otros) brindándote transparencia y control de todos tus movimientos.",
  },
  {
    title: "En mi estado de cuenta no aparece una de las notas de crédito que pacté",
    description: "En tu estado de cuenta puedes observar las notas de crédito que están autorizadas y facturadas. En caso de que no aparezca es porque no cumple alguno de estos requisitos. Por favor contacta a tu representante de ventas.",
  }, {
    title: "Tengo problemas con las facturas que recibo ¿con quién puedo revisarlo?",
    description: "Puedes contactar a tu representante de ventas o levantar un ticket desde tu App BEES ingresando a Mi Cuenta > Obtener ayuda y soporte > Contáctanos para analizar tu situación y poderle dar seguimiento.",
  }, {
    title: "¿Por qué si tengo crédito disponible no puedo realizar mi pedido?",
    description: "Te pedimos contactar a tu representante de ventas o levantar un ticket desde tu App BEES ingresando a Mi Cuenta > Obtener ayuda y soporte > Contáctanos para validar esta situación.",
  }, {
    title: "En mi estado de cuenta no aparecen las promociones que compré",
    description: "En tu estado de cuenta únicamente podrás encontrar el detalle de todos los pedidos que ya estén facturados. En caso de que tu pedido no haya sido facturado puedes contactar a tu representante de ventas o levantar un ticket desde tu App BEES ingresando a Mi Cuenta > Obtener ayuda y soporte > Contáctanos para conocer el estatus de estos pedidos.",
  }, {
    title: "¿Cómo puedo aumentar el plazo para pagar mi pedido?",
    description: "Deberás cumplir 3 meses con un excelente uso de tu crédito (pagar a tiempo y usarlo frecuentemente) para poder evaluar tu caso y de ser posible brindarte la opción de aumentar tu plazo de pago.",
  }, {
    title: "¿Cómo obtengo mi estado de cuenta?",
    description: "El estado de cuenta lo podrás visualizar en esta herramienta, podrás consultar tus movimientos y saldos disponibles. Si tienes alguna duda podrás explorar en el módulo de Soporte nuestra sección de Tutoriales.",
  },
  {
    title: "¿Qué días puedo consultar mis resultados de mi Programa de Puntos?",
    description: "Los primeros días de cada mes.",
  },
  {
    title: "¿Dónde puedo aceptar un desafío?",
    description: "Dentro de BEES se deben aceptar cada uno de los desafíos.",
  },
  {
    title: "¿Qué pasa si al término del mes no cumplí mis metas?",
    description: "Tranquilo, si no pudiste completar tus desafíos tendrás la oportunidad de cumplir tus metas y ganar el próximo mes.",
  },
  {
    title: "¿Qué debo hacer si la información de mi programa de Puntos no es correcta?",
    description: "Reportarle el problema a tu ejecutivo de ventas.",
  },
  {
    title: "Problemas con mi fomento",
    description: [
      {
        title: "¿Cuál es el tipo de fomento?",
        description: "Consúltalo en la sección de fomentos en BEES FINANCE",
        image: tipofomento,
      },
      {
        title: "¿Cual es el monto de pago?",
        description: "Consúltalo en la sección de fomentos en BEES FINANCE",
        image: montodepago,
      },
      {
        title: "¿Por qué no se actualiza mi pago en la plataforma?",
        description: "Los movimientos de su cuenta podrían demorar en verse reflejados hasta 72 hrs., si persiste, cierre sesion, borre cache e inicie de nuevo sesión.",
      },
      {
        title: "Realicé mi pedido, pero no se actualiza mi fomento",
        description: "Los movimientos de su cuenta podrían demorar en verse reflejados hasta 72 hrs., si persiste, cierre sesion, borre cache e inicie de nuevo sesión.",
      },
      {
        title: "¿Qué pasa si hago una devolución de producto? ¿Mi saldo en fomento disminuye?",
        description: "Si el producto que está devolviendo tuvo alguna bonificación en el fomento , dicha bonificación también será dada de baja junto con el producto que está devolviendo.",
      },
      {
        title: "Si hago uso de una promoción, ¿el fomento se actualiza por el total de cajas de la promoción o solo por las que pago?",
        description: "Los productos promocionados están limitados a bonificar en su fomento, es posible que no vea reducción en el saldo de fomento al adquirir promociones o precios especiales.",
      },
      {
        title: "¿Por qué tengo un adeudo si me dijeron me dijeron que se iba a pagar con las compras?",
        description: "Posiblemente la cantidad de sus compras no han alcanzado el mínimo para cubrir la mensualidad de su FOM y/o los productos que ha venido adquiriendo no todos han bonificado, por el tipo de producto o por adquirirlo con alguna promoción o descuento.",
      },
      {
        title: "¿Qué tipo de amortizaciones existen?",
        description: `<b>Efectivo:</b> Deberás de abonar el importe correspondiente en el banco con tu referencia de cliente<br></br><br></br>
                      <b>Porcentaje de descuento:</b> Tu porcentaje de descuento será aplicado para la amotizacion del saldo de fomento.No aplican los productos promocionado por lo que estos no abonada al saldo<br></br><br></br>
                      <b>Compra gana:</b> Se fija un volumen que debes de comprar en(x) plazo si llegas se otorga el beneficio`,
      },
      {
        title: "¿Cuáles son los métodos de pago?",
        description: "Transferencia electrónica, deposito en banco, cajeros y/o directamente en agencia",
      },
      {
        title: "¿Dónde puedo obtener mis pagares que ya pague?",
        description: "Los pagarés ya cubiertos se deben entregar en agencia, puede agendar una cita por parte del equipo de atención a clientes.",
      },
      {
        title: "¿Cuál es el cartonaje que debo cumplir al mes para cubrir la mensualidad de mi fomento? ",
        description: "Consúltalo en la sección de fomentos en BEES FINANCE",
        image: cartonaje,
      },
      {
        title: "¿Cuál es el monto de mi mensualidad? ",
        description: "Consúltalo en la sección de fomentos en BEES FINANCE",
        image: montomensualidad,
      },
      {
        title: "¿Puedo realizar los pagos en Oxxo?",
        description: "De momento no se tiene convenio con Oxxo, pero puede realizar transferencia electrónica, deposito en banco, cajeros y/o directamente en agencia",
      },
    ],
  },
];

export const tutorials = [{
  title: '¿Qué hay de nuevo?',
  steps: [{
    title: '¿Qué hay de nuevo?',
    description: 'Visualiza la suma de tu adeudo de contado y  crédito',
    image: new_1,
  }, {
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Visualiza el detalle de utilización de tu crédito y tus condiciones de pago',
    image: new_2,
  }, {
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Visualiza el listado de tus movimientos: Pedidos, pagos, notas de crédito',
    image: new_3,
  }, {
    title: 'Detalle de pedido',
    description: 'Visualiza el detalle de tu pedido',
    image: new_4,
  }, {
    title: 'Detalle de pedido',
    description: 'Visualiza el detalle de tu factura',
    image: new_5,
  }],
}, {
  title: "¿Cómo leer tu estado de cuenta?",
  steps: [{
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Visualiza la suma de tu adeudo de contado y  crédito',
    image: account_1,
  }, {
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Visualiza el detalle de tu adeudo total, y como se encuentra distribuido',
    image: account_2,
  }, {
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Visualiza el detalle de utilización de tu crédito y tus condiciones de pago',
    image: account_3,
  }, {
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Visualiza el listado de tus movimientos: Pedidos, pagos, notas de crédito',
    image: account_4,
  }, {
    title: '¿Cómo leer tu estado de cuenta?',
    description: 'Ordena y filtra tus movimientos',
    image: account_5,
  }],
}, {
  title: 'Detalle de pedido',
  steps: [{
    title: 'Detalle de pedido',
    description: 'Ve a el listado de tus movimientos',
    image: order_1,
  }, {
    title: 'Detalle de pedido',
    description: 'Selecciona el pedido que deseas consultar',
    image: order_2,
  }, {
    title: 'Detalle de pedido',
    description: 'Visualiza el detalle de tu pedido',
    image: order_3,
  }, {
    title: 'Detalle de pedido',
    description: 'Encontrarás el número de pedido realizado y la fecha en que fue realizado',
    image: order_4,
  }, {
    title: 'Detalle de pedido',
    description: 'Visualiza la dirección de entrega de tu pedido',
    image: order_5,
  }],
}, {
  title: 'Detalle de facturas',
  steps: [{
    title: 'Detalle de factura',
    description: 'Ve a el listado de tus movimientos',
    image: bill_1,
  }, {
    title: 'Detalle de factura',
    description: 'Selecciona el pedido que deseas consultar',
    image: bill_2,
  }, {
    title: 'Detalle de pedido',
    description: 'Selecciona facturación',
    image: bill_3,
  }, {
    title: 'Detalle de pedido',
    description: 'Selecciona la factura que deseas visualizar',
    image: bill_4,
  }, {
    title: 'Detalle de pedido',
    description: 'Visualiza el detalle de tu factura',
    image: bill_5,
  }],
},
{
  title: 'Fomentos',
  steps: [
    {
      title: 'Fomentos',
      description: 'Visualiza las condiciones de tu fomento',
      image: condicionesdetufomento,
    },
    {
      title: 'Fomentos',
      description: 'Visualiza el total acumulado de ventas que amortizan a tu fomento',
      image: totalacumulado,
    },
    {
      title: 'Fomentos',
      description: 'Visualiza el progreso de tus metas de ventas por período',
      image: progresodetusmetas,
    },
    {
      title: 'Fomentos',
      description: 'Visualiza tu saldo pendiente a pagar',
      image: saldopendienteapagar,
    },
    {
      title: 'Fomentos',
      description: 'Visualiza tu cuenta virtual de fomentos',
      image: cuentavirtualfomentos,
    },
  ]
},
];
