import PropTypes from 'prop-types';

export const propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.string,
  confirmTxt: PropTypes.string,
  noCancelBtn: PropTypes.bool,
  className:PropTypes.string,
};

export const defaultProps = {
  open: false,
  onCancel: () => {},
  onConfirm: () => {},
  text: "",
  confirmTxt: "",
  noCancelBtn: false,
  className:""
};
