import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import Icon from '../../components/global/Icon/Icon';

import MazTuInfoBeesAccountCard from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card';
import ActionRow from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/ActionRow';
import ColorRow from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/ColorRow';
import DepositDialog from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/DepositDialog';
import InfoRow from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/InfoRow';
import TransferDialog from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/TransferDialog';
import MazTuInfoBeesAccountCredit from '../../components/maz-tu-info-bees/maz-tu-info-bees-resume-credit';
import { getUser } from '../../utils/sessionInfo';
import { isMobile } from 'react-device-detect';

import styles from './styles.module.scss';

const hotJarConfiguration = {
  hjid: 3065000,
  hjsv: 6,
}

const VirtualAccount = (props) => {

  const genericRfcAccounts = ['XAXX010101000', 'XAX010101000'];

  const { user, setPath, path, needBackButton, sendGA, setBackButton } = props;

  console.log(user);
  const [transferDialog, setTransferDialog] = useState(false);
  const [depositDialog, setDepositDialog] = useState(false);

  const sendHotJarTrap = (params) => {
    hotjar.identify(user.ID, params);
  }

  const handleClickTranfer = () => {

    props.sendGA('GAEvent', 'Content', 'Content', 'Transference', true, 'transference_button', 'Transferencia');
    //sendGATrap('Transferencia', 'Interaction', 'Click');
    sendHotJarTrap({ section: 'Transferencia' });
    setTransferDialog(true);
  }

  const handleClickDeposit = () => {
    props.sendGA('GAEvent', 'Content', 'Content', 'Bank deposit', true, 'bank_deposit_button', 'Depósito en banco');
    //sendGATrap('Deposito Banco', 'Interaction', 'Click');
    sendHotJarTrap({ section: 'Deposito Banco' });
    setDepositDialog(true);
  }

  /*const sendGATrap = (category, action, label) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent',
        event_category: category,
        event_action: action,
        event_label: label,
        userid: user.ID,
        POC_name: user.name
      },
      dataLayerName: 'PageDataLayer'
    });
  }*/

  const handleBackNavigation = () => {
    if (
      props.path === "/desafios" ||
      props.path === "/historial" ||
      props.path === "/resultados" ||
      props.path === "/registro" ||
      props.path === "/facturacion" ||
      props.path === "/cobertura" ||
      props.path === "/registro/exito" ||
      props.path === "/contrato"
    ) {
      if (props.path === "/registro" || props.path === "/registro/exito") {
        props.setPath("/");
      } else {
        props.setPath("/dashboard");
      }
    } else if (props.needBackButton) {
      console.log("Path previo: " + props.previousPath);
    }
  }

  const onMobile = styles.hideOnMobile;

  return (
    <div className="container">

      <TransferDialog
        user={user}
        open={transferDialog}
        onClose={() => setTransferDialog(false)}
      />

      <DepositDialog
        user={user}
        open={depositDialog}
        onClose={() => setDepositDialog(false)}
      />

      
      {/*<MazTuInfoBeesAccountCredit
        sendGA={props.sendGA}
        className={onMobile}
        customerAccountState={user.accountState ? user.accountState : null}
        customerPaymentCondition={(user.virtualAccount && user.virtualAccount.accountState) ? user.virtualAccount.accountState.paymentCondition.description : null}
      />*/}
      {!isMobile ? (<h2>¿Cómo pagar?</h2>) : null}
      <div className="row">        
        {!isMobile ?
          (
            <MazTuInfoBeesAccountCredit
              sendGA={props.sendGA}
              className={styles.card + " card"}
              setPath={setPath}
              setBackButton={setBackButton}
              customerAccountState={user.accountState ? user.accountState : null}
              customerPaymentCondition={(user.virtualAccount && user.virtualAccount.accountState) ? user.virtualAccount.accountState.paymentCondition.description : null}
            />
          ) : null
        }
        <div className="mt-2 mb-3 col-12 col-lg-8">
      <MazTuInfoBeesAccountCard>

        {props.path === "/desafios" ||
          props.path === "/registro" ||
          props.path === "/facturacion" ||
          props.path === "/cobertura" ||
          props.path === "/historial" ||
          props.path === "/resultados" ||
          props.path === "/registro/exito" ||
          props.path === "/contrato" || props.needBackButton ? (
          <div
            className="d-none d-md-flex align-items-center p-2"
            onClick={props.needBackButton ? props.backButtonAction : handleBackNavigation}
          >
            <Icon icon={"back-navigation"} size="30" />
            <span>Atrás</span>
          </div>
        ) : ("")}

        <h1>¿Cómo pagar?</h1>

        <ActionRow type="transfer" onClick={handleClickTranfer} />
        <ActionRow type="deposit" onClick={handleClickDeposit} />
        <ActionRow />

      </MazTuInfoBeesAccountCard>

      <MazTuInfoBeesAccountCard>
        <h1>Mi Cuenta Virtual</h1>



        <InfoRow
          title="Número de cliente"
          description={user.ID}
        />

        <InfoRow
          title="Cuenta"
          description={user.name}
        />

        <InfoRow
          title="Teléfono"
          description={user.phone}
        />

        {!genericRfcAccounts.includes(user.rfc) ?
          <InfoRow
            title="RFC"
            description={user.rfc}
          /> : null
        }

        <InfoRow
          title="Facturación anticipada"
        />

        <ColorRow
          color="black"
          header={getUser().ID.charAt(0) != 1 ?"Los datos presentados aqui pertenecen a la matriz":null}
          title="Número de cuenta"
          className="mt-3"
          description={user.virtualAccount ? user.virtualAccount.virtual_account : null}
        />
        {user.virtualAccount?.clabe ?
          <ColorRow
            color="black"
            title="CLABE Interbancaria"
            description={user.virtualAccount ? user.virtualAccount.clabe : null}
          />
          : null}
        {user.virtualAccount?.foment_payment_reference ?
          <ColorRow
            color="yellow"
            title="Referencia fomento"
            description={user.virtualAccount ? user.virtualAccount.foment_payment_reference : null}
          />
          : null}
        <ColorRow
          color="yellow"
          title="Referencia"
          description={user.virtualAccount ? user.virtualAccount.payment_reference : null}
        />

      </MazTuInfoBeesAccountCard>

          <div className={styles.xto__dump}></div>
      </div>
      </div>
    </div>
  );
};

export default VirtualAccount;
