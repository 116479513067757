import React, { Component } from "react";
import MazTuInfoBeesButton from "../maz-tu-info-bees-button/MazTuInfoBeesButton";

import "./MazTuInfoBeesGalleryCard.scss";
import { challengeTypeLabel } from "../../../utils/utils";

const imgChallenge = require('./assets/image-challenge-total.jpg');

export default class MazTuInfoBeesGalleryCard extends Component {

  openBees = () => {
    window.open("https://mybees.mx/categories?categoryId=5ffe520e26b93207e8538998", "_blank");
  }

  render() {
    const type = typeof this.props.type !== "undefined" ? this.props.type : "";
    const data = typeof this.props.data !== "undefined" ? this.props.data : "";

    let title = "", urlImage = "", points = 0, challengeType = "";

    try {
      title = data.title;
      urlImage = data.urlImage;
      points = data.points;
      challengeType = data.challengeType;
    } catch(e) {
      console.log(e);
    }

    const date = new Date();
    let expirationDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return(
      
      <div className="maz-tu-info-bees-gallery-card">

        <div className="maz-tu-info-bees-gallery-card__image">
          <img src={ urlImage !== "" && urlImage !== null ? urlImage : imgChallenge } alt="challenge" crossorigin="anonymous" />
        </div>

        <div className="maz-tu-info-bees-gallery-card__title">
        { title }
        </div>

        <div className={"maz-tu-info-bees-gallery-card__type maz-tu-info-bees-gallery-card__type--" + challengeType}>
          {challengeTypeLabel(challengeType)}
        </div>

        <div className={"maz-tu-info-bees-gallery-card__points maz-tu-info-bees-gallery-card__points--" + type}>
          { points.toLocaleString() } puntos
        </div>

        <br />

        {
          type === "not-accepted" || type === "progress" ?

            <>
              <div className="maz-tu-info-bees-gallery-card__expiration">
                Válido hasta { expirationDate.getDate() + "/" + (expirationDate.getMonth() + 1) + "/" + expirationDate.getFullYear() }
              </div>
              
              <div className="maz-tu-info-bees-gallery-card__button">
                <MazTuInfoBeesButton 
                  type="secondary" 
                  handleClick={this.openBees}
                  textButton={ type === "not-accepted" ? "ACEPTAR EN BEES" : "IR A BEES"} />
              </div>
            </> : null
        }

      </div>

    )
  }
}