import { useCallback, useEffect, useState } from "react";

import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";
import useTable from "../../../../utils/hooks/useTable";

const urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_UENGOAL" }) || '';
const service = `${urlService.replace('{customer}', 'admin')}s`;

const useUenGoalsTable = (handleToast) => {
  const {
    setList,
    list,
    sortByKey,
  } = useTable();
  const [isLoading, setIsLoading] = useState(false);

  const postUenGoals = useCallback((uenGoalList) => {
    const isHandleToast = typeof handleToast === 'function';
    if (!uenGoalList) {
      (isHandleToast && handleToast({
        open: true,
        message: 'No se recibió información válida para guardar.',
        type: 'error',
      }));
      return;
    }
    const params = { uenGoalList };
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "POST",
      params,
      callbackThen: () => {
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [handleToast]);

  const getAllGoals = useCallback(() => {
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        setList(data || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setList]);

  useEffect(() => getAllGoals(), [getAllGoals])

  return {
    list,
    setList,
    isLoading,
    getAllGoals,
    postUenGoals,
    sortByKey,
  };
};

export default useUenGoalsTable;
