import React from 'react';
import Paginator from '../../maz-info-bees-paginator/Paginator';

import HeaderTable from '../ProgramsTable/HeaderTable';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';
import useContractTable from './useContractTable';
import { COLUMNS, getContractSignStatus, getContractStatus, isInNoActionHeaders } from './utils';

const ContractTable = props => {
  const { setLoading, loading, searchText } = props;
  const {
    filteredList,
    list: fetchedList,
    sortByKey,
    sortDescStates,
    getReportsByPage,
    metadata,
  } = useContractTable(searchText, loading, setLoading);

  const list = filteredList || fetchedList;

  if (!list?.length && loading) return <>Loading ...</>;
  if (!list?.length && !loading) return <>No se encontró información</>;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {COLUMNS.map(({ text, key }) => {
            return (
              <HeaderTable
                key={key}
                onClick={() => sortByKey(key)}
                title={text}
                iconDesc={sortDescStates ? sortDescStates[`${key}SortDesc`] : null}
                noIcon={isInNoActionHeaders(key)}
              />
            )
          })}
        </div>

        <div className={styles.body}>
          {list
            .map((contractData) => {
              const {
                customer_no,
                process_no,
                initiative,
                trato_id,
                name,
                statusTrato,
                legalrep,
                signStatus: signStatusReceived,
                goalSelected,
              } = contractData;


              const contractStatus = getContractStatus(signStatusReceived, statusTrato);
              const signStatus = getContractSignStatus(signStatusReceived, statusTrato);
              return (
                <div
                  className={styles.row}
                  key={process_no}
                >
                  <div>{customer_no}</div>
                  <div>{initiative}</div>
                  <div>{trato_id}</div>
                  <div>{name}</div>
                  <div>{contractStatus}</div>
                  <div>{legalrep}</div>
                  <div>{signStatus}</div>
                  <div>{goalSelected}</div>
                </div>
              )}
          )}
        </div>
      </div>

      <div className={styles.paginator}>
        <Paginator
          totalPages={metadata.totalPages}
          currentPage={metadata.page}
          pagesToShow={5}
          onChange={getReportsByPage}
        />
      </div>
    </>
  );
}

ContractTable.prototype = propTypes;
ContractTable.defaultProps = defaultProps;

export default ContractTable;
