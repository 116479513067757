import React from 'react';
import "../MazTuInfoBeesCardPoints.scss";

const PointsRow = (props) => {
  const { hidden, total, title } = props;
  if (hidden) return null;
  return (
    <div className="maz-tu-info-bees-card-points__detail__row">
      <div className="maz-tu-info-bees-card-points__detail__row__label">
        {title}
      </div>
      <div className="maz-tu-info-bees-card-points__detail__row__points">
        {`${total?.toLocaleString()} puntos`}
      </div>
    </div>
  );
};

export default PointsRow;