import { useCallback, useEffect, useState } from "react";
import fetchAPI from "../../../utils/fetchAPI";
import useTable from "../../../utils/hooks/useTable";
import { getStripeByType } from "../../../utils/utils";

const useCandidatesTable = (props) => {
  const { newCustumers, searchText } = props;
  const {
    setList,
    list,
    sortByKey,
    sortDescStates,
  } = useTable();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredList, setFilteredList] = useState(null);

  const filterList = useCallback(() => {
    const newFilteredList = list.filter(({ originalFilename }) => {
      if (originalFilename && originalFilename.includes(searchText)) {
        return true;
      }
      return false;
    });
    setFilteredList(newFilteredList);
  }, [list, searchText]);

  const getHistory = useCallback(() => {
    const service = `v1/mx/massive_load/${ newCustumers ? 'new' : 'upgrade'}/history`;
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        setList(data || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [newCustumers, setList]);

  useEffect(() => getHistory(), [getHistory]);
  useEffect(() => {
    if (searchText && list) {
      filterList();
    }
  }, [filterList, list, searchText]);

  useEffect(() => {
    if (!searchText && filteredList) setFilteredList(undefined);
  }, [filteredList, searchText]);

  return {
    list,
    setList,
    isLoading,
    getHistory,
    sortByKey,
    sortDescStates,
    getStripeByType,
    filteredList,
  };
};

export default useCandidatesTable;
