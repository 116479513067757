import React from 'react';
import Dialog from '../../../maz-tu-info-bees-dialog';
import styles from './styles.module.scss';
import { financeOrderByOptions } from '../../../../../utils/utils'

const DialogOrderBy = props => {
  const { open, setOpen, onClick } = props;

  const handleOnClick = filterType => {
    console.log(filterType);
    if (onClick) onClick(filterType);
    if (setOpen) setOpen(false);
  }

  return (
    <Dialog
      open={open}
    >
      <div className={styles.container}>
        <div><h1>Ordenar por</h1></div>
        {financeOrderByOptions.map( option => (
          <div onClick={() => handleOnClick({option})}>
          <h2>{option}</h2>
        </div>
        ))
        }
      </div>
    </Dialog>
  );
};

export default DialogOrderBy;
