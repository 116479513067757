import React from 'react';
import Icon from '../../../global/Icon/Icon';
import styles from './styles.module.scss';
import {financeCurrencyFormat, formatDateDDMMYYYY, formatDateDDMMYYYYFinance, cashpaymenttypes, payment_doc_types, bills_doc_types, payment_types  } from '../../../../utils/utils'
import { getUser } from '../../../../utils/sessionInfo';

const AccountRow = props => {

  const {
    doc_no, //temporal no. doc
    net_due_date, //fecha vencimiento
    zterm, //Tipo de pago contado-crédito
    amt_doccur, //Valor documento, si es pago va negativo o se debe mostrar igualmente como positivo?
    doc_type, //Tipo de documento factura-pago - Método de Pago
    creation_date, //Fecha Pedido
    bline_date, //Fecha de entrega?
    order_data, //Data de la orden
    onClick,
  } = props;

const handleClick = () => {
  // eslint-disable-next-line no-unused-vars
  const { onClick: onClickOrigin, ...publicProps} = props;
    if (typeof onClick === "function") onClick(publicProps);
  }

  const iconName = payment_doc_types.includes(doc_type) ? 'credit-card' : 'bill';

  var UserID = getUser().ID;

  return (

    <div className={styles.container} onClick = {bills_doc_types.includes(doc_type) && order_data ? () => handleClick():null}>
      <Icon
        icon={iconName}
        width="14"
        height="14"
      />

      <div className={styles.body}>
        <div className={styles.body__title}>
          {bills_doc_types.includes(doc_type) ? <h2>{ order_data? order_data.orderNo : doc_no}</h2>:<h2>     </h2>}
          <h5>{payment_doc_types.includes(doc_type)?'Pago':cashpaymenttypes.includes(zterm)?'Contado':'Crédito'}</h5>
        </div>

        <div className={styles.body__description}>
          <div>
            {bills_doc_types.includes(doc_type) ? (
              <><div className={styles.body__label}>
                <h3>Pedido: </h3>
                <h4>{formatDateDDMMYYYYFinance(order_data?order_data.order_date : bline_date)}</h4>
              </div>
              <div className={styles.body__label}>
              <h3>Fecha de entrega: </h3>
              <h4>{formatDateDDMMYYYYFinance(order_data?order_data.position_delivery_date : bline_date)}</h4>
              </div>
              <div className={styles.body__label}>
                <h3>Fecha de vencimiento: </h3>
                <h4>{formatDateDDMMYYYYFinance(net_due_date)}</h4>
              </div>
                {
                  UserID.charAt(0) == 1 && order_data && order_data.CustomerInfo ?
                    (
                      <div className={styles.body__label}>
                        <h3>Sucursal: </h3>
                        <h4>{`${order_data.CustomerInfo.customer_no} - ${order_data.CustomerInfo.city}`}</h4>
                      </div>
                    ): null
              }
            </>
            ) : null}

            {payment_doc_types.includes(doc_type) ? (
              <>
              <div className={styles.body__label}>
                <h3>Fecha: </h3>
                <h4>{formatDateDDMMYYYYFinance(bline_date)}</h4>
              </div>
              <div className={styles.body__label}>
              <h3>Método de pago: </h3>
              <h4>{payment_types[doc_type]}</h4>
              </div>

{/*               <div className={styles.body__label}>
                <h3>Asignación: </h3>
                <h4>{doc_no}</h4>
              </div> */}

              <div className={styles.body__label}>
                <h3>Factura asociada: </h3>
                <h4>{doc_no}</h4>
              </div>

              </>
            ) : null}

          </div>

          <div className={styles.body__bill}>
            <div>
              <h1>{financeCurrencyFormat(amt_doccur)}</h1>
            </div>
            {bills_doc_types.includes(doc_type) && order_data ?
             <Icon
              icon="arrow-right"
              width="18"
              height="18"
              handleClick={handleClick}
            />: null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountRow;
