import PropTypes from 'prop-types';

export const propTypes = {
  onClickEdit: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export const defaultProps = {
  onClickEdit: () => {},
  loading: false,
  setLoading: () => {},
};
