import React from 'react';
import styles from '../styles.module.scss';

const ColorRow = ({ title, description, color, header }) => {
  let colorClassName = styles['card__row--black'];

  if (color === 'yellow') {
    colorClassName = styles['card__row--yellow'];
  }

  return (
    <div className={colorClassName}>
      <div>
        {header ? (<span>{header}</span>):null}
        <h4>{title}</h4>
        <div>{description}</div>
      </div>
      <div>
        <button onClick={() => navigator.clipboard.writeText(description)}>Copiar</button>
      </div>
    </div>
  );
};

export default ColorRow;
