import React, { useState } from 'react';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const EditableRow = props => {
  const {
    goal,
    className,
    onChangeGoal,
    onCancel,
    onSave,
    editing,
    setEditing,
  } = props;
  const { uen, points } = goal;
  const evenRowNumber = undefined === goal.index ? 0 : goal.index % 2;

  const [editedGoal, setEditedGoal] = useState(goal);

  const handleOnChangeInput = ({ target }) => {
    const { value } = target || {};
    const newGoal = { uen, points: value };
    onChangeGoal(newGoal);
    setEditedGoal(newGoal);
  }

  const resetStates = () => {
    setEditedGoal(goal);
    setEditing(false);
  }

  const handleOnSave = () => {
    setEditing(false);
    onSave(editedGoal);
  }

  const handleOnCancel = () => {
    resetStates();
    onCancel(goal);
  }

  const handleRowClick = () => {
    if (!editing) setEditing(true);
  }

  return (
    <div
      className={`${styles[`row--${evenRowNumber}`]} ${(!editing && styles.editing)} ${className}`}
      onClick={handleRowClick}
    >
      <div>{uen}</div>
      {!editing && <div>{points}</div>}
      {editing && (
        <>
          <div>
            <input
              type="number"
              value={editedGoal.points}
              onChange={handleOnChangeInput} autoFocus
            />
          </div>
          <div className={styles.btn__group}>
            <button
              className={`${styles.btn} ${styles['btn--save']}`}
              alt="Guardar"
              onClick={handleOnSave}
            />
            <button
              className={`${styles.btn} ${styles['btn--cancel']}`}
              alt="Cancelar"
              onClick={handleOnCancel}
            />
          </div>
        </>
      )}
    </div>
  );
};

EditableRow.prototype = propTypes;
EditableRow.defaultProps = defaultProps;

export default EditableRow;
