import React, { useState } from 'react';
import Icon from '../../../../global/Icon/Icon';
import MazTuInfoBeesDatepicker from '../../../maz-tu-info-bees-datepicker';
import Dialog from '../../../maz-tu-info-bees-dialog';
import MazTuInfoBeesSlider from '../../../maz-tu-info-bees-slider';
import styles from './styles.module.scss';
import {
  financeCurrencyFormat,formatDateDDMMYYYY
 } from '../../../../../utils/utils'
import { getUser } from '../../../../../utils/sessionInfo';
 


const DialogFilters = props => {
  const {
    open,
    setOpen,
    onFilter,
    onClear,
    sliderData,
    setSliderData,
    dates,
    setDates,
    sendGA
  } = props;

  const [hideClass, setHideClass] = useState(styles.hidden);

  let list;
  let sucursalesSelected = [];

  const handleOnFilter = () => {
    props.sendGA('GAEvent', 'Content', 'Content', 'Filter by', true, 'filter_by_button', 'filtrar');
    if (onFilter) onFilter({ sliderData, dates, sucursalesSelected });
    setOpen(false);
  }

  const handleChangeDates = newDates => {
    setDates(newDates);
    if (newDates.start && newDates.end) {
      setHideClass(styles.hidden);
    }
  }

  const handleClear = () => { onClear && onClear() };

  const clearList = () => {
    if (list.firstChild)
    while (list.firstChild) {
      list.removeChild(list.firstChild)
    }
  }

  const noResults = () => {
    const error = document.createElement('li')
    error.classList.add('error-message')

    const text = document.createTextNode('No se encuentran resultados con ésos parametros. :(')
    error.appendChild(text)
    list.appendChild(error)

  }


  const searchAction = (e) => {

    let value = e.target.value

    list = document.getElementById("list")

    if (value && value.trim().length > 0) {
      value = value.trim().toLowerCase()
      setList(props.sucursales().filter(sucursal => {
        return (sucursal.name.includes(value) || sucursal.id.includes(value))
      }))
    } else {
      clearList()

    }
  }

  const updateSelected = (event) => {
    if (event.target.checked)
      sucursalesSelected.push(event.target.value);
    else
      sucursalesSelected = sucursalesSelected.filter(item => item !== event.target.value);
  }

  const setList = (results) => {
    clearList()
    for (const sucursal of results) {
      const resultItem = document.createElement('li')
      const text = document.createTextNode(`${sucursal.id} - ${sucursal.name}`)
      resultItem.appendChild(text)
      const input = document.createElement("input", );
      input.setAttribute("type", "checkbox");
      input.value = sucursal.id;
      input.onchange = function (e) { updateSelected(e); };
      resultItem.appendChild(input)
      list.appendChild(resultItem)
    }

    if (results.length === 0) {
      noResults()
    }
  }

  return (
    <Dialog
      open={open}
      customDialog={{fullScreen: true}}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Filtrar</h1>
          <button>
            <Icon
              icon="close-black"
              width="14"
              height="14"
              handleClick={() => setOpen(false)}
            />
          </button>
        </div>

        <div className={styles.slider}>
          <h2>Filtrar por monto</h2>
          <div>
            <MazTuInfoBeesSlider
              data={sliderData}
              setData={setSliderData}
            />

            <div className={styles.slider__titles}>
              <p>
                <h3>{financeCurrencyFormat(sliderData.currentMin)}</h3>
                <h4>Monto inicial</h4>
              </p>
              <p>
                <h3>{financeCurrencyFormat(sliderData.currentMax)}</h3>
                <h4>Monto final</h4>
              </p>
            </div>
          </div>
        </div>

        <div className={styles.dates}>
          <h2>Filtrar por fecha</h2>

          <section>
            <div>
              <h5>Fecha inicial</h5>
              <div>
                <h1>{`${formatDateDDMMYYYY(dates.start)}`}</h1>
                <Icon
                  icon="calendar"
                  width="14"
                  height="14"
                  handleClick={() => setHideClass('')}
                />
              </div>
            </div>

            <div>
              <h5>Fecha Final</h5>
              <div>
                <h1>{`${formatDateDDMMYYYY(dates.end)}`}</h1>
                <Icon
                  icon="calendar"
                  width="14"
                  height="14"
                  handleClick={() => setHideClass('')}
                />
              </div>
            </div>
          </section>
          <div className={`${styles.calendar}`}>
            <MazTuInfoBeesDatepicker
              dates={dates}
              setDates={handleChangeDates}
            />
          </div>
        </div>
        {
          getUser().ID.charAt(0) == 1 ?
          (<div className={styles.sucursal}>
            <h2>Filtrar por Sucursal</h2>
            <div>
              <input type="text" className="filterSucursal search" placeholder="Buscar sucursal"  onInput={searchAction}/>
              <label for="filter_search" class="leading-icon material-icons-round">search</label>
            </div>
            <div class="results-container">
              <ul className={styles["results-list"]} id="list">

               </ul>
            </div>
          </div>):null
        }

        <div className={styles.btns}>
          <button
            onClick={handleClear}
          >
            Limpiar
          </button>
          <button
            className={styles.btn_black}
            onClick={handleOnFilter}
          >
            Filtrar
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogFilters;
