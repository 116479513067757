export const TYPE_REPRESENTATIVE = {
  gerente_administrativo: 'Gerente Administrativo',
  gerente_general: 'Gerente General',
};

export const TYPE_REPRESENTATIVE_OPTIONS = [{
  text: TYPE_REPRESENTATIVE.gerente_administrativo,
  value: 'gerente_administrativo',
}, {
  text: TYPE_REPRESENTATIVE.gerente_general,
  value: 'gerente_general',
}];
