import PropTypes from 'prop-types';

const legalRepresentativeType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  rfc: PropTypes.string,
  drv: PropTypes.string,
})

export const propTypes = {
  legalRepresentative: legalRepresentativeType,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export const defaultProps = {
  legalRepresentative: undefined,
  onCancel: null,
  onSubmit: null,
  loading: false,
  setLoading: () => {},
};
