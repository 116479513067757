import React, { useRef } from 'react';

import useUenGoalsFile from './useUenGoalsFile';

const UenGoalsCsvFileButton = (props) => {
  const { buttonProps, setLoading, handleToast } = props;
  const fileInputRef = useRef(null);
  const {
    handleOnChange,
    isLoading,
    file,
  } = useUenGoalsFile(setLoading, handleToast);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        paddingTop: '10px',
        paddingRight: '50px',
      }}
    >
      <button
        onClick={handleClick}
        disabled={isLoading}
        { ...buttonProps }
      >Cargar archivo csv</button>

      {!file && (
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleOnChange}
          accept=".csv"
        />
      )}
    </div>
  );
};

export default UenGoalsCsvFileButton;
