import { useCallback, useEffect, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";
import useTable from "../../../../utils/hooks/useTable";

const PER_PAGE_REQUEST = 20;

const useContractTable = (searchText, isLoading, setIsLoading) => {
  const {
    setList,
    list,
    sortByKey,
    sortDescStates,
  } = useTable();
  const [filteredList, setFilteredList] = useState(undefined);
  const [metadata, setMetadata] = useState({});

  const getAllReports = useCallback(() => {

    const service = `${getVarConfig({ key: "REACT_APP_ALL_CONTRACTS" })}?docs=true`;
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        const { contracts, metadata: metadataResponse } = data || {};
        setList(contracts || []);
        setMetadata(metadataResponse || {});
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setIsLoading, setList]);

  const getReportsByPage = useCallback((page = 1) => {
    const service = `${getVarConfig({ key: "REACT_APP_ALL_CONTRACTS" })}?docs=true&perPage=${PER_PAGE_REQUEST}&page=${page}`;
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        const { contracts, metadata: metadataResponse } = data || {};
        setList(contracts || []);
        setMetadata(metadataResponse || {});
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setIsLoading, setList]);

  useEffect(() => getReportsByPage(1), [getReportsByPage]);

  useEffect(() => { // filters List by searchText
    if (!isLoading && searchText) {
        const normalizeStr = (str) => {
            if (!!!str) return '';
            return str.toLowerCase();
        }
        const localSearchText = normalizeStr(searchText);
        const newFilteredList = list.filter(({
            process_no,
            trato_id,
            customer_no,
            initiative,
            name,
            legalrep,
            statusTrato,
            signStatus,
        }) => {
          return normalizeStr(process_no).includes(localSearchText) || normalizeStr(trato_id).includes(localSearchText) || normalizeStr(customer_no).includes(localSearchText) || normalizeStr(initiative).includes(localSearchText) || normalizeStr(name).includes(localSearchText) || normalizeStr(legalrep).includes(localSearchText) || normalizeStr(statusTrato).includes(localSearchText) || normalizeStr(signStatus).includes(localSearchText);
        });
        setFilteredList(newFilteredList);
    }
  }, [isLoading, list, searchText]);

  useEffect(() => { // resets filtered List
    if (!isLoading && !searchText && filteredList) {
        setFilteredList(undefined);
    }
  }, [filteredList, isLoading, searchText]);

  return {
    filteredList,
    list,
    setList,
    getAllReports,
    sortByKey,
    sortDescStates,
    getReportsByPage,
    metadata,
  };
};

export default useContractTable;
