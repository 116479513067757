import React, { useState } from 'react';
import Icon from '../../../global/Icon/Icon';
import DialogFilters from './DialogFilters';
import DialogOrderBy from './DialogOrderBy';
import styles from './styles.module.scss';

import {
 financeOrderWay,
} from '../../../../utils/utils'

const FilterButtons = props => {
  const { orderByField, changeWay, filterByParams, dataFilterParams, sendGA } = props;
  const [openOrderBy, setOpenOrderBy] = useState(false);
  const [waySelected, setWaySelected] = useState("descendente");
  const [openFilters, setOpenFilters] = useState(false);
  const [sliderData, setSliderData] = useState({
    min : dataFilterParams.min_ammount,
    max: dataFilterParams.max_ammount,
    currentMin: dataFilterParams.min_ammount,
    currentMax: dataFilterParams.max_ammount,
  });

  const [dates, setDates] = useState({
    start: new Date(dataFilterParams.min_date),
    end: new Date(dataFilterParams.max_date),
  });

  const handleFilter = filters => {
    if (filterByParams) filterByParams({ filters });
  };

  const handleClickIcon = (filter) => {
    if (filter === 'ordenar') {
      props.sendGA('GAEvent', 'Content', 'Content', 'Order', true, 'bank_deposit_button', 'Depósito en banco');
      setOpenOrderBy(true);
    } else if (filter ==='ascendente'){
      changeWay(financeOrderWay[0]);
      setWaySelected("descendente");
    } else if (filter ==="descendente"){
      changeWay(financeOrderWay[1]);
      setWaySelected("ascendente");
    } else if (filter === "filtrar") {
      props.sendGA('GAEvent', 'Content', 'Content', 'Filter', true, 'filter_button', 'filter_button');
      setOpenFilters(true);
    }
    else {
      handleFilter(filter)
    };
  }

  const buttons = [{
    title: 'ordenar',
    icon: 'chevron-sort',
  },{
    title: 'filtrar',
    icon: 'filter',
  },
  {
    title: waySelected,
    icon: 'sort-asc',
  }];

  const clearClick = (filter) => {
    props.sendGA('GAEvent', 'Content', 'Content', 'Clean filter', true, 'clean_filter_button', 'Limpiar');
    console.log('clear')
  }

  return (
    <div className={styles.container}>
      <DialogOrderBy
        open={openOrderBy}
        setOpen={setOpenOrderBy}
        onClick={orderByField}
      />

      <DialogFilters
        sendGA={props.sendGA}
        open={openFilters}
        setOpen={setOpenFilters}
        onFilter={handleFilter}
        sliderData={sliderData}
        setSliderData={setSliderData}
        dates={dates}
        setDates={setDates}
        onClear={clearClick}
        sucursales={props.sucursales}
        sucursalesSelected={props.sucursalesSelected}
      />

      {buttons.map(({icon, title}) => {
        return(
          <div onClick={() => handleClickIcon(title)}>
          <Icon
            icon={icon}
            width="18"
            height="18"
            handleClick={() => null}
          />
          <h1>{title}</h1>
        </div>
        );
      })}
    </div>
  );
};

export default FilterButtons;
