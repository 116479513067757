import React from 'react';
import Icon from '../../../../global/Icon/Icon';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';

const MenuItem = props => {
  const { icon, title, active, onClick } = props;

  const className = active ? styles.active : '';
  const iconColor = active ? 'yellow' : 'white';
  const iconName = `${icon}-${iconColor}`;

  const handleClick = () => onClick && onClick(props);

  return (
    <li
      className={`${className} ${styles.li}`}
      onClick={handleClick}
    >
      <Icon icon={iconName} />
      <h5>{title}</h5>
    </li>
  );
};

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default MenuItem;
