import React, { Component } from "react";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesTitle from "../../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import "./ThankYou.scss";

export default class ThankYou extends Component {

  state = {
    personaType: "fisica"
  }

  render() {
    return(
      <div className="thank-you">

        <MazTuInfoBeesTitle
          title="¡Gracias!"
          />

        <div className="thank-you__container">

          <div className="thank-you__container__logo"></div>

          <div className="thank-you__container__title">
            ¡Tu solicitud ha sido<br />enviada exitosamente!
          </div>

          <div className="thank-you__container__text">
            La información está siendo revisada. Ingresa durante la semana para comprobar que se haya activado tu programa.
          </div>

        </div>

      </div>
    )
  }

}