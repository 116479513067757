import React, { useEffect, useState } from 'react';

import Icon from '../../../global/Icon/Icon';
import HeaderTable from '../ProgramsTable/HeaderTable';

import useLegalRepresentativeTable from './useLegalRepresentativeTable';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';
import { TYPE_REPRESENTATIVE } from '../../../../pages/Admin/LegalRepresentative/utils';
import ConfirmDialog from '../ProgramForm/ConfirmDialog';

const LegalRepresentativeTable = props => {
  const { onClickEdit, setLoading, loading } = props;
  const [deletingRepresentative, setDeletingRepresentative] = useState({
    openDialog:false,
    representativeInfo: null,
  });
  const {
    list,
    isLoading,
    sortByKey,
    sortDescStates,
    deleteLegalRepresentative,
  } = useLegalRepresentativeTable();

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  if (isLoading) return <>Loading ...</>;
  if (!list?.length && !isLoading) return <>No se encontraron representantes legales</>;

  const onCancelDialog = () => setDeletingRepresentative({ openDialog: false, representativeInfo: null });
  const handleDeleteClick = (representativeInfo) => setDeletingRepresentative({ openDialog: true, representativeInfo });

  const onConfirmDialog = () => {
    deleteLegalRepresentative(deletingRepresentative.representativeInfo);
    onCancelDialog();
  }

  return (
    <div className={styles.container}>
      <ConfirmDialog
        open={deletingRepresentative.openDialog}
        text={`¿Deseas eliminar al representante ${deletingRepresentative.representativeInfo?.name} de la base de datos?`}
        confirmTxt="Eliminar"
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
      />
      <div className={styles.header}>
        <HeaderTable
          onClick={() => sortByKey('name')}
          title="Nombre representatnte legal"
          iconDesc={sortDescStates?.nameSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('email')}
          title="Correo"
          iconDesc={sortDescStates?.emailSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('phone')}
          title="Teléfono"
          iconDesc={sortDescStates?.phoneSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('rfc')}
          title="RFC"
          iconDesc={sortDescStates?.rfcSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('uen')}
          title="UEN Asignada"
          iconDesc={sortDescStates?.uenSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('typeRepresentative')}
          title="Tipo de representante"
          iconDesc={sortDescStates?.typeRepresentative}
        />

        <div></div>
      </div>

      <div className={styles.body}>
        {list
            .map((legalRep) => {
              const {
                id,
                name,
                email,
                phone,
                rfc,
                uen,
                typeRepresentative,
              } = legalRep;
              return (
                <div
                  className={styles.row}
                  key={id}
                >
                  <div className={styles.ml10}>{name}</div>
                  <div>{email}</div>
                  <div>{phone}</div>
                  <div>{rfc}</div>
                  <div>{uen}</div>
                  <div>{TYPE_REPRESENTATIVE[typeRepresentative]}</div>
                  <div>
                    <Icon
                      icon="edit"
                      handleClick={() => onClickEdit(legalRep)}
                    />
                    <Icon
                      icon="trash"
                      handleClick={() => handleDeleteClick(legalRep)}
                    />
                  </div>
                </div>
              )}
        )}
      </div>
    </div>
  );
};

LegalRepresentativeTable.prototype = propTypes;
LegalRepresentativeTable.defaultProps = defaultProps;

export default LegalRepresentativeTable;
