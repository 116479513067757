import PropTypes from 'prop-types';

export const propTypes = {
  goal: PropTypes.object,
  className: PropTypes.string,
  onChangeGoal: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export const defaultProps = {
  goal: {},
  className: '',
  onChangeGoal: () => {},
  onCancel: () => {},
  onSave: () => {},
};
