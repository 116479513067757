import React, { Component } from "react";
import { getVarConfig } from "../../../utils/environment";
import fetchAPI from "../../../utils/fetchAPI";
import { getUser } from "../../../utils/sessionInfo";

import "./MazTuInfoBeesCardInfo.scss";

const imgClubBBlack = require('./assets/B_BLACK.png');
const imgClubBlackPremium = require('./assets/logo-club-black-prem.svg');
const imgClubBlackZonas = require('./assets/logo-club-black-zonas.svg');
const imgClubBlack = require('./assets/logo-club-black.svg');
const imgClubMixtoPremium = require('./assets/logo-club-mixto-premium.svg');
const imgClubMixto = require('./assets/logo-club-mixto.svg');
const imgClubBMax = require('./assets/B_MAX.png');
const imgBmax1 = require('./assets/bmax_info_1.png');
const imgBmax2 = require('./assets/bmax_info_2.png');

export default class MazTuInfoBeesCardInfo extends Component {

  state = {
    dataPerformance: null
  }

  componentDidMount() {
    // this.getPerformance();
  }

  getPerformance = () => {
    const user = getUser();
    let urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_PERFORMANCE" });
    urlService = urlService.replace("{client}", user.ID);
    this.setState({
      loaderPerformanceOpen: true
    });
    fetchAPI({
      service: urlService,
      protocol: "GET",
      callbackThen: (response) => {
        this.setState({
          dataPerformance: {
            total: {
              boxes: response.data.total.goal,
              points: response.data.total.points
            },
            premium: {
              boxes: response.data.premium.goal,
              points: response.data.premium.points
            },
            coverage: {
              boxes: response.data.coverage.goal,
              points: response.data.coverage.points
            },
            returnable: {
              boxes: response.data.returnable.goal,
              points: response.data.returnable.points
            }
          }
        })
      },
      callbackError: (status) => {
        console.log(status);
      },
    });
  }

  calcFontSizeClass = (str = '') => {
    str = str ? `${str}`.trim() : '';
    const lengthStr = str.length;
    const lengthNumber = lengthStr < 7 ? lengthStr : 7;
    switch(lengthNumber) {
      case 5:
        return 'font-small--1';
      case 6:
        return 'font-small--2';
      case 7:
        return 'font-small--3';
      default: return '';
    }
  }

  render() {

    const user = getUser();
    let imageClub;

    switch(user.clubLevel) {
      case 'CLUB B BLACK':
        imageClub = imgClubBlack;
        break;
      case 'CLUB B BLACK ZONAS':
        imageClub = imgClubBlackZonas;
        break;
      case 'CLUB B BLACK PREMIUM':
        imageClub = imgClubBlackPremium;
        break;
      case 'CLUB B BLACK MIXTO':
        imageClub = imgClubMixto;
        break;
      case 'CLUB B BLACK PREMIUM MIXTO':
        imageClub = imgClubMixtoPremium;
        break;
      case 'B_MAX':
        imageClub = imgClubBMax;
        break;
      case 'B_BLACK':
        imageClub = imgClubBBlack;
        break;
      default:
        console.log('default');
    }

    const info = typeof this.props.info !== "undefined" ? this.props.info : "";

    return(
      <div className="maz-tu-info-bees-card-info">

        <div className="maz-tu-info-bees-card-info__head">
          Información
        </div>

        <div className="maz-tu-info-bees-card-info__columns">

          {

            info !== "" && info === "bmax" ?

              <>

                <div className="maz-tu-info-bees-card-info__columns__column no-border">
                  <div className="maz-tu-info-bees-card-info__columns__column__image bmax">
                    <img src={ imgBmax1 } alt="Club" />
                  </div>
                  <div className="maz-tu-info-bees-card-info__columns__column__label">
                    <span>Facturación</span>
                  </div>
                </div>

                <div className="maz-tu-info-bees-card-info__columns__column no-border">
                  <div className="maz-tu-info-bees-card-info__columns__column__image bmax">
                    <img src={ imgBmax2 } alt="Club" />
                  </div>
                  <div className="maz-tu-info-bees-card-info__columns__column__label">
                    <span>Cobertura</span>
                  </div>
                </div>

              </>

            :

              <>
              
                <div className="maz-tu-info-bees-card-info__columns__column">
                  <div className="maz-tu-info-bees-card-info__columns__column__image">
                    <img src={ imageClub } alt="Club" />
                  </div>
                  <div className="maz-tu-info-bees-card-info__columns__column__label">
                    <span>Programa</span>
                  </div>
                </div>

                <div className="maz-tu-info-bees-card-info__columns__column">
                  <div className="maz-tu-info-bees-card-info__columns__column__image">
                    <div className="maz-tu-info-bees-card-info__columns__column__image__hex">
                      {
                      
                      this.state.dataPerformance !== null ?
                      
                        <>
                          <div className="maz-tu-info-bees-card-info__columns__column__image__hex__column maz-tu-info-bees-card-info__columns__column__image__hex__column--left">
                            <div className="maz-tu-info-bees-card-info__columns__column__image__hex__column__row top-fixed--1">
                              <span>{ this.state.dataPerformance.total.boxes }</span>
                              <span>Cajas</span>
                            </div>
                            <div className="maz-tu-info-bees-card-info__columns__column__image__hex__column__row maz-tu-info-bees-card-info__columns__column__image__hex__column__row--white top-fixed--2">
                              <span>{ this.state.dataPerformance.premium.boxes }</span>
                              <span>Cajas</span>
                            </div>
                            <div className="maz-tu-info-bees-card-info__columns__column__image__hex__column__row top-fixed--3">
                              <span>{ this.state.dataPerformance.coverage.boxes }</span>
                              <span>Cajas</span>
                            </div>
                            <div className="maz-tu-info-bees-card-info__columns__column__image__hex__column__row top-fixed--4">
                              <span>{ this.state.dataPerformance.returnable.boxes }</span>
                              <span>Cajas</span>
                            </div>
                          </div>
                          <div className="maz-tu-info-bees-card-info__columns__column__image__hex__column maz-tu-info-bees-card-info__columns__column__image__hex__column--right">
                            <div className={`maz-tu-info-bees-card-info__columns__column__image__hex__column__row top-fixed--1 ${this.calcFontSizeClass(this.state.dataPerformance.total.points)}`}>
                              <span>{ this.state.dataPerformance.total.points }</span>
                              <span>Puntos</span>
                            </div>
                            <div className={`maz-tu-info-bees-card-info__columns__column__image__hex__column__row maz-tu-info-bees-card-info__columns__column__image__hex__column__row--white top-fixed--2 ${this.calcFontSizeClass(this.state.dataPerformance.premium.points)}`}>
                              <span>{ this.state.dataPerformance.premium.points }</span>
                              <span>Puntos</span>
                            </div>
                            <div className={`maz-tu-info-bees-card-info__columns__column__image__hex__column__row top-fixed--3 ${this.calcFontSizeClass(this.state.dataPerformance.coverage.points)}`}>
                              <span>{ this.state.dataPerformance.coverage.points }</span>
                              <span>Puntos</span>
                            </div>
                            <div className={`maz-tu-info-bees-card-info__columns__column__image__hex__column__row top-fixed--4 ${this.calcFontSizeClass(this.state.dataPerformance.returnable.points)}`}>
                              <span>{ this.state.dataPerformance.returnable.points }</span>
                              <span>Puntos</span>
                            </div>
                          </div>
                        </> : null
                    
                      }
                    </div>
                  </div>
                  <div className="maz-tu-info-bees-card-info__columns__column__label">
                    <span>Incentivos</span>
                  </div>
                </div>

                <div className="maz-tu-info-bees-card-info__columns__column">
                  <div className="maz-tu-info-bees-card-info__columns__column__image">
                    <div className="maz-tu-info-bees-card-info__columns__column__image__level">
                      { user.level }
                    </div>
                  </div>
                  <div className="maz-tu-info-bees-card-info__columns__column__label">
                    <span>Nivel de meta</span>
                  </div>
                </div>
              
              </>

          }

        </div>

      </div>
    )
  }

}
