export function getVarConfig({ key }) {

  let keyValue = null;

  if(typeof window[key] !== "undefined") {
    if(window[key].includes("<%=")) {
      keyValue = getEnvKeyValue(key);
    } else {
      keyValue = window[key];
    }
  } else {
    keyValue = getEnvKeyValue(key);
  }

  return keyValue;

}

function getEnvKeyValue(key) {
  if(typeof process.env[key] !== "undefined") {
    return process.env[key];
  } else {
    console.error("No se encuentra el key " + key);
    return "";
  }
}