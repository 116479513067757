import React from 'react';
import Icon from '../../../../global/Icon/Icon';
import { defaultProps, propTypes } from './types';

const HeaderTable = props => {
  const {
    onClick,
    title,
    iconDesc,
    iconName,
    noIcon,
  } = props;

  let icon = iconDesc ? 'arrow-down-black' : 'arrow-upper-black';
  icon = iconName || icon;

  return (
    <div onClick={onClick}>
      <h1>{title}</h1>
      {!noIcon ? (
        <Icon
          icon={icon}
          height="12"
          width="12"
        />
      ) : null}
    </div>
  );
};

HeaderTable.prototype = propTypes;
HeaderTable.defaultProps = defaultProps;

export default HeaderTable;
