import PropTypes from 'prop-types';

export const propTypes = {
  thumbnail: PropTypes.string,
  file: PropTypes.any,
  setFile: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
};

export const defaultProps = {
  thumbnail: '',
  file: null,
  setFile: null,
};
