import React from 'react';
import styles from './styles.module.scss';
import { financeCurrencyFormat } from '../../../utils/utils'
import { getUser } from '../../../utils/sessionInfo';

const MazTuInfoBeesAccountFomentoInvoices = (props) => {

  const {
    className,
    setPath,
    customerPromotions,
    setBackButton,
  } = props;

  const {
    invoices
  } = customerPromotions || {};

  return (
    <div className={`${className} card mt-2 mb-3 ${styles.container}`}>
      <h1>Detalle de facturas de fomento</h1>
      {(invoices)?.map(invoice => {
        let tipo = ""
        switch (invoice.type) {
          case "RC":
            tipo = "Nota de Cargo";
            break;
          case "RG":
            tipo = "Nota de Crédito";
            break;
          case "EF":
          case "CH":
          case "TE":
          case "TJ":
          case "3P":
          case "EW":
            tipo = "Pagos"
            break;
        }
        const invoice_date = new Date(invoice.invoice_date);
        const invoice_due_date = new Date(invoice.invoice_due_date);
        return (
          <div className={`${styles.container__card}`}>
            <div className={``}>
              <span
                className={`${styles.container__body__row} py-3`}
              >
                <div className={`container d-flex justify-content-between align-items-center`}>
                  <h3>Tipo:</h3>
                  <h2>{tipo}</h2>
                </div>
              </span>
              <span
                className={`${styles.container__body__row} py-3`}
              >
                <div className={`container d-flex justify-content-between align-items-center`}>
                  <h3>No. factura:</h3>
                  <h2>{invoice.invoice_no ? invoice.invoice_no : invoice.invoice_doc}</h2>
                </div>
              </span>
              <span
                className={`${styles.container__body__row} py-3`}
              >
                <div className={`container d-flex justify-content-between align-items-center`}>
                  <h3>Factura generada:</h3>
                  <h2>{`${invoice_date.getDate()}/${invoice_date.getMonth() + 1}/${invoice_date.getFullYear()}`}</h2>
                </div>
              </span>
              <span
                className={`${styles.container__body__row} py-3`}
              >
                <div className={`container d-flex justify-content-between align-items-center`}>
                  <h3>Vence:</h3>
                  <h2>{`${invoice_due_date.getDate()}/${invoice_due_date.getMonth() + 1}/${invoice_due_date.getFullYear()}`}</h2>
                </div>
              </span>
            </div>
            <div className={`${styles.container__card__footer}`}>
              <div className={`container d-flex justify-content-between align-items-center`}>
                <h3>Importe:</h3>
                <h2>{financeCurrencyFormat(Math.round((invoice.amount) * 100) / 100)}</h2>
              </div>
            </div>
          </div>
        )
      }
      )}

    </div>
  );
}

export default MazTuInfoBeesAccountFomentoInvoices;
