import PropTypes from 'prop-types';

export const propTypes = {
  file: PropTypes.any,
  setFile: PropTypes.func,
};

export const defaultProps = {
  file: null,
  setFile: null,
};
