import React, { Component } from 'react';
import MazTuInfoBeesAccountCard from '../../components/maz-tu-info-bees/maz-tu-info-bees-home-card';
import Disclaimer from './Disclaimer';
import Faqs from './Faqs';
import Tutorial from './Tutorial';
import styles from './styles.module.scss';
import Icon from '../../components/global/Icon/Icon';
import { isMobile } from 'react-device-detect';

const initialState = {
  faqActive: false,
  disclaimerActive: false,
  homeActive: false,
  tutorial: undefined,
  currentFaq: null,
};

class Help extends Component {
  constructor(props) {
    super(props);
    this.setHomeActive = this.setHomeActive.bind(this);
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.setState({
      ...initialState,
      homeActive: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      tutorial: prevTutorial,
      faqActive: prevfaqActive,
    } = prevState;
    const { tutorial, faqActive } = this.state;

    if (!!!tutorial && tutorial !== prevTutorial) {
      this.setFaqActive();
    }

    if (faqActive && faqActive !== prevfaqActive) {
      this.props.setBackButton(true, this.setFaqActive);
    }
  }


  setFaqActive = () => {
    this.props.sendGA('GAEvent', 'Content', 'Content', 'FAQS', true, 'FAQS_button', 'Preguntas frecuentes');
    this.setState({
      ...initialState,
      faqActive: true,
    });
    this.props.setBackButton(true, this.setHomeActive);
  }

  setDisclaimerActive = () => {
    this.props.sendGA('GAEvent', 'Content', 'Content', 'Disclaimers', true, 'FAQS_button', 'Aclaraciones');
    this.setState({
      ...initialState,
      disclaimerActive: true,
    })
    this.props.setBackButton(true, this.setHomeActive);
  }

  setHomeActive = () => {
    this.setState({
      ...initialState,
      homeActive: true,
    })
    this.props.setBackButton(false, null);
  }

  setTutorial = tutorial => {
    const { title } = tutorial;
    this.props.sendGA('GAEvent', 'Content', 'Content', 'Tutorials', true, 'Tutorials_card', title);
    this.setState({
      ...initialState,
      tutorial,
    })
  }

  setCurrentFaq = currentFaq => {
    const { title } = currentFaq;
    this.props.sendGA('GAEvent', 'Content', 'Card', 'FAQS Section', true, 'FAQS_card', title);
    this.setState({
      ...initialState,
      currentFaq,
      faqActive: true,
    });
    this.props.setBackButton(true, this.setFaqActive);
  }

  render() {
    const {
      state,
      setFaqActive,
      setDisclaimerActive,
      setTutorial,
      setCurrentFaq,
    } = this;

    const {
      faqActive,
      disclaimerActive,
      homeActive,
      tutorial,
      currentFaq,
    } = state;

    return (
      <div className={styles.container}>
        {homeActive ? (
          <div className={styles.banner}>
            <Icon
              icon="support-globe"
              width="45"
              height="45"
            />
          </div>
        ) : null}

        <div className={styles.body}>
          {homeActive ? (
            <>
            <div className={styles.body__header}>
              <div className="maz-tu-info-bees-header__logo" />
              <h1>{tutorial?.currentTitle || 'Ayuda'}</h1>
            </div>
            <div class="container">
              <div className="row justify-content-center">
                <MazTuInfoBeesAccountCard
                  title="Preguntas frecuentes"
                  description="Aquí encontrarás  respuestas y sugerencias para resolver tus dudas"
                  bannerColor="max"
                  bannerIcon="support"
                  onClick={setFaqActive}
                />

                <MazTuInfoBeesAccountCard
                  title="Aclaraciones"
                  description="Contactanos para poder apoyarte con cualquier aclaración"
                  bannerColor="max"
                  bannerIcon="support-exclamation"
                  onClick={setDisclaimerActive}
                />
              </div>
            </div>
            </>
          ) : null}

          {faqActive || tutorial ? (
            <Faqs
              tutorial={tutorial}
              setTutorial={setTutorial}
              currentFaq={currentFaq}
              setCurrentFaq={setCurrentFaq}
              user={this.props.user}
            />
          ) : null}

          {disclaimerActive ? (
            <Disclaimer />
          ) : null}

          {!!tutorial && isMobile ? (
            <Tutorial
              tutorial={tutorial}
              setTutorial={setTutorial}
              user={this.props.user}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default Help;
