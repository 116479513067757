import React from 'react';
import { useMsal } from '@azure/msal-react';

import Icon from '../../../global/Icon/Icon';
import { getNicknameByFullName } from '../../../../utils/utils';

import { defaultProps, propTypes } from './types';
import styles from './styles.module.scss'

const Header = props => {
  const {
    title,
    back,
    onClickBack,
  } = props;

  const { accounts } = useMsal();
  const [userAccountMsal] = accounts || [];
  const userName = userAccountMsal?.name || '';
  const nickname = getNicknameByFullName(userName);

  const handleOnClickBack = () => {
    if (onClickBack) onClickBack();
  }

  return (
    <div className={styles.container}>
      {!back && (<h1>{title}</h1>)}

      {back && (
        <div
          className={styles.container__back}
          onClick={handleOnClickBack}
        >
          <Icon icon="back-navigation" />
          Atras
        </div>
      )}
      <div className={styles.container__user_info}>
        <h5>{userName}</h5>
        <span>{nickname}</span>
      </div>
    </div>
  );
};

Header.prototype = propTypes;
Header.defaultProps = defaultProps;

export default Header;
