import PropTypes from 'prop-types';

export const propTypes = {
  color: PropTypes.string,
  colorIcon: PropTypes.string,
  title: PropTypes.string,
};

export const defaultProps = {
  color: '',
  colorIcon: '',
  title: '',
};
