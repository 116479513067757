import React, { Component } from "react";
import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesLoader from "../../components/maz-tu-info-bees/maz-tu-info-bees-loader/MazTuInfoBeesLoader";
import fetchAPI from "../../utils/fetchAPI";
import { getUser } from "../../utils/sessionInfo";
import { getVarConfig } from "../../utils/environment";

import "./Facturacion.scss";

export default class Facturacion extends Component {

  state = {
    loader: false,
    dataBilling: []
  }

  componentDidMount() {
    this.getDatsBilling();
  }

  getDatsBilling = () => {
    const user = getUser();
    this.setState({
      loader: true
    });
    fetchAPI({
      service: "v1/MX/challenges/billing/customers/" + user.ID,
      protocol: "GET",
      callbackThen: (response) => {
        if(typeof response.data !== "undefined") {
          this.setState({
            dataBilling: response.data,
            loader: false
          });
        } else {
          this.setState({
            loader: false
          });
        }
      },
      callbackError: (status) => {
        console.log(status);
        this.setState({
          loader: false
        });
      },
    });
  }

  render() {

    const nameFacturacion = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_FACTURACION" });

    let real = this.state.dataBilling.length > 0 ? parseInt(this.state.dataBilling[0].real) : 0;
    const goal = this.state.dataBilling.length > 0 ? parseInt(this.state.dataBilling[0].goal) : 0;
    const points = this.state.dataBilling.length > 0 ? parseInt(this.state.dataBilling[0].points) : 0;
    const nextPoints = points === 0 ? 500 : points === 500 ? 1800 : 3500;

    let widthProgress = 0;
    let leftGoal = 0;
    let goalProgress = 0;
    
    goalProgress = points === 0 ? goal + 6000 : points === 500 ? goal + 3000 : goal;

    if(this.state.dataBilling.length > 0) {
      if(real > goal) {
        widthProgress = 100;
      } else {
        widthProgress = (100 * real) / goalProgress;
        leftGoal = goal - real;
      }
    }

    return (
      <div className="facturacion">

        <MazTuInfoBeesLoader open={this.state.loader} />
        
        <MazTuInfoBeesTitle 
          title={nameFacturacion} 
          subtitle="Aquí encontrarás el monto acumulado en pesos por tus compras en Miscelánea durante el mes." />

        <div className="facturacion__resume">

          <div className="facturacion__resume__title">
            Cantidad facturada al día de hoy:
          </div>

          <div className="facturacion__resume__amount">
            { '$' + real.toLocaleString() }
          </div>

          <div className="facturacion__resume__progress">

            <div 
              className="facturacion__resume__progress__bar"
              style={{
                width: widthProgress + "%"
              }}
              ></div>

            <div className="facturacion__resume__progress__section">
              500 puntos
            </div>

            <div className="facturacion__resume__progress__section">
              1,800 puntos
            </div>

            <div className="facturacion__resume__progress__section">
              3,500 puntos
            </div>

          </div>

          <div className="facturacion__resume__labels">

            <div className="facturacion__resume__labels__left">
              $0
            </div>

            <div className="facturacion__resume__labels__right">
              $9,000
            </div>

          </div>

          <div className="facturacion__resume__description">
            {
              points < 3500
                ? <>Estás a <b>${leftGoal.toLocaleString()}</b> de lograr la siguiente meta y alcanzar <b>{nextPoints.toLocaleString()}</b>.</>
                : <><b>¡Bien hecho!</b><br />Lograste cumplir la meta.</>
            }
          </div>

          <div className="facturacion__resume__award">
            Premio alcanzado: <span>{points.toLocaleString()} puntos</span>
          </div>

        </div>

        <div className="facturacion__levels">

          <div className="facturacion__levels__title">
            Niveles de facturación
          </div>

          <div className="facturacion__levels__resume">

            <div className="facturacion__levels__resume__column">

              <div className="facturacion__levels__resume__column__row">
                <div className="facturacion__levels__resume__column__row__title">
                  Meta
                </div>
                <div className="facturacion__levels__resume__column__row__amount">
                  $4,000
                </div>
                <div className="facturacion__levels__resume__column__row__label">
                  facturados
                </div>
              </div>

              <div className="facturacion__levels__resume__column__row">
                <div className="facturacion__levels__resume__column__row__title">
                  Meta
                </div>
                <div className="facturacion__levels__resume__column__row__amount">
                  $6,000
                </div>
                <div className="facturacion__levels__resume__column__row__label">
                  facturados
                </div>
              </div>

              <div className="facturacion__levels__resume__column__row">
                <div className="facturacion__levels__resume__column__row__title">
                  Meta
                </div>
                <div className="facturacion__levels__resume__column__row__amount">
                  $9,000
                </div>
                <div className="facturacion__levels__resume__column__row__label">
                  facturados
                </div>
              </div>

            </div>

            <div className="facturacion__levels__resume__column">

              <div className="facturacion__levels__resume__column__row">
                <div className="facturacion__levels__resume__column__row__title">
                  Premio
                </div>
                <div className="facturacion__levels__resume__column__row__amount">
                  500
                </div>
                <div className="facturacion__levels__resume__column__row__label">
                  Puntos
                </div>
              </div>

              <div className="facturacion__levels__resume__column__row">
                <div className="facturacion__levels__resume__column__row__title">
                  Premio
                </div>
                <div className="facturacion__levels__resume__column__row__amount">
                  1,800
                </div>
                <div className="facturacion__levels__resume__column__row__label">
                  Puntos
                </div>
              </div>

              <div className="facturacion__levels__resume__column__row">
                <div className="facturacion__levels__resume__column__row__title">
                  Premio
                </div>
                <div className="facturacion__levels__resume__column__row__amount">
                  3,500
                </div>
                <div className="facturacion__levels__resume__column__row__label">
                  Puntos
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>
    );
  }
}
