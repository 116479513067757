import PropTypes from 'prop-types';

export const propTypes = {
  label: PropTypes.string,
  points: PropTypes.number,
  highlighted: PropTypes.bool,
};

export const defaultProps = {
  label: '',
  points: 0,
  highlighted: false,
};
