import PropTypes from 'prop-types';
import { propTypes as MenuItemTypes } from './MenuItem/types';

export const propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape(MenuItemTypes)
  ),
  onClickItem: PropTypes.func,
};

export const defaultProps = {
  menuItems: [],
  onClickItem: () => {},
};
