import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';

import BandButton from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/BandButton';
import CandidatesTable from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/CandidatesTable';
import DetailsTable from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/CandidatesTable/DetailsTable';
import Header from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header';
import SearchInput from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/SearchInput';
import UploadFileCandidatesBtn from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/UploadFileCandidatesBtn';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";
import useCandidatesTable from './useCandidatesTable';
import useDetailsTable from './useDetailsTable';
import useUploadFile from './useUploadFile';

const Candidates = props => {
  const {
    setLoading,
    loading,
    handleToast,
    title,
  } = props;

  const [currentHistoryId, setCurrentHistoryId] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const showDetails = useMemo(() => (!!currentHistoryId), [currentHistoryId]);
  const newCustumers = true;
  const {
    list,
    isLoading,
    sortByKey,
    sortDescStates,
    getHistory,
    filteredList,
  } = useCandidatesTable({ newCustumers, searchText });

  const {
    list: listDetails,
    isLoading: isLoadingDetails,
    sortByKey: sortByKeyDetails,
    sortDescStates: sortDescStatesDetails,
  } = useDetailsTable(currentHistoryId);

  const {
    submitFile,
  } = useUploadFile({ newCustumers, handleToast, setLoading, refreshData: getHistory });

  const titleBand = newCustumers ? 'Candidatos para firma de programas de mercado' : 'Candidatos para upgrade a Club B MAX';

  const handleShowDetail = (historyId) => {
    setCurrentHistoryId(historyId);
  }

  useEffect(() => {
    if (title && !!currentHistoryId) setCurrentHistoryId(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <div className={styles.container}>
      <Header
        title="Candidatos"
        userName="User Name"
        onClickBack={() => setCurrentHistoryId(undefined)}
        back={showDetails}
      />

      <div className={styles.body}>
        <div className={styles.body__padding}>
          <section>
            <SearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              hide={showDetails}
            />

            <UploadFileCandidatesBtn
              hide={showDetails}
              submitFile={submitFile}
            />
          </section>

          <BandButton
            title={titleBand}
            color="yellow"
            colorIcon="yellow"
          />

          <CandidatesTable
            onClickRow={({ id }) => handleShowDetail(id)}
            setLoading={setLoading}
            loading={loading}
            hide={showDetails}
            list={filteredList || list}
            isLoading={isLoading}
            sortByKey={sortByKey}
            sortDescStates={sortDescStates}
          />

          <DetailsTable
            setLoading={setLoading}
            loading={loading}
            hide={!showDetails}
            list={listDetails}
            isLoading={isLoadingDetails}
            sortByKey={sortByKeyDetails}
            sortDescStates={sortDescStatesDetails}
          />
        </div>
      </div>
    </div>
  );
};

Candidates.propTypes = propTypes;
Candidates.defaultProps = defaultProps;

export default Candidates;
