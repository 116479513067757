import { FileUploader } from "react-drag-drop-files";
import { defaultProps, propTypes } from './types';
import styles from './styles.module.scss';

const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG'];

const DragNDrop = props => {
  const {file, setFile} = props;

  const handleChange = (file) => {
    if (typeof setFile === 'function') {
      setFile(file);
    }
  };

  return (
    <div className={styles.container}>
      <FileUploader
        multiple={false}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      />
      <p>{file ? `Imagen: ${file.name}` : "Imagen no seleccionada"}</p>
    </div>
  );
}

DragNDrop.prototype = propTypes;
DragNDrop.defaultProps = defaultProps;

export default DragNDrop;
