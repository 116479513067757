import React from 'react';
import MazTuInfoBeesButton from '../../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const LoginTemplate = props => {
  const { onClick } = props;
  return (
    <div className={styles.login}>
        <div className={styles.login__logo} />
          <div className={styles.login__button}>
            <MazTuInfoBeesButton
              handleClick={onClick}
              textButton={"INGRESAR"}
            />
        </div>
      </div>
  );
};

LoginTemplate.propTypes = propTypes;
LoginTemplate.defaultProps = defaultProps;

export default LoginTemplate;
