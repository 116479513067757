import { useEffect, useState } from "react";
import { sortArrayObjectByKeyAsc, sortArrayObjectByKeyDesc } from "../utils";

const useTable = () => {
  const [sortDescStates, setSortDescStates] = useState(undefined);
  const [list, setList] = useState([]);

  /**
   * If the current state of the key is true, set it to false, otherwise set it to true.
   */
  const updateSortStates = (key) => {
    const stateKey = `${key}SortDesc`;
    const currentState = sortDescStates[`${stateKey}`];
    Object.keys(sortDescStates)
      .forEach(state => (sortDescStates[state] = false));
    sortDescStates[stateKey] = !currentState;
    setSortDescStates({ ...sortDescStates });
  }

  /**
   * If the key is programId, name, or updatedAt,
   * then sort the array by the key, otherwise do nothing.
   */
  const sortByKey = (key) => {
    let sortedArray = [...list];
    if (sortDescStates[`${key}SortDesc`]) {
      sortedArray = sortArrayObjectByKeyAsc(sortedArray, key);
    } else {
      sortedArray = sortArrayObjectByKeyDesc(sortedArray, key);
    }

    setList([...sortedArray]);
    updateSortStates(key);
  }

  useEffect(() => {
    const challenge = list[0];
    if (challenge && !sortDescStates) {
      const keysArray = Object.keys(list).map(key => `${key}SortDesc`);
      const sortDescStatesObject = Object.assign(...keysArray.map(key => ({ [key]: false })));
      setSortDescStates(sortDescStatesObject);
    }
  }, [list, sortDescStates]);

  return {
    setList,
    list,
    sortByKey,
    sortDescStates,
  }
}

export default useTable;
