import { useCallback, useEffect, useMemo, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";
import { rfcValido, validEmail } from "../../../../utils/utils";

const useLegalRepresentativeForm = props => {
  const {
    legalRepresentative,
    onSubmit,
  } = props;
  const [legalRepresentativeData, setLegalRepresentativeData] = useState(undefined);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uenList, setUenList] = useState([]);

  const requireFields = useMemo(() => [
  'name',
  'email',
  'phone',
  'rfc',
  'uen',
  'typeRepresentative'
  ], []);

  /* Checking if the form is valid. */
  const validForm = useMemo(() => !!requireFields
    .reduce((total, field) => {
      if (legalRepresentativeData) {
        const value = legalRepresentativeData[field];
        let hasValue = !!value;
        if (field === 'name' && hasValue) {
          hasValue = 2 < value.trim().length;
        }
        if (field === 'phone' && hasValue) {
          hasValue = value.length === 10;
        }
        if (field === 'rfc' ) {
          if (hasValue) {
            hasValue = rfcValido(value);
          } else {
            hasValue = true;
          }
        }
        if (field === 'email' && hasValue) {
          hasValue = validEmail(value);
        }
        if (field === 'typeRepresentative' && hasValue) {
          hasValue = 2 < value.trim().length;
        }
        return total * hasValue;
      }
      return false;
    }, true), [legalRepresentativeData, requireFields]);

  const onChangeValue = useCallback((value, fieldName) => {
    if (fieldName !== 'name') value = value.trim();
    if (fieldName === 'rfc') value = value.toUpperCase();
    setLegalRepresentativeData({
      ...legalRepresentativeData,
      [fieldName]: value,
    })
  }, [legalRepresentativeData]);

  const getUenList = useCallback(() => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_LEGAL_REPRESENTATIVE" }) + '/uen_list';
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        setUenList(data || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setUenList([]);
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, []);

  const saveNewLegalRepresentative = () => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_LEGAL_REPRESENTATIVE" }) + '/new';
    setIsLoading(true);
    fetchAPI({
      params: legalRepresentativeData,
      service,
      protocol: "POST",
      callbackThen: () => {
        setIsLoading(false);
        if (onSubmit) onSubmit(legalRepresentativeData);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  };

  const updateLegalRepresentative = () => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_LEGAL_REPRESENTATIVE" });
    const customHeaders = {
      "Content-Type": "application/json",
    };

    setIsLoading(true);
    fetchAPI({
      params: legalRepresentativeData,
      service,
      protocol: "PUT",
      customHeaders,
      callbackThen: () => {
        setIsLoading(false);
        if (onSubmit) onSubmit(legalRepresentativeData);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  };

  const handleSubmit = () => {
    setOpenConfirm(false);
    if (legalRepresentative) {
      updateLegalRepresentative();
    } else {
      saveNewLegalRepresentative();
    }
  }

  useEffect(() => {
    if (legalRepresentative && !legalRepresentativeData) {
      setLegalRepresentativeData(legalRepresentative);
    }
  }, [legalRepresentative, legalRepresentativeData]);

  useEffect(() => getUenList(), [getUenList]);

  return {
    legalRepresentativeData,
    openConfirm,
    setOpenConfirm,
    onChangeValue,
    isLoading,
    setIsLoading,
    validForm,
    uenList,
    handleSubmit,
  }
}

export default useLegalRepresentativeForm;
