import React from 'react';
import { Dialog } from '@mui/material';

import styles from './styles.module.scss';
import Icon from '../../../global/Icon/Icon';

const DepositDialog = (props) => {
  const { user, open, onClose } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.dialog}
      fullScreen
    >
      <div className={styles.card}>
          <div className={styles.card__header}>
            <h1>Despósito en banco</h1>
            <div
              className={styles.card__close}
              onClick={onClose}
            >
              <Icon
                icon={"close-black"}
                width="14"
                height="14"
              />
            </div>
          </div>
          <br />
          <div className={styles.card__info_row}>
            <span>Utiliza los siguientes datos para pagar por medio de depósito bancario</span>
          </div>
          <div className={styles.card__info_row}>
            <h4>Nombre del beneficiario</h4>
            <span>Grupo Modelo</span>
          </div>
          <div className={styles.card__info_row}>
            <h4>Banco</h4>
            <span>Banamex</span>
          </div>
          <div className={styles.card__info_row}>
            <h4>Sucursal</h4>
            <span>6505</span>
          </div>
          <div className={styles.card__info_row}>
            <h4>Número de cuenta</h4>
            <span>{user.virtualAccount?user.virtualAccount.virtual_account:null}</span>
          </div>
          <div className={styles.card__info_row}>
            <h4>Referencia*</h4>
            <span>{user.virtualAccount?user.virtualAccount.payment_reference:null}</span>
            <div className={styles.card__footer}>
              *Proporciona al ejecutivo del banco esta referencia
            </div>
          </div>
        {user.virtualAccount ? user.virtualAccount.foment_payment_reference ? (
          <div className={styles.card__info_row}>
            <h4>Referencia Fomento*</h4>
            <span>{user.virtualAccount ? user.virtualAccount.foment_payment_reference : null}</span>
          </div>
        ) : null : null}

        <div className={`${styles.card__footer_icon}`}>
            Recuerda siempre utilizar tu referencia para poder identificar tus pagos
          </div>
      </div>
    </Dialog>
  );
};

export default DepositDialog;
