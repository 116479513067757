import React, { useEffect } from 'react';

import Icon from '../../../global/Icon/Icon';
import styles from './styles.module.scss';
import HeaderTable from './HeaderTable';
import { defaultProps, propTypes } from './types';

import useProgramsTable from './useProgramsTable';
import { formatDateDDMMYYYY } from '../../../../utils/utils';

const ProgramsTable = props => {
  const { onClickEdit, setLoading, loading } = props;

  const {
    programsList,
    isLoading,
    sortByKey,
    sortDescStates
  } = useProgramsTable();

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  if (isLoading) return <>Loading ...</>;
  if (!programsList.length && !isLoading) return <>No se encontraron programas</>;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeaderTable
          onClick={() => sortByKey('programId')}
          title="ID"
          iconDesc={sortDescStates?.programIdSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('name')}
          title="Nombre desafío"
          iconDesc={sortDescStates?.nameSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('updatedAt')}
          title="Fecha de creación"
          iconDesc={sortDescStates?.updatedAtSortDesc}
        />

        <div></div>
      </div>

      <div className={styles.body}>
        {programsList
          .map((program) => {
            const { programId, name, updatedAt} = program;
            return (
              <div
                className={styles.row}
                key={name}
              >
                <div className={styles.center}>{programId}</div>
                <div>{name}</div>
                <div className={styles.center}>{formatDateDDMMYYYY(updatedAt)}</div>
                <div className={styles.center}>
                  <Icon
                    icon="edit"
                    handleClick={() => onClickEdit(program)}
                  />
                </div>
              </div>
            )}
        )}
      </div>
    </div>
  );
}

ProgramsTable.prototype = propTypes;
ProgramsTable.defaultProps = defaultProps;

export default ProgramsTable;
