import React, { useEffect } from 'react';
import ImageUploader from '../ImageUploader';
import ConfirmDialog from '../ProgramForm/ConfirmDialog';
import TextEditor from '../TextEditor';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";
import useTermsForm from './useTermsForm';

const TermsForm = props => {
  const {
    terms,
    onCancel,
    loading,
    setLoading,
  } = props;

  const {
    validForm,
    handleSubmit,
    openConfirm,
    setOpenConfirm,
    isLoading,
    termsData,
    file,
    setFile,
    onChangeValue,
  } = useTermsForm(props);

  const termsSheet = terms?.termsSheet ? decodeURIComponent(terms.termsSheet) : '';
  const confirmTitle = terms ? '' : '¿Deseas guardar los nuevos términos y condiciones?';
  const title = terms ? 'Editar términos y condiciones' : 'Nuevos términos y condiciones';

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  return (
    <div className={styles.container}>
      <ConfirmDialog
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={handleSubmit}
        text={confirmTitle}
      />

      <div className={styles.form}>
        <h1>{title}</h1>

        <section>
        <div>
          <h2>Imagen brochure</h2>
            <ImageUploader
              thumbnail={termsData?.brochure}
              file={file}
              setFile={setFile}
              onDelete={() => onChangeValue('', 'brochure')}
            />
          </div>

          <div className={styles.form__control__text_editor}>
            <h2>Términos y condiciones</h2>
            <TextEditor
              initialValue={termsSheet}
              onChange={(newTerms) => onChangeValue(newTerms, 'termsSheet')}
            />
          </div>
        </section>
      </div>
      <div className={styles.footer}>
        <button
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancelar
        </button>

        <button
          className={styles.save}
          disabled={isLoading || !validForm}
          onClick={() => setOpenConfirm(true)}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

TermsForm.propTypes = propTypes;
TermsForm.defaultProps = defaultProps;

export default TermsForm;
