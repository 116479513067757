import React from 'react';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const InputCustomerGoal = props => {
  const { hide, value, setValue, min, max } = props;
  if (hide) return null;

  const handleGoalInput = ({ target }) => {
    const { value } = target || {};
    const intValue = Number.parseInt(value);
    if (Number.isNaN(intValue)) {
      setValue('');
    } else {
      setValue(intValue);
    }
  };

  return (
    <div className={styles.container}>
      <input value={value} onChange={handleGoalInput} type="number" autoFocus={true} min={min} max={max} />
      <label className={styles.error}>{`Elige una meta entre ${min} y ${max}`}</label>
    </div>
  );
};

InputCustomerGoal.propTypes = propTypes;
InputCustomerGoal.defaultProps = defaultProps;

export default InputCustomerGoal;
