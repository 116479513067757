import React, { Component } from "react";
import fetchAPI from "./../../utils/fetchAPI";
import { getVarConfig } from "./../../utils/environment";
import { MainContext } from './../../MainContext';

import "./Login.scss";

import MazTuInfoBeesButton from "./../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesInput from "./../../components/maz-tu-info-bees/maz-tu-info-bees-input/MazTuInfoBeesInput";

import TagManager from 'react-gtm-module';
import { escapeAccents } from "../../utils/utils";

export default class Login extends Component {

  static contextType = MainContext;

  state = {
    sessionID: "",
    errorInput: false,
    errorInputPhone: false,
    errorInputHelper: "",
    errorInputPhoneHelper: "",
    user: {},
    errorLogin: false,
  };

  constructor(props) {
    super(props);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  handleLoginAccess = () => {
    const userID = document.getElementById("txt-user").value;
    const userPhone = document.getElementById("txt-phone").value;

    if (userID !== "") {
      if (userPhone !== "") {
        if (userPhone.length === 10) {
          this.props.setLoaderOpen(true);
          this.getUserInfo({ userID, userPhone });
        } else {
          this.setState({
            errorInputPhone: true,
            errorInputPhoneHelper: "El teléfono debe tener 10 dígitos"
          });
        }
      } else {
        this.setState({
          errorInputPhone: true,
          errorInputPhoneHelper: "Escriba un teléfono"
        });
      }
    } else {
      this.setState({
        errorInput: true,
        errorInputHelper: "Escriba un código de cliente"
      });
    }

  };

  getUserInfo = ({ userID, userPhone }) => {
    fetchAPI({
      service: getVarConfig({ key: "REACT_APP_SERVICES_GET_CUSTOMER" }),
      protocol: "POST",
      params: {
        customerId: userID,
        phone: userPhone
      },
      callbackThen: this.handleLoginSuccess,
      callbackError: this.handleLoginError,
    });
  };

  handleLoginSuccess = (response) => {
    const user = response.data;
    if (typeof response.status !== "undefined") {
      if (response.status === "success") {
        const isTradeUser = user.permissions.some(e => e.name === 'trade_customer' && e.active);
        const isCandidateUser = user.permissions.some(e => e.name === 'trade_candidate' && e.active);
        if (isTradeUser || isCandidateUser) {

          this.props.setLoaderOpen(false);
          const userBees = {
            name: user.name,
            ID: user.customerId,
            drv: escapeAccents(user.drv),
            rfc: user.rfc,
            uen: user.uen,
            phone: user.phone,
            permissions: user.permissions,
            matriz: user.CustomerInfo ? user.CustomerInfo.payer_no : null,
            sucursal: user.CustomerInfo ? user.CustomerInfo.city : null,
            level: user.tradeData ? user.tradeData.level : null,
            clubLevel: user.tradeData ? user.tradeData.clubLevel : null,
            resultsSeen: user.tradeData ? user.tradeData.resultsSeen : null,
            isTradeUser,
            isCandidateUser,
            isBmax: user.isBmax,
            initiative: user.tradeData ? user.tradeData.initiative : null,
          }

          if (
            userBees.isBmax == 'CANDIDATE'
          ) {
            localStorage.setItem('bmaxLogin', 'logged');
          } else {
            localStorage.removeItem('bmaxLogin');
          };

          this.props.setUser(JSON.stringify(userBees));
          TagManager.dataLayer({
            dataLayer: {
              event: 'GAEvent',
              event_category: 'LogIn',
              event_action: 'Success',
              event_label: 'LoginAPI',
              userid: user.customerId,
              POC_name: user.name
            },
            dataLayerName: 'PageDataLayer'
          });
        } else {
          this.props.setLoaderOpen(false);
          this.props.handleToast({
            open: true,
            type: "error",
            message: "No tiene permisos para acceder a la aplicación"
          });
          console.log("No tiene permisos para acceder a la aplicación")
        }

        try {
          if (isCandidateUser) {
            this.props.setPath("/programa-mercado");
          } else if (isTradeUser) {
            this.props.setPath("/dashboard");
          }

        } catch (e) {
          console.log(e);
        }
      }
    }
  };
  handleLoginError = (status) => {
    if (status === 404) {
      this.setState({
        errorLogin: true,
        errorInputPhone: true,
        errorInput: true,
        errorInputHelper: "",
        errorInputPhoneHelper: "Lo sentimos. Has introducido un número Grupo Modelo o teléfono incorrecto."
      });
    }
    if (status === 401 || status === 500) {
      this.props.handleToast({
        open: true,
        type: "error",
        message: "Hubo un error al cargar la información, intente nuevamene"
      });
    }
    this.props.setLoaderOpen(false);
  }

  render() {
    return (
      <div className="login">
        <div className="login__logo"></div>

        <div className="login__form">
          <MazTuInfoBeesInput
            textLabel={"Ingrese número Grupo Modelo"}
            textPlaceHolder={"Coloca acá"}
            id="txt-user"
            error={this.state.errorInput}
            textHelperLeft={this.state.errorInputHelper}
            type="number"
          />
          <MazTuInfoBeesInput
            textLabel={"Teléfono registrado en BEES"}
            textPlaceHolder={"Coloca acá"}
            id="txt-phone"
            error={this.state.errorInputPhone}
            textHelperLeft={this.state.errorInputPhoneHelper}
            type="number"
            length={10}
            hidePhone={true}
          />
          <div className="login__button">
            <MazTuInfoBeesButton
              handleClick={this.handleLoginAccess}
              textButton={"INGRESAR"}
            />
          </div>
        </div>
        {this.state.errorLogin ?
          <div className="registry__footer">

            <button className="registry__footer__btn__help">¿Tuviste algún problema para ingresar?</button>
            <button
              className="registry__footer__btn__payment"
              onClick={() => this.props.setPath('/registro')}>DÉJANOS TUS DATOS</button>
          </div>
          : null}
      </div>
    );
  }
}
