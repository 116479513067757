import React, { Component } from "react";
import MazTuInfoBeesCardUpload from "../../../components/maz-tu-info-bees/maz-tu-info-bees-card-upload/MazTuInfoBeesCardUpload";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";

import "./DocumentUpload.scss";
import { getUser } from "../../../utils/sessionInfo";
import fetchAPI from "../../../utils/fetch";
import { getVarConfig } from "../../../utils/environment";
import { once } from "../../../utils/events";

export default class DocumentUpload extends Component {

  state = {
    status: "pending",
    statusText: "",
    uploadFields: [],
    successFieldsCounter: 0,
    enableContinue: false,
    personaType: "",
    isLoading: false,
    ine:{
      front: null,
      back: null,
    },
    passport:{
      front: null,
      back: null,
    },
    driving_license:{
      front: null,
      back: null,
    },
    rfcFile: null,
    constitutiveFile: null,
    validation: true,
    intervalID: null,
    buttonText: "ENVIAR DOCUMENTOS"
  }

  componentDidMount() {
    try{
      const handleProcess = this.props.handleProcess;
      once("click-back-navigation-programa-mercado", function(event) {
        handleProcess({
          protocol: "PATCH",
          params: {
            transition: "returnToPersonalInfo"
          }
        });
      });
    } catch(e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  setStatus = (status) => {
    this.setState({
      status
    });
  }

  validateStatus = () => {
    this.setState({
      validation: false
    });
    this.props.setLoaderOpen(true);
    const uploadId = this.props?.dataProcess?.documents?.[0]?.id;
    const candidateId = this.props?.dataProcess?.responseData?.process?.candidateId;

    const service = getVarConfig({ key: "REACT_APP_SERVICES_GET_METAMAP_STATUS" })
      .replace('{candidateId}', candidateId).replace('{uploadId}', uploadId);

    if((candidateId && uploadId) && uploadId !== "0") {

      const intervalID = window.setInterval(() => {
        if(this.state.status === "success" || this.state.status === "error"){
          clearInterval(this.state.intervalID);
          if(this.state.status === "success") {
            this.setState({
              statusText: ""
            });
          }
        } else {
          this.setState({
            intervalID,
            statusText: "Estamos validando tu documento. Espera un momento por favor."
          });
          fetchAPI({
            noContentType: true,
            service,
            protocol: "GET",
            callbackThen: (response) => {
              this.props.setLoaderOpen(false);
              const responseMessage = response.data?.response?.message || response.data?.message || 'Error inesperado';
              try {
                switch(response.data.response.status) {
                  case "deleted":
                    this.setState({
                      status: "pending",
                      statusText: ""
                    });
                    break;
                  case "rejected":
                    this.setState({
                      status: "error",
                      statusText: `${responseMessage}. Inténtalo de nuevo.`,
                    });
                    break;
                  case "reviewNeeded":
                    this.setState({
                      status: "error",
                      statusText: `${responseMessage}. Inténtalo de nuevo.`,
                    });
                    break;
                  case "verified":
                    this.setState({
                      status: "success",
                      statusText: "",
                      buttonText: "CONTINUAR"
                    });
                    break;
                  case "local_verified":
                    this.setState({
                      status: "success",
                      statusText: "",
                      buttonText: "CONTINUAR"
                    });
                    break;
                  case "metamap_rejected":
                    this.setState({
                      status: "error",
                      statusText: `${responseMessage}. Inténtalo de nuevo.`,
                    });
                    break;
                  case "pending":
                    this.setState({
                      status: "validating",
                      statusText: "Estamos validando tu documento. Espera un momento por favor.",
                      buttonText: "VALIDANDO DOCUMENTO"
                    });
                    break;
                  case "document_pending":
                    this.setState({
                      status: "pending",
                      statusText: ""
                    });
                    break;
                  default:
                    this.setState({
                      status: "pending",
                      statusText: ""
                    });
                }
              } catch(e) {

              }
            },
            callbackError: err => {
              this.props.setLoaderOpen(false);
              if(err === 404 || err === 400 || err === 500) {
                this.setState({
                  status: "pending",
                  statusText: ""
                });
              }
              clearInterval(this.state.intervalID);
              console.log('err', err);
            },
          });
        }
      }, 5000);

    } else {
      this.setState({
        status: "pending",
        statusText: ""
      });
    }
  }

  setSuccessCounter = (value) => {
    const successFields = this.state.successFieldsCounter + value;
    this.setState({
      successFieldsCounter: successFields
    });
  }

  enableContinue = (files) => {
    const cantCampos = this.state.uploadFields.length;
    const cantFiles = files.length;
    this.setState({
      enableContinue: !(cantFiles === cantCampos)
    });
  }

  handleButtonContinue = () => {

    const satType = typeof this.props.dataProcess !== "undefined" ?
                      typeof this.props.dataProcess.satType !== "undefined" ?
                        this.props.dataProcess.satType
                        : "Fisica"
                      : "Fisica";

    if(this.state.status !== "success") {
      if (satType === 'Moral') {
        this.postAzureStorageFiles();
      } else {
        this.postIneFiles({});
      }
    } else {
      this.continueProcess({});
    }
  }

  setIsLoading = isLoading => this.setState({ isLoading });

  postAzureStorageFiles = () => {
    const { state, props, setIsLoading } = this;
    const { rfcFile, constitutiveFile } = state;
    const user = getUser();
    const { ID: candidateId } = user || {};
    let processId = typeof props.dataProcess.responseData !== "undefined" ? props.dataProcess.responseData.process.processId : props.dataProcess.processId;
    const data = JSON.stringify({ candidateId, processId });
    const service = getVarConfig({ key: "REACT_APP_SERVICES_POST_AZURESTORAGE_FILE" })
      .replace('{documentType}', 'actaconstitutiva')
      .replace('{candidateId}', candidateId);

    setIsLoading(true);

    let documents;

    const customBody = new FormData();
    customBody.append('data', data);
    customBody.append('file', constitutiveFile);

    this.props.setLoaderOpen(true);

    fetchAPI({
      customBody,
      noContentType: true,
      service,
      protocol: "POST",
      callbackThen: (response) => {
        const service = getVarConfig({ key: "REACT_APP_SERVICES_POST_AZURESTORAGE_FILE" })
        .replace('{documentType}', 'rfc')
        .replace('{candidateId}', candidateId);

        const customBodyRfc = new FormData();
        customBodyRfc.append('data', data);
        customBodyRfc.append('file', rfcFile);

        documents = [
          {
            type: 'actaconstitutiva',
            host: "azure",
            id: response.data.blockBlobName
          }
        ];

        fetchAPI({
          customBody: customBodyRfc,
          noContentType: true,
          service,
          protocol: "POST",
          callbackThen: (response) => {
            setIsLoading(false);
            //this.handleContinue({});
            documents.push({
              type: 'rfc',
              host: "azure",
              id: response.data.blockBlobName
            });
            this.postIneFiles({ documents });
          },
          callbackCatch: err => {
            setIsLoading(false);
            console.log('err', err);
          },
        });
      },
      callbackCatch: err => {
        setIsLoading(false);
        console.log('err', err);
      },
    });
  };

  postIneFiles = ({ documents = null }) => {
    const { state, props, handleContinue } = this;
    let front, back;
    const user = getUser();
    const { ID: candidateId } = user || {};
    let processId = typeof props.dataProcess.responseData !== "undefined" ? props.dataProcess.responseData.process.processId : props.dataProcess.processId;
    const data = JSON.stringify({ candidateId, processId });

    const idDocument = typeof props.dataProcess.responseData !== "undefined" ? props.dataProcess.responseData.process.contractData.personalInfo.id : props.dataProcess.contractData.personalInfo.id;

    const service = getVarConfig({ key: "REACT_APP_SERVICES_POST_METAMAP_VERIFICATION" }).replace('{documentType}', idDocument);

    switch(idDocument) {
      case 'ine':
        front = state.ine.front;
        back = state.ine.back;
        break;
      case 'driving_license':
        front = state.driving_license.front;
        back = state.driving_license.back;
        break;
      case 'passport':
        front = state.passport.front;
        break;
      default:
        console.log('default');
    }

    props.setLoaderOpen(true);

    const customBody = new FormData();
    customBody.append('data', data);
    customBody.append('front', front);
    if(typeof back !== "undefined")
      customBody.append('back', back);

    fetchAPI({
      customBody,
      noContentType: true,
      service,
      protocol: "POST",
      callbackThen: (response) => {

        props.setLoaderOpen(false);

        let uploadId = "0";

        try {
          uploadId = response.data.metamapResponse.uploadId;
        } catch(e) {
          console.log(e);
        }

        handleContinue({ uploadId, documents });
      },
      callbackError: (err, errorBody) => {
        props.setLoaderOpen(false);

        const alertProps = {
          toggle: true,
          type: "error",
          title: "Carga de documentos",
          message: "Hubo un error en el formato de sus documentos, intente cargarlos con una mejor resolución y que no contengan brillos ni se encuentren borrosos.",
          alertButton: "Cerrar",
        };

        if (err === 400 && "function" === typeof errorBody?.then) {
          errorBody.then(text => {
            const message = text || alertProps.message;
            this.props.handleAlert({ ...alertProps, message });
          })
        } else {
          this.props.handleAlert(alertProps);
        }
      },
    });
  }

  fileLoaded = (field, file, isEdit = false) => {
    this.setState({
      status: "pending",
      statusText: "Estamos validando tu documento. Espera un momento por favor.",
      buttonText: "ENVIAR DOCUMENTOS"
    });

    if (Array.isArray(file) && file[0]) {
      if(!isEdit)
        this.setSuccessCounter(1);
      const { ine, driving_license, passport } = this.state;
      switch(field.field) {
        case "ine.front":
          this.setState({
            ine: {
              ...ine,
              front: file[0],
            }
          });
          break;
        case "ine.back":
          this.setState({
            ine: {
              ...ine,
              back: file[0],
            }
          });
          break;
        case "driving_license.front":
          this.setState({
            driving_license: {
              ...driving_license,
              front: file[0],
            }
          });
          break;
        case "driving_license.back":
          this.setState({
            driving_license: {
              ...driving_license,
              back: file[0],
            }
          });
          break;
        case "passport.front":
          this.setState({
            passport: {
              ...passport,
              front: file[0],
            }
          });
          break;
        default:
          console.log("default");
      }
      if (field.field === 'constitutive') {
        const constitutiveFile = file[0];
        this.setState({ constitutiveFile });
      }
      if (field.field === 'rfc') {
        const rfcFile = file[0];
        this.setState({ rfcFile });
      }
    } else {
      this.setSuccessCounter(-1);
    }
  }

  handleContinue = ({ uploadId, documents = null }) => {

    this.setState({
      buttonText: "ENVIANDO DOCUMENTOS"
    });

    try {

      switch(this.state.status) {
        case "pending":
          this.continueProcess({
            uploadId,
            documents,
            callbackSuccess: () => {
              this.props.handleAlert({
                toggle: true,
                title: "Carga de documentos",
                message: "Sus documentos han sido cargados exitósamente y se encuentran en validación.",
                alertButton: "Cerrar",
                callback: () => {
                  this.props.setCallbackAlertClose(() => {
                    this.setState({
                      successFieldsCounter: 0,
                      status: "validating",
                      buttonText: "VALIDANDO DOCUMENTOS"
                    });
                    this.validateStatus();
                  });
                }
              });
            }
          });
          break;
        case "validating":
          this.props.handleAlert({
            toggle: true,
            title: "Carga de documentos",
            message: "Sus documentos continúan en validación.",
            alertButton: "Cerrar",
          });
          this.setState({
            buttonText: "VALIDANDO DOCUMENTOS"
          });
          break;
        case "success":
          this.continueProcess({});
          this.setState({
            buttonText: "CONTINUAR"
          });
          break;
        default:
          console.log('default');
      }
    } catch(e) {
      console.log(e);
    }
  }

  continueProcess = ({ uploadId = "", callbackSuccess = null, documents = null }) => {
    const { props } = this;
    let isDocumentUpload = uploadId !== "";
    const idDocument = typeof props.dataProcess.responseData !== "undefined" ? props.dataProcess.responseData.process.contractData.personalInfo.id : props.dataProcess.contractData.personalInfo.id;
    let documentsParams = [{
      type: idDocument,
      host: "metamap",
      id: uploadId
    }];
    const concatDocuments = documents !== null ? documentsParams.concat(documents) : documentsParams;

    let params = isDocumentUpload ? {
      transition: "loadDocuments",
      stepData: {
        documents: concatDocuments
      }
    } : {
      transition: "loadDocuments"
    };
    this.props.handleProcess({
      protocol: "PATCH",
      isDocumentUpload: isDocumentUpload,
      callbackSuccess,
      params
    });
  }

  render() {

    const { props } = this;

    let enableContinue, documentType;
    let uploadFields = [];

    try {
      documentType = typeof props.dataProcess.responseData !== "undefined" ? props.dataProcess.responseData.process.contractData.personalInfo.id : props.dataProcess.contractData.personalInfo.id;
    } catch(e) {
      documentType = "";
    }

    if(this.props.dataProcess !== null) {
      switch(documentType) {
        case "ine":
          uploadFields = [
            {
              label: "INE/IFE (delantera)",
              field: 'ine.front',
            },
            {
              label: "INE/IFE (trasera)",
              field: 'ine.back',
            }
          ];
          break;
        case "passport":
          uploadFields = [
            {
              label: "Pasaporte",
              field: 'passport.front',
            },
          ];
          break;
        case "driving_license":
          uploadFields = [
            {
              label: "Licencia de conducir (delantera)",
              field: 'driving_license.front',
            },
            {
              label: "Licencia de conducir (trasera)",
              field: 'driving_license.back',
            },
          ];
          break;
        default:
          uploadFields = [];
      }

      if(this.props.dataProcess.satType === "Moral") {
        uploadFields.push(
          {
            label: "Acta constitutiva",
            field: 'constitutive',
          }
        );
        uploadFields.push(
          {
            label: "RFC empresa",
            field: 'rfc',
          }
        );
      }

    }

    if(this.state.validation && this.props.dataProcess !== null) {
      this.validateStatus();
    }

    enableContinue = (this.state.status === "pending" && uploadFields.length === this.state.successFieldsCounter)
      ? true
      : (this.state.status === "pending" || this.state.status === "validating" || this.state.status === "error" || this.state.status === "")
        ? false
        : true;

    return(
      <div className="document-upload">

        <div className="document-upload__title">
          Carga de documentos
        </div>

        {
          uploadFields.map((field, key) => (
            <MazTuInfoBeesCardUpload
              setSuccess={(file, isEdit) => this.fileLoaded(field, file, isEdit)}
              label={field.label}
              field={field}
              status={this.state.status} />
          ))
        }

        {
          this.state.statusText !== "" ?
            <div className={"document-upload__message document-upload__message--" + this.state.status}>
              {this.state.statusText}
            </div> : null
        }

        <div className="document-upload__next">
          <MazTuInfoBeesButton
            textButton={this.state.buttonText}
            handleClick={this.handleButtonContinue}
            disabled={ !enableContinue } />
        </div>

      </div>
    )
  }

}