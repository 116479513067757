import React, { Component } from "react";

import "./Registro.scss";

import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesButton from "./../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";

export default class RegistroExito extends Component {

  handleClick = () => {
    this.props.setPath("/");
  }

  render() {
    return (
      <div className="registro registro--exito">
        <MazTuInfoBeesTitle title="¡Gracias!" />
        <div className="registro__success-card">
        Hemos recibido tu información de manera exitosa te sugerimos que contactes que tu ejecutivo de ventas o supervisor.
        </div>
        <div className="registro__button">
          <MazTuInfoBeesButton
            text={"REGRESAR A BEES"}
            handleClick={this.handleClick}
          />
        </div>
      </div>
    );
  }
}
