import React, { Component } from "react";
import TagManager from 'react-gtm-module';
import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesLoader from "../../components/maz-tu-info-bees/maz-tu-info-bees-loader/MazTuInfoBeesLoader";
import MazTuInfoBeesCardChallenge from "../../components/maz-tu-info-bees/maz-tu-info-bees-card-challenge/MazTuInfoBeesCardChallenge";
import { getUser } from "../../utils/sessionInfo";
import { getVarConfig } from "../../utils/environment";
import fetchAPI from "../../utils/fetchAPI";

import "./Cobertura.scss";

const imgDesafiosVolumen = require('./assets/default-image.jpeg');

export default class Cobertura extends Component {

  state = {
    challenges: [],
    loader: false
  }

  componentDidMount() {
    this.getChallenges();
  }

  getMonth = (unformattedDate) => {
    let dateHourArr = unformattedDate.split("T");
    let dateArr = dateHourArr[0].split("-");
    return parseInt(dateArr[1]);
  }

  getChallenges = () => {
    const user = getUser();
    this.setState({
      loader: true
    });
    fetchAPI({
      service: "v1/mdcolombia/challenges/customers/" + user.ID + "?type=marketpl",
      protocol: "GET",
      callbackThen: (response) => {
        if(typeof response.data !== "undefined") {
          const dataChallenges = response.data;
          if(dataChallenges.length > 0) {
            /*
            let dataChallengesFiltered = [];
            for(var i in dataChallenges) {
              if(dataChallenges[i].material !== null){
                const date = new Date();
                const presentMonth = date.getMonth() + 1;
                const dataMonth = this.getMonth(dataChallenges[i].material.createdAt);
                if(presentMonth === dataMonth) {
                  dataChallengesFiltered.push(dataChallenges[i]);
                }
              }
            }
            */
            this.setState({
              challenges: dataChallenges,
              loader: false
            });
          } else {
            this.setState({
              challenges: response.data,
              loader: false
            });
          }
        } else {
          this.setState({
            loader: false
          });
        }
      },
      callbackError: (status) => {
        console.log(status);
        this.setState({
          loader: false
        });
      },
    });
  }

  handleClickButton = (e) => {
    const user = getUser();
    TagManager.dataLayer({
      dataLayer: {
        event: 'GAEvent',
        event_category: 'Buy in Bees',
        event_action: 'Interaction',
        event_label: e.target.dataset.promo,
        userid: user.ID,
        POC_name: user.name
      },
      dataLayerName: 'PageDataLayer'
    });
    window.open("https://mybees.mx/categories?categoryId=5ffe520e26b93207e85389bd", "_blank");
  }

  render() {

    const nameCoberturaCalendario = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA_CALENDARIO" });

    return (
      <div className="cobertura">

        <MazTuInfoBeesLoader open={this.state.loader} />

        <MazTuInfoBeesTitle
          title={nameCoberturaCalendario} />

        <div className="cobertura__lista">

          {
            this.state.challenges.length > 0 ?
              this.state.challenges.map((card, index) => (
                <>
                  {/*
                    card.material !== null ?
                      <div className="cobertura__lista__card">
                        <div className={card.completed ? `cobertura__lista__card__head cobertura__lista__card__head--complete` : `cobertura__lista__card__head cobertura__lista__card__head--incomplete`}>
                          {
                            card.completed ? 'Completado' : 'Incompleto'
                          }
                        </div>
                        <div className="cobertura__lista__card__container">
                          <div className="cobertura__lista__card__container__image">
                            <img
                              crossorigin="anonymous"
                              src={card.material.image !== null ? card.material.image : imgDesafiosVolumen}
                              alt="desafios-voumen" />
                          </div>
                          <div className="cobertura__lista__card__container__title">
                            {card.material.name}
                          </div>
                          {
                            card.completed ?
                              null
                              : <button
                                  className="cobertura__lista__card__container__button"
                                  data-promo={card.material.name}
                                  onClick={this.handleClickButton}>
                                  COMPRAR EN BEES
                                </button>
                          }

                        </div>
                      </div> : null
                  */}
                  <MazTuInfoBeesCardChallenge
                    description={card.challenge.name}
                    goalReached={card.goalReached}
                    real={card.real}
                    goal={card.goal}
                    image={card.challenge.image}
                    points={card.goalPoints}
                    deadline={card.dueDate}
                    accepted={card.challengeAccepted === 1}
                    desafioID={card.challengeId}
                    desafioType={card.challenge.type}
                    desafio={this.props.typeDesafio}
                    user={getUser()}
                  />
                </>
              )) : null
          }

        </div>

      </div>
    );
  }
}
