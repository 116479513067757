import React, { Component, useState } from "react";
import { cleanUser, getUser } from "../../../utils/sessionInfo";
import { isMobile } from 'react-device-detect';

import "./mazTuInfoBeesNavBar.scss";
import MazTuInfoBeesNavBarLink from "./mazTuInfoBeesNavBarLink";

import '../../../styles/navbar.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { cleanCookiesAndReload } from "../../../utils/cookiesUtils";



export default class MazTuInfoBeesNavBar extends Component {

  state = {
    activeHome: '/home' === this.props.path,
    activeVirtualAccount: '/cuenta-virtual' === this.props.path,
    activeAccountState: '/estado-cuenta' === this.props.path,
    activeDash: '/dashboard' === this.props.path,
    activeHelp: '/ayuda' === this.props.path,
    expanded: false
  }

  toggleNav = () => {
    this.setState({ expanded: (this.state.expanded ? false : true) });
  }

  handleClickHome = (e) => {

    this.toggleNav();

    console.log(`hola mundito ${this.state.expanded}`);
    if (this.props.path !== "/dashboard" || this.props.path !== "/registro") {
      const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
      const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
      this.setState({ activeHome: true, activeVirtualAccount: false, activeAccountState: false, activeDash: false, activeHelp: false })
      setLoaderOpen(true);
      setTimeout(function () {
        setPath("/home");
        setLoaderOpen(false);
      }, 1000);
    }
  }

  handleClickTrade = (e) => {
    this.toggleNav();
    if (this.props.path !== "/home" || this.props.path !== "/registro") {
      const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
      const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
      this.setState({ activeHome: false, activeVirtualAccount: false, activeAccountState: false, activeDash: true, activeHelp: false })
      setLoaderOpen(true);
      setTimeout(function () {
        setPath("/dashboard");
        setLoaderOpen(false);
      }, 1000);
    }
  }

  handleClickAccountState = (e) => {
    this.toggleNav();
    if (this.props.path !== "/home" || this.props.path !== "/registro") {
      const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
      const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
      this.setState({ activeHome: false, activeVirtualAccount: false, activeAccountState: true, activeDash: false, activeHelp: false })
      setLoaderOpen(true);
      setTimeout(function () {
        setPath("/estado-cuenta");
        setLoaderOpen(false);
      }, 1000);
    }
  }

  handleClickVirtualAccount = () => {
    this.toggleNav();
    if (this.props.path !== "/home" || this.props.path !== "/registro") {
      const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
      const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
      this.setState({ activeHome: false, activeVirtualAccount: true, activeAccountState: false, activeDash: false, activeHelp: false })
      setLoaderOpen(true);
      setTimeout(function () {
        setPath("/cuenta-virtual");
        setLoaderOpen(false);
      }, 1000);
    }
  }

  handleClickSupport = () => {
    this.toggleNav();
    if (this.props.path !== "/home" || this.props.path !== "/registro") {
      const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
      const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
      this.setState({ activeHome: false, activeVirtualAccount: false, activeAccountState: false, activeDash: false, activeHelp: true })
      setLoaderOpen(true);
      setTimeout(function () {
        setPath("/ayuda");
        setLoaderOpen(false);
      }, 1000);
    }
  }

  handleClickLogout = (e) => {
    this.toggleNav();
    const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
    const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
    setLoaderOpen(true);
    setPath("/cerrar");
    setLoaderOpen(false);
    cleanCookiesAndReload();
  }

  handleClickFomentos = (e) => {
    this.toggleNav();
    const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
    const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
    this.setState({ activeHome: false, activeVirtualAccount: false, activeAccountState: false, activeDash: false, activeHelp: false })
    this.props.sendGA('GAEvent', 'Content', 'Content', 'Fomentos', true, 'fomentos_button', 'Fomentos');
    setLoaderOpen(true);
    setTimeout(function () {
      setPath("/fomentos");
      setLoaderOpen(false);
    }, 1000);
  }

  submit = () => {
    var confirmation = window.confirm("¿Estás seguro que deseas salir?");

    if (confirmation)
      this.handleClickLogout()

  }

  handleHomeClick = (e) => {
    const { permissions = [] } = getUser(); // if permission 3 (trade_candidate) disabled home button
    const noDuplicatedPermissions = [...new Map(permissions.map(item => [item['name'], item])).values()];
    if (noDuplicatedPermissions.length === 1 && noDuplicatedPermissions[0].name === 'trade_candidate') return;

    this.handleClickTrade(e);
  };

  render() {

    if (this.props.headerMenu)
      return (
        <div className="col-12">
          <Navbar bg="light" expand="lg" expanded={this.state.expanded}>
            <div className="container px-0">
              <Navbar.Toggle onClick={this.toggleNav} aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mx-auto">

                  <Nav.Link className={isMobile ? "bhome" : null} onClick={this.handleHomeClick}>Inicio</Nav.Link>

                  {
                    this.props.user.isTradeUser ?
                      (<Nav.Link className={isMobile ? "bmetas" : null} onClick={this.handleClickTrade}>Metas y desafíos Bees</Nav.Link>) : null
                  }

                  <Nav.Link className={isMobile ? "bestadocuenta" : null} onClick={this.handleClickAccountState}>Estado de cuenta</Nav.Link>
                  <Nav.Link className={isMobile ? "bformaspago" : null} onClick={this.handleClickVirtualAccount}>Formas de pago</Nav.Link>
                  {
                    this.props.user.promotions ? (
                      <Nav.Link className={isMobile ? "bfomentos" : null} onClick={this.handleClickFomentos}>Fomentos</Nav.Link>
                    ) : null
                  }
                  <Nav.Link className={isMobile ? "bayuda" : null} onClick={this.handleClickSupport}>¿Necesitas ayuda?</Nav.Link>
                  {this.props.user ? (
                    <Nav.Link className={isMobile ? "blogout" : null} onClick={this.submit}>Salir</Nav.Link>
                  ) : null}
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
      );
    else

      return (
        <div className="maz-tu-info-bees-nav-bar-pad">
          {
            this.props.user.isTradeUser || isMobile ? (
              <div className="maz-tu-info-bees-nav-bar">
                <MazTuInfoBeesNavBarLink
                  section="home"
                  evtClick={this.handleHomeClick}
                  active={this.state.activeHome}
                />

                {this.props.user.isTradeUser ? (
                  <MazTuInfoBeesNavBarLink
                    section="trade"
                    active={this.state.activeDash}
                    evtClick={this.handleClickTrade} />) : null
                }

                <MazTuInfoBeesNavBarLink
                  section="logout"
                  evtClick={this.submit} />

              </div>
            ) : ("")}
        </div>

      );
  }
}

