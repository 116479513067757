import React, { Component } from "react";

import "./MazTuInfoBeesLoader.scss";

export default class MazTuInfoBeesLoader extends Component {
  render() {
    let open = typeof this.props.open !== "undefined" ? this.props.open : false;

    const styleOpen = open ? {} : { display: "none" };

    return <div className="maz-tu-info-bees-loader" style={styleOpen}></div>;
  }
}
