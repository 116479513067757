import PropTypes from 'prop-types';

const termsSheet = PropTypes.shape({
  id: PropTypes.string,
  brochure: PropTypes.string,
  termsSheet: PropTypes.string,
  createdAt: PropTypes.string,
})

export const propTypes = {
  terms: termsSheet,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export const defaultProps = {
  terms: undefined,
  onCancel: null,
  onSubmit: null,
  loading: false,
  setLoading: () => {},
};
