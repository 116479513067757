import React, { useState } from 'react';
import Icon from '../../components/global/Icon/Icon';

import fetchAPI from "../../utils/fetchAPI";

import MazTuInfoBeesAccountCard from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card';
import ActionRow from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/ActionRow';
import ColorRow from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/ColorRow';
import DepositDialog from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/DepositDialog';
import InfoRow from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/InfoRow';
import TransferDialog from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-card/TransferDialog';
import AccountDetails from '../../components/maz-tu-info-bees/maz-tu-info-bees-account-details';
import MazTuInfoBeesAccountCredit from '../../components/maz-tu-info-bees/maz-tu-info-bees-resume-credit';
import MazTuInfoBeesFooter from "./../../components/maz-tu-info-bees/maz-tu-info-bees-footer/MazTuInfoBeesFooter";
import OrderAndBill from './OrderAndBill';

import { isMobile } from 'react-device-detect';

import styles from './styles.module.scss';


const AccountStateFinance = (props) => {

  const { user, setPath, setBackButton, setLoaderOpen, sendGA, needBackButton, backButtonAction } = props;
  const [currentOrder, setCurrentOrder] = useState(undefined)

  const data = user.openItems ? user.openItems : [];

  // console.log(user);
  const handleOnClikRow = order => {
    console.log(order);
    props.sendGA('GAEvent', 'Content', 'Card', 'Order info', true, 'order_info_card', 'Informacion de orden');
    if (order) {
      getOrderDetail(order.doc_no)
    }

  }

  const clearCurrentOrder = () => {
    setCurrentOrder(null);
  }


  const getOrderDetail = (doc_no) => {
    let urlService = 'v1/MX/finance/{doc_no}/orders_detail_by_doc_fi'; //getVarConfig({ key: "REACT_APP_SERVICES_GET_ORDER_DETAIL_BY_DOC_NO" });
    //let urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_ORDER_DETAIL_BY_DOC_NO" });
    urlService = urlService.replace("{doc_no}", doc_no);
    setLoaderOpen(true);
    fetchAPI({
      service: urlService,
      protocol: "GET",
      callbackThen: callbackThenOrderDetail,
      callbackError: callbackErrorOrderDetail,
    });
  }

  const callbackThenOrderDetail = (response) => {
    const data_rec = response.data
    const order_detail = data_rec[0];
    setCurrentOrder(order_detail);
    setBackButton(true, clearCurrentOrder);
    setLoaderOpen(false);
  }

  const goToCuentaEstadoCuenta = () => {
    setPath('/estado-cuenta');
    setBackButton(false, goToCuentaEstadoCuenta);
  }

  const callbackErrorOrderDetail = (status) => {
    let messageError, type;
    switch (status) {
      case 404:
        messageError = "No se encuentra información sobre detalle de orden";
        type = "error";
        break;
      case 505:
        messageError = "Hubo un error al obtener la información sobre detalle de orden";
        type = "error";
        break;
      default:
        messageError = "Hubo un error al obtener la información sobre detalle de orden";
        type = "error";
    }
    this.props.handleToast({
      open: true,
      type: type,
      message: messageError
    });
    this.setState({
      loaderVirtualAccountOpen: false
    });
    setLoaderOpen(false);
  }  

  return (
    <div className="container">

      {currentOrder ? (<h2>Detalle</h2>): (<h2>Mi estado de cuenta</h2>) }
      <div className="row">
        {!isMobile || !currentOrder ?
          (
            <MazTuInfoBeesAccountCredit
              sendGA={props.sendGA}
              className={styles.card + " card"}
              setPath={setPath}
              setBackButton={setBackButton}
              customerAccountState={user.accountState ? user.accountState : null}
              customerPaymentCondition={(user.virtualAccount && user.virtualAccount.accountState) ? user.virtualAccount.accountState.paymentCondition.description : null}
            />
          ) : null
        }

        {
          currentOrder ?
            (<OrderAndBill sendGA={props.sendGA} data={currentOrder} user={user} setCurrentOrder={setCurrentOrder} needBackButton={needBackButton} backButtonAction={backButtonAction} />) :
            (needBackButton ? setBackButton(false, goToCuentaEstadoCuenta) : null)
        }

      {/* <div className={styles.card_payments}>
        <button>
          <Icon
            icon="credit-card-purple"
            width="18"
            height="18"
          />
        </button>
        <h2>Desgloza tus pagos</h2>
      </div> */}

      {!currentOrder ? (
        data.length > 0 ?
        <AccountDetails
            sendGA={props.sendGA}
            data={data}
            onClickRow={handleOnClikRow}
            setBackButton={setBackButton}
            setPath={setPath}
            needBackButton={needBackButton}
        /> :
            <h2>No tienes facturas pendientes de pago</h2>
        ) : null
      }
      </div>
    </div>
  );
};

export default AccountStateFinance;
