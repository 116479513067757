import React from 'react';
import Icon from '../../../../global/Icon/Icon';
import MenuItem from '../MenuItem';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';

const SubmenuItem = props => {
  const { item, onClick } = props;
  const {
    icon,
    title,
    active,
    submenu,
  }= item;

  const className = active ? styles.active : '';
  const handleClick = subitemTitle => {
    if (onClick) onClick(item, subitemTitle);
  };

  const arrowColor = active ? 'yellow' : 'white';
  const iconName = `arrow-down-${arrowColor}`;

  return (
    <details
      className={`${styles.submenu} ${className}`}
    >
      <summary>
        <MenuItem
          title={title}
          icon={icon}
          active={active}
        />

        <Icon
          icon={iconName}
        />
      </summary>
      <p>
        <ul>
          {submenu?.map(({ title: subitemTitle, active: subitemActive }) => (
            <MenuItem
              key={subitemTitle}
              title={subitemTitle}
              onClick={() => handleClick(subitemTitle)}
              active={subitemActive}
            />
          ))}
        </ul>
      </p>
    </details>
  );
};

SubmenuItem.propTypes = propTypes;
SubmenuItem.defaultProps = defaultProps;

export default SubmenuItem;
