import React from 'react';
import Icon from '../../../global/Icon/Icon';
import { defaultProps, propTypes } from './types';
import styles from './styles.module.scss';

const BandButton = props => {
  const { color, colorIcon, title } = props;
  return (
    <button
      className={`${styles.band} ${styles[`band--${color || 'orange'}`]}`}
      >
      {title}
      <Icon icon={`arrow-upper-${colorIcon || 'white'}`} />
    </button>
  );
};

BandButton.prototype = propTypes;
BandButton.defaultProps = defaultProps;

export default BandButton;
