import PropTypes from 'prop-types';

export const propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export const defaultProps = {
  active: false,
  onClick: null,
};
