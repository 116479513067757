import React, { Component } from "react";
import fetchAPI from "./../../utils/fetchAPI";
import { getVarConfig } from "./../../utils/environment";

import "./Registro.scss";

import MazTuInfoBeesTitle from "../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesInput from "./../../components/maz-tu-info-bees/maz-tu-info-bees-input/MazTuInfoBeesInput";
import MazTuInfoBeesButton from "./../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";

export default class Registro extends Component {

  state = {
    errorInputNumeroCliente: false,
    errorInputNombre: false,
    errorInputApellidos: false,
    errorInputEmail: false,
    errorInputCelular: false,
    errorInputHelperNumeroCliente: "",
    errorInputHelperNombre: "",
    errorInputHelperApellidos: "",
    errorInputHelperEmail: "",
    errorInputHelperCelular: ""
  }

  validateForm = () => {
    let validation = true;
    const arrayInputID = [
      'txt-numero-cliente',
      'txt-nombre',
      'txt-apellidos',
      'txt-email',
      'txt-celular'
    ];
    for(let inputID in arrayInputID) {
      let validate;
      validate = this.validateInput(arrayInputID[inputID]);
      if(validation)
        validation = validate;
    }
    return validation;
  }

  validateInput = (inputID) => {
    let validate = true;
    let helper = "";

    const input = document.getElementById(inputID);

    //1. Validate if is INPUT tag
    if(input.tagName === "INPUT") {

      //2. Validate INPUT type:
      switch(input.type) {
        case 'text':

          //2.1 Validate empty text field
          if(input.value === "") {
            validate = false
            helper = "El campo se encuentra vacío";
          }

          //2.2 Only text dataset
          if(typeof input.dataset.onlyText !== "undefined") {
            if(input.dataset.onlyText) {
              if(/\d/.test(input.value)) {
                validate = false
                helper = "El campo no debe contener números";
              }
            }
          }

          if(typeof input.dataset.length !== "undefined") {
            if(input.dataset.length !== null) {
              if(input.dataset.length !== input.value.length) {
                validate = false
                helper = "El campo debe tener " + input.dataset.length + " letras";
              }
            }
          }

          break;

        case 'number':

          if(input.value === "") {
            validate = false
            helper = "El campo se encuentra vacío";
          }

          if(typeof input.dataset.length !== "undefined") {
            if(input.dataset.length !== null) {
              if(parseInt(input.dataset.length) !== input.value.length) {
                validate = false
                helper = "El campo debe tener " + input.dataset.length + " números";
              }
            }
          }

          break;

        case 'email':

          if(input.value === "") {
            validate = false
            helper = "El campo se encuentra vacío";
          }

          if(input.value !== "") {
            if(!input.value.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
              validate = false
              helper = "El correo es inválido";
            }
          }

          break;

        default:
          console.log('default');
      }

    }

    this.setValidation(inputID, helper);

    return validate;
  }

  setValidation = (inputID, helper) => {
    switch(inputID) {
      case 'txt-numero-cliente':
        this.setState({
          errorInputNumeroCliente: helper === "" ? false : true,
          errorInputHelperNumeroCliente: helper
        });
        break;
      case 'txt-nombre':
        this.setState({
          errorInputNombre: helper === "" ? false : true,
          errorInputHelperNombre: helper
        });
        break;
      case 'txt-apellidos':
        this.setState({
          errorInputApellidos: helper === "" ? false : true,
          errorInputHelperApellidos: helper
        });
        break;
      case 'txt-email':
        this.setState({
          errorInputEmail: helper === "" ? false : true,
          errorInputHelperEmail: helper
        });
        break;
      case 'txt-celular':
        this.setState({
          errorInputCelular: helper === "" ? false : true,
          errorInputHelperCelular: helper
        });
        break;
      default:
        console.log('default');
    }
  }

  handleRegister = () => {
    const setPath = typeof this.props.setPath !== "undefined" ? this.props.setPath : null;
    const setLoaderOpen = typeof this.props.setLoaderOpen !== "undefined" ? this.props.setLoaderOpen : null;
    if(this.validateForm()) {
      setLoaderOpen(true);
      fetchAPI({
        service: getVarConfig({ key: "REACT_APP_SERVICES_POST_NEW_PROSPECT" }),
        protocol: "POST",
        params: {
          number: document.getElementById('txt-numero-cliente').value,
          name: document.getElementById('txt-nombre').value,
          lastName: document.getElementById('txt-apellidos').value,
          email: document.getElementById('txt-email').value,
          phone: document.getElementById('txt-celular').value
        },
        callbackThen: (response) => {
          setLoaderOpen(false);
          setPath("/registro/exito");
        },
        callbackError: (status) => {
          if(status === 500){
            console.log("Error al registrar cliente");
          }
          setLoaderOpen(false);
        },
      });
    } else {
      console.log("Error en formulario");
    }
  }

  render() {
    return (
      <div className="registro">
        <MazTuInfoBeesTitle title="Contacto de soporte" />

        <MazTuInfoBeesInput
          textLabel="Número de cliente Grupo Modelo"
          textPlaceHolder="Digite acá"
          id="txt-numero-cliente"
          error={this.state.errorInputNumeroCliente}
          textHelperLeft={this.state.errorInputHelperNumeroCliente}
          type="number"
        />

        <MazTuInfoBeesInput
          textLabel="Nombres"
          textPlaceHolder="Digite acá"
          id="txt-nombre"
          error={this.state.errorInputNombre}
          textHelperLeft={this.state.errorInputHelperNombre}
          type="onlyText"
        />

        <MazTuInfoBeesInput
          textLabel="Apellidos"
          textPlaceHolder="Digite acá"
          id="txt-apellidos"
          error={this.state.errorInputApellidos}
          textHelperLeft={this.state.errorInputHelperApellidos}
          type="onlyText"
        />

        <MazTuInfoBeesInput
          textLabel="Correo electrónico"
          textPlaceHolder="Digite acá"
          id="txt-email"
          error={this.state.errorInputEmail}
          textHelperLeft={this.state.errorInputHelperEmail}
          type="email"
        />

        <MazTuInfoBeesInput
          textLabel="Número de celular"
          textPlaceHolder="Digite acá"
          id="txt-celular"
          error={this.state.errorInputCelular}
          textHelperLeft={this.state.errorInputHelperCelular}
          type="number"
          length={10}
        />

        <div className="registro__button">
          <MazTuInfoBeesButton
            handleClick={this.handleRegister}
            text={"ENVIAR"}
          />
        </div>
      </div>
    );
  }
}
