import Candidates from "../Candidates";
import Contratos from "../Contratos";
import Programs from "../Programs";
import Terms from "../Terms";
import LegalRepresentative from "../LegalRepresentative";
import EndContracts from "../EndContracts";
import UenGoals from "../UenGoals";

export const  MenuItems = [
  {
    title: 'Programas',
    icon: 'airplay',
    component: Programs,
    active: true,
  },
  {
    title: 'REP. LEGALES',
    icon: 'legal-representative',
    component: LegalRepresentative,
    active: true,
  },
  {
    title: 'Reportes',
    icon: 'airplay',
    component: Contratos,
  },
  {
    title: 'Candidatos',
    icon: 'candidate-menu',
    component: Candidates,
  },
  {
    title: 'Términos y condiciones',
    icon: 'airplay',
    component: Terms,
  },
  {
    title: 'Actualizar contratos',
    icon: 'airplay',
    component: EndContracts,
  },
  {
    title: 'Metas UEN',
    icon: 'airplay',
    component: UenGoals,
  },
];
