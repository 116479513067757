import { usePagination } from './usePaginator';
import React, { useState, useEffect } from "react";
import Icon from '../../../global/Icon/Icon';
import {
  Container,
  PagesColumn,
  Page,
  LeftIconContainer,
  RightIconContainer,
} from "./styles";

const Paginator = ({
  totalPages,
  currentPage,
  onChange,
  pagesToShow,
}) => {
  const [count, setCount] = useState(1);
  const [lastIndex, ] = useState(0);

  const paginationRange = usePagination({
    currentPage: currentPage,
    totalCount: totalPages,
    totalPages,
    siblingCount: 2,
    pageSize: 5,
  });

  useEffect(() => {
    if (currentPage > lastIndex) { // render forward
      if (currentPage === totalPages) { // go to last page
        setCount(totalPages - pagesToShow + 1);
      } else {
        setCount(count + pagesToShow);
      }
    } else if (currentPage <= lastIndex - pagesToShow) { // render backward
      const newCount = count - pagesToShow;
      setCount(0 < newCount ? newCount : 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onNext = () => onChange(currentPage + 1);

  const onPrevious = () => onChange(currentPage - 1);

  return (
    <div>
      <Container>
        <PagesColumn>
          {<LeftIconContainer
            onClick={() => {
              if (onChange && currentPage > 1) {
                onPrevious();
              }
            }}
          >
            <Icon
              icon={"arrow-left"}
              width="14"
              height="14"
            />
          </LeftIconContainer>}
          {
            paginationRange.map((pageNumber, index) => {
              return (
                <Page
                  key={`paginator-${totalPages}-${index}`}
                  isSelected={pageNumber === currentPage}
                  onClick={() => {
                    if (onChange && Number.isInteger(pageNumber)) {
                      onChange(pageNumber);
                    }
                  }}
                >
                  {pageNumber}
                </Page>
              );
            })
          }

          {<RightIconContainer
              onClick={() => {
                if (currentPage < totalPages) {
                  onNext();
                }
              }}
            >
            <Icon
              icon={"arrow-right"}
              width="14"
              height="14"
            />
          </RightIconContainer>}
        </PagesColumn>
      </Container>
    </div>
  );
};

export default Paginator;