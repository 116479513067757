import React, { useState } from 'react';

import Icon from '../../../global/Icon/Icon';
import DragNDrop from '../DragNDrop';

import { defaultProps, propTypes } from './types';
import styles from './styles.module.scss';

const ImageUploader = props => {
  const {
    thumbnail,
    file,
    setFile,
    onDelete,
  } = props;

  const [previewUrl, setPreviewUrl] = useState(undefined);

  const handleSetFile = (newFile) => {
    const url = URL.createObjectURL(newFile);
    setPreviewUrl(url);
    setFile(newFile);
  }

  const handleOnDelete = () => {
    setPreviewUrl(undefined);
    if (onDelete) onDelete();
  }

  if (!!thumbnail || !!previewUrl) return (
    <div className={styles.thumbnail}>
      <img
        key={thumbnail || previewUrl}
        src={thumbnail || previewUrl}
        alt="thumbnail"
        crossOrigin='anonymous'
      />

      <div
        className={styles.icon}
        onClick={handleOnDelete}
      >
        <Icon
          icon="trash"
          width="20"
          height="20"
        />
      </div>
    </div>
  );

  return (
    <DragNDrop
      file={file}
      setFile={handleSetFile}
    />
  );
};

ImageUploader.prototype = propTypes;
ImageUploader.defaultProps = defaultProps;

export default ImageUploader;
