import React from 'react';
import Icon from '../../../../global/Icon/Icon';
import Dialog from '../../../maz-tu-info-bees-dialog';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';

const ConfirmDialog = props => {
  const { open, onCancel, onConfirm, text, confirmTxt, noCancelBtn, className } = props;
  const msg = text || '¿Deseas guardar los cambios aplicados?';
  const confirmTxtBtn = confirmTxt || 'Guardar';
  return (
    <Dialog
      open={open}
      onClose={onCancel}
    >
      <div className={`${styles.container} ${className}`}>
        <Icon icon="exclamation" />
        <h1>{msg}</h1>

        <div
          className={styles.container__footer}
        >
          {noCancelBtn ? null : (
            <button
              onClick={onCancel}
            >
              Cancelar
            </button>
          )}
          <button
            className={styles.save}
            onClick={onConfirm}
          >
            {confirmTxtBtn}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

ConfirmDialog.prototype = propTypes;
ConfirmDialog.defaultProps = defaultProps;

export default ConfirmDialog;
