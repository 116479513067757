import React, { Component } from "react";
import "./MazTuInfoBeesCardChallenge.scss";
import MazTuInfoBeesButton from "./../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import TagManager from 'react-gtm-module';
import { getImage } from "./MazTuInfoBeesCardChallengeGetImage";

export default class MazTuInfoBeesCardChallenge extends Component {

  handleAcceptChallenge = () => {
    const desafio =
      typeof this.props.desafio !== "undefined" ? this.props.desafio : "";
    const challengeLabel = 
      desafio === "cerveza-premium" ? 'Desafio Marcas premium' : 
      desafio === "cobertura" ? 'Desafio Marcas Premium Cobertura' :
      desafio === "volumen" ? 'Desafio Volumen total' : null;

    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'GAEvent',
          event_category: 'Aceptar Bees',
          event_action: 'Click',
          event_label: challengeLabel,
          userid: this.props.user.ID,
          POC_name: this.props.user.name
        },
        dataLayerName: 'PageDataLayer'
      });
    } catch(e) {
      console.log('No se pudo ejecutar el Datalayer al aceptar el desafío');
    }
    window.open("https://mybees.mx/rewards?tabName=challenges", "_blank");
  }

  render() {
    const desafio =
      typeof this.props.desafio !== "undefined" ? this.props.desafio : "";
    const desafioType =
      typeof this.props.desafioType !== "undefined" ? this.props.desafioType : "";
    const goalReached =
      typeof this.props.goalReached !== "undefined" ? this.props.goalReached : false;
    const desafioID =
      typeof this.props.desafioID !== "undefined" ? this.props.desafioID : "";
    const description =
      typeof this.props.description !== "undefined"
        ? this.props.description
        : "Descripción";
    const points =
      typeof this.props.points !== "undefined" ? this.props.points.toLocaleString() : 0;
    const goal =
      typeof this.props.goal !== "undefined" ? parseInt(this.props.goal) : 0;
    const real =
      typeof this.props.real !== "undefined" ? parseInt(this.props.real) : 0;
    const accepted = typeof this.props.accepted !== "undefined" ? this.props.accepted : false;
    const image =
      typeof this.props.image !== "undefined" ? this.props.image : null;

    let date = new Date();
    const [month, year] = [date.getMonth(), date.getFullYear()];
    var d = new Date(year, month + 1, 0);

    let deadline = d.getDate() + "/" + (month + 1) + "/" + year;

    const imageCard = image !== null ? image : getImage(desafioID, desafioType);

    const type = 
      (accepted) 
        ? (goalReached) 
          ? "complete" 
          : "incomplete"
        : "hold";

    return (
      <div className="maz-tu-info-bees-card-challenge">
        <div
          className={
            `maz-tu-info-bees-card-challenge__title maz-tu-info-bees-card-challenge__title--` +
            type
          }
        >
          {type === "hold"
            ? "En espera"
            : type === "complete"
            ? "Completo"
            : "Incompleto"}
        </div>

        <div className={"maz-tu-info-bees-card-challenge__image maz-tu-info-bees-card-challenge__image--" + desafio}>
          <img
            crossorigin="anonymous"
            src={imageCard}
            alt={"Imagen Desafíos " + desafio}
          />
        </div>

        <div className="maz-tu-info-bees-card-challenge__description">
          {description}
        </div>

        <div className="maz-tu-info-bees-card-challenge__points">
          Ganas: {points} puntos BEES
        </div>

        <div className="maz-tu-info-bees-card-challenge__deadline">
          Valido hasta {deadline}
        </div>

        {type === "hold" ? (
          <MazTuInfoBeesButton 
            textButton={"IR A ACEPTAR EN BEES"} 
            type="secondary"
            handleClick={this.handleAcceptChallenge} />
        ) : null}
      </div>
    );
  }
}
