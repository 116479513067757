import React, { useEffect } from 'react';
import { formatDateDDMMYYYY } from '../../../../utils/utils';

import Icon from '../../../global/Icon/Icon';
import HeaderTable from '../ProgramsTable/HeaderTable';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';
import useTermsTable from './useTermsTable';

const TermsTable = props => {
  const { onClickEdit, setLoading, loading } = props;

  const {
    list,
    isLoading,
    sortByKey,
    sortDescStates,
  } = useTermsTable();

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  if (isLoading) return <>Loading ...</>;
  if (!list?.length && !isLoading) return <>No se encontraron términos y condiciones</>;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeaderTable
          onClick={() => sortByKey('id')}
          title="ID"
          iconDesc={sortDescStates?.idSortDesc}
        />

        <HeaderTable
          onClick={() => {}}
          title="Brochure"
        />

        <HeaderTable
          onClick={() => sortByKey('createdAt')}
          title="Fecha de creación"
          iconDesc={sortDescStates?.createdAtSortDesc}
        />

        <div></div>
      </div>

      <div className={styles.body}>
        {list
          .map((terms) => {
            const {
              id,
              brochure,
              createdAt,
            } = terms;
            return (
              <div
                className={styles.row}
                key={id}
              >
                <div className={styles.center}>{id}</div>
                <div className={styles.center}>
                  <img
                    src={brochure}
                    alt="thumbnail"
                    crossOrigin='anonymous'
                  />
                </div>
                <div className={styles.center}>
                  {formatDateDDMMYYYY(createdAt)}
                </div>
                <div className={styles.center}>
                  <Icon
                    icon="edit"
                    handleClick={() => onClickEdit(terms)}
                  />
                </div>
              </div>
            )}
        )}
      </div>
    </div>
  );
}

TermsTable.prototype = propTypes;
TermsTable.defaultProps = defaultProps;

export default TermsTable;
