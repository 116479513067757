import styled from "styled-components";

export const Container = styled.div.attrs(() => ({
  className: 'PaginatorContainer'
}))`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const PagesColumn = styled.div.attrs(() => ({
  className: 'PaginatorPagesColumn'
}))`
  display: flex;
  align-items: center;
  margin-top: 0px;
  text-align: left;
`;

export const Page = styled.div.attrs(() => ({
  className: 'PaginatorPage'
}))  `
  margin-right: 25px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  ${({ isSelected }) => `
  background-color: ${isSelected ? '#FFF000' : ''};
  color: ${isSelected ? '#000' : '#657480'};
  border-radius: ${isSelected ? '100%' : ''};
  `}

  &:hover {
    ${({ isSelected }) => `
    color: ${isSelected ? '#000' : '#FFF000'};
    `}
  }
`;

export const LeftIconContainer = styled.div.attrs(() => ({
  className: 'PaginatorLeftIconContainer'
}))`
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
`;

export const RightIconContainer = styled.div.attrs(() => ({
  className: 'PaginatorRightIconContainer'
}))`
  display: flex;
  align-items: center;
  margin-right: 25px;
  margin-left: 0px;
  cursor: pointer;
`;
