import React, { useEffect } from 'react';

import Icon from '../../../global/Icon/Icon';
import HeaderTable from '../ProgramsTable/HeaderTable';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';
import { formatDateDDMMYYYY } from '../../../../utils/utils';

const CandidatesTable = props => {
  const {
    onClickRow,
    setLoading,
    loading,
    hide,
    list,
    isLoading,
    sortByKey,
    sortDescStates,
  } = props;

  useEffect(() => {
    if (!hide && isLoading && !loading) setLoading(true);
  }, [hide, isLoading, loading, setLoading]);

  useEffect(() => {
    if (!hide && !isLoading && loading) setLoading(false);
  }, [hide, isLoading, loading, setLoading]);

  if (hide) return null;
  if (isLoading) return <>Loading ...</>;
  if (!list?.length && !isLoading) return <>No se encontró información</>;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <HeaderTable
          onClick={() => sortByKey('originalFilename')}
          title="Nombre del documento"
          iconDesc={sortDescStates?.originalFilenameSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('createdAt')}
          title="Fecha de creación"
          iconDesc={sortDescStates?.createdAtSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('totalRows')}
          title="Total de registros"
          iconDesc={sortDescStates?.totalRowsSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('succeedRows')}
          title="Registros exitosos"
          iconDesc={sortDescStates?.succeedRowsSortDesc}
        />

        <HeaderTable
          onClick={() => sortByKey('failRows')}
          title="Registros fallidos"
          iconDesc={sortDescStates?.failRowsSortDesc}
        />

        <div></div>
      </div>

      <div className={styles.body}>
        {list
            .map((fileHistory) => {
              const {
                id,
                originalFilename,
                createdAt,
                totalRows,
                succeedRows,
                failedRows,
              } = fileHistory;
              return (
                <div
                  className={styles.row}
                  key={id}
                >
                  <div className={styles.ml10}>{originalFilename}</div>
                  <div>{formatDateDDMMYYYY(createdAt)}</div>
                  <div className={styles.bold}>{totalRows}</div>
                  <div className={styles.success}>{succeedRows}</div>
                  <div className={styles.fail}>{failedRows}</div>
                  <div>
                    {String(failedRows) === '0' ? null : (
                      <Icon
                        icon="circled-arrow-right"
                        width="16"
                        height="16"
                        handleClick={() => onClickRow(fileHistory)}
                      />
                    )}
                  </div>
                </div>
              )}
        )}
      </div>
    </div>
  );
};

CandidatesTable.prototype = propTypes;
CandidatesTable.defaultProps = defaultProps;

export default CandidatesTable;
