import React, { useEffect, useState } from 'react';

import HeaderTable from '../ProgramsTable/HeaderTable';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';
import EditableRow from './EditableRow';
import useUenGoalsTable from './useUenGoalsTable';

function handleBeforeUnload (event) {
  event.preventDefault();
};

const UenGoalsTable = props => {
  const { setLoading, loading, hide } = props;
  const [editedGoals, setEditedGoals] = useState({});
  const [editingGoals, setEditingGoals] = useState({});
  const [isListening, setIsListening] = useState(false);

  const {
    list: goalList,
    sortByKey,
    isLoading,
    postUenGoals,
    getAllGoals,
  } = useUenGoalsTable();

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  // prevents leave before save
  useEffect(() => {
    if(!hide && !isListening &&  !!Object.keys(editedGoals).length) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      setIsListening(true);
    }
  }, [editedGoals, hide, isListening]);

  useEffect(() => {
    if(!hide && isListening &&  !!!Object.keys(editedGoals).length) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      setIsListening(false);
    }
  }, [editedGoals, hide, isListening]);

  useEffect( () => { // Decoupling using events
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, []);

  if (hide) return null;
  if (!goalList.length) return <>No se encontraron metas registradas</>;

  const handleOnChangeGoal = (goal) => {
    const { uen, points } = goal;
    const newEditedGoals = { ...editedGoals, [`${uen}`]: points };
    setEditedGoals(newEditedGoals);
  }

  const removeEditedGoal = (goal) => {
    const {
      [`${goal.uen}`]: removedUen,
      ...newEditedGoals
    } = editedGoals;
    setEditedGoals(newEditedGoals);
  }

  const handleOnEditingGoal = (isEditing, uen) => {
    setEditingGoals({ ...editingGoals, [uen]: isEditing });
  };

  const handleOnCancel = (goal) => {
    removeEditedGoal(goal);
  }

  const handleOnSave = async (goal) => {
    await postUenGoals([goal]);
    await getAllGoals();
    removeEditedGoal(goal);
    handleOnEditingGoal(false, goal.uen);
  }

  const parseData = (inputObject) => Object
    .entries(inputObject)
    .map(([uen, points]) => ({ uen, points }));

  const handleOnSaveAll = async () => {
    const newUenGoalList = parseData(editedGoals);
    await postUenGoals(newUenGoalList);
    await getAllGoals();
    resetStates();
  }

  const resetStates = () => {
    setEditedGoals({});
    setEditingGoals({});
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
          <HeaderTable
            onClick={() => sortByKey('uen')}
            title="UEN"
            noIcon
            />
          <HeaderTable
            onClick={() => sortByKey('points')}
            title="Puntos"
            noIcon
          />
        <div>
          {(1 < Object.keys(editedGoals).length) &&
            <button
              className={styles.btn}
              onClick={handleOnSaveAll}
            >
                Guardar todo
            </button>
          }
        </div>
      </div>
      <div className={styles.body}>
        {goalList
          .map((goal, index) => {
            const { uen } = goal;
            return (
              <EditableRow
                className={styles.row}
                key={`${uen}-key`}
                goal={{ ...goal, index }}
                onSave={handleOnSave}
                onCancel={handleOnCancel}
                onChangeGoal={handleOnChangeGoal}
                editing={!!editingGoals[uen]}
                setEditing={(isEditing) => handleOnEditingGoal(isEditing, uen)}
              />
            )
        })}
      </div>
    </div>
  );
};

UenGoalsTable.prototype = propTypes;
UenGoalsTable.defaultProps = defaultProps;

export default UenGoalsTable;
