import React, { Component } from "react";
import { MsalContext } from "@azure/msal-react";

import Menu from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Menu";
import MazTuInfoBeesLoader from "../../../components/maz-tu-info-bees/maz-tu-info-bees-loader/MazTuInfoBeesLoader";
import MazTuInfoBeesToast from "../../../components/maz-tu-info-bees/maz-tu-info-bees-toast/MazTuInfoBeesToast";

import styles from "./styles.module.scss";
import { defaultProps, propTypes } from "./types";
import { MenuItems } from "./MenuItems";

import { callMsGraph } from '../../../graph';
import { loginRequest } from "../../../authConfig";

/*
* It renders a menu on the left and a component on the right.
* The component on the right is determined by the active menu item
*/
class AdminMain extends Component {

  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = {
      menuItems: MenuItems,
      toastType: "success",
      toastMessage: "",
      toastOpen: false,
      loaderOpen: false,
    };
  }

  componentDidMount() {
    // this.setActiveByIndex(1);
    this.setActiveBySubmenuTitle(MenuItems[0], 'franja')
    this.requestProfileData();
  }

  requestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    this.context.instance.acquireTokenSilent({
      ...loginRequest,
      account: this.context.accounts[0]
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => {
        // console.log(response);
        return response;
      });
    }).catch((e) => {
      console.error(e);
    });
  }

  getIndexOfActive = (array) => {
    array = array || [];
    return array.findIndex(({ active }) => active);
  }

 /* A function that is used to set the active item in the menu. */
  setActiveByIndex = (index) => {
    const { title } = this.state.menuItems[index] || {};
    this.setActiveByTitle(title);
  }

  getItemFromArrayByTitle = (array = [], title) => (array
  .find(({ title: itemTitle }) => (
    itemTitle.trim().toLowerCase() === title.trim().toLowerCase()
  )));

  setActiveAsFalse = array => array.forEach(itemArray => {
    itemArray.active = false;
    if (itemArray.submenu) {
      this.setActiveAsFalse(itemArray.submenu);
    }
  });

  /* A function that is used to set the active item in the menu. */
  setActiveByTitle = (title) => {
    const { menuItems } = this.state;
    this.setActiveAsFalse(menuItems);
    const foundItem = this.getItemFromArrayByTitle(menuItems, title);

    if (foundItem) {
      foundItem.active = true;
      this.setState({ menuItems });
    }
  }

  setActiveBySubmenuTitle = (item, subitemTitle) => {
    const { menuItems } = this.state;

    this.setActiveAsFalse(menuItems);
    const foundItem = this.getItemFromArrayByTitle(menuItems, item.title);

    if (foundItem) {
      foundItem.active = true;
      const foundSubItem = this.getItemFromArrayByTitle(foundItem.submenu, subitemTitle);
      if (foundSubItem) {
        foundSubItem.active = true;
      }
      this.setState({ menuItems });
    }
  }

  handleOnClickItem = (item, subitemTitle) => {
    const { title, submenu } = item;
    if (submenu) {
      this.setActiveBySubmenuTitle(item, subitemTitle);
    } else {
      this.setActiveByTitle(title);
    }
  };

  getActiveItemMenu = () => {
    const currentItem = this.state.menuItems.find(({active}) => active) || [{}];
    if (currentItem.submenu) {
      const currentSubmenuComponent = currentItem.submenu.find(({active}) => active);
      return currentSubmenuComponent;
    }
    return currentItem;
  }

  handleToast = (args) => {
    const {
      open = false,
      message = '',
      timeoutClose = 3000,
      type = 'success',
    } = args;

    const toastClose = this.toastClose;

    if (open) {
      this.setState({
        toastOpen: true,
        toastMessage: message,
        toastType: type,
      });

      setTimeout(function() {
        toastClose();
      }, timeoutClose);
    } else {
      toastClose();
    }
  }

  toastClose = () => this.setState({ toastOpen: false });

  setLoaderOpen = loaderOpen => this.setState({ loaderOpen });

  render() {
    const {
      state,
      handleOnClickItem,
      getActiveItemMenu,
      handleToast,
      setLoaderOpen,
    } = this;

    const {
      menuItems,
      loaderOpen,
      toastType,
      toastMessage,
      toastOpen,
    } = state;

    const { component: RightView, title } = getActiveItemMenu();

    return (
      <div className={styles.container}>
        <div className={styles.container__left}>
          <Menu
            menuItems={menuItems}
            onClickItem={handleOnClickItem}
          />
        </div>
        <div className={styles.container__rigth}>
          {RightView && (
            <RightView
              title={title}
              loading={loaderOpen}
              setLoading={setLoaderOpen}
              handleToast={handleToast}
            />
          )}
        </div>

        <MazTuInfoBeesLoader open={loaderOpen} />

        <MazTuInfoBeesToast
          style={{ width: '500px', left: 'initial' }}
          type={toastType}
          message={toastMessage}
          open={toastOpen}
          handleToast={handleToast}
        />
      </div>
    );
  }
}

AdminMain.propTypes = propTypes;
AdminMain.defaultProps = defaultProps;

export default AdminMain;
