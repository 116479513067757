import React, { Component } from "react";

import "./MazTuInfoBeesButton.scss";

export default class MazTuInfoBeesButton extends Component {

  render() {
    const type =
      typeof this.props.type !== "undefined" ? this.props.type : "primary";
    const textButton =
      typeof this.props.textButton !== "undefined" ? this.props.textButton : "Aceptar";
    const handleClick = typeof this.props.handleClick !== "undefined" ? this.props.handleClick : this.handleClick;
    const disabled = typeof this.props.disabled !== "undefined" ? this.props.disabled : false;

    return (
      <button
        className={`maz-tu-info-bees-button ` + type}
        onClick={handleClick}
        disabled={disabled}
      >
        {textButton}
      </button>
    );
  }
}
