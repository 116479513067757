import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import LoginAdmin from './pages/Admin/LoginAdmin/LoginAdmin';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
//import AdminMain from './pages/Admin/AdminMain';
/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
 const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="admin" element={<LoginAdmin/>} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
