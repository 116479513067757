import React, { useRef } from 'react';
import Icon from '../../../global/Icon/Icon';
import styles from './styles.module.scss';

const UploadFileCandidatesBtn = (props) => {
  const { submitFile, hide } = props;
  const inputFile = useRef(null);

  const onFileSelected = (event) => {
    const { target: {
      files
    } } = event;
    const currentFile = files[0];
    if(currentFile) {
      submitFile(currentFile);
      inputFile.current.value='';
    };
  }

  if (hide) return null;
  return (
    <>
    <input
      id='file'
      ref={inputFile}
      type='file'
      onChange={onFileSelected}
      style={{display: 'none'}}
      accept=".csv, text/csv"
      multiple={false}
      />
      <button
        className={`${styles.btn} ${styles['btn--black']}`}
        onClick={() => inputFile.current.click()}
      >
        <Icon
          icon="upload"
          width="14"
          height="14"
        />
        CARGAR DOCUMENTO
      </button>
    </>
  );
};

export default UploadFileCandidatesBtn;