import { useCallback, useEffect, useState } from "react";
import fetchAPI from "../../../utils/fetchAPI";
import { getUser } from "../../../utils/sessionInfo";
import { getVarConfig } from "../../../utils/environment";

const MONTHS = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre"
};

const transformData = (data, top) => {
  if (!data) return;

  const updatedMonth = new Date(data.updatedAt).getMonth(); // current month is forward  1 month since origin

  const result = [];

  for (let i = 0; i < 6; i++) {
    const monthKey = i === 0 ? 'boxesCurrentMonth' : `boxes${i}MonthAgo`;
    const monthNameIndex = updatedMonth - i;

    if (data.hasOwnProperty(monthKey)) {
      const mo = 0 < monthNameIndex ? monthNameIndex : monthNameIndex + 12;
      const month = MONTHS[mo];
      const points = data[monthKey];
      const highlighted = !!top?.boxes[monthKey];

      result.push({ month, points, highlighted });
    }
  }

  return result;
}

const useInitialResume = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [volumeHistory, setVolumeHistory] = useState(null);
  const [currentSales, setCurrentSales] = useState(null);

  const getLastVolume = useCallback(() => {
    const { ID: customerId } = getUser();
    if (customerId) {
      const service = `${getVarConfig({ key: "REACT_APP_CUSTOMERS_SERVICES" })}/${customerId}/lastVolume` || '';
      setIsLoading(true);
      fetchAPI({
        service,
        protocol: "GET",
        callbackThen: ({ data }) => {

          const lastMonths =transformData(data?.last6MonthVolume, data?.top);
          setVolumeHistory({ lastMonths });

          const newCurrentSales = data?.last6MonthVolume?.currentSales === null ? 0 : data?.last6MonthVolume?.currentSales;
          setCurrentSales(newCurrentSales);

          setIsLoading(false);
        },
        callbackCatch: err => {
          setIsLoading(false);
          throw new Error(err);
        },
      });
    }
  }, []);

  useEffect(() => getLastVolume(), [getLastVolume]);

  return {
    isLoading,
    getLastVolume,
    volumeHistory,
    currentSales,
  };
}

export default useInitialResume;
