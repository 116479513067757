import React from 'react';
import "./Resumen.scss";

const EditActionButton = (props) => {
  const { hide, onSave, editing, setEditing, newTextInput } = props;
  console.log({ props });
  if (hide) return null;

  const handleOnCancel = () => setEditing(false);

  const handleOnEdit = () => setEditing(true);

  const handleOnSave = () => {
    handleOnCancel();
    if (typeof onSave === 'function') onSave();
  }

  if (editing) {
    if (!newTextInput) {
      return (
        <button
          onClick={handleOnCancel}
          className="resumen__datos__row__actions__button resumen__datos__row__actions__button--cancel"
        />
      );
    }

    return (
        <button
          onClick={handleOnSave}
          className="resumen__datos__row__actions__button resumen__datos__row__actions__button--save"
        />
    );
  }

  return (
    <button
      onClick={handleOnEdit}
      className="resumen__datos__row__actions__button resumen__datos__row__actions__button--edit"
    />
  );
};

export default EditActionButton;
