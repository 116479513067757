import React, { useEffect, useState } from 'react';
import MazTuInfoBeesIndicators from '../../../components/maz-tu-info-bees/maz-tu-info-bees-indicators';
import styles from './styles.module.scss';

const Tutorial = props => {
  const { tutorial, setTutorial } = props;
  const { title, steps, currentTitle } = tutorial ||  {};
  const [currentStep, setCurrentStep] = useState(NaN);

  useEffect(() => {
    if (isNaN(currentStep)) setCurrentStep(0);
  }, [currentStep]);

  useEffect(() => {
    const { title: newCurrentTitle} = steps[currentStep] || {};
    if(!isNaN(currentStep) && currentTitle !== newCurrentTitle) {
      setTutorial({ ...tutorial, currentTitle: newCurrentTitle });
    }
  }, [currentStep, currentTitle, setTutorial, steps, tutorial])

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  }

  const finalStep = () => {
    setTutorial(undefined);
  }

  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.container__image}>
            <img
              src={steps[currentStep]?.image}
              alt={title}
              height={348}
            />
          </div>
          <p>
            <h2>{steps[currentStep]?.description}</h2>
          </p>
        </div>

        <MazTuInfoBeesIndicators
          className={styles.indicators}
          indexActive={currentStep}
          length={steps.length}
          onClick={setCurrentStep}
        />
      </div>

      <br /><br /><br />

      <div className={styles.buttons}>
        {currentStep + 1 < steps.length ? (
          <button className={styles.btn__black} onClick={nextStep}>Siguiente</button>
        ): null}
        {currentStep + 1 === steps.length ? (
          <button className={styles.btn__black} onClick={finalStep}>Finalizar</button>
        ) : null}
        <button
          className={`${styles.btn__white} ${currentStep === 0 ? styles.btn__white__hide : ''}`}
          onClick={prevStep}
        >
            Anterior
        </button>
      </div>
    </>
  );
};

export default Tutorial;
