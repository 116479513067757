import React, { Component } from "react";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesTitle from "../../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import "./ValidatingRequest.scss";

export default class ValidatingRequest extends Component {

  state = {
    personaType: "fisica"
  }

  render() {
    return(
      <div className="validating-request">

        <div className="validating-request__container">

          <div className="validating-request__container__logo"></div>

          <div className="validating-request__container__title">
            Aún estamos<br />procesando tu solicitud
          </div>

          <div className="validating-request__container__text">
            Ya casi terminamos, la información está siendo revisada. Ingresa durante la semana para comprobar que se haya activado tu programa
          </div>

          <div className="validating-request__container__title">
            ¡Pronto seras parte del club! 
          </div>

        </div>

      </div>
    )
  }

}