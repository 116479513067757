import React from 'react';
import styles from './styles.module.scss';
import { financeCurrencyFormat } from '../../../utils/utils'
import { getUser } from '../../../utils/sessionInfo';

const MazTuInfoBeesAccountFomento = (props) => {

  const {
    className,
    setPath,
    customerPromotions,
    setBackButton,
  } = props;

  const {
    import_monthly_payment, //Línea de crédito
    total_due, //monto a pagar
    total_due_expired,
    invoices,
    total_amort
  } = customerPromotions || {};

  const goToCuentaVirtual = () => {
    props.sendGA('GAEvent', 'Content', 'Content', 'How to pay', true, 'how_to_pay_button', '¿Cómo pagar?');
    props.setPath('/cuenta-virtual');
    props.setBackButton(true, goToCuentaFomentos);

  }

  const goToCuentaFomentos = () => {
    props.setPath('/fomentos');
    props.setBackButton(false, null);
  }

  return (
    <div className={`${className} card mt-2 mb-3 ${styles.container}`}>

      <div className={`${styles.container__header} py-3`}>
        <h2>Saldo total</h2>
        <h1>{financeCurrencyFormat(total_due && parseFloat(total_due) > 0 ? Math.round(parseFloat(total_due) * 100) / 100 : '0.00')}</h1>
      </div>

      {/* {currentDebit > 0 ? ( */}
      {customerPromotions && total_due > 0 ? (
        <div className={styles.container__body}>

          <div className={``}>

            <span
              className={`${styles.container__body__row} py-3`}
            >
              <div className={`container d-flex justify-content-between align-items-center`}>
                <h3>Saldo vencido:</h3>
                <h2 className={`${styles.container__body__red} py-3`}>{financeCurrencyFormat(Math.round(parseFloat(total_due_expired) * 100) / 100)}</h2>
              </div>
            </span>

            <span
              className={`${styles.container__body__row} ${styles.container__body__bb0} pt-3`}
            >
              <div className={`container d-flex justify-content-between align-items-center`}>
                <h3>Importe pagaré mensual:</h3>
                <h2><b>{financeCurrencyFormat(Math.round(parseFloat(import_monthly_payment) * 100) / 100)}</b></h2>
              </div>
            </span>

            <span
              className={`${styles.container__body__row} ${styles.container__body__bb0} pt-3`}
            >
              <div className={`container d-flex justify-content-between align-items-center`}>
                <h3>Importe amortizado en nota de crédito:</h3>
                <h2><b>{financeCurrencyFormat(Math.round(parseFloat(total_amort) * 100) / 100)}</b></h2>
              </div>
            </span>

            <span
              className={`${styles.container__body__row} py-3`}
            >
              <div className={`container d-flex justify-content-between align-items-center`}>
                <h3>Saldo de este período:</h3>
                <h2><b>{financeCurrencyFormat(Math.round((parseFloat(total_amort) + parseFloat(import_monthly_payment)) * 100) / 100)}</b></h2>
              </div>
            </span>

            <span
              className={`${styles.container__body__row} ${styles.container__body__bb0} py-3`}
            >
              <div className={`container d-flex justify-content-between align-items-center`}>
                <h3><b>Total a pagar:</b></h3>
                <h2><b>{financeCurrencyFormat(Math.round((parseFloat(total_amort) + parseFloat(import_monthly_payment) + parseFloat(total_due_expired)) * 100) / 100)}</b></h2>
              </div>
            </span>

            <div
              className={`${styles.container__body__row} ${styles.container__warning} py-3`}
            >
              <span>Tu total a pagar esta conformado por tu saldo vencido mas el saldo del período</span>
            </div>

          </div>
        </div>
      ) : null}

      <div className={styles.container__warning}>
        <span>*Recuerda que esta información es actualizada a partir del día 10 de cada mes</span>
      </div>
      {/*
        <div className={styles.container__footer}>
          <button
            className={`${styles.btn__payment} ${styles.btn}`}
            onClick={goToCuentaVirtual}
          >¿Cómo Pagar?</button>
        </div>
      */}
    </div>
  );
}

export default MazTuInfoBeesAccountFomento;
