import { useCallback, useEffect, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";
import useTable from "../../../../utils/hooks/useTable";
import { getStripeByType } from "../../../../utils/utils";

const useTermsTable = () => {
  const {
    setList,
    list,
    sortByKey,
    sortDescStates,
  } = useTable();
  const [isLoading, setIsLoading] = useState(false);

  const getAllTerms = useCallback(() => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_TERMS" });
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        setList(data || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setList]);

  useEffect(() => getAllTerms(), [getAllTerms])

  return {
    list,
    setList,
    isLoading,
    getAllTerms,
    sortByKey,
    sortDescStates,
    getStripeByType,
  };
};

export default useTermsTable;
