import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './styles.scss';

const MazTuInfoBeesDatepicker = props => {
  const { dates, setDates } = props;
  const {start, end} = dates;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (newDates) => {
    const [startDate, endDate] = newDates;
    setDates({
      start: startDate,
      end: endDate,
    });
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (!startDate && start) {
      setStartDate(new Date(start));
    }
  }, [start, startDate]);

  useEffect(() => {
    if (!endDate && end) {
      setEndDate(new Date(end));
    }
  }, [end, endDate]);

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
    />
  );
};

export default MazTuInfoBeesDatepicker;
