const imgDesafiosCervezas = require('./assets/image-challenge-premium.jpg');
const imgDesafiosCobertura = require('./assets/image-challenge-coverage.jpg');
const imgDesafiosVolumen = require('./assets/image-challenge-total.jpg');

const _1656970336120 = require('./assets/1656970336120.jpg');
const _1656971098092 = require('./assets/1656971098092.jpg');
const _1656971271278 = require('./assets/1656971271278.jpg');
const _1656971491276 = require('./assets/1656971491276.jpg');
const _1656971616760 = require('./assets/1656971616760.jpg');
const _1656971866059 = require('./assets/1656971866059.jpg');
const _1656971984256 = require('./assets/1656971984256.jpg');
const _1656972081868 = require('./assets/1656972081868.jpg');
const _1656972228068 = require('./assets/1656972228068.jpg');
const _1656972318349 = require('./assets/1656972318349.jpg');
const _1656972446727 = require('./assets/1656972446727.jpg');
const _1656972775252 = require('./assets/1656972775252.jpg');
const _1656972894849 = require('./assets/1656972894849.jpg');
const _1656972979984 = require('./assets/1656972979984.jpg');
const _1656973108746 = require('./assets/1656973108746.jpg');
const _1656973439426 = require('./assets/1656973439426.jpg');
const _1656973863015 = require('./assets/1656973863015.jpg');
const _1656973983230 = require('./assets/1656973983230.jpg');
const _1656974244134 = require('./assets/1656974244134.jpg');
const _1656974343439 = require('./assets/1656974343439.jpg');
const _1656974549353 = require('./assets/1656974549353.jpg');
const _1656974628277 = require('./assets/1656974628277.jpg');
const _1656974755832 = require('./assets/1656974755832.jpg');
const _1656974952927 = require('./assets/1656974952927.jpg');
const _1656975067844 = require('./assets/1656975067844.jpg');
const _1656975151584 = require('./assets/1656975151584.jpg');
const _1656975291754 = require('./assets/1656975291754.jpg');
const _1656975387945 = require('./assets/1656975387945.jpg');
const _1656975624735 = require('./assets/1656975624735.jpg');
const _1656975715551 = require('./assets/1656975715551.jpg');
const _1656975837345 = require('./assets/1656975837345.jpg');
const _1656975926806 = require('./assets/1656975926806.jpg');
const _1656976042176 = require('./assets/1656976042176.jpg');
const _1656976149635 = require('./assets/1656976149635.jpg');
const _1656976267807 = require('./assets/1656976267807.jpg');
const _1656976362111 = require('./assets/1656976362111.jpg');
const _1656976605522 = require('./assets/1656976605522.jpg');
const _1656976694424 = require('./assets/1656976694424.jpg');

const images = {
  _1656970336120,
  _1656971098092,
  _1656971271278,
  _1656971491276,
  _1656971616760,
  _1656971866059,
  _1656971984256,
  _1656972081868,
  _1656972228068,
  _1656972318349,
  _1656972446727,
  _1656972775252,
  _1656972894849,
  _1656972979984,
  _1656973108746,
  _1656973439426,
  _1656973863015,
  _1656973983230,
  _1656974244134,
  _1656974343439,
  _1656974549353,
  _1656974628277,
  _1656974755832,
  _1656974952927,
  _1656975067844,
  _1656975151584,
  _1656975291754,
  _1656975387945,
  _1656975624735,
  _1656975715551,
  _1656975837345,
  _1656975926806,
  _1656976042176,
  _1656976149635,
  _1656976267807,
  _1656976362111,
  _1656976605522,
  _1656976694424
}

export function getImage(challengeId, challengeType) {
  let image;
  if(typeof images['_' + challengeId] !== "undefined") {
    image = images['_' + challengeId];
  } else {
    switch(challengeType) {
      case "total":
      case "marketpl":
        image = imgDesafiosVolumen;
        break;
      case "premium":
        image = imgDesafiosCervezas;
        break;
      case "coverage":
        image = imgDesafiosCobertura;
        break;
      default:
        console.log('no image');
    }
  }
  return image;
}