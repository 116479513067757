import React, { Component } from "react";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import "./ContratoFirma.scss";

import { on } from "../../../utils/events";

export default class ContratoFirma extends Component {

  state = {
    personaType: "fisica",
    validation: true,
    urlContract: "",
    intervalID: null,
    iframeOpen: false
  }

  componentDidMount() {
    try{
      const handleProcess = this.props.handleProcess;
      on("click-back-navigation-programa-mercado", function(event) {
        handleProcess({
          protocol: "PATCH",
          params: {
            transition: "returnToLoadDocs"
          }
        });
      });
    } catch(e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.state.intervalID);
  }

  getContractSign = () => {
    const hasContract = (typeof this.props.dataProcess.contractData !== "undefined" && this.props.dataProcess.contractData.tratoId !== "");
    this.setState({
      validation: false
    });
    this.props.setLoaderOpen(true);
    if(hasContract) {
      this.getStatusSign();
      this.getSignature();
    } else {
      this.props.handleProcess({
        protocol: "POST",
        isCreateContract: true,
        params: {
          processId: this.props.dataProcess.responseData.process.processId
        },
        callbackSuccess: (response) => {
          try {
            if(response.data.contractId !== "") {
              this.getSignature();
            }
          } catch(e) {
            this.props.setLoaderOpen(false);
            console.log(e);
          }
        }
      });
    }
    
  }

  getSignature = () => {
    let processId;
    try {
      processId = this.props.dataProcess.responseData.process.processId;
    } catch(e) {
      processId = this.props.dataProcess.processId;
    }
    this.props.setLoaderOpen(true);
    this.props.handleProcess({
      protocol: "POST",
      isSignature: true,
      params: {
        processId
      },
      callbackSuccess: (response) => {
        this.props.setLoaderOpen(false);
        try {
          console.log(response);
          this.setState({
            urlContract: response.data.signUrl
          });
        } catch(e) {
          console.log(e);
        }
      }
    });
  }

  getStatusSign = (callback) => {
    const intervalID = window.setInterval(() => {

      this.setState({
        intervalID
      });

      this.props.handleProcess({
        loader: false,
        protocol: "GET",
        isStatusSignature: true,
        callbackSuccess: (response) => {
          try {
            console.log(response);
            if(response.data.documentSigned) {
              window.clearInterval(this.state.intervalID);
              this.props.handleProcess({
                protocol: "PATCH",
                params: {
                  transition: "completeProcess"
                }
              });
            }
          } catch(e) {
            console.log(e);
          }
        }
      });

    }, 5000);
    
  }

  handleButton = () => {
    this.getStatusSign();
    window.open(this.state.urlContract, '_blank');
    /*this.setState({
      iframeOpen: true
    });*/
  }

  render() {

    const iframeOpen = this.state.iframeOpen ? 'open' : 'close';

    if(this.state.validation && this.props.dataProcess !== null) {
      this.props.setLoaderOpen(true);
      console.log(this.props.dataProcess);
      this.getContractSign();
    }

    return(
      <div className="contrato-firma">

        <div className="contrato-firma__title">
          Firma de contrato
        </div>

        <div className="contrato-firma__message">
          Da clic en el botón para abrir la ventana del contrato y sigue las instrucciones.<br /><br />
          Una vez firmado, esta pantalla se actualizará para terminar el proceso de la firma.
        </div>

        <div className="contrato-firma__button">
          <MazTuInfoBeesButton
            disabled={this.state.urlContract === ""}
            textButton={this.state.urlContract === "" ? "OBTENIENDO CONTRATO" : "FIRMAR CONTRATO"}
            handleClick={this.handleButton} />
        </div>

        <div className={"contrato-firma__iframe contrato-firma__iframe--" + iframeOpen}>
          <iframe 
            src={this.state.urlContract} 
            title='iframe-signature'
            crossorigin="anonymous" />
        </div>

      </div>
    )
  }

}