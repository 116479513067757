import React, { Component } from "react";

import "./mazTuInfoBeesNavBar.scss";

import Icon from "../../global/Icon/Icon";

export default class MazTuInfoBeesNavBarLink extends Component {
  render() {
    const section = typeof this.props.section !== "undefined" ? this.props.section : "home";
    const active =
      typeof this.props.active !== "undefined"
        ? this.props.active
          ? true
          : false
        : false;
    const evtClick = typeof this.props.evtClick !== "undefined" ? this.props.evtClick : null;
    return (
      <div 
        className={`maz-tu-info-bees-nav-bar__link` + (active ? " active" : "")} 
        onClick={evtClick}>
        <Icon icon={section} size="26" disabled={!active}/>
      </div>
    );
  }
}
