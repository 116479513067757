import React from 'react';
import Icon from '../../../global/Icon/Icon';
import styles from '../styles.module.scss';

const ActionRow = (props) => {
  const { type, onClick } = props;

  const handleOnClick = () => {
    if (typeof onClick === 'function') onClick(type);
  }

  if (type==='transfer') {
    return(
      <div
        className={styles.card__action}
        onClick={handleOnClick}
      >
        <div>
          <Icon
            icon={"bank-transfer"}
            width="14"
            height="14"
            className="me-2"
          />
          <span>Transferencia</span>
        </div>
        <Icon
          icon={"arrow-right"}
          width="14"
          height="14"
          className="me-2"
        />
      </div>
    );
  }

  if (type==='deposit') {
    return(
      <div
        className={styles.card__action}
        onClick={handleOnClick}
      >
        <div>
          <Icon
            icon={"bank-deposit"}
            width="14"
            height="14"
          />
          <span>Depósito en banco</span>
        </div>
        <Icon
          icon={"arrow-right"}
          width="14"
          height="14"
        />
      </div>
    );
  }

  return (<div className={styles.card__action} />);
};

export default ActionRow;
