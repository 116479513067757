import React from 'react';
import styles from './styles.module.scss';

const Disclaimer = () => {
  return (
    <div className={styles.container}>
      <h1>Aclaraciones</h1>
      <p>
        Para cualquier duda o aclaración te invitamos
        a que nos contactes por medio de tu <b>APP BEES.</b>
      </p>

      <p>
        <b>{'Ingresa a Mi cuenta > Obtener ayuda y soporte  > Contáctanos.'}</b>
      </p> 

      <p>o comúnicate con <b>Modelo Amigo</b> al</p>

      <p className={styles.phone}>
        <h2>800 466 3356</h2>
        <h3>lada sin costo</h3>
      </p>

      <p className={styles.phone}>
        <h2>55 9138 9990</h2>
        <h3>(CDMX)</h3>
      </p>

      <p className={styles.text_center}>
        Escucha nuestro menú y elige la opción
        correspondiente para proporcionarte
        la información que necesitas
      </p>
    </div>
  );
};

export default Disclaimer;
