import { useCallback, useEffect, useState } from "react";
import { getVarConfig } from "../../../utils/environment";
import fetchAPI from "../../../utils/fetchAPI";
import { getUser } from "../../../utils/sessionInfo";

const useUenPoints = () => {
  const [points, setPoints] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomerGoalPoints = useCallback(() => {
    const { ID: customer } = getUser();
    if (customer) {
      const urlService = getVarConfig({ key: "REACT_APP_SERVICES_GET_UENGOAL" }) || '';
      const service = urlService.replace('{customer}', customer)
      setIsLoading(true);
      fetchAPI({
        service,
        protocol: "GET",
        callbackThen: ({ data }) => {
          setPoints(data?.points);
          setIsLoading(false);
        },
        callbackCatch: err => {
          setIsLoading(false);
          throw new Error(err);
        },
      });
    }
  }, []);

  useEffect(() => fetchCustomerGoalPoints(), [fetchCustomerGoalPoints]);

  return {
    points,
    isLoading,
  }
}

export default useUenPoints;
