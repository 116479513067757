import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';

const MazTuInfoBeesIndicators = props => {
  const {
    indexActive,
    onClick,
    length,
    className,
  } = props;

  const [ steps, setSteps] = useState(undefined);

  const handleClick = index => {
    if (onClick) onClick(index);
  }

  useEffect(() => {
    if (!steps && !isNaN(length)) {
      const newSteps = new Array(length).fill(undefined);
      setSteps(newSteps);
    }
  }, [length, steps])

  return (
    <div
      className={`${styles.indicators} ${className}`}
    >
      {steps?.map((s, index) => (
        <div
          key={`indicator-${index}`}
          className={index === indexActive ? styles.active : ''}
          onClick={() => handleClick(index)}
        />
        ))}
    </div>
  );
};

export default MazTuInfoBeesIndicators;
