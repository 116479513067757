import React, { Component } from "react";

import './MazTuInfoBeesFooter.scss';

const pdfAvisoPrivacidad = require('./../../../assets/pdf/aviso-de-privacidad-tu-info-bees.pdf');
const pdfTerminosYCondiciones = require('./../../../assets/pdf/terminos-y-condiciones-tu-info-bees.pdf');

export default class MazTuInfoBeesFooter extends Component {
  render() {
    return (
      <div className="maz-tu-info-bees-footer">

        <div className="maz-tu-info-bees-footer__link">
          <a href={pdfTerminosYCondiciones} target={`_blank`}>Términos & Condiciones</a>
        </div>

        <div className="maz-tu-info-bees-footer__link">
          <a href={pdfAvisoPrivacidad} target={`_blank`}>Política de protección de datos personales</a>
        </div>

        <div className="maz-tu-info-bees-footer__link">
          <a href="https://www.tapintoyourbeer.com/es" target={`_blank`}>Hablemos del alcohol</a>
        </div>

        <div className="maz-tu-info-bees-footer__text">
          No compartas este contenido con menores de edad.<br />
          Tomar bebidas alcohólicas en exceso es dañino.
        </div>

        <div className="maz-tu-info-bees-footer__icons">
          <div className="maz-tu-info-bees-footer__icons__pregnant"></div>
          <div className="maz-tu-info-bees-footer__icons__driving"></div>
          <div className="maz-tu-info-bees-footer__icons__18"></div>
        </div>

        <div className="maz-tu-info-bees-footer__abinbev">
          <a href="https://www.ab-inbev.com/" target={"_blank"} rel="noreferrer">ANHEUSER-BUSCH INBEV ©2022</a>
        </div>
        
      </div>
    );
  }
}
