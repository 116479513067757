import React, { useState } from 'react';
import { Slider } from '@mui/material';
import './styles.scss';
import { useEffect } from 'react';

const MazTuInfoBeesSlider = props => {
  const { data, setData } = props;
  const { max, min, currentMin, currentMax } = data;
  const [value, setValue] = useState([]);

  const handleChange = (event, newValue) => {
    const [currentMin, currentMax] = newValue;

    setData({
      ...data,
      currentMin,
      currentMax,
    });

    setValue(newValue);
  };

  useEffect(() => {
    if (!value.length) {
      setValue([currentMin, currentMax]);
    }
  }, [currentMin, currentMax]);

  return (
    <Slider
      getAriaLabel={() => 'Temperature range'}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="auto"
      min={min}
      max={max}
    />
  );
};

export default MazTuInfoBeesSlider;
