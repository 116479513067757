import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../../components/global/Icon/Icon';
import { CSVLink } from "react-csv";

import BandButton from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/BandButton';
import ContractTable from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/ContractTable';
import Header from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

import fetchAPI from "./../../../utils/fetchAPI";
import { getVarConfig } from "./../../../utils/environment";
import { getContractSignStatus, getContractStatus } from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/ContractTable/utils"
const headers = [
  { label: "No de contrato", key: "trato_id" },
  { label: "Nombre del cliente", key: "name" },
  { label: "POC", key: "legalrep" },
  { label: "Id del cliente", key: "customer_no" },
  { label: "Estado de contrato", key: "statusTrato" },
  { label: "Estado de firma", key: "signStatus" },
  { label: "Iniciativa", key: "initiative" },
  { label: "Nivel", key: "level" },
  { label: "UEN", key: "uen" },
  { label: "SAG", key: "sag" },
  { label: "DRV", key: "drv" },
  { label: "Meta elegida", key: "goalSelected" },
];
const Contratos = props => {
  const {
    setLoading,
    loading,
    handleToast,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [allContracts, setAllContracts] = useState(undefined);
  const csvLinkRef = useRef(null);

  useEffect(() => {
    if (allContracts) csvLinkRef.current.link.click();
  }, [allContracts]);

  const getAllContracts = async() => {
    const service = getVarConfig({ key: "REACT_APP_ALL_CONTRACTS" }) + '/async';
    setLoading(true);
    await fetchAPI({
      service,
      protocol: "GET",
      params: {},
      callbackThen: resolveCallBack,
      callbackError: errorCallBAck,
    });
  };

  const resolveCallBack = (response) => {
    if (response.status === 'success') {
      const newContracts = response.data.contracts.map((item, index) => {
        return {
          ...item,
          statusTrato: getContractStatus(item.signStatus, item.statusTrato),
          signStatus: getContractSignStatus(item.signStatus, item.statusTrato),
        }
      });
      setAllContracts(newContracts);
      setLoading(false);
    }
  }

  const errorCallBAck = (e) => {
    if (e === 404) {
      handleToast({
        open: true,
        type: 'error',
        message: 'Archivo en construcción'
      });
    }

    setLoading(false);
  }

  const handleGetAllContracts = (event, done) => {
    if (!allContracts) {
      getAllContracts()
      .then(() => done(false))
      .catch(() => done(false));
    }else { // contracts already in state allContracts
      done();
      setAllContracts(undefined);
    }
  }

  return (
    <div className={styles.container}>
      <Header
        title="Contratos"
        userName="User Name"
        onClickBack={() => {}}
      />

      <div className={styles.body}>
        <div className={styles.body__padding}>
          <section>
            <div className={`${styles.searcher}`}>
              <input
                type="text"
                placeholder="Buscar"
                value={searchText}
                onChange={({target}) => setSearchText(target.value)}
              />
              <button>
                <Icon
                  icon="search"
                  width="14"
                  height="14"
                />
              </button>
            </div>
            <CSVLink
              ref={csvLinkRef}
              data={allContracts || []}
              headers={headers}
              filename={"contratos.csv"}
              target="_blank"
              asyncOnClick={true}
              onClick={handleGetAllContracts}
              className={`${styles.btn} ${styles['btn--black']}`}
            >
              <span
                className="textButtonExcel"
              >
                DESCARGAR EXCEL
              </span>
            </CSVLink>
          </section>

          <BandButton
            title="Contratos"
            color="yellow"
            colorIcon="yellow"
          />

          <ContractTable
            setLoading={setLoading}
            loading={loading}
            searchText={searchText}
          />

        </div>
      </div>
    </div>
  );
};

Contratos.propTypes = propTypes;
Contratos.defaultProps = defaultProps;

export default Contratos;
