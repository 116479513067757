import React, { useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { defaultProps, propTypes } from './types';
import './styles.scss';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const deletFigureTag = text => {
  return text.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
};

const TextEditor = props => {
  const {
    initialValue,
    onChange,
  } = props;

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(deletFigureTag(initialValue))
      )
  ));
  const editor = useRef(null);

  const handleChange = (editorState) => {
    setEditorState(editorState);
    if (onChange) {
      const currentContent = editorState.getCurrentContent();
      let newValue = '';
      if (currentContent.getPlainText()) {
        newValue = convertToHTML(currentContent);
        newValue = deletFigureTag(newValue);
      }
      onChange(newValue);
    }
  }

  return (
    <Editor
      ref={editor}
      editorState={editorState}
      onEditorStateChange={handleChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
        ]
      }}
    />
  );
};

TextEditor.prototype = propTypes;
TextEditor.defaultProps = defaultProps;

export default TextEditor;
