import React, { useMemo } from 'react';
import PointsRow from '../PointsRow';
import { challengeTypeLabel } from '../../../../utils/utils';
import '../MazTuInfoBeesCardPoints.scss';

const STATUS_PROPS = {
  'notAccepted': {
    label: 'No aceptado',
    cssTag: '--error',
  },
  'progress': {
    label: 'En progreso',
    cssTag: '--progress',
  },
  'completed': {
    label: 'Completado',
    cssTag: '--success',
  },
}

const ChallegesByStatus = (props) => {
  const { hidden, status, challenges = [], isUserBMax = false, billing = {} } = props;

  const groupByChallengeType = useMemo(() => {
    const groupedChallenges = challenges.reduce((group, item) => {
      const { challenge: { type: challengeType } } = item;
      group[challengeType] = group[challengeType] ? [...group[challengeType]] : [];
      group[challengeType].push(item);
      return group;
    }, {});

    const challengeTypes = Object.keys(groupedChallenges);

    // sum based on the challengeType array.
    challengeTypes.forEach((challengeType) => {
      const cursorChallenge = groupedChallenges[challengeType];
      const totalChallenges = cursorChallenge.length;
      const totalPoints = cursorChallenge.reduce((a, b) => (a + b.goalPoints), 0);
      groupedChallenges[challengeType].meta = {
        totalChallenges,
        totalPoints,
        title : challengeTypeLabel(challengeType),
      };
    });

    return {
      challengeTypes,
      groupedChallenges,
    };
  }, [challenges]);

  const billingCompleted = isUserBMax ? billing.goal <= billing.real : false;

  if (hidden || challenges.length === 0) return null;

  return (
    <div className="maz-tu-info-bees-card-points__detail">
      <div
        className={`maz-tu-info-bees-card-points__detail__status maz-tu-info-bees-card-points__detail__status${STATUS_PROPS[status]?.cssTag}`}
      >
        <ul><li>{STATUS_PROPS[status]?.label}</li></ul>
      </div>

    {groupByChallengeType.challengeTypes.map((challengeType) => {
      if (challengeType === 'marketpl' && !isUserBMax) return null;

      const { title, totalPoints } = groupByChallengeType.groupedChallenges[challengeType].meta;
      return (
        <PointsRow
          total={totalPoints}
          title={title}
        />
      );
    })}

    {(status === 'progress' && !billingCompleted && isUserBMax) ? (
      <PointsRow
        total={billing.goal}
        title={challengeTypeLabel('billing')}
      />
    ) : null}

    {(status === 'completed' && billingCompleted && isUserBMax) ? (
      <PointsRow
        total={billing.goal}
        title={challengeTypeLabel('billing')}
      />
    ) : null}

  </div>
  );
}

export default ChallegesByStatus;
