import React from 'react';
import styles from './styles.module.scss';
import { financeCurrencyFormat } from '../../../utils/utils'
import { getUser } from '../../../utils/sessionInfo';

const MazTuInfoBeesAccountCredit = (props) => {

  const {
    className,
    setPath,
    customerAccountState,
    customerPaymentCondition,
    setBackButton,
  } = props;

  const {
    payterms, //cómo se mapea a condiciones de pago?
    credit_limit, //Línea de crédito
    total_due, //monto a pagar
    available, //disponible
    due_ndays, //deuda a vencer
    due, //deuda vencida
    future, //deuda corriente
    cash_debt, //Deuda contado
    //¿adeudo por vencer?, ¿adeudo vencido?, ¿de contado?, ¿en crédito?
  } = customerAccountState || {};

  const percentile = credit_limit > 0 && total_due > 0 ? Math.ceil(parseFloat(total_due) <= parseFloat(credit_limit) ? total_due / credit_limit * 100 : 100) : 0;
  

  const available_checked = available > credit_limit ? credit_limit : available >= 0 ? available : 0;

  const goToAyuda = () => {
    props.setPath('/ayuda');
    props.setBackButton(true, goToCuentaEstadoCuenta);
  }

  const goToCuentaVirtual = () => {
    props.sendGA('GAEvent', 'Content', 'Content', 'How to pay', true, 'how_to_pay_button', '¿Cómo pagar?');
    props.setPath('/cuenta-virtual');
    props.setBackButton(true, goToCuentaEstadoCuenta);

  }

  const goToCuentaEstadoCuenta = () => {
    props.setPath('/estado-cuenta');
    props.setBackButton(false, null);
  }



  return (
    <div className={`col-12 col-lg-4 mt-2 mb-3 px-lg-0`}>
      <div className={`card ${className} ${styles.container}`}>
      <div className={styles.container__header}>
        <h2>Monto a pagar</h2>
        <h1>{financeCurrencyFormat(total_due && total_due > 0 ? total_due : '0.00')}</h1>
        <h2>{`Condiciones de pago: ${customerPaymentCondition ? customerPaymentCondition : 'Pago de contado'}`}</h2>
      </div>

      {/* {currentDebit > 0 ? ( */}
      {customerAccountState && credit_limit > 0 ? (
        <div className={`container`}>

          <div>
            <span
              className={styles.container__body__row}
            >
              <h3>De contado</h3>
              <h2>{financeCurrencyFormat(cash_debt)}</h2>
            </span>

            <div className={styles['container__body__row--group']}>
              <span
                className={styles.container__body__row}
              >
                <h3>En crédito</h3>
                <h2>{financeCurrencyFormat(total_due)}</h2>
              </span>

              <span
                className={styles.container__body__row}
              >
                <h3>Adeudo corriente</h3>
                <h2>{financeCurrencyFormat(future)}</h2>
              </span>

              <span
                className={`${styles['container__body__row--yellow']} ${styles.container__body__row}`}
              >
                <h3>Adeudo por vencer</h3>
                <h2>{financeCurrencyFormat(due_ndays)}</h2>
              </span>

              <span
                className={`${styles['container__body__row--red']} ${styles.container__body__row}`}
              >
                <h3>Adeudo vencido</h3>
                <h2>{financeCurrencyFormat(due)}</h2>
              </span>
            </div>
          </div>
          <div className={styles.creditCard}>
            <h3>Mi crédito</h3>
            <div className={styles.progress}>
              <div
                className={`${styles.progress__bar__yellow} ${styles[`progress__bar__${percentile}`]}`}>
              </div>
              <span>
                <h1>
                  {financeCurrencyFormat(total_due >= 0 ? total_due : 0)}
                </h1>
                <h1>
                  {financeCurrencyFormat(available_checked)}
                </h1>
              </span>
              <span>
                <h5>Utilizado</h5>
                <h5>Disponible</h5>
              </span>
            </div>
            <span>
              <h1>Línea de crédito: {financeCurrencyFormat(credit_limit)}</h1>
            </span>
            <span>
              <h1>Condiciones de pago: {customerPaymentCondition ? customerPaymentCondition : 'Pago de contado'}</h1>
            </span>
          </div>
        </div>
      ) : null}
      <div className={styles.container__warning}>
        <span>Tus movimientos pueden tardar hasta 72 horas en verse reflejados en tu estado de cuenta.</span>
      {
        getUser().ID.charAt(0) != 1 ? (          
          <span class="d-block">Los datos representados aquí pertenecen a la matriz</span>
        ):null
      }
      </div>
      <div className={styles.container__footer}>
        <button
          className={`${styles.btn__payment} ${styles.btn}`}
          onClick={goToCuentaVirtual}
        >¿Cómo Pagar?</button>
        <button
          className={`${styles.btn__help} ${styles.btn}`}
          onClick={goToAyuda}
        >¿Necesitas ayuda?</button>
      </div>
    </div>
    </div>
  );
}

export default MazTuInfoBeesAccountCredit;
