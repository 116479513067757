import React, { Component } from "react";
import { getVarConfig } from "../../../utils/environment";
import "./MazTuInfoBeesResume.scss";

export default class MazTuInfoBeesResume extends Component {

  calcFontSizeClass = (str = '') => {
    str = str ? `${str}`.trim() : '';
    const lengthStr = str.length;
    const lengthNumber = lengthStr < 7 ? lengthStr : 7;
    switch(lengthNumber) {
      case 5:
        return 'font-small--1';
      case 6:
        return 'font-small--2';
      case 7:
        return 'font-small--3';
      default: return '';
    }
  }

  render() {

    const nameVolumenTotal = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_VOLUMEN_TOTAL" });
    const nameMarcasPremium = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_MARCAS_PREMIUM" });
    const nameCobertura = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA" });

    const performance = this.props.performance || {};

    return (
      <div className="maz-tu-info-bees-resume">

        <div className="maz-tu-info-bees-resume__nivel">
          {this.props.nivel}
        </div>

        <div className="maz-tu-info-bees-resume__info">

          <div className="maz-tu-info-bees-resume__info__row">

            <div className="maz-tu-info-bees-resume__info__row__cell">

              <div className="maz-tu-info-bees-resume__info__row__cell__title font-small-1">{nameVolumenTotal}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__value">{performance.total?.goal?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">CAJAS</div>

            </div>

            <div className="maz-tu-info-bees-resume__info__row__cell maz-tu-info-bees-resume__info__row__cell--right">

              <div className={`maz-tu-info-bees-resume__info__row__cell__value ${this.calcFontSizeClass(performance.total?.points)}`}>{performance.total?.points?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">PUNTOS</div>

            </div>

          </div>

          <div className="maz-tu-info-bees-resume__info__row maz-tu-info-bees-resume__info__row--2">

            <div className="maz-tu-info-bees-resume__info__row__cell">

            <div className="maz-tu-info-bees-resume__info__row__cell__title">{nameMarcasPremium}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__value">{performance.premium?.goal?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">CAJAS</div>

            </div>

            <div className="maz-tu-info-bees-resume__info__row__cell maz-tu-info-bees-resume__info__row__cell--right">

              <div className={`maz-tu-info-bees-resume__info__row__cell__value ${this.calcFontSizeClass(performance.premium?.points)}`}>{performance.premium?.points?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">PUNTOS</div>

            </div>

          </div>

          <div className="maz-tu-info-bees-resume__info__row maz-tu-info-bees-resume__info__row--3">

            <div className="maz-tu-info-bees-resume__info__row__cell">

            <div className="maz-tu-info-bees-resume__info__row__cell__title">{nameCobertura}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__value">{performance.coverage?.goal?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">CAJAS</div>

            </div>

            <div className="maz-tu-info-bees-resume__info__row__cell maz-tu-info-bees-resume__info__row__cell--right">

              <div className={`maz-tu-info-bees-resume__info__row__cell__value ${this.calcFontSizeClass(performance.coverage?.points)}`}>{performance.coverage?.points?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">PUNTOS</div>

            </div>

          </div>

          <div className="maz-tu-info-bees-resume__info__row maz-tu-info-bees-resume__info__row--3">

            <div className="maz-tu-info-bees-resume__info__row__cell">

            <div className="maz-tu-info-bees-resume__info__row__cell__title">Retornable</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__value">{performance.returnable?.goal?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">CAJAS</div>

            </div>

            <div className="maz-tu-info-bees-resume__info__row__cell maz-tu-info-bees-resume__info__row__cell--right">

              <div className={`maz-tu-info-bees-resume__info__row__cell__value ${this.calcFontSizeClass(performance.returnable?.points)}`}>{performance.returnable?.points?.toLocaleString()}</div>

              <div className="maz-tu-info-bees-resume__info__row__cell__description">PUNTOS</div>

            </div>

          </div>

        </div>

      </div>
    );
  }
}
