import React, { useState } from 'react';
import BandButton from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/BandButton';
import Header from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header';
import TermsForm from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/TermsForm';
import TermsTable from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/TermsTable';
import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const Terms = props => {
  const {
    setLoading,
    loading,
    handleToast,
  } = props;

  const [showForm, setShowForm] = useState(false);
  const [currentTerms, setCurrentTerms] = useState(undefined);

  const cancelForm = () => {
    setCurrentTerms(undefined);
    setShowForm(false);
  };

  const handleEdit = terms => {
    setShowForm(true);
    setCurrentTerms(terms);
  }

  const handleSubmit = isEdit => {
    const message = isEdit ? 'Representante legal editado correctamente' : 'Representante legal agregado correctamente';
    cancelForm();
    handleToast({
      open: true,
      message,
      type: 'success',
    });
  }

  return (
    <div className={styles.container}>
      <Header
        title="Términos y condiciones"
        userName="User Name"
        onClickBack={() => setShowForm(false)}
        back={showForm}
      />

      <div className={styles.body}>
        {!showForm ? (
          <div className={styles.body__padding}>
            <section>
              <button
                className={`${styles.btn} ${styles['btn--black']}`}
                onClick={() => setShowForm(true)}
              >
                Agregar términos
              </button>
            </section>
            <BandButton
              title="Brochure, términos y condiciones"
              color="yellow"
              colorIcon="black"
            />

          <TermsTable
            onClickEdit={handleEdit}
            setLoading={setLoading}
            loading={loading}
          />

          </div>
        ) : null}
      {showForm ? (
        <TermsForm
          terms={currentTerms}
          loading={loading}
          setLoading={setLoading}
          onCancel={cancelForm}
          onSubmit={() => handleSubmit(!!currentTerms)}
        />
      ) : null}
      </div>
    </div>
  );
};

Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;

export default Terms;


