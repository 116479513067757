import React from 'react';
import MenuItem from './MenuItem';
import styles from './styles.module.scss';
import SubmenuItem from './SubmenuItem';
import { useMsal } from "@azure/msal-react";
import { defaultProps, propTypes } from './types';

function handleLogout(instance) {
  instance.logoutRedirect().catch(e => {
      console.error(e);
  });
}

const Menu = props => {

  const { instance } = useMsal();

  const { menuItems, onClickItem } = props;

  return (
    <div className={styles.container}>
      <div className={styles.container__body}>
        <div className={styles.container__bees_icon} />
          <ul>
            {menuItems
              .map((item) => {
                const { title, icon, active, submenu } = item;
                if (submenu) {
                  return (
                    <SubmenuItem
                      item={item}
                      onClick={onClickItem}
                    />
                  )
                }

                return (
                  <MenuItem
                    key={title}
                    title={title}
                    icon={icon}
                    onClick={onClickItem}
                    active={active}
                  />
                );
              })
            }
          </ul>
        </div>

      <div className={styles.container__footer} >
        <MenuItem
          title="logout"
          icon="logout"
          onClick={() => handleLogout(instance)}
        />
      </div>
    </div>
  );
};

Menu.prototype = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
