import React, { useState } from "react";

import BandButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/BandButton";
import Header from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header";
import ProgramForm from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/ProgramForm";
import ProgramsTable from "../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/ProgramsTable";

import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const Programs = props => {
  const { setLoading, loading, handleToast } = props;
  const [currentProgram, setCurrentProgram] = useState(undefined);

  const handleSubmit = () => {
    setCurrentProgram(undefined);
    handleToast({
      open: true,
      message: 'Programa editado exitosamente',
      type: 'success',
    });
  }

  return (
    <div className={styles.container}>
      <Header
        title="Programas de mercado"
        back={currentProgram}
        onClickBack={() => setCurrentProgram(undefined)}
      />
      <div className={styles.container__body}>
        {!!!currentProgram && (
          <div className={styles.container__body__padding}>
            <BandButton
              title="Programas"
              color="yellow"
              colorIcon="black"
            />

            <ProgramsTable
              onClickEdit={setCurrentProgram}
              setLoading={setLoading}
              loading={loading}
            />
          </div>
        )}

        {!!currentProgram && (
          <ProgramForm
            program={currentProgram}
            loading={loading}
            setLoading={setLoading}
            onCancel={() => setCurrentProgram(undefined)}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

Programs.propTypes = propTypes;
Programs.defaultProps = defaultProps;

export default Programs;
