import { useCallback, useEffect, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";
import useTable from "../../../../utils/hooks/useTable";

const useProgramsTable = () => {
  const {
    setList,
    list,
    sortByKey,
    sortDescStates,
  } = useTable();

  const [isLoading, setIsLoading] = useState(false);

  const getAllPrograms = useCallback(() => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_GET_ADMIN_PROGRAMS" });
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        setList(data || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setList]);

  useEffect(() => getAllPrograms(), [getAllPrograms])

  return {
    programsList: list,
    setProgramsList: setList,
    isLoading,
    getAllPrograms,
    sortByKey,
    sortDescStates,
  };
};

export default useProgramsTable;
