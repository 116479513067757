import React, { Component } from "react";

import "./MazTuInfoBeesCardUpload.scss";

export default class MazTuInfoBeesCardUpload extends Component {

  state = {
    files: [],
    status: "",
    label: "",
    isEdit: false
  }

  componentDidMount() {
    this.setState({
      label: typeof this.props?.label !== "undefined" ? this.props?.label : "label",
    });
  }

  componentDidUpdate(prevProps) {
    const { status: prevStatus } = prevProps;
    const { status } = this.props;
    if (status === 'error' && status !== prevStatus) this.resetsFileInput();
  }

  resetsFileInput = () => {
    this.setState({ files: [], status: '' });
    document.getElementById("input-file-pdf-" + this.props.field.field).value = '';
  }

  getFiles = (files) => {
    if(typeof this.props.setSuccess !== "undefined") {
      this.props.setSuccess(files, this.state.isEdit);
    }
    this.setState({
      files: files,
      status: "selected"
    });
  }

  getFilesInput = (e) => {
    this.getFiles(Array.from(e.target.files));
  }

  handleFile = (status) => {
    if (status === 'validating') return;
    const isEdit = status === "error" || status === "pending" ? false : true;
    this.setState({ isEdit });
    document.getElementById("input-file-pdf-" + this.props.field.field).click();
  }

  render() {

    let status = "pending";

    if(typeof this.props.status !== "undefined") {
      if(this.props.status === "success" || this.props.status === "error" || this.props.status === "validating") {
        status = this.props.status === "success" ? this.props.status : this.props.status === "error" ? this.props.status : "validating";
      } else {
        status = this.state.status !== "" ? this.state.status : "pending";
      }
    } else {
      status = "pending";
    }

    let field;

    try {
      field = this.props.field.field;
    } catch(e) {
      field = "";
    }

    return (

      <div className="maz-tu-info-bees-card-upload" onClick={() => this.handleFile(status)}>

        <div className="maz-tu-info-bees-card-upload__label">
          <span className={"maz-tu-info-bees-card-upload__label__status maz-tu-info-bees-card-upload__label__status--" + status}></span>
          <span className="maz-tu-info-bees-card-upload__label__text">
            {typeof this.props.field.label !== "undefined" ? this.props.field.label : this.state.label}
          </span>
        </div>

        <div className="maz-tu-info-bees-card-upload__actions">

          {
            status !== "" ?
              <>

                {
                  status === "error" || status === "pending" ?
                    <button
                      className="maz-tu-info-bees-card-upload__actions__upload-document"
                      data-edit="add"
                      ></button>
                    : status !== "validating" ?
                      <button
                        className="maz-tu-info-bees-card-upload__actions__edit-document"
                        data-edit="edit"
                        ></button> : null
                }

              </> : null
          }

        </div>

        <input type="file" id={"input-file-pdf-" + field} onChange={this.getFilesInput} style={{ display: "none" }} />

      </div>

    );
  }
}
