import React, {useState} from "react"
import "./styles.scss";


// images
import iconExclamation from '../../../assets/icons/icon-exclamation.png';
import infoBeesHeader from '../../../assets/icons/Logo_InfoBEES.png';
import iconBee from '../../../assets/icons/icon-bee.png';
function InApp({vissible, onClose, inApp}){

  if (!vissible) return null;

  return(
    <div
      className="containerAppInApp"
    >
      <div
        className="block"
      />
      <div
        className="brochuereInApp"
      >
        <div className="appInAppHeader">
          <div className="appInAppLeft">
            <img
              src={infoBeesHeader}
              width="222"
              height="48"
            />            
          </div>
          <div className="appInAppRight">
            <div
              onClick={onClose}
              className="closeButtonContainer"
            >
              <img 
                src={require('../../../assets/icons/icon-close-black.png')}
                width="14"
                height="14"
              /> 
            </div> 
          </div>
        </div>
        <div
          className={"appInAppBody"}
        >
          <div className="elementRow">
            <div className="appInAppTopNotif">
              <h3
                className="notifTittle"
              >
                {inApp.content}
              </h3>
            </div> 
          </div>
          <div className="elementRow">
            <div className="appImagePreviewInApp">
              <img
                crossorigin="anonymous"
                className="appInAppImage"
                src={inApp.image}
                alt={"imageInApp"}
              />
            </div>
          </div>
        </div>
        <div className="appInAppPreviewFooter">
          <div className="inAppLeftPreviewInApp">
            <img
              src={iconBee}
              width="40"
              height="32"
            /> 
          </div>
          <div className="inAppRigthPreviewInApp">
            <div
              className="dialogGlowInAppPreview"
            >
              <p>
                {inApp.description}
              </p>
              <div
                className="triangleDialog" 
              />
            </div> 
          </div>
        </div>
      </div>
    </div>
    )
}

export default InApp
