import React, { useEffect } from 'react';

import styles from './styles.module.scss';
import useLegalRepresentativeForm from './useLegalRepresentativeForm';
import { defaultProps, propTypes } from './types';
import ConfirmDialog from '../ProgramForm/ConfirmDialog';
import { removeLettersFronString, removeNumbersFronString } from '../../../../utils/utils';
import { TYPE_REPRESENTATIVE_OPTIONS } from '../../../../pages/Admin/LegalRepresentative/utils';

const LegalRepresentativeForm = props => {
  const {
    legalRepresentative,
    onCancel,
    loading,
    setLoading,
  } = props;

  const {
    validForm,
    handleSubmit,
    openConfirm,
    setOpenConfirm,
    isLoading,
    legalRepresentativeData,
    onChangeValue,
    uenList,
  } = useLegalRepresentativeForm(props);

  const title = legalRepresentative ? 'Editar representante legal' : 'Nuevo representante legal';
  const confimrTitle = legalRepresentative ? '' : '¿Desea guardar un nuevo representante legal?';

  useEffect(() => {
    if (isLoading && !loading) setLoading(true);
  }, [isLoading, loading, setLoading]);

  useEffect(() => {
    if (!isLoading && loading) setLoading(false);
  }, [isLoading, loading, setLoading]);

  return (
    <div className={styles.container}>
      <ConfirmDialog
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={handleSubmit}
        text={confimrTitle}
      />

      <div className={styles.form}>
        <h1>{title}</h1>

        <section>
          <div className={styles.form__control}>
            <h2>Nombre <span>*</span></h2>
            <input
              type="text"
              value={legalRepresentativeData?.name}
              onChange={({ target }) => onChangeValue(removeNumbersFronString(target.value), 'name')}
            />
          </div>

          <div className={styles.form__control}>
            <h2>Correo <span>*</span></h2>
            <input
              type="email"
              value={legalRepresentativeData?.email}
              onChange={({ target }) => onChangeValue(target.value, 'email')}
            />
          </div>

          <div className={styles.form__control}>
            <h2>Teléfono <span>*</span></h2>
            <input
              type="text"
              maxLength={10}
              value={legalRepresentativeData?.phone}
              onChange={({ target }) => onChangeValue(removeLettersFronString(target.value), 'phone')}
            />
          </div>

          <div className={styles.form__control}>
            <h2>RFC</h2>
            <input
              type="text"
              value={legalRepresentativeData?.rfc}
              onChange={({ target }) => onChangeValue(target.value, 'rfc')}
            />
          </div>

          <div className={styles.form__control}>
            <h2>UEN asignada <span>*</span></h2>
            <select
              placeholder=''
              value={legalRepresentativeData?.uen}
              onChange={({ target }) => onChangeValue(target.value, 'uen')}
            >
              <option value="">Selecciona una UEN</option>
              {uenList?.map(({ uen }) => (
                <option
                  key={uen}
                  value={uen}
                >
                  {uen}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.form__control}>
            <h2>Tipo de representante<span>*</span></h2>
            <select
              placeholder=''
              value={legalRepresentativeData?.typeRepresentative}
              onChange={({ target }) => onChangeValue(target.value, 'typeRepresentative')}
            >
              <option value="">Selecciona un tipo</option>
              {TYPE_REPRESENTATIVE_OPTIONS?.map(({ text, value }) => (
                <option
                  key={value}
                  value={value}
                >
                  {text}
                </option>
              ))}
            </select>
          </div>
        </section>
      </div>
      <div className={styles.footer}>
        <button
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancelar
        </button>

        <button
          className={styles.save}
          disabled={isLoading || !validForm}
          onClick={() => setOpenConfirm(true)}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

LegalRepresentativeForm.prototype = propTypes;
LegalRepresentativeForm.defaultProps = defaultProps;

export default LegalRepresentativeForm;
