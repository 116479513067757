import React, { Component } from "react";

import "./MazTuInfoBeesInput.scss";

export default class MazTuInfoBeesInput extends Component {

  state = {
    classHidePhone: "",
    type: "text",
    startType: "text",
    dataOnlyText: false,
  }

  componentDidMount() {
    const hidePhone = typeof this.props.hidePhone !== "undefined" ? this.props.hidePhone : false;
    const classHidePhone = hidePhone ? " hide-phone" : "";
    let type =
      typeof this.props.type !== "undefined" ? this.props.type : "text";
    let dataOnlyText = this.state.dataOnlyText;
    if(type === "onlyText") {
      type = "text";
      dataOnlyText = true;
    }
    this.setState({
      classHidePhone,
      type,
      startType: type,
      dataOnlyText
    });
  }

  handleHidePhone = () => {
    const classHide = this.state.classHidePhone === " hide-phone" ? " show-phone" : " hide-phone";
    const typeHide = this.state.classHidePhone === " hide-phone" ? "password" : this.state.startType;
    this.setState({
      classHidePhone: classHide,
      type: typeHide
    });
  }

  setValueSelect = (e) => {
    const setValue = typeof this.props.setValue !== "undefined" ? this.props.setValue : null;
    const element = e.target;
    if(setValue !== null) {
      setValue({
        value: element.value,
        text: element.options[element.selectedIndex].text
      });
    }
  }

  setValueInput = (e) => {
    const setValue = typeof this.props.setValue !== "undefined" ? this.props.setValue : null;
    if(setValue !== null) setValue(e.target.value);
  }

  render() {

    let type =
      typeof this.props.type !== "undefined" ? this.props.type : "text";
    const id =
      typeof this.props.id !== "undefined" ? this.props.id : "";
    const value =
      typeof this.props.value !== "undefined" ? this.props.value : "";
    const textLabel =
      typeof this.props.textLabel !== "undefined" ? this.props.textLabel : "";
    const textPlaceHolder =
      typeof this.props.textPlaceHolder !== "undefined"
        ? this.props.textPlaceHolder
        : "";
    const disabled =
      typeof this.props.disabled !== "undefined" ? this.props.disabled : false;
    const textHelperLeft =
      typeof this.props.textHelperLeft !== "undefined"
        ? this.props.textHelperLeft
        : '';
    const textHelperRight =
      typeof this.props.textHelperRight !== "undefined"
        ? this.props.textHelperRight
        : '';
    const success =
      typeof this.props.success !== "undefined" ? this.props.success : false;
    const error =
      typeof this.props.error !== "undefined" ? this.props.error : false;
    const status = success ? " success" : error ? " error" : '';
    const hidePhone = typeof this.props.hidePhone !== "undefined" ? this.props.hidePhone : false;
    const options = typeof this.props.options !== "undefined" ? this.props.options : [];

    const length =
      typeof this.props.length !== "undefined" ? this.props.length : null;

    let maxLength =
      typeof this.props.maxLength !== "undefined" ? this.props.maxLength : null;

    if(length !== null) {
      maxLength = length;
    }

    return (
      <div className="maz-tu-info-bees-input">
        {textLabel !== "" ? (
          <label className={`maz-tu-info-bees-input__label` + status}>
            {textLabel}
          </label>
        ) : null}

        {
          type === "text" ||
          type === "number" ||
          type === "onlyText" ||
          type === "email" ?
            <input
              id={id}
              className={`maz-tu-info-bees-input__input` + status + this.state.classHidePhone}
              type={this.state.type}
              placeholder={textPlaceHolder}
              disabled={disabled}
              data-only-text={this.state.dataOnlyText}
              data-length={length}
              maxLength={maxLength}
              onKeyUp={this.setValueInput}
            /> : null
        }

        {
          type === "select" ?
            <div className="maz-tu-info-bees-input__select">
              <select onChange={this.setValueSelect}>
                <option value="0">Seleccione una opción</option>
                {
                  options.map((option, index) => (
                    <option value={option.key}>{option.value}</option>
                  ))
                }
              </select>
            </div>
            : null
        }
        
        {hidePhone ? 
          <div 
            className="maz-tu-info-bees-input__click-hide-phone" 
            onClick={this.handleHidePhone}></div> : null
        }
        {(textHelperLeft !== '' || textHelperRight !== '') ?
          <div className={`maz-tu-info-bees-input__helpers`}>
            <label className={`maz-tu-info-bees-input__label helper-left` + status}>
              {textHelperLeft}
            </label>
            {textHelperRight !== ''?
            <label className={`maz-tu-info-bees-input__label helper-right` + status}>
              {textHelperRight}
            </label>:null}
          </div> : null
        }
        
      </div>
    );
  }
}
