import { useEffect, useMemo, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";

const useProgramForm = props => {
  const {
    program,
    onSubmit,
  } = props;
  const [editedProgram, setEditedProgram] = useState(undefined);
  const [showErrors, setShowErrors] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const requireFields = useMemo(() => [
    'termSheet',
    'privacyDocument',
    'brochure',
  ], []);

  /* Checking if the form is valid. */
  const validForm = useMemo(() => !!requireFields
    .reduce((total, field) => {
      if (editedProgram) {
        let hasValue = !!editedProgram[field];
        // if there isn't brochure take value from file
        if (field === 'brochure' && !hasValue) hasValue = !!file;
        return total * hasValue;
      }
      return false;
    }, true), [editedProgram, file, requireFields]);

  const updateProgram = () => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_PUT_ADMIN_PROGRAMS" });
    const customParams = new FormData();
    customParams.append('data', JSON.stringify(editedProgram));
    customParams.append('file', file);

    setIsLoading(true);

    fetchAPI({
      noContentType: true,
      customParams,
      service,
      protocol: "PUT",
      callbackThen: () => {
        setIsLoading(false);
        if (onSubmit) onSubmit(editedProgram);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }

  const handleSubmit = () => {
    setOpenConfirm(false);
    updateProgram();
  }

  const setTermSheet = termSheet => {
    termSheet = encodeURIComponent(termSheet);
    setEditedProgram({
      ...editedProgram,
      termSheet,
    })
  }

  const setPrivacyDocument = privacyDocument => {
    privacyDocument = encodeURIComponent(privacyDocument);
    setEditedProgram({
      ...editedProgram,
      privacyDocument,
    })
  }

  const setNewBrochure = brochure => {
    setEditedProgram({
      ...editedProgram,
      brochure,
    })
  }

  useEffect(() => {
    if (program && !editedProgram) {
      setEditedProgram({ ...program });
    }
  }, [editedProgram, program]);

  return {
    editedProgram,
    setNewBrochure,
    setTermSheet,
    setPrivacyDocument,
    showErrors,
    validForm,
    handleSubmit,
    openConfirm,
    setOpenConfirm,
    isLoading,
    setFile,
    file,
  }
}

export default useProgramForm;
