import { useRadioGroup } from '@mui/material';
import React, { useMemo } from 'react';
import { financeCurrencyFormat, formatDateDDMMYYYYFinance } from '../../../utils/utils';
import styles from './styles.module.scss';

const MazTuInfoBeesOrder = props => {
  const { data, user } = props;
  const nameClient = user.name;
  const addressClient = data.orderDetails? data.orderDetails[0]?.delivery_address:'No hay dirección asociada al pedido';

  const {
    orderNo,
    order_date,
    income_before_taxes,
    order_tax,
    order_total,
    orderDetails,
  } = data;

  const items = orderDetails?orderDetails:null;

  return (
    <div className={styles.details}>
      <section>
        <h3>Número de pedido:</h3>
        <h4>{orderNo}</h4>
      </section>

      <section>
        <h3>Ordenado el:</h3>
        <h4>{formatDateDDMMYYYYFinance(order_date)}</h4>
      </section>

      {items?.map((item) => {
        const {
          material_denomination,
          description,
          unit_price,
          quantity,
          canceled_quantity,
          total_price,
        } = item;

        const highlightClass = unit_price > 0 ? styles.green : '';
        return (
          <div className={styles.row}>
            <h1>{material_denomination}</h1>
            <h2>{description}</h2>
            <div className={styles.row__quantities}>
              <span>
                <h2 className={highlightClass}>
                  {financeCurrencyFormat(unit_price)}/Unidad
                </h2>
              </span>
              <div>
                <h2>x{quantity}</h2>
              </div>
              <h2 className={highlightClass}>{financeCurrencyFormat(total_price)}</h2>
            </div>
          </div>
        );
      })}

      <div className={styles.totals}>
        <p>
          <h2>Subtotal</h2>
          {income_before_taxes && <h2>{financeCurrencyFormat(income_before_taxes)}</h2>}
        </p>
        <p>
          <h2>Impuestos</h2>
          {order_tax && <h2>{financeCurrencyFormat(order_tax)}</h2>}
        </p>
        <p>
          <h1>Total</h1>
          {order_total && <h2>{financeCurrencyFormat(order_total)}</h2>}
        </p>
      </div>

      <div className={styles.infoClient}>
        <h2>Dirección de entrega</h2>
        <h1>{nameClient}</h1>
        <h3>{addressClient?addressClient:'No hay dirección asociada al pedido'}</h3>
      </div>
    </div>
  );
};

export default MazTuInfoBeesOrder;