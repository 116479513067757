import React, { Component } from "react";

import "./Icon.scss";

export default class Icon extends Component {

  handleClick = () => {
    if(typeof this.props.handleClick !== "undefined") {
      this.props.handleClick();
    } else {
      console.log('No está definido el click del Icono');
    }
  }

  render() {
    const icon = typeof this.props.icon !== "undefined" ? this.props.icon : "";
    const width =
      typeof this.props.width !== "undefined" ? this.props.width : "26";
    const height =
      typeof this.props.height !== "undefined" ? this.props.height : this.props.width;
    
    const disabled =
      typeof this.props.disabled !== "undefined"
        ? this.props.disabled
          ? true
          : false
        : false;

    //document.documentElement.style.setProperty("--icon-size", size + "px");
    const styles = {
      width: width + "px",
      height: height + "px",
      backgroundSize: "contain",
    };

    return (
      <span
        style={styles}
        className={`icon icon-` + (disabled ? icon + "-disabled" : icon)}
        onClick={this.handleClick}
      ></span>
    );
  }
}
