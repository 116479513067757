import React from "react"
import MazTuInfoBeesButton from "../maz-tu-info-bees-button/MazTuInfoBeesButton";
import './modal.scss';
const ModalInfoBees = ({
  isVissible,
  logoClass,
  onClick,
  title,
  Body,
  textButton,
  buttonType = 'primary',
  backgroundStyle = {},
  titleStyle = {},
  Logo,
}) => {
  if (!isVissible) return null;
  return(
    <div className="containerbMaxModal">
      <div
        className="blockBMax"
      />
      <div
        className="modalDetails"
        style={backgroundStyle}
      >
        <div className="modalHeaderBMax">
          <div
            className={logoClass}
          >
            <Logo/>
          </div>
        </div>
        <p
          style={titleStyle}
        >
          <strong>
            {title}
          </strong>
        </p>
        <Body/>
        <MazTuInfoBeesButton
          type={buttonType}
          handleClick={onClick}
          textButton={textButton}
        />
      </div> 
    </div>
  )
}

export default ModalInfoBees
