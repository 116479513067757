import { useCallback, useEffect, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";
import useTable from "../../../../utils/hooks/useTable";

const useLegalRepresentativeTable = () => {
  const {
    setList,
    list,
    sortByKey,
    sortDescStates,
  } = useTable();
  const [isLoading, setIsLoading] = useState(false);

  const getAllChallenges = useCallback(() => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_LEGAL_REPRESENTATIVE" });
    setIsLoading(true);
    fetchAPI({
      service,
      protocol: "GET",
      callbackThen: ({ data }) => {
        setList(data || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setList]);

  useEffect(() => getAllChallenges(), [getAllChallenges])

  const deleteLegalRepresentative = (legalRepresentativeData) => {
     const service = getVarConfig({ key: "REACT_APP_SERVICES_LEGAL_REPRESENTATIVE" });
    const customHeaders = {
      "Content-Type": "application/json",
    };

    setIsLoading(true);
    fetchAPI({
      params: legalRepresentativeData,
      service,
      protocol: "DELETE",
      customHeaders,
      callbackThen: () => {
        setIsLoading(false);
        getAllChallenges();
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }

  return {
    list,
    setList,
    isLoading,
    getAllChallenges,
    sortByKey,
    sortDescStates,
    deleteLegalRepresentative,
  };
};

export default useLegalRepresentativeTable;
