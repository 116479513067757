import fetchAPI from "../../../utils/fetchAPI";

const useUploadFile = (props) => {
  const { newCustumers, handleToast, setLoading, refreshData } = props;

  const submitFile = (file) => {
    return new Promise((resolve, reject) => {
      try {
        if (file && file.type === 'text/csv') {
          const service = `v1/mx/massive_load/${ newCustumers ? 'new' : 'upgrade'}/bmax`;
          const customParams = new FormData();
          customParams.append('file', file);
          setLoading(true);
          fetchAPI({
            customParams,
            service,
            protocol: "POST",
            noContentType: true,
            callbackThen: (responseCB) => {
              setLoading(false);
              if (responseCB === 500) {
                handleToast({
                  open: true,
                  message: 'Hubo un problema al cargar el archivo',
                  type: 'error',
                });
                reject(responseCB);
              } else {
                handleToast({
                  open: true,
                  message: 'ok',
                  type: 'success',
                });
                if (refreshData) refreshData();
                resolve(true);
              }
            },
            callbackCatch: err => {
              setLoading(false);
              reject(err);
            },
          });
        } else {
          setLoading(false);
          handleToast({
            open: true,
            message: 'Tipo de archivo inválido',
            type: 'error',
          });
          resolve(true);
        }

      } catch (error) {
        reject(error);
      }
    });
  }

  return {
    submitFile,
  };
};

export default useUploadFile;
