import { CircularProgress } from "@mui/material";
import { Component } from "react";
import { isMobile } from "react-device-detect";
import Icon from "../../components/global/Icon/Icon";
import MazTuInfoBeesAccountFomento from "../../components/maz-tu-info-bees/maz-tu-info-bees-resume-fomento";
import MazTuInfoBeesAccountFomentoInvoices from "../../components/maz-tu-info-bees/maz-tu-info-bees-resume-fomento/invoices";
import { getUser } from "../../utils/sessionInfo";

import styles from './styles.module.scss';


export default class Fomentos extends Component {

  constructor() {

    super();
    this.state = {
      periodoactual: true,
    }
  }

  monthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  goToAyuda = () => {
    this.props.setPath('/ayuda');
    this.props.setBackButton(true, this.goToCuentaFomento);
  }

  goToCuentaFomento = () => {
    this.props.setPath('/fomentos');
    this.props.setBackButton(false, this.goToCuentaFomento);
  }

  gotToPeriodoAnterior = () => {
    this.state.periodoactual = this.state.periodoactual ? false : true;
    this.forceUpdate()
  }

  render() {

    /*const percentile = credit_limit > 0 && total_due > 0 ? Math.ceil(parseFloat(total_due) <= parseFloat(credit_limit) ? total_due / credit_limit * 100 : 100) : 0;*/
    const user = this.props.user;


    const d = new Date();
    if (this.state.periodoactual === false)
      d.setMonth(d.getMonth() - 1);
    const days = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    const percentile = this.state.periodoactual ? Math.min(100, parseInt((d.getDate() * 100) / days)) : 100;
    const monthly_total_volume_progress = Math.round((this.state.periodoactual ? parseFloat(user.promotions.monthly_total_volume_progress) : parseFloat(user.promotions.last_monthly_total_volume_progress)) * 100) / 100;

    const ventaMensualVol = Math.round(parseFloat(user.promotions.monthly_total_volume_goal) * 100) / 100;
    const ventaTotalVol = Math.round(parseFloat(user.promotions.total_volume_goal) * 100) / 100;
    const total_volume_progress = Math.round((this.state.periodoactual ? parseFloat(user.promotions.total_volume_progress) : parseFloat(user.promotions.last_total_volume_progress)) * 100) / 100;
    const accumulated_porcentile = parseInt((parseFloat(total_volume_progress) * 100) / ventaTotalVol);

    const MonthlyPercent = Math.min(100, Math.round(((parseFloat(monthly_total_volume_progress) * 100) / ventaMensualVol) * 100) / 100);
    const percentileVol2 = parseInt(208 - ((MonthlyPercent * 208) / 100));
    const pagoActualNo = this.monthDiff(new Date(user.promotions.start_promotion_date), new Date(user.promotions.updated_at)) - 1;
    const pagosTotal = this.monthDiff(new Date(user.promotions.start_promotion_date), new Date(user.promotions.expiration_date))
    const updatedDate = new Date(user.promotions.updated_at);
    const expiration_date = new Date(user.promotions.expiration_date);
    const TotalAcumulada = Math.round(((pagoActualNo * ventaMensualVol) - total_volume_progress) * 100) / 100

    const mesAnterior = new Date();
    mesAnterior.setMonth(d.getMonth() - 1);

    let tipoAmortizacion = "";

    switch (user.promotions.amortization_tipe) {
      case "01":
        tipoAmortizacion = "Efectivo";
        break;
      case "02":
        tipoAmortizacion = "% de Descuento";
        break;
      case "03":
        tipoAmortizacion = "Efectivo y % de Descuento";
        break;
      case "04":
        tipoAmortizacion = "$ por Caja";
        break;
      case "05":
        tipoAmortizacion = "$ por Caja y % de Descuento";
        break;
      case "06":
        tipoAmortizacion = "% de Descuento y Complemento";
        break;
      case "07":
        tipoAmortizacion = "Tiempo";
        break;
      case "08":
        tipoAmortizacion = "Volumen";
        break;
      case "09":
        tipoAmortizacion = "Tiempo y Volumen";
        break;
      case "10":
        tipoAmortizacion = "% de Compras";
        break;
    }

    return (
      <div className="container">
        <h2>Fomentos</h2>
        <div className={`row`}>
          <div className={`col-12 col-lg-4`}>
            {this.state.periodoactual ? (
              <MazTuInfoBeesAccountFomento
                sendGA={this.props.sendGA}
                className={styles.card + " card"}
                setPath={this.props.setPath}
                setBackButton={this.props.setBackButton}
                customerPromotions={user.promotions ? user.promotions : null}
              />
            ) : null}
            {!isMobile ? (
              <div className={`${styles.card} card mt-2 mb-3`}>
                <div className={styles.container__body}>
                  <button
                    className={`${styles.card_button} d-flex justify-content-center py-3`}
                    onClick={this.gotToPeriodoAnterior}
                  >{this.state.periodoactual !== false ? ("Consulta el periodo anterior") : ("Consulta el periodo actual")}</button>
                  <button
                    className={styles.card_button}
                    onClick={this.goToAyuda}
                  >¿Necesitas ayuda?</button>
                </div>
              </div>
            ) : null}
          </div>

          <div className={`col-12 col-lg-8`}>
            <div className={`row pb-lg-3`}>
              <div className={`col-lg-6`}>
                <div className={`${styles.card} card mt-2 mb-3 ${styles.height100}`}>

                  {/* {currentDebit > 0 ? ( */}
                  {/*customerAccountState && credit_limit > 0 ? (*/
                    <div className={styles.container__body}>

                      <div className={styles['container__body__row--group']}>
                        <span
                          className={styles.container__body__row}
                        >
                          <h3>Tipo de amortización:</h3>
                          <b>{tipoAmortizacion}</b>
                        </span>
                        <span
                          className={styles.container__body__row}
                        >
                          <h3>Venta mensual:</h3>
                          <b>{ventaMensualVol} cajas</b>
                        </span>
                        <span
                          className={`$ ${styles.container__body__row}`}
                        >
                          <h3>Fecha de vencimiento:</h3>
                          <b>{`${expiration_date.getDate()}/${expiration_date.getMonth() + 1}/${expiration_date.getFullYear()}`}</b>
                        </span>
                        <span
                          className={styles.container__body__row}
                        >
                          <h3>Venta total:</h3>
                          <b>{ventaTotalVol} cajas</b>
                        </span>
                        <span
                          className={styles.container__body__row}
                        >
                          <h3>Número de contrato:</h3>
                          <b>{user.promotions.promotion_no}</b>
                        </span>
                      </div>
                    </div>
                /*) : null*/}
                </div>
              </div>
              <div className={`col-lg-6`}>
                <div className={`${styles.card} card mt-2 mb-3 ${styles.height100}`}>
                  <div className={styles.container__body}>
                    <div className="py-3">
                      <div className={styles['container__body__row--group']}>
                        <div className={`${styles.progressCard}`}>
                          <span>Venta total acumulada</span>
                          <div className={`${styles.progress} mt-3`}>
                            <div
                              className={`${(accumulated_porcentile < 100 ? styles.progress__bar__orange : styles.progress__bar__green)} ${styles[`progress__bar__${accumulated_porcentile}`]}`}>
                              <span>{total_volume_progress}</span>
                            </div>
                            <span>
                              <h1>
                                0
                              </h1>
                              <h1>
                                {ventaTotalVol}
                              </h1>
                            </span>
                          </div>
                          <span><b>A {mesAnterior.toLocaleString('default', { month: 'long' })} {new Date(mesAnterior.getFullYear(), mesAnterior.getMonth() + 1, 0).getDate()}</b></span>
                          <span>Deberías llevar: {Math.round((TotalAcumulada + total_volume_progress) * 100) / 100} cajas</span>
                          <span>Llevas: {Math.max(0, total_volume_progress)} cajas</span>
                          <span>Te faltan: {Math.max(0, TotalAcumulada)} cajas</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.card} card mt-2 mb-3`}>
              <div className={styles.container__body}>
                <div className="py-3">
                  <div className={styles['container__body__row--group']}>
                    <span className={styles.right}><b>Pago {pagoActualNo} de {pagosTotal}</b></span>
                    <div className={styles.progressCard}>
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <span>Días restantes {d.toLocaleString('default', { month: 'long' })} {d.getFullYear}</span>
                          <div className={styles.progress}>
                            <div
                              className={`${(percentile < 100 ? styles.progress__bar__orange : styles.progress__bar__red)} ${styles[`progress__bar__${percentile}`]}`}>
                            </div>
                            <span>
                              <h1>
                                1
                              </h1>
                              <h1>
                                {days}
                              </h1>
                            </span>
                          </div>
                          {percentile < 100 ? (
                            <>
                              <b>¡Aún puedes lograrlo!</b><br></br>
                              <span>quedan {days - d.getDate()} días para poder completar tus metas</span>
                            </>
                          ) : null}
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className={`${styles.card} ${styles.card_circle} card mt-4 mb-3 pt-3`}>
                            <center><b>Tu venta este mes</b></center>
                            <div className={`${styles.progressBarCircle}  ${styles[`progressBarCircle__bar__${percentileVol2}`]}`}>
                              <span>{MonthlyPercent}%</span>
                            </div>
                            <center>Llevas: <b>{monthly_total_volume_progress} cajas</b></center>
                            <center>Te faltan: <b>{Math.max(0, Math.round((ventaMensualVol - monthly_total_volume_progress) * 100) / 100)} cajas</b></center>
                            <div className={`${(MonthlyPercent < 100 ? styles.card_bottom_red : styles.card_bottom_green)}`}>
                              {MonthlyPercent < 100 ? `¡Casi lo logras!` : `¡Lo lograste!`}
                            </div>
                          </div>
                        </div>
                        <div className={`col-12 mt-2`}>
                          *Información actualizada el {`${updatedDate.getDate()}/${updatedDate.getMonth() + 1}/${updatedDate.getFullYear()}`}
                        </div>
                        <div className={`col-12 mt-3`}>*Recuerda que la amortización de tu fomento, se realiza unica y exclusivamente de productos de cerveza que hayan sido suministrados en el período del mes inmediato anterior con excepción de los que se hayan vendido a precio de promoción</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile ? (
              <div className={`${styles.card} card mt-2 mb-3`}>
                <div className={styles.container__body}>
                  <button
                    className={`${styles.card_button} d-flex justify-content-center py-3`}
                    onClick={this.gotToPeriodoAnterior}
                  >{this.state.periodoactual !== false ? ("Consulta el periodo anterior") : ("Consulta el periodo actual")}</button>
                  <button
                    className={styles.card_button}
                    onClick={this.goToAyuda}
                  >¿Necesitas ayuda?</button>
                </div>
              </div>
            ): null}
            {this.state.periodoactual ? (
              <MazTuInfoBeesAccountFomentoInvoices
                sendGA={this.props.sendGA}
                className={styles.card + " card"}
                setPath={this.props.setPath}
                setBackButton={this.props.setBackButton}
                customerPromotions={user.promotions ? user.promotions : null}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}
