import React, { useMemo, useRef, useState, useEffect } from 'react';
import { financeCurrencyFormat, formatDateDDMMYYYYFinance, formatDateDDMMYYYY } from '../../../utils/utils';
import Icon from '../../global/Icon/Icon';
import MazTuInfoBeesIndicators from '../maz-tu-info-bees-indicators';
import DownloadDialog from './DownloadDialog';
import styles from './styles.module.scss';
import useInvoices from './useInvoices';

const MazTuInfoBeesBill = props => {
  const { data, user } = props;
  const [currentBill, setCurrentBill] = useState(undefined);
  const [downloadDialog, setDownloadDialog] = useState(false);
  const scrollRefs = useRef([]);

  const invoices = data.finance_invoices_with_detail;
  const bills = invoices;

  const activeIndicator = useMemo(() => (
    bills?.findIndex(({ invoice_no }) => invoice_no === currentBill?.invoice_no)
  ), [bills, currentBill]);

  useEffect(() => {
    if (!currentBill && bills?.length > 0) {
      setCurrentBill(bills[0]);
    }
  }, [bills, currentBill]);

  const handleClickBill = index => {
    if (bills) {
      setCurrentBill(bills[index]);
      scrollRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleClickDownload = () => {

    //props.sendGA('GAEvent', 'Content', 'Content', 'Transference', true, 'transference_button', 'Transferencia');
    //sendGATrap('Transferencia', 'Interaction', 'Click');
    setDownloadDialog(true);
  }

  return (
    <div className={styles.container}>

      <DownloadDialog
        user={user}
        open={downloadDialog}
        onClose={() => setDownloadDialog(false)}
      />

      <h1>Selecciona tu factura para ver el detalle</h1>

      {/* carousel */}
      <div
        className={styles.container__bills}
      >
        {bills?.map((bill, indexBill) => {

          const {
            total_order: total,
            invoice_no,
            invoice_date,
            invoice_due_date,
          } = bill;

          const fields = [{
            title: 'Tipo:',
            value: 'Factura',
          }, {
            title: 'No. factura:',
            value: invoice_no,
          }, {
            title: 'Factura generada:',
            value: formatDateDDMMYYYY(invoice_date),
          }, {
            title: 'Estatus de entrega:',
            value: 'Entregada',
          }, {
            title: 'Vence:',
            value: formatDateDDMMYYYY(invoice_due_date),
          }];

          const classActive = invoice_no === currentBill?.invoice_no ? styles.active : '';

          return (
            <div
              key={`card-${invoice_no}`}
              ref={el => scrollRefs.current[indexBill] = el}
              className={`${styles.card} ${classActive}`}
              onClick={() => handleClickBill(indexBill)}
            >
              <div className={styles.card__body}>
                <div>
                  {fields.map(field => {
                    const {
                      title,
                      value,
                      color,
                    } = field;
                    return (
                      <span key={`${invoice_no}-field-${title}`}>
                        <h1>{title}</h1>
                        <h2 style={{ color }}>{value}</h2>
                      </span>
                    );
                  })}
                </div>
                <div className={styles.check}>
                  <Icon
                    icon="check"
                    width="14"
                    height="14"
                    handleClick={() => {}}
                  />
                </div>
              </div>

              <div className={styles.footer}>
                <h1>Importe:</h1>
                <h1>{ financeCurrencyFormat(total)}</h1>
              </div>
            </div>
          );
        })}
      </div>

      <MazTuInfoBeesIndicators
        indexActive={activeIndicator}
        length={bills?.length}
        className={styles.indicators}
        onClick={handleClickBill}
      />

      {/* table */}
      <div className={styles.table}>
        <div className={styles.table__header}>
          <h1>Ctd</h1>
          <h1>Producto</h1>
          <h1>Total</h1>
        </div>
        {currentBill?.financeInvoiceDetails?.map(detail => {
          return (
            <div className={styles.table__row}>
              <div><h1>{parseFloat((detail?.product_quantity)).toFixed(2)}</h1></div>
              <div>
                <h2>{detail?.material_denomination}</h2>
                {detail?.description?<h3>{detail?.description}</h3>:null}
                <h2>{financeCurrencyFormat(parseFloat(detail?.total_price) / parseFloat(detail?.product_quantity))}</h2>
              </div>
              <div><h4>{financeCurrencyFormat(detail?.total_price)}</h4></div>
            </div>
          );
        })}
      </div>

      <div className={styles.totals}>
        <h2>Subtotal</h2>
        <h1>{financeCurrencyFormat(currentBill?.invoice_income_before_taxes)}</h1>
      </div>

      <div className={styles.totals}>
        <h2>Impuestos</h2>
        <h1>{financeCurrencyFormat(currentBill?.invoice_taxes)}</h1>
      </div>

      <div className={styles.totals}>
        <h2>Total</h2>
        <h1>{financeCurrencyFormat(currentBill?.total_order)}</h1>
      </div>
      {/*
        <div className="row">
          <button className={styles.button} onClick={handleClickDownload}>Descargar Factura</button>
        </div>
      */}
      
    </div>
  );
};

export default MazTuInfoBeesBill;
