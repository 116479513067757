import PropTypes from 'prop-types';

export const propTypes = {
  title: PropTypes.string,
  back: PropTypes.bool,
  onClickBack: PropTypes.func,
};

export const defaultProps = {
  title: '',
  back: false,
  onClickBack: null,
};
