import React, { Component } from "react";
import MazTuInfoBeesInput from "../../../components/maz-tu-info-bees/maz-tu-info-bees-input/MazTuInfoBeesInput";
import EditActionButton from "./EditActionButton";

import "./Resumen.scss";

export default class ResumenRow extends Component {

  state = {
    data: [],
    isEdit: false,
    inputValue: ""
  }

  setEditing = (isEdit) => {
    this.setState({ isEdit });
    this.props.setEditing({ [this.props.data?.subtitle]: isEdit });
    if (isEdit) this.handleSaveEdit();
  }

  handleSaveEdit = (newValue) => {
    const value = newValue || this.props.data.text;
    switch(this.props.data.subtitle) {
      case 'Numero de CURP/RFC':
        this.props.setDataRow({key: 'dataCURP', value});
        break;
      case 'Nombre cliente':
        this.props.setDataRow({key: 'dataName', value});
        break;
      default:
        console.log('default');
    }
  }

  setInputValue = (value) => {
    this.setState({
      inputValue: value
    });
  }

  render() {

    const data = this.props.data;

    return(
      <div className="resumen__datos__row">

        <div className="resumen__datos__row__label">

          {this.state.isEdit ?

            <>
              <MazTuInfoBeesInput
                textLabel={data.subtitle}
                type="text"
                value={data.text}
                setValue={this.setInputValue}
              />
            </>

            : <>
              <div className="resumen__datos__row__label__subtitle">
                {data.subtitle}
              </div>

              <div className="resumen__datos__row__label__text">
                {this.state.inputValue !== "" ? this.state.inputValue : data.text}
              </div>
            </>

          }

        </div>

        <div className="resumen__datos__row__actions">

          <EditActionButton
            hide={!data.edit}
            onSave={() => this.handleSaveEdit(this.state.inputValue)}
            editing={this.state.isEdit}
            setEditing={this.setEditing}
            newTextInput={this.state.inputValue}
          />

          <button className="resumen__datos__row__actions__button resumen__datos__row__actions__button--success" disabled></button>

        </div>

      </div>
    )
  }

}