import PropTypes from 'prop-types';

export const propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  handleToast: PropTypes.func,
};

export const defaultProps = {
  loading: false,
  setLoading: () => {},
  handleToast: () => {},
};
