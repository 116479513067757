import React from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Icon from '../../../components/global/Icon/Icon';
import Tutorial from '../Tutorial';
import { faqs, tutorials } from './faqsAndTutorials';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles.module.scss';
import './general.scss';

const pdfManualBEES = require('../../../assets/pdf/BEESFinance_ManualDeUsuario.pdf');

const Faqs = props => {
    const { setTutorial, setCurrentFaq, currentFaq, tutorial, user } = props;

  const handleClickTutorial = tutorial => {
    if (setTutorial) setTutorial(tutorial);
  }
  if (currentFaq && isMobile) {
    return (
      <div className={styles.container}>
      <div className={styles.faq_content}>
        <h1>{currentFaq.title}</h1>
          {typeof currentFaq.description === "object" ?

            currentFaq.description.map((curretObj, index) => {
              const { title, description, image } = curretObj;
              const controlName = `panel${index}a-content`;
              const idName = `panel${index}a-header`;
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={controlName}
                    id={idName}
                  >
                    <Typography>{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {image ? (
                        <div className={styles.container__image}>
                          <img
                            src={image}
                            alt={title}
                          />
                        </div>
                      ) : null}
                      <p>
                        <h2>{description}</h2>
                      </p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })
            :
            (
              <p><h2>{currentFaq.description}</h2></p>
            )
          }
      </div>
      </div>

    );
  }
  let currentFaq1 = currentFaq
 
  return (
    <>
    <div className={`container`}>
        <div className={`row`}>
          <div className={`col-12 col-lg-4`}>
            <div className={styles.container}>
              <div className={styles.banner}>
                <Icon
                  icon="support-globe"
                  width="45"
                  height="45"
                />
              </div>
              <div className={styles.body__header}>
                <div className="maz-tu-info-bees-header__logo" />
                <h1>{tutorial?.currentTitle || 'Ayuda'}</h1>
              </div>
            </div>
      <div className={`${styles.container} mt-3`}>
        <h1 className="p-3 m-0">Preguntas frecuentes</h1>
        {faqs.map((faq, index) => {

          const { title, description } = faq;
          if (!currentFaq) {
            currentFaq1 = {
              title: title,
              description: description
            }
            }
            if (!user.promotions && title == "Problemas con mi fomento")
                return null
            else 
          return (
          <div
            key={`faq-${index}`}
            className={styles.row}
            onClick={() => setCurrentFaq(faq)}
          >
            <h2>{title}</h2>
            <Icon
              icon="arrow-right"
              width="18"
              height="18"
              handleClick={() => setCurrentFaq(faq)}
            />
          </div>
        )})}
      </div>

      <div className={`${styles.container} mt-3`}>
      <h1 className="p-3 m-0">Tutoriales</h1>
      {tutorials.map((tutorial, index) => {
        const { title } = tutorial;
        if (!user.promotions && title == "Fomentos")
          return null
        else 
        return (
          <div
            key={`tuto-${index}`}
            className={styles.row}
            onClick={() => handleClickTutorial(tutorial)}
          >
            <h2>{title}</h2>
            <Icon
              icon="arrow-right"
              width="18"
              height="18"
              handleClick={() => handleClickTutorial(tutorial)}
            />
          </div>
        );
      })}
       <div
          key={`tuto-${'manual'}`}
          className={styles.row}
        >
          <h2><a href={pdfManualBEES} target={`_blank`}>Consulta tu manual de usuario</a> </h2>

        </div>
            </div>
          </div>
          {!isMobile ?
            (
              <div className={`col-lg-8`}>
                <div className={`${styles.container} ${styles.container_h100}`}>
                  <div className={`${styles.faq_content} faq`}>
                    {tutorial ? (
                      <Tutorial
                        tutorial={tutorial}
                        setTutorial={setTutorial}
                      />
                    ) : (
                      <>
                      <h1>{currentFaq1.title}</h1>
                          {typeof currentFaq1.description === "object" ?

                            currentFaq1.description.map((curretObj, index) => {
                              const { title, description, image } = curretObj;
                              const controlName = `panel${index}a-content`;
                              const idName = `panel${index}a-header`;
                              return (
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={controlName}
                                    id={idName}
                                  >
                                    <Typography>{title}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      {image ? (
                                        <div className={styles.container__image}>
                                          <img
                                            src={image}
                                            alt={title}
                                          />
                                        </div>
                                      ) : null}
                                      <p>
                                        <h2>{description}</h2>
                                      </p>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })
                            :
                            (
                              <p><h2>{currentFaq1.description}</h2></p>
                            )
                          }
                      </>
                    )}
                  </div>
                </div>
              </div>
            ): null
          }
          
      </div>
      </div>
    </>
  );
};

export default Faqs;
