import PropTypes from 'prop-types';

export const propTypes = {
  page: PropTypes.string,
  handleProcess: PropTypes.func,
  data: PropTypes.any,
  setLoaderOpen: PropTypes.func,
  handleAlert: PropTypes.func,
  setCallbackAlertClose: PropTypes.func,
  setCallbackAlertCancel: PropTypes.func,
};

export const defaultProps = {
  page: '',
  handleProcess: () => {},
  data: null,
  setLoaderOpen: () => {},
  handleAlert: () => {},
  setCallbackAlertClose: () => {},
  setCallbackAlertCancel: () => {},
};
