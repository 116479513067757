import PropTypes from 'prop-types';

const programType = PropTypes.shape({
  brochure: PropTypes.string,
  name: PropTypes.string.isRequired,
  privacyDocument: PropTypes.string,
  termSheet: PropTypes.string,
})

export const propTypes = {
  program: programType.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export const defaultProps = {
  onCancel: null,
  onSubmit: null,
  loading: false,
  setLoading: () => {},
};
