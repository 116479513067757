import { useCallback, useEffect, useMemo, useState } from "react";
import { getVarConfig } from "../../../../utils/environment";
import fetchAPI from "../../../../utils/fetchAPI";

const useTermsForm = props => {
  const {
    terms,
    onSubmit,
  } = props;
  const [termsData, setTermsData] = useState(undefined);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const requireFields = useMemo(() => [
  'brochure',
  'termsSheet',
  ], []);

  /* Checking if the form is valid. */
  const validForm = useMemo(() => !!requireFields
    .reduce((total, field) => {
      if (termsData) {
        let hasValue = !!termsData[field];
        // if there isn't brochure take value from file
        if (field === 'brochure' && !hasValue) hasValue = !!file;
        return total * hasValue;
      }
      return false;
    }, true), [requireFields, termsData, file]);

  const onChangeValue = useCallback((value, fieldName) => {
    if (fieldName === 'brochure' && !value){
      setFile(null);
    }

    setTermsData({
      ...termsData,
      [fieldName]: value,
    })
  }, [termsData]);

  const saveNewTerms = () => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_TERMS" });
    const customBody = new FormData();
    customBody.append('data', JSON.stringify(termsData));
    customBody.append('file', file);
    setIsLoading(true);
    fetchAPI({
      customBody,
      noContentType: true,
      service,
      protocol: "POST",
      callbackThen: () => {
        setIsLoading(false);
        if (onSubmit) onSubmit(termsData);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  };

  const updateTerms = () => {
    const service = getVarConfig({ key: "REACT_APP_SERVICES_TERMS" });
    const customBody = new FormData();
    customBody.append('data', JSON.stringify(termsData));

    if (file) customBody.append('file', file);

    setIsLoading(true);
    fetchAPI({
      customBody,
      noContentType: true,
      service,
      protocol: "PUT",
      callbackThen: () => {
        setIsLoading(false);
        if (onSubmit) onSubmit(termsData);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  };

  const handleSubmit = () => {
    setOpenConfirm(false);
    if (terms) {
      updateTerms();
    } else {
      saveNewTerms();
    }
  }

  useEffect(() => {
    if (terms && !termsData) {
      setTermsData(terms);
    }
  }, [terms, termsData]);

  return {
    validForm,
    handleSubmit,
    openConfirm,
    setOpenConfirm,
    isLoading,
    termsData,
    file,
    setFile,
    onChangeValue,
  }
}

export default useTermsForm;
