import React, { Component } from "react";
import MazTuInfoBeesButton from "../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";

import "./Desafios.scss";

import MazTuInfoBeesCardChallenge from "./../../components/maz-tu-info-bees/maz-tu-info-bees-card-challenge/MazTuInfoBeesCardChallenge";
import MazTuInfoBeesResumeTotals from "../../components/maz-tu-info-bees/maz-tu-info-bees-resume-totals/MazTuInfoBeesResumeTotals";
import MazTuInfoBeesCardBrands from "../../components/maz-tu-info-bees/maz-tu-info-bees-card-brands/MazTuInfoBeesCardBrands";

import { getUser } from "../../utils/sessionInfo";
import { getVarConfig } from "../../utils/environment";

const imgPremium = require('../../assets/images/img-premium.png');

export default class Desafios extends Component {

  handleBuyOnBees = () => {
    window.open("https://mybees.mx/categories?categoryId=5ffe520e26b93207e8538998", "_blank");
  }

  render() {

    const nameVolumenTotal = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_VOLUMEN_TOTAL" });
    const nameMarcasPremium = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_MARCAS_PREMIUM" });
    const nameCobertura = getVarConfig({ key: "REACT_APP_LITERAL_CHALLENGE_COBERTURA" });

    const user = getUser();
    const dataChallenges = this.props.challenges;

    let classClub;

    switch(user.clubLevel) {
      case 'CLUB B BLACK':
        classClub = ' black';
        break;
      case 'CLUB B BLACK ZONAS':
        classClub = ' black-zonas';
        break;
      case 'CLUB B BLACK PREMIUM':
        classClub = ' black-premium';
        break;
      case 'CLUB B BLACK MIXTO':
        classClub = ' mixto';
        break;
      case 'CLUB B BLACK PREMIUM MIXTO':
        classClub = ' mixto-premium';
        break;
      case 'B_MAX':
        classClub = ' b-max';
        break;
      case 'B_BLACK':
        classClub = ' b-black';
        break;
      default:
        console.log('default');
    }

    let labelTitle, classLabel;

    switch (this.props.typeDesafio) {
      case "volumen":
        labelTitle = nameVolumenTotal;
        classLabel = "orange";
        break;
      case "cerveza-premium":
        labelTitle = nameMarcasPremium;
        classLabel = "green";
        break;
      case "cobertura":
        labelTitle = nameCobertura;
        classLabel = "blue";
        break;
      default:
        console.log("default");
    }

    let countIncomplete = 0, countComplete = 0, countAccepted = 0;

    for(let challenge in dataChallenges) {
      if(dataChallenges[challenge].challengeAccepted === 1) {
        countAccepted = countAccepted + 1;
        if(dataChallenges[challenge].goalReached) {
          countComplete = countComplete + 1;
        } else {
          countIncomplete = countIncomplete + 1;
        }
      } else {
        countIncomplete = countIncomplete + 1;
      }
    }

    const totals = [
      {
        label: "Desafios completados",
        value: countComplete,
        isTotal: false
      },
      {
        label: "Desafios incompletos",
        value: countIncomplete,
        isTotal: false
      },
      {
        label: "Total aceptados",
        value: countAccepted,
        isTotal: true
      }
    ];

    const keyChallenge = 
      this.props?.typeDesafio === "cerveza-premium" ? "premium" : 
      this.props?.typeDesafio === "cobertura" ? "coverage" :
      this.props?.typeDesafio === "volumen" ? "total" : null;

    const realBoxes = typeof this.props?.performance !== "undefined" ? this.props?.performance[keyChallenge].real : 0;
    const goalBoxes = typeof this.props?.performance !== "undefined" ? this.props?.performance[keyChallenge].goal : 0;

    return (
      <div className="desafios">

        <div className={`desafios__logo-nivel black` + classClub}></div>

        <div className="desafios__title">Desaf&iacute;os</div>

        <div className={`desafios__label desafios__label--${classLabel}`}>
          {labelTitle}
        </div>

        {
          typeof dataChallenges !== "undefined" && dataChallenges.length > 0?
            dataChallenges.map((card, index) => (
              <>
                <MazTuInfoBeesCardChallenge
                  description={card.challenge.name}
                  goalReached={card.goalReached}
                  real={card.real}
                  goal={card.goal}
                  image={card.challenge.image}
                  points={card.goalPoints}
                  deadline={card.dueDate}
                  accepted={card.challengeAccepted === 1}
                  desafioID={card.challengeId}
                  desafioType={card.challenge.type}
                  desafio={this.props.typeDesafio}
                  user={this.props.user}
                />
                <MazTuInfoBeesResumeTotals
                  totals={totals}
                />
              </>
            )) 
            : <>
                <MazTuInfoBeesCardBrands 
                  desafio={this.props.typeDesafio}
                  performance={this.props.performance}
                />

                {realBoxes < goalBoxes 
                  ? <MazTuInfoBeesButton 
                      textButton={"COMPRAR EN BEES"} 
                      type="secondary"
                      handleClick={this.handleBuyOnBees} /> : null
                }

                {this.props.typeDesafio !== "volumen" 
                  ? <img className="desafios__image" src={imgPremium} alt="Imagen"/> : null
                }
              </>
        }

      </div>
    );
  }
  
}
