import PropTypes from 'prop-types';

export const defaultProps = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  handleToast: PropTypes.func,
};

export const propTypes = {
  title: '',
  loading: false,
  setLoading: () => {},
  handleToast: () => {},
};
