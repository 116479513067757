import React, { Component } from "react";

import "./MazTuInfoBeesCardBrands.scss";

const imgDesafiosCervezas = require('./assets/image-challenge-premium.jpg');
const imgDesafiosCobertura = require('./assets/image-challenge-coverage.jpg');
const imgDesafiosVolumen = require('./assets/image-challenge-total.jpg');

export default class MazTuInfoBeesCardBrands extends Component {
  render() {

    const desafio =
      typeof this.props.desafio !== "undefined" ? this.props.desafio : "";
      
    const image = 
      desafio === "cerveza-premium" ? imgDesafiosCervezas : 
      desafio === "cobertura" ? imgDesafiosCobertura :
      desafio === "volumen" ? imgDesafiosVolumen : null;

    let dataPerformance;

    if(typeof this.props.performance !== "undefined") {
      dataPerformance = 
        desafio === "cerveza-premium" ? this.props.performance.premium : 
        desafio === "cobertura" ? this.props.performance.coverage :
        desafio === "volumen" ? this.props.performance.total : null;
    } else {
      dataPerformance = {
        real: 0,
        goal: 0,
        points: 0
      }
    }

    let date = new Date();
    const [month, year] = [date.getMonth(), date.getFullYear()];
    var d = new Date(year, month + 1, 0);

    const dataBrand = {
      real: dataPerformance !== null && dataPerformance.real !== null ? dataPerformance.real.toLocaleString() : 0,
      goal: dataPerformance !== null && dataPerformance.goal !== null ? dataPerformance.goal.toLocaleString() : 0,
      dateStart: "01/" + (month + 1) + "/" + year,
      dateEnd: d.getDate() + "/" + (month + 1) + "/" + year,
      points: dataPerformance !== null ? dataPerformance.points.toLocaleString() : 0,
    }

    return (
      <div className="maz-tu-info-bees-card-brands">
        <div
          className={
            `maz-tu-info-bees-card-brands__title maz-tu-info-bees-card-brands__title--` +
            desafio
          }
        >
          Marcas participantes
        </div>

        <div className={"maz-tu-info-bees-card-brands__image maz-tu-info-bees-card-brands__image--" + desafio}>
          <img
            src={image}
            alt={"Imagen Desafíos " + desafio}
          />
        </div>

        <div className="maz-tu-info-bees-card-brands__goal">

          <div className="maz-tu-info-bees-card-brands__goal__title">
            Llevas
          </div>

          <div className="maz-tu-info-bees-card-brands__goal__quantity">
            <span>{dataBrand.real}</span> de <span>{dataBrand.goal}</span>
          </div>
          
          <div className="maz-tu-info-bees-card-brands__goal__label">
            Cartones
          </div>

        </div>

        <div className="maz-tu-info-bees-card-brands__deadline">
          <div className="maz-tu-info-bees-card-brands__deadline__label">
            Vigencia meta
          </div>
          <div className="maz-tu-info-bees-card-brands__deadline__dates">
            {dataBrand.dateStart} al {dataBrand.dateEnd}
          </div>
        </div>

        <div className="maz-tu-info-bees-card-brands__points">
          Puedes ganar hasta <span>{dataBrand.points}</span> puntos BEES 
        </div>

      </div>
    );
  }
}
