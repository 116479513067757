const encondeValue = (value = '') => {
  return encodeURIComponent(JSON.stringify(value));
}

export const setCookie = (name, value, options = { expires: new Date() }) => {
  const expiresDate = new Date(options.expires).toUTCString();
  const cookieOptions = `Expires=${expiresDate}; path=${options.path || '/'}; secure=${options.secure || true};`;
  document.cookie = `${name}=${encondeValue(value)};${cookieOptions}`;
};

export const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === name) {
      return JSON.parse(decodeURIComponent(cookieValue));
    }
  }
  return null;
};

export const updateCookie = (name, value, options) => {
  setCookie(name, value, options);
};

export const deleteCookie = (name, options) => {
  setCookie(name, '', { expires: -1, ...options });
};

export const cleanCookiesAndReload = () => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const parts = cookie.trim().split('=');
    const name = parts.shift();
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  }

  window.location.reload(); // Reload the page
}
