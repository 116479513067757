import React from "react";
import styles from './styles.module.scss';
import { defaultProps, propTypes } from "./types";

const RowResume = props => {
  const { label, points, highlighted } = props;

  const Pointer = () => {
    return (
      <div className={styles.triangleGroup}>
        <div className={styles.triangleUp}></div>
        <div className={styles.triangleUp}></div>
        <div className={styles.triangleUp}></div>
      </div>
    )
  };

  return (
    <ul className={styles.container}>
      <div>{label}</div>
      <div className={`${styles.bold} ${highlighted && styles.highlighted}`}>
        {points}
        {highlighted && <Pointer />}
      </div>
    </ul>
  )
}

RowResume.propTypes = propTypes;
RowResume.defaultProps = defaultProps;

export default RowResume;
