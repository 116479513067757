import PropTypes from 'prop-types';

export const defaultProps = {
  hide: false,
  value: '',
  setValue: () => {},
};

export const propTypes = {
  hide: PropTypes.bool,
  value: PropTypes.number,
  setValue: PropTypes.func,
};
