import React, { Component } from "react";
import MazTuInfoBeesButton from "../../../components/maz-tu-info-bees/maz-tu-info-bees-button/MazTuInfoBeesButton";
import MazTuInfoBeesTitle from "../../../components/maz-tu-info-bees/maz-tu-info-bees-title/MazTuInfoBeesTitle";
import MazTuInfoBeesModalAction from "../../../components/maz-tu-info-bees/maz-tu-info-bees-modal-action/MazTuInfoBeesModalAction";

import "./Brochuere.scss";
import { getVarConfig } from "../../../utils/environment";
import fetchAPI from "../../../utils/fetchAPI";
import { getUser } from '../../../utils/sessionInfo';
import { once } from "../../../utils/events";

const imageBrouchere = require('./../assets/image-brouchere-3.jpg');

export default class Brochuere extends Component {

  state = {
    showTerms: false,
    showPrivacy: false,
    personaType: "fisica",
    urlImageBrochure: imageBrouchere,
    terms: "",
    privacy: ""
  }

  componentDidMount() {
    this.setState({
      showTerms: false,
      showPrivacy: false,
    });
    this.getTerms();

    const handleProcess = this.props.handleProcess;
    once("click-back-navigation-programa-mercado", function(event) {
      handleProcess({
        protocol: "PATCH",
        params: {
          transition: "returnToInitial"
        }
      });
    });
  }

  getTerms = () => {

    const user = getUser();

    fetchAPI({
      service: "v1/mx/programs/terms/"  + user.initiative,
      protocol: "GET",
      callbackThen: (response) => {
        console.log(response);
        try {
          this.setState({
            urlImageBrochure: response.data.brochure || imageBrouchere,
            terms: decodeURIComponent(response.data.termSheet),
            privacy: decodeURIComponent(response.data.privacyDocument),
          });
        } catch(e) {
          this.setState({
            urlImageBrochure: imageBrouchere,
          });
        }
      },
      callbackError: (status) => {
        console.log(status);
      },

    });
  }

  handleOpenTerms = () => {
    this.setState({
      showTerms: true
    });
  }

  handleCloseTerms = () => {
    this.setState({
      showTerms: false
    });
  }

  handleOpenPrivacy = () => {
    this.setState({
      showPrivacy: true
    });
  }

  handleClosePrivacy = () => {
    this.setState({
      showPrivacy: false
    });
  }

  handleContinue = () => {

    try {
      this.props.handleProcess({
        protocol: "PATCH",
        params: {
          transition: "acceptTerms",
          stepData: {
              termsAccepted: true ,
              privacyAgreementAccepted: true
          }
        }
      });
    } catch(e) {
      console.log(e);
    }

  }

  render() {
    return(
      <div className="brochuere">

        <MazTuInfoBeesTitle title="¡Has sido seleccionado!" />

        <div className="brochuere__image">
          <img 
            crossorigin="anonymous"
            src={this.state.urlImageBrochure} 
            alt="Brouchere detail" />
        </div>

        <MazTuInfoBeesButton 
          type="primary-outline" 
          textButton="VER TÉRMINOS Y CONDICIONES"
          handleClick={this.handleOpenTerms} />
          
        <MazTuInfoBeesButton 
          textButton="ACEPTAR TÉRMINOS Y CONDICIONES"
          handleClick={this.handleOpenPrivacy} />

        <MazTuInfoBeesModalAction 
          show={this.state.showTerms}
          terms={this.state.terms}
          title="TÉRMINOS Y CONDICIONES"
          buttonAcceptText="ENTENDIDO"
          handleClickAccept={this.handleCloseTerms} />

        <MazTuInfoBeesModalAction 
          show={this.state.showPrivacy}
          title="AVISO DE PRIVACIDAD"
          checkboxText="He leído el aviso de privacidad y estoy de acuerdo con la recopilación de mis datos"
          buttonAcceptText="CONTINUAR"
          terms={this.state.privacy}
          showCancelButton
          handleClickCancel={this.handleClosePrivacy}
          showAcceptTerms 
          handleClickAccept={this.handleContinue} />

      </div>
    )
  }

}