import React from "react";
import MazTuInfoBeesInput from "../maz-tu-info-bees-input/MazTuInfoBeesInput";
import { defaultProps, propTypes } from "./types";
import useGoalOptions from "./useGoalOptions";
import InputCustomerGoal from "../../../pages/ProgramaMercado/InitialResume/InputCustomerGoal";

const MazTuInfoBeesGoalOptions = (props) => {
  const { onChange, input: { value, setValue, hide } } = props;
  const { customerGoalOptions, customChoice, isLoading } = useGoalOptions();
  const customChoiceAvailable = customerGoalOptions?.length === 1 ? {} : { key: 'custom', value: 'Otra' };

  if(isLoading) return <p>Cargando información</p>;
  if (0 < customerGoalOptions?.length) {
    return (
      <>
        <MazTuInfoBeesInput
          textLabel="Meta próximos 3 meses"
          id="sel-tipo-meta"
          type="select"
          options={[...customerGoalOptions, customChoiceAvailable]}
          setValue={onChange}
          textPlaceHolder="Seleccione su meta mensual"
        />
        <InputCustomerGoal
          value={value}
          setValue={(val) => {
            setValue(val, customChoice);
          }}
          hide={hide}
          min={customChoice?.min}
          max={customChoice?.max}
        />
      </>
    );
  }
  return null;
};

MazTuInfoBeesGoalOptions.propTypes = propTypes;
MazTuInfoBeesGoalOptions.defaultProps = defaultProps;

export default MazTuInfoBeesGoalOptions;
