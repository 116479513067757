import React, { useState } from 'react';
import styles from './styles.module.scss';

const ButtonsNav = props => {
  const {
    onClick,
    options,
    activeOption,
    setActiveOption,
  } = props;

  const handleClick = (activeNav) => {
    activeNav.toLowerCase();
    if (typeof onClick === 'function') onClick({ activeNav });
    setActiveOption(activeNav);
  }

  return (
    <div  className={styles.container}>
      <div>
        {options?.map((optionTitle, index) => {
          return (
            <>
              <button
                onClick={() => handleClick(optionTitle)}
                className={activeOption === optionTitle ? styles.active : null}
              >
                {optionTitle}
              </button>
              {(index < options.length - 1) ? <h1>|</h1> : null}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonsNav;
