const COLUMNS = [{
  text: 'Cliente No.',
  key: 'customer_no'
},{
  text: 'Iniciativa',
  key: 'initiative'
}, {
  text: 'No. de contrato',
  key: 'trato_id'
}, {
  text: 'POC',
  key: 'name'
}, {
  text: 'Estado del contrato',
  key: 'statusTrato'
}, {
  text: 'Nombre del cliente',
  key: 'legalrep'
}, {
  text: 'Estatus de firma',
  key: 'signStatus'
}, {
  text: 'Meta seleccionada',
  key: 'goalSelected'
}];

const noActionHeaders = ['contract', 'document_id', 'acta', 'rfc'];

const isInNoActionHeaders = (key) => {
  return noActionHeaders.includes(key);
}


const IDENTITY_DOCUMENT_TYPE = {
  'ine': 'ine',
  'passport': 'passport',
  'driving_license': 'driving_license'
};

const CONTRACT_STATUS = {
  '': 'Borrador',
  RECEIVED_FILLED: 'Pendiente de validar',
  SENT_TO_FILL: 'Pendiente de completar',
  NEGOTIATION: 'En negociacion',
  APPROVED: 'Aprobado',
  SENT_TO_SIGN: 'Pendiente de firmar',
  EXPIRED: 'Vencido',
  Expired: 'Vencido',
  AUTHORIZE: 'Pendiente de completar',
  RECEIVED_SIGNED: 'Firmado',
  NEGOTIATED: 'Negociado',
  CANCELED: 'Cancelado',
  SENT_TO_DOWNLOAD: 'Enviado a participantes',
  FINALIZED: 'Finalizado',
  SENT_TO_ACCEPT: 'Enviado a aceptacion',
  SENT_SIGNED: 'Firmado',
  REJECTED: 'Rechazado',
  WAITING_LEGAL_APPROVE: 'En aprobacion legal',
  WAITING_MANAGER_SIGN: 'En Firma Gerente Administrativo',
  WAITING_GENERAL_MANAGER_SIGN: 'En Firma Gerente General',
  CAPTURE: 'Captura',
  VALIDATING: 'Validacion',
  ATTORNEY: 'Apoderados',
  ACTIVE: 'Vigente',
};

const getDocumentDataByType = (documents, documentType) => {
  if ((documents && documentType) && 0 < documents.length) {
    const data = documents.filter(({ type }) => (type === documentType));
    if (0 < data.length) return data;
  }
  return [{ url: null }];
}

const getIdentityDocument = (documents) => {
  if ((documents) && 0 < documents.length) {
    const data = documents.filter(({ type }) => (IDENTITY_DOCUMENT_TYPE[type]));
    if (0 < data.length) return data;
  }
  return [{ url: null }];
}

const getActaData = (documents) => getDocumentDataByType(documents, 'actaconstitutiva');
const getRfcData = (documents) => getDocumentDataByType(documents, 'rfc');
const getContractData = (documents) => getDocumentDataByType(documents, 'contract');

const getDocumentsObj = (documents) => {
  return {
    identityDocument: getIdentityDocument(documents),
    contract: getContractData(documents),
    acta: getActaData(documents),
    rfc: getRfcData(documents),
  }
}

const getContractStatus = (signStatus, statusTrato) => {
  if (statusTrato === 'Expired') return CONTRACT_STATUS.EXPIRED;
  if (statusTrato === 'FINALIZED') return CONTRACT_STATUS.ACTIVE;
  if (statusTrato === 'CANCELED') return CONTRACT_STATUS.CANCELED;
  switch (signStatus) {
    case 'Firmado por cliente':
      if (statusTrato === 'APPROVED') return CONTRACT_STATUS.ATTORNEY;
      return CONTRACT_STATUS.VALIDATING;
    case 'Firmado por gerente de unidad':
      if (statusTrato === 'SENT_TO_SIGN') return CONTRACT_STATUS.ATTORNEY;
      return CONTRACT_STATUS.ACTIVE;
    case 'Firmado por gerente regional':
      return CONTRACT_STATUS.ACTIVE;
    case 'Sin Firmas':
      return CONTRACT_STATUS.CAPTURE
    default:
      return CONTRACT_STATUS[statusTrato] || signStatus;
  }
}

const getContractSignStatus = (signStatus, statusTrato) => {
  if (statusTrato === 'Expired') return CONTRACT_STATUS.EXPIRED;
  if (statusTrato === 'FINALIZED') return CONTRACT_STATUS.FINALIZED;
  if (statusTrato === 'CANCELED') return CONTRACT_STATUS.CANCELED;
  switch (signStatus) {
    case 'Firmado por cliente':
      if (statusTrato === 'APPROVED') return CONTRACT_STATUS.WAITING_MANAGER_SIGN;
      return CONTRACT_STATUS.WAITING_LEGAL_APPROVE;
    case 'Firmado por gerente de unidad':
      if (statusTrato === 'SENT_TO_SIGN') return CONTRACT_STATUS.WAITING_GENERAL_MANAGER_SIGN;
      return CONTRACT_STATUS.SENT_SIGNED;
    case 'Firmado por gerente regional':
      if (statusTrato === 'FINALIZED') return CONTRACT_STATUS.FINALIZED;
      return CONTRACT_STATUS.SENT_SIGNED;
    case 'Sin Firmas':
      return signStatus;
    default:
      return CONTRACT_STATUS[statusTrato] || signStatus;
  }
}

export {
  COLUMNS,
  IDENTITY_DOCUMENT_TYPE,
  CONTRACT_STATUS,
  isInNoActionHeaders,
  getDocumentsObj,
  getContractSignStatus,
  getContractStatus,
};
