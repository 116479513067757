import { useCallback, useEffect, useState } from "react";
import fetchAPI from "../../../utils/fetchAPI";
import useTable from "../../../utils/hooks/useTable";


const useDetailsTable = (id) => {
  const {
    setList,
    list,
    sortByKey,
    sortDescStates,
  } = useTable();
  const [isLoading, setIsLoading] = useState(false);

  const getDetailedHistory = useCallback((historyId) => {
    setIsLoading(true);
    fetchAPI({
      service: 'v1/mx/massive_load/history/' + historyId,
      protocol: "GET",
      callbackThen: ({ data }) => {
        const { details } = data || {};
        setList(details || []);
        setIsLoading(false);
      },
      callbackCatch: err => {
        setIsLoading(false);
        throw new Error(err);
      },
    });
  }, [setList]);

  useEffect(() => {
    if (id) {
      getDetailedHistory(id);
    }
  }, [getDetailedHistory, id])

  return {
    list,
    setList,
    isLoading,
    getDetailedHistory,
    sortByKey,
    sortDescStates,
  };
};

export default useDetailsTable;
