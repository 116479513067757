import { useCallback, useEffect, useState } from "react";
import { getVarConfig } from "../../../utils/environment";
import fetchAPI from "../../../utils/fetchAPI";
import { getUser } from "../../../utils/sessionInfo";

const buildPair = (value) => {
  if (!value) return null;
  return { key: value, value };
};

const parseData = (levelDetails) => {
  if (!levelDetails) return;
  return [...levelDetails].map(({ goal }) => buildPair(goal)).filter((val) => val);
};

const useGoalOptions = () => {
  const [customerGoalOptions, setCustomerGoalOptions] = useState([]);
  const [customChoice, setCustomChoice] = useState({min: 99999, max: 0});
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomerGoalPoints = useCallback(() => {
    const { ID: customerId } = getUser();
    if (customerId) {
      const service = `${getVarConfig({ key: "REACT_APP_CUSTOMERS_SERVICES" })}/${customerId}/goalsDetail` || '';
      setIsLoading(true);
      fetchAPI({
        service,
        protocol: "GET",
        callbackThen: (response) => {
          const newCustumerGoalOptions = parseData(response?.data?.levelDetails);
          setCustomerGoalOptions(newCustumerGoalOptions);
          const newCustomChoice = {
            min: response?.data?.levelDetails[0]?.goal,
            max: response?.data?.maxLevel?.maxLimit,
          }
          setCustomChoice(newCustomChoice);
          setIsLoading(false);
        },
        callbackCatch: err => {
          setIsLoading(false);
          throw new Error(err);
        },
      });
    }
  }, []);

  useEffect(() => fetchCustomerGoalPoints(), [fetchCustomerGoalPoints]);

  return {
    customerGoalOptions,
    isLoading,
    customChoice,
  }
}

export default useGoalOptions;
