import React, { Component } from "react";
import { MsalContext } from "@azure/msal-react";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import AdminMain from "../AdminMain";
import LoginTemplate from "./LoginTemplate";

export default class LoginAdmin extends Component {

  static contextType = MsalContext;

  render() {

    const msalInstance = this.context.instance;
    return(
      <div className="login-admin">
        <AuthenticatedTemplate>
            <AdminMain />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <LoginTemplate
              onClick={() => msalInstance.loginRedirect()}
            />
        </UnauthenticatedTemplate>
      </div>
    )

  }

}
