import React, { useEffect, useState } from 'react';

import Header from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/Header';
import UenGoalsTable from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/UenGoalsTable';
import BandButton from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/BandButton';
import UenGoalsCsvFileButton from '../../../components/maz-tu-info-bees/maz-tu-info-bees-admin/UenGoalsCsvFileButton';

import styles from './styles.module.scss';
import { defaultProps, propTypes } from './types';

const UenGoals = props => {
  const { setLoading, loading, handleToast } = props;
  const [postingFile, setPostingFile] = useState(false);

  useEffect(() => {
    if (postingFile && !loading) setLoading(true);
  }, [postingFile, loading, setLoading]);

  useEffect(() => {
    if (!postingFile && loading) setLoading(false);
  }, [postingFile, loading, setLoading]);

  return (
    <div className={styles.container}>
      <Header
        title="Metas UEN"
        userName="User Name"
        back={false}
      />

      <div className={styles.container__body}>
        <UenGoalsCsvFileButton
          handleToast={handleToast}
          setLoading={setPostingFile}
          buttonProps={{
            className: `${styles.btn} ${styles['btn--black']}`,
          }}
        />

        <div className={styles.container__body__padding}>
          <BandButton
            title="Metas"
            color="yellow"
            colorIcon="yellow"
          />

          {!postingFile && (
            <UenGoalsTable
              setLoading={setLoading}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

UenGoals.prototype = propTypes;
UenGoals.defaultProps = defaultProps;

export default UenGoals;
